import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const RequestedTemperatureInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const getValue = () => contextModal.compromiseTemperature

  return (
    <>
      <div>Temp. Compromiso:</div>
      <Input disabled value={ getValue() } />
    </>
  )
}

export { RequestedTemperatureInput }
