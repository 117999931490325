import React from 'react'
import { Radio } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const DoubleTankRadio = () => {
  const context = usePlantsContext()
  const doubleTankHanlder = createInputValueHandler(context.setDoubleTank)

  return (
    <div className='field'>
      <label>Carga TQ x TQ en full:</label>
      <div className='radio-group'>
        <Radio
          value={ 1 }
          checked={ context.doubleTank === 1 }
          onChange={ doubleTankHanlder }
          label='Sí'
        />
        <Radio
          value={ 0 }
          checked={ context.doubleTank === 0 }
          onChange={ doubleTankHanlder }
          label='No'
        />
      </div>
    </div>
  )
}

export { DoubleTankRadio }
