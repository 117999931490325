import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Button, Icon } from 'semantic-ui-react'

const AddButton = () => {
  const context = useUploadEvidenceContext()

  return (
    <Button
      className='add-button'
      loading={ context.isWorking }
      onClick={ context.add }
    >
      <Icon name="plus"/>
    </Button>
  )
}

export { AddButton }
