import React, { useState } from 'react'
import { useDailyStatusContext } from '../../contexts/DailyStatusContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'transportista',      title: 'Transportista' },
  { path: 'economico',          title: 'Económico' },
  { path: 'fecha',              title: 'Fecha' },
  { path: 'estado',             title: 'Estado' },
  { path: 'motivoNoDisponible', title: 'Motivo ND' }
]

const searchBy = [
  'transportista',
  'economico',
  'estado',
  'motivoNoDisponible'
]
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useDailyStatusContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleSort = (data, e) => {
    context.setUnits(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  return (
    <div className="table">
      <StyledTable
        searchBy={ searchBy }
        data={ context.units }
        headers={ headers }
        headerSearchBy={ headerSearchBy }
        handleOnClickRow={ () => {} }
        handleOnClickHeader={ e => handleSort(context.units, e) }
      />
    </div>
  )
}

export { Table }
