import React from 'react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const DownloadPDFButton = ({ evidenciaUid, name }) => {
  const context = useAuthorizedFreightsContext()

  const handlePDF = async () => {
    context.setPDFUId(evidenciaUid, name)
    context.downloadPDF(evidenciaUid, name)
  }

  return (
    <p onClick={ handlePDF } >
      <strong style={ { textDecorationLine: 'underline' } }>
        Descargar
      </strong>
    </p>
  )
}

export { DownloadPDFButton }
