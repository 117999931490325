import React from 'react'
import { useHistory } from 'react-router-dom'
import resources from '../../resources'
import MainMenuSection from './MainMenuSection'

const SidebarContent = (user, userActions) => {
  const history = useHistory()
  const arrayWithActionsOfRegistros = []
  const availableQueriesScreens = []

  const isSuperAdmin    = () => user?.perfil?.id === 3
  const isAdmin         = () => user?.perfil?.id === 1
  const isRegularUser   = () => user?.perfil?.id === 2
  const hasCentralLevel = () => user?.nivel?.id  === 1

  if (isRegularUser()) {
    if (!userActions) return
    userActions.forEach(a => {
      if(a.id === 5)
        availableQueriesScreens.push(resources.commonQueries[0])
      if(a.id === 11)
        availableQueriesScreens.push(resources.commonQueries[1])
      if (a.id === 2)
        arrayWithActionsOfRegistros.push(resources.actionTwo[0])
      if (a.id === 3)
        arrayWithActionsOfRegistros.push(resources.actionTree[0])
      if (a.id === 1)
        arrayWithActionsOfRegistros.push(resources.actionOne[0])
      if (a.id === 10)
        arrayWithActionsOfRegistros.push(resources.actionFour[0])
      if (a.id === 13)
        arrayWithActionsOfRegistros.push(resources.actionFive[0])
    })
  }

  const goHome = () => history.push('/inicio')
  const canConfigure = () => isSuperAdmin() || (hasCentralLevel() && isAdmin())
  const getConsultationActions = () => (
    user?.perfil?.id ? availableQueriesScreens : resources.commonQueries
  )

  return (
    <div className="c-sidebar">
      <img onClick={ goHome } src={ resources.images.ergonLogo } alt="logo"/>
      <span>{ resources.version }</span>
      <MainMenuSection
        title="Administrador"
        visible={ isSuperAdmin() }
        actions={ resources.superAdminActions }
      />
      <MainMenuSection
        title="Configuración"
        visible={ canConfigure() }
        actions={ resources.actionsForPlantAdim }
      />
      <MainMenuSection
        title="Registros"
        visible={ !isRegularUser() }
        actions={ resources.commonActions }
      />
      <MainMenuSection
        title="Consultas"
        visible={ !isRegularUser() }
        actions={ resources.commonQueries }
      />
      <MainMenuSection
        title="Registros"
        visible={ isRegularUser() }
        actions={ arrayWithActionsOfRegistros }
      />
      <MainMenuSection
        title="Consultas"
        visible={ isRegularUser() }
        actions={ getConsultationActions() }
      />
    </div>
  )
}

export default SidebarContent
