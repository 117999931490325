import axios from 'axios'
import resources from '../resources'

const buildUrl = (url = '') => (
  `${resources.urls.ergonApi}/orderAssignation${url}`
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getOrderFreights = async (id, token) => {
  const { data } = await axios.get(
    buildUrl(`/authorizedFreights/${id}`),
    buildHeaders(token)
  )
  return data
}

export {
  getOrderFreights
 }
