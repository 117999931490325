import React from 'react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'

const InputPhone = () => {
  const context = useParkedUnitsContext()

  return (
    <div className="form-field">
      <label>Teléfono:</label>
      <label>{ context.unitInfo.celular }</label>
    </div>
  )
}

export { InputPhone }
