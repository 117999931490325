import React from 'react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'
import { StyledTable } from '../../../StyledTable'

const headers = [
  { path: 'fecha',          title: 'Fecha' },
  { path: 'tipoIncidencia', title: 'Tipo de incidencia' },
  { path: 'notas',          title: 'Notas de incidencia' }
]

const Table = () => {
  const context = useOrdersInTransitIncidencesContext()

  const handleClick = (_, index) => context.fillForm(index)

  return (
    <StyledTable
      className='incidences-table'
      search={ false }
      headers={ headers }
      data={ context.incidences }
      handleOnClickRow={ handleClick }
    />
  )
}

export { Table }
