import React, { useState } from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const EmailsInput = () => {
  const context = usePlantsContext()
  const [currentEmail, setCurrentEmail] = useState('')
  const currentEmailHandler = createInputValueHandler(setCurrentEmail)

  const add = () => {
    if (currentEmail === '' || context.emails.includes(currentEmail)) return
    context.setEmails([...context.emails, currentEmail])
    setCurrentEmail('')
  }

  const remove = email => (
    context.setEmails(context.emails.filter(e => e !== email))
  )

  return (
    <div className='field'>
      <label className='email-label'>Correos Notificaciones:</label>
      <div className='emails-wrap'>
        {
          context.emails.map(e => (
            <Input
              key={ e }
              value={ e }
              icon={ <Icon name='delete' link onClick={ () => remove(e) }/> }
              readOnly
            />
          ))
        }
        <Input
          value={ currentEmail }
          onChange={ currentEmailHandler }
          icon={ <Icon name='add' link onClick={ add }/> }
        />
      </div>
    </div>
  )
}

export { EmailsInput }
