import React from 'react'

const FreightName = ({ order }) => {
  return (
    <div className='single-row'>
      <label>Nombre de flete:</label>
      <label>{ order?.fleteNombre || 'Sin asignar' }</label>
    </div>
  )
}

export { FreightName }
