import React from 'react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const Economic = () => {
  const context = useStayClaimContext()

  return (
    <div className='item'>
      <label>Unidad:</label>
      <label className='value'>
        { context.economic || 'Sin asignar' }
      </label>
    </div>
  )
}

export { Economic }
