import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const UnitNameLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.economico || 'Sin asignar'

  return (
    <div>
      <div>Económico:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { UnitNameLabel }
