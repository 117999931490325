import React from 'react'
import { Dropdown, Icon, Popup } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const timezones = [
  { key: 1, text: '-3 Hrs', value: -3 },
  { key: 2, text: '-2 Hrs', value: -2 },
  { key: 3, text: '-1 Hrs', value: -1 },
  { key: 4, text: ' 0 Hrs', value:  0 },
  { key: 5, text: '+1 Hrs', value:  1 },
  { key: 6, text: '+2 Hrs', value:  2 },
  { key: 7, text: '+3 Hrs', value:  3 },
]

const TimezoneInput = () => {
  const context = useOrderAssignationContext()

  const handleTimezone = (_, { value }) => {
    context.setSelectedTimezone(value)
    context.setEditOffset(true)
  }

  const showEditWarning = () => (
    context.editOffset && (context.estadoId > 1 || context.hasBeenUnassigned)
  )

  return (
    <div className="form-field">
      <label className="timezone-label">Dif de horario:</label>
      {
        showEditWarning() ?
          <Popup
            content='Ha modificado la zona horaria, la fecha de carga y de compromiso de entrega volverán a calcularse al guardar los cambios, asegurese de que las fechas sean correctas.'
            trigger={<Icon className="warning-icon" name="warning" color="red" />}
          /> : (<></>)
      }
      <Dropdown
        fluid
        selection
        options={ timezones }
        value={ context.selectedTimezone }
        onChange={ handleTimezone }
      />
    </div>
  )
}

export { TimezoneInput }
