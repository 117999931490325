import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const DriverLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.operador

  return (
    <div>
      <div>Operador:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { DriverLabel }
