import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import {
  useInitialInventoryContext
} from '../../../contexts/InitialInventoryContext'
import { createInputValueHandler } from '../../../helpers/input'

const InventoryInput = () => {
  const context = useInitialInventoryContext()
  const plantInventory = createInputValueHandler(context.setPlantInventory)

  return (
    <div className='inventory-input'>
      <Form>
        <label>Inventario inicial:</label>
        <Input
          type='number'
          value={ context.plantInventory }
          onChange={ plantInventory }
        />
      </Form>
    </div>
  )
}

export { InventoryInput }
