import React from 'react'
import { ChangeUnitsProvider } from '../contexts/ChangeUnitsContext'
import { PlantDropdown } from '../components/change_units/PlantDropdown'
import { Table } from '../components/change_units/Table'
import { ChangeUnitsModal } from '../components/change_units/ChangeUnitsModal'

const ChangeUnits = () => {
  return (
    <ChangeUnitsProvider>
      <h1>Cambio de estatus/base a unidades</h1>
      <div className='change-units-screen-container'>
        <ChangeUnitsModal />
        <PlantDropdown />
        <Table />
      </div>
    </ChangeUnitsProvider>
  )
}

export { ChangeUnits }
