import React from 'react'
import { useSelector } from 'react-redux'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../contexts/OrdersInTransitInfoModalContext'
import { TabOptions } from '../../tab/TabOptions'

const options = [
  { order: 1, key: 'info', label: 'Información del pedido', icon: 'list' },
  { order: 2, key: 'stay', label: 'Tiempo de estadía', icon: 'clock' },
  { order: 3, key: 'incidences', label: 'Incidencias de viaje', icon: 'truck' },
  { order: 4, key: 'navixy', label: 'Información de Navixy', icon: 'navixy' }
]

const OrderTabs = () => {
  const mainContext = useOrdersInTransitContext()
  const context = useOrdersInTransitInfoModalContext()

  const { credentials } = useSelector((state) => state.auth)

  const isSuperAdmin = () => {
    if (!credentials) return false
    if (credentials.user.perfil.id === 3) return true
    return false
  }

  const hasAccessIncidences = () => {
    if (!mainContext.selectedOrder.unidadAsignada) return false
    return isSuperAdmin() || credentials.actions.some(a => a.id === 12)
  }

  const hasAccessStays = () => {
    if (!mainContext.selectedOrder.unidadAsignada) return false
    return isSuperAdmin() || credentials.actions.some(a => a.id === 14)
  }

  const getPermissionlessOptions = () => (
    options.filter(o => !['incidences', 'stay'].includes(o.key))
  )

  const getIncidenceOption = () => {
    if (!hasAccessIncidences()) return []
    return options.filter(o => o.key === 'incidences')
  }
  const getStayOption = () => {
    if (!hasAccessStays()) return []
    return options.filter(o => o.key === 'stay')
  }

  const getOptions = () => (
    [
      ...getPermissionlessOptions(),
      ...getIncidenceOption(),
      ...getStayOption()
    ].sort((a, b) => a.order > b.order)
  )

  return (
    <TabOptions
      options={ getOptions() }
      selected={ context.selectedTab }
      onClick={ context.setSelectedTab }
    />
  )
}

export { OrderTabs }
