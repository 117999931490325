import React from 'react'
import { Radio } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const TypePlantRadio = () => {
  const context = usePlantsContext()
  const typeHandler = createInputValueHandler(context.setTypePlant)

  return (
    <div className='field'>
      <label>Tipo de planta:</label>
      <div className='radio-group'>
        <Radio
          value={ 1 }
          checked={ context.typePlant === 1 }
          onChange={ typeHandler }
          label='Principal'
        />
        <Radio
          value={ 2 }
          checked={ context.typePlant === 2 }
          onChange={ typeHandler }
          label='Refinería'
        />
      </div>
    </div>
  )
}

export { TypePlantRadio }
