import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const UnitPriceInput = ({ unitPrice = '', setUnitPrice = () => {} }) => {
  const priceHandler = createInputValueHandler(setUnitPrice)

  return (
    <div className="form-field">
      <label>Precio unitario:</label>
      <Input
        placeholder="Precio unitario"
        value={ unitPrice }
        onChange={ priceHandler }
      />
    </div>
  )
}

export { UnitPriceInput }
