import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const ContactPhoneInput = () => {
  const context = useOrderContext()

  const contactPhoneOptions = { limit: 11, isNumbersOnly: true }
  const inputHandler =
    createInputValueHandler(context.setTelefonoContacto, contactPhoneOptions)

  return (
    <div className="form-field">
      <label>Teléfono contacto:</label>
      <Input
        placeholder="Teléfono contacto"
        value={ context.telefonoContacto }
        onChange={ inputHandler }
      />
    </div>
  )
}

export { ContactPhoneInput }
