import React from 'react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLogContext'

const Destination = () => {
  const context = useAuthorizedStaysLogContext()

  return (
    <div className="form-field">
      <label>Destino:</label>
      <label>{ context.info?.destino }</label>
    </div>
  )
}

export { Destination }
