import React from 'react'
import { Button } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../../contexts/StayClaimContext'

const SaveButton = () => {
  const context = useStayClaimContext()

  return (
    <div className='item-save'>
      <Button
        primary
        disabled={ context.isClaimed }
        loading={ context.isWorking }
        onClick={ context.save }
      >
        Enviar
      </Button>
    </div>
  )
}

export { SaveButton }
