import React from "react"
import { Modal } from "react-bootstrap"
import { Button } from "semantic-ui-react"

const AssignOperatorModal = ({
  showModal,
  setShowModal,
  saveChanges,
  lastDriverWithUnit,
  token
}) => {

  const onAccept = () => {
    setShowModal(false)
    saveChanges()
  }

  return (
    <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unidad asignada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ textAlign: "justify", margin: 0, marginBottom: 5 }}>
              La unidad <b>{lastDriverWithUnit.economico}</b>, se encuentra en este momento{" "}
              <b>asignada</b> al operador <b>{lastDriverWithUnit.nombre}</b>.
            </p>
            <p style={{ textAlign: "justify", margin: 0, marginBottom: 5 }}>
              {" "}
              Realizar esta operación puede ocacionar problemas con los pedidos asignados a éste operador.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowModal(false)}
            color={"grey"}
            style={{ fontSize: 12 }}
          >
            { "Cancelar" }
          </Button>
          <Button
            onClick={onAccept}
            color={ "blue" }
            style={{ fontSize: 12 }}
          >
            { "Aceptar y guardar" }
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export { AssignOperatorModal }
