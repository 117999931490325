import React from 'react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { PlantsDropdown } from '../../PlantsDropdown'

const PlantDropdown = () => {
  const context = useOrderContext()

  const canEdit = () => (
    !context.isEditing || (context.isEditing && !context.asignado)
  )

  const isPlantUser = () => (
    context?.credentials?.user.nivel.id === 2
  )

  const changeConfirmation = value => {
    if (context.isEditing && !context.editionAllowed){
      context.setAction({ action: context.setPlantaId, param: value })
      return context.setShowConfirmEdit(true)
    }
    context.setPlantaId(value)
  }

  const handleSetter = () => (
    isPlantUser() ?
      context.setPlantaId
    :
      changeConfirmation
  )

  return (
    <div className="form-field">
      <label>Planta:</label>
      <PlantsDropdown
        disabled={ !canEdit() }
        value={ context.plantaId }
        setSelectedPlant={ handleSetter() }
      />
    </div>
  )
}

export { PlantDropdown }
