import React from 'react'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const SaveConfirmationModal = () => {

  const contex = useOrdersInTransitIncidencesContext()

  const handleOnClickCancel = () => {
    contex.setShowSaveConfirmation(false)
  }

  return (
    <Modal
      centered
      enforceFocus
      show={ contex.showSaveConfirmation }
      onHide={ handleOnClickCancel }
    >
      <Modal.Header>
        <Modal.Title>Guardar evidencias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Hay una incidencia pendiente de agregarse a la tabla, si guardas perderas los cambios ¿Deseas continuar?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='grey'
          onClick={ handleOnClickCancel }
        >
          Cancelar
        </Button>
        <Button
          color='red'
          loading={ contex.isWorking }
          onClick={ contex.save }
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { SaveConfirmationModal }
