import React from 'react'
import {
  VerifyButton
} from './no_availability_evidence_code_verification/VerifyButton'
import {
  CodeInput
} from './no_availability_evidence_code_verification/CodeInput'
import { useUploadEvidenceContext } from '../../contexts/UploadEvidenceContext'
import resources from '../../resources'

const CodeVerification = () => {
  const context = useUploadEvidenceContext()

  const handleStyle = () => (
    context?.isVerified ? 'verify-modal-hidden' : 'verify-modal'
  )

  return (
    <div className={ handleStyle() }>
      <img
        src={ resources.images.ergonLogo }
        className='img-container'
        alt='ergon-logo'
      />
      <CodeInput />
      <VerifyButton />
    </div>
  )
}

export { CodeVerification }
