import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useFreightsConsultContext } from '../../contexts/FreightsConsultContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'clienteNombre',   title: 'Cliente'         },
  { path: 'fleteNombre',     title: 'Nombre'          },
  { path: 'origen',          title: 'Origen'          },
  { path: 'destino',         title: 'Destino'         },
  { path: 'costoCasetas',    title: 'Casetas ($)'     },
  { path: 'costoTonelada',   title: 'Flete x ton ($)' },
  { path: 'fechaAutorizada', title: 'Autorización'    },
  { path: 'vigencia',        title: 'Vigencia'        }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useFreightsConsultContext()

  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setFreightsData(filterdata(context.freightsData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table-container'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.freightsData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
        handleOnClickRow={ context.onSelectRow }
      />
    </div>
  )
}

export { Table }
