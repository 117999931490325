import React, { useEffect, useState } from 'react'
import { useOrderContext } from '../../contexts/OrderContext'
import { showErrorAlert } from '../../helpers/notifications'
import { OrderFormTable } from './OrderFormTable'
import { AddButton } from './order_form_details/AddButton'
import { DestinationDropdown } from './order_form_details/DestinationDropdown'
import { LegalNameDropdown } from './order_form_details/LegalNamesDropdown'
import { ProductDropdown } from './order_form_details/ProductDropdown'
import { ProductLabel } from './order_form_details/ProductLabel'
import { QuantityInput } from './order_form_details/QuantityInput'
import { QuantityLabel } from './order_form_details/QuantityLabel'
import { RealQuantityInput } from './order_form_details/RealQuantityInput'
import { TicketInput } from './order_form_details/TicketInput'
import { UnitPriceInput } from './order_form_details/UnitPriceInput'

const OrderFormDetails = () => {
  const context = useOrderContext()

  const [editingRowPosition, setEditingRowPosition] = useState(null)

  const [productoId,     setProductoId]     = useState('')
  const [destinoId,      setDestinoId]      = useState('')
  const [razonSocial,    setRazonSocial]    = useState('')
  const [precioUnitario, setPrecioUnitario] = useState(1)
  const [cantidad,       setCantidad]       = useState(1)
  const [unidadMedida,   setUnidadMedida]   = useState('ton')
  const [remision,       setRemision]       = useState('')
  const [cantidadReal,   setCantidadReal]   = useState(0)

  useEffect(() => {
    if (context.folio === '') clean()
  }, [context.folio])

  const findDestination = destinationId => {
    if (context.unidadCliente) return 'Libre a bordo en planta'
    return context.destinations.find(d => d.value === destinationId).text
  }

  const getDetailsEditting = row => {
    const details = [...context.detalles]
    details[editingRowPosition] = row
    return details
  }

  const getParams = () => ({
    productoId,
    destinoId,
    razonSocial,
    precioUnitario,
    cantidad,
    unidadMedida,
    remision,
    cantidadReal,
    destino: findDestination(destinoId)
  })

  const handleAdd = () => {
    if (!productoId) return showErrorAlert('Debes seleccionar un producto')
    if (!destinoId && !context.unidadCliente) return showErrorAlert('Debes selecionar un destino')
    if (cantidad <= 0) return showErrorAlert('La cantidad debe ser mayor a cero')
    if (cantidadReal?.toString()?.trim() === '') return showErrorAlert('Debe especificar la cantidad real cargada')
    const row = getParams()
    if (editingRowPosition !== null)
      context.setDetalles(getDetailsEditting(row))
    else
      context.setDetalles([...context.detalles, row])
    clean()
  }

  const loadFromTable = (row, position) => {
    setEditingRowPosition(position)
    setProductoId(row.productoId)
    setDestinoId(row.destinoId)
    setRazonSocial(row.razonSocial)
    setPrecioUnitario(row.precioUnitario)
    setCantidad(row.cantidad)
    setUnidadMedida(row.unidadMedida)
    setRemision(row.remision)
    setCantidadReal(row.cantidadReal)
  }

  const clean = () => {
    setEditingRowPosition(null)
    setProductoId('')
    setDestinoId('')
    setRazonSocial('')
    setPrecioUnitario(1)
    setCantidad(1)
    setUnidadMedida('ton')
    setRemision('')
    setCantidadReal(0)
  }

  return (
    <>
      <h6>Detalle del pedido</h6>
      <div className="row">
        <div className="col">
          <ProductDropdown
            productId={ productoId }
            setProductId= { setProductoId }
          />
          <DestinationDropdown
            destinationId={ destinoId }
            setDestinationId={ setDestinoId }
          />
          <LegalNameDropdown
            legalName={ razonSocial }
            setLegalName={ setRazonSocial }
          />
          <UnitPriceInput
            unitPrice={ precioUnitario }
            setUnitPrice={ setPrecioUnitario }
          />
        </div>
        <div className="col">
          <QuantityInput
            quantity={ cantidad }
            setQuantity={ setCantidad }
            measure={ unidadMedida }
            setMeasure={ setUnidadMedida }
          />
          <TicketInput
            ticket={ remision }
            setTicket={ setRemision }
          />
          <RealQuantityInput
            realQuantity={ cantidadReal }
            setRealQuantity={ setCantidadReal }
          />
          <div className="form-actions">
            <AddButton
              onClick={ handleAdd }
              editingRowPosition={ editingRowPosition }
            />
          </div>
        </div>
      </div>
      <footer>
        <ProductLabel />
        <QuantityLabel />
      </footer>
      <OrderFormTable onSelectDetail={ loadFromTable } />
    </>
  )
}

export { OrderFormDetails }
