import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLogContext'

const DownloadButton = () => {
  const context = useAuthorizedStaysLogContext()

  return (
    <Button
      primary
      loading={ context.isLoading }
      disabled={ context.isLoading }
      onClick={ context.download }
    >
      Descargar
    </Button>
  )
}

export { DownloadButton }
