import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const ContactInput = () => {
  const context = useOrderContext()

  const contactHandler = createInputValueHandler(context.setContacto)

  return (
    <div className="form-field">
      <label>Contacto obra:</label>
      <Input
        placeholder="Contacto"
        value={ context.contacto }
        onChange={ contactHandler  }
      />
    </div>
  )
}

export { ContactInput }
