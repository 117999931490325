import axios from 'axios'
import resources from '../resources'

const buildUrl = (endpoint, id = '') => (
  `${resources.urls.ergonApi}/changeUnits/${endpoint}/${id}`
)

const buildParamsToLends = id => ({ plant: id })

const buildHeaders = token => ({ headers: { Authorization: token } })

const lends = async (unitId, plantId, token) => (
  await axios.post(
    buildUrl('lend', unitId),
    buildParamsToLends(plantId),
    buildHeaders(token)
  )
)

const returns = async (unitId, token) => (
  await axios.post(buildUrl('returns', unitId), null, buildHeaders(token))
)

const setAvailable = async (unitId, token) => (
  await axios.post(
    buildUrl('setAvailable', unitId),
    buildParamsToLends(),
    buildHeaders(token)
  )
)

const setNotAvailable = async (unitId, params, token) => (
  await axios.post(
    buildUrl('setNoAvailable', unitId),
    params,
    buildHeaders(token)
  )
)

const getUnitEvidenceHistory = async (unitId, token) => (
  await axios.get(
    buildUrl('evidencesHistory', unitId),
    buildHeaders(token)
  )
)

const getPDF = async (docId, token) => (
  await axios.get(
    buildUrl('evidencesHistory/download', docId),
    { headers: { Authorization: token }, responseType: 'arraybuffer' }
  )
)

export {
  lends,
  returns,
  setAvailable,
  setNotAvailable,
  getUnitEvidenceHistory,
  getPDF
}
