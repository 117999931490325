import React from 'react'
import { Modal } from 'react-bootstrap'

const Header = () => {
  return (
    <Modal.Header>
      <Modal.Title>Unidad de cliente</Modal.Title>
    </Modal.Header>
  )
}

export { Header }
