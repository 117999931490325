import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const AuthorizedFreight = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setAuthorizedFreight)

  return (
    <div className='form-field'>
      <label>Flete autorizado por Ton:</label>
      <Input
        type='number'
        value={ context.authorizedFreight || '' }
        onChange={ InputHandler }
        placeholder='$ 0.00'
      />
    </div>
  )
}

export { AuthorizedFreight }
