import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const ReportField = () => {
  const context = useIncidencesLogContext()

  const getReport = () => (
    context.selectedIncidence?.reporteSuceso || 'No especificado'
  )

  return (
    <div className="field textarea">
      <label>Reporte suceso:</label>
      <label>{ getReport() }</label>
    </div>
  )
}

export { ReportField }
