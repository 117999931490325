import React, { useEffect, useState } from 'react'
import { useOrderAssignationContext } from '../../contexts/OrderAssignationContext'
import { StyledTable } from '../StyledTable'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'folio',        title: 'Folio' },
  { path: 'fechaPedido',  title: 'Fecha de registro' },
  { path: 'tipo',         title: 'Tipo' },
  { path: 'cliente',      title: 'Cliente/Planta' },
  { path: 'remision',     title: 'Remisión' },
  { path: 'caso',         title: 'Caso' },
  { path: 'destinos',     title: '#Destinos' },
  { path: 'fechaEntrega', title: 'Fecha requerida' },
  { path: 'estado',       title: 'Estado' },
]

const searchBy = [
  'folio',
  'tipo',
  'cliente',
  'remision',
  'estado',
]

const headerSearchBy = [
  'Folio',
  'Tipo',
  'Cliente/Planta',
  'Remisión',
  'Estado',
]

const Table = () => {
  const context = useOrderAssignationContext()

  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)
  const [orders, setOrders] = useState([])

  useEffect(() => {
    setOrders(context.filteredOrders)
  }, [context.filteredOrders, context])

  const handleFilterData = (data, e) => {
    setOrders(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const onSelectRow = ({ folio }) => context.getOrderById(folio)

  return (
    <StyledTable
      headers={ headers }
      searchBy={ searchBy }
      headerSearchBy={ headerSearchBy }
      data={ orders }
      handleOnClickRow={ onSelectRow }
      handleOnClickHeader={ e => handleFilterData(orders, e) }
    />
  )
}

export { Table }
