import React from 'react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLogContext'
import { toCurrency } from '../../../helpers/currency'

const StayPrice = () => {
  const context = useAuthorizedStaysLogContext()

  const getTotal = () => (
    toCurrency(context.info?.costoEstadia || 0)
  )

  return (
    <div className="form-field">
      <label>Costo total de la estadía:</label>
      <label>{ getTotal() }</label>
    </div>
  )
}

export { StayPrice }
