import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AuthProvider } from './authContext'
import { getPlantInventory, saveInventory } from '../services/initialInventory'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import { dateNow } from '../helpers/date'

const InitialInventoryContext = createContext()

const InitialInventoryProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)
  const [isWorking,      setIsWorking]      = useState(false)
  const [selectedPlant,  setSelectedPlant]  = useState('')
  const [dateInventory,  setDateInventory]  = useState(dateNow())
  const [plantInventory, setPlantInventory] = useState(0)

  useEffect(() => {
    if (selectedPlant) currentPlantInventory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant])

  const handleError = ({ message }) => showErrorAlert(message)

  const clear = () => {
    setSelectedPlant('')
    setDateInventory(dateNow())
    setPlantInventory(0)
  }

  const currentPlantInventory = () => {
    setIsWorking(true)
    getPlantInventory(selectedPlant, credentials.token)
      .then((response) => {
        setPlantInventory(response.cantidad || 0)
        setDateInventory(response.fecha || dateNow())
      })
      .catch(error => handleError(error.data))
      .finally(() => {
        setIsWorking(false)
      })
  }

  const params = {
    plantId: selectedPlant,
    quantity: plantInventory,
    date: dateInventory
  }

  const save = () => {
    if (!selectedPlant)
      return showErrorAlert('Favor de seleccionar una planta')
    if (!dateInventory)
      return showErrorAlert('Favor de ingresar una fecha')
    if (dateInventory !== (dateNow()))
      return showErrorAlert('Favor de ingresar la fecha actual')
    setIsWorking(true)
    saveInventory(params, credentials.token)
      .then( showOkAlert('Se ha cambiado exitosamente'))
      .catch(error => handleError(error.data))
      .finally(() => {
        clear()
        setIsWorking(false)
      })
  }

  const context = {
    dateInventory,
    isWorking,
    plantInventory,
    selectedPlant,

    save,
    setDateInventory,
    setPlantInventory,
    setSelectedPlant
  }

  return (
    <AuthProvider>
      <InitialInventoryContext.Provider value={ context }>
        { children }
      </InitialInventoryContext.Provider>
    </AuthProvider>
  )
}

const useInitialInventoryContext = () => useContext(InitialInventoryContext)

export { InitialInventoryProvider, useInitialInventoryContext }
