import React from 'react'
import { Form } from 'semantic-ui-react'
import { LeftContainer } from './form/section_one/LeftContainer'
import { RightContainer } from './form/section_one/RightContainer'
import { ActionButtons } from './form/section_one/ActionButtons'

const SectionOne = () => {
  return (
    <div className='section-one'>
      <h6>Cotización de flete</h6>
      <Form>
        <LeftContainer />
        <RightContainer />
      </Form>
      <ActionButtons />
    </div>
  )
}

export { SectionOne }
