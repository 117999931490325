import React from 'react'
import { Input } from 'semantic-ui-react'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'
import { createInputValueHandler } from '../../../helpers/input'

const EstimatedAvailability = () => {
  const context = useChangeUnitsContext()
  
  const estimatedAvailability = createInputValueHandler(context.setEstimatedAvailability)

  return (
    <>
      {
        !context.isNotAvailable() ?
        (
          <div>
            <label className='change-status-label'>
              Disponibilidad estimada:
            </label>
            <Input
              type='datetime-local'
              value={ context.estimatedAvailability}
              onChange={ estimatedAvailability }
            />
          </div>
        ) : (<></>)
      }
    </>
  )
}

export { EstimatedAvailability }
