import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const ClientUnitsDropdown = ({ onClick = () => {} }) => {
  const context = useOrderContext()

  const [selectedClientUnit, setSelectedClientUnit] = useState('')

  const getUnits = () => (
    context.clientUnits.map(u => ({
      key: u.id,
      text: u.economico,
      value: u.id
    }))
  )

  const selectHandler = (_, { value }) => {
    setSelectedClientUnit(value)
    const unit = context.clientUnits.find(u => u.id === value)
    onClick(unit)
  }

  return (
    <div className="client-unit-modal-field">
      <label>Unidades registradas:</label>
      <Dropdown
        fluid
        search
        selection
        options={ getUnits() }
        value={ selectedClientUnit }
        onChange={ selectHandler }
        placeholder="Unidades registradas"
      />
    </div>
  )
}

export { ClientUnitsDropdown }
