import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const SaveButton = () => {
  const orderInfoContext = useOrdersInTransitContext()
  const context = useOrdersInTransitIncidencesContext()

  const handleClick = () => {
    if (!context.isEditing) return context.save()
    context.setShowSaveConfirmation(true)
  }

  const canPress = () => {
    if (orderInfoContext.selectedOrder.borrado) return false
    if (context.isWorking) return false
    return context.hasAccess()
  }

  return (
    <div className='form-actions'>
      <Button
        primary
        loading={ context.isWorking }
        disabled={ !canPress() }
        onClick={ handleClick }
      >
        Guardar incidencias
      </Button>
    </div>
  )
}

export { SaveButton }
