import React from 'react'
import { Input } from 'semantic-ui-react'
import { useScheduleContext } from '../../../contexts/ScheduleContext'

const ShiftInput = ({ shift = {}, day }) => {
  const context = useScheduleContext()

  const handleChangeStartTime = (_, { value }) => (
    context.setEditedShift(day, shift.tipoTurnoId, true, value)
  )

  const handleChangeFinishTime = (_, { value }) => (
    context.setEditedShift(day, shift.tipoTurnoId, false, value)
  )

  return (
    <div className='shift'>
      <Input
        type='time'
        value={ shift.horaInicio || '00:00' }
        onChange={ handleChangeStartTime }
      />
      <Input
        type='time'
        value={ shift.horaFin || '00:00' }
        onChange={ handleChangeFinishTime }
      />
    </div>
  )
}

export { ShiftInput }
