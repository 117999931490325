import React from 'react'
import {
  Input,
  Dropdown,
  TextArea,
  Form,
  Button,
  Checkbox
} from 'semantic-ui-react'
import { ImageContainer } from '../ImageContainer'
import { useVehicleContext } from '../../contexts/vehicleContext'
import {
  createFileInputHandler,
  createInputValueHandler
} from '../../helpers/input'
import { PlantsDropdown } from '../PlantsDropdown'
import { Tanks } from './form/Tanks'

const VehicleForm = () => {
  const context = useVehicleContext()
  const economicoHandler = createInputValueHandler(context.setEconomico)
  const platesHandler    = createInputValueHandler(context.setPlates)
  const brandHandler     = createInputValueHandler(context.setBrand)
  const modelHandler     = createInputValueHandler(context.setModel)
  const colorHandler     = createInputValueHandler(context.setColor)
  const notesHandler     = createInputValueHandler(context.setNotes)
  const imageHandler     = createFileInputHandler(context.setImage)

  const findFromWebfleetVehicles = () => (
    context.webfleetVehicles.find(wv => wv.text === context.economico)
  )

  const handleWithoutGps = () => {
    if (context.isWithoutGps) {
      const objectuid = findFromWebfleetVehicles()?.key
      context.setObjectuid(objectuid)
    }
    context.setIsWithoutGps(!context.isWithoutGps)
  }

  return (
    <Form size="mini" className='vehicles-screen-form'>
      <div className='form-section-one'>
        <ImageContainer
          source={ context.image }
          onChangeImage={ imageHandler }
          isLoading={ context.isLoadingImage }
        />
        <div className='form-section-one-state'>
          <p><b>Estado:</b> { context.state }</p>
        </div>
      </div>

      <div className='form-section-two'>
        <div>
          <label> Económico: </label>
          {
            !context.isWithoutGps ?
              <div className='dropdown-container'>
                <Dropdown
                  options={ context.webfleetVehiclesToDropdown }
                  value={ context.objectuid }
                  selection
                  search
                  onChange={ (e , { value }) => context.onSelectWebfleetVehicle(value) }
                  className='section-dropdown'
                  placeholder='Seleccione un vehículo de webfleet'
                />
              </div>
            :
              <Input
                value={ context.economico || '' }
                onChange={ economicoHandler }
              />
          }
        </div>
        <div>
          <label> Unidad sin GPS: </label>
          <Checkbox
            checked={ !!context.isWithoutGps }
            onChange={ handleWithoutGps }
          />
        </div>
        <div>
          <label> Base principal: </label>
          <div className='dropdown-container'>
            <PlantsDropdown
              setSelectedPlant={ context.setPlantId }
              value={ context.plantId }
            />
          </div>
        </div>
        <div>
          <label> Base actual: </label>
          <Input
            disabled
            value={ context.currentPlantName }
          />
        </div>
        <div>
          <label> Placas unidad: </label>
          <Input
            value={ context.plates || '' }
            onChange={ platesHandler }
          />
        </div>
        <div>
          <label> Transportista: </label>
          <div className='dropdown-container'>
            <Dropdown
              options={ context.shipmentCompanies }
              value={ context.shipmentCompany }
              onChange={ (e, { value }) => context.setShipmentCompany(value) }
              className='section-dropdown'
              selection
              search
              placeholder='Seleccione un transportista'
            />
          </div>
        </div>
        <div>
          <label> Marca: </label>
          <Input
            value={ context.brand || '' }
            onChange={ brandHandler }
          />
        </div>
        <div>
          <label> Modelo: </label>
          <Input
            value={ context.model || '' }
            onChange={ modelHandler }
          />
        </div>
        <div>
          <label> Color: </label>
          <Input
            value={ context.color || '' }
            onChange={ colorHandler }
          />
        </div>
        <div>
          <label> Unidad para traspasos: </label>
          <Checkbox
            checked={ !!context.isInternalTransfer }
            onChange={() => (
              context.setIsInternalTransfer(!context.isInternalTransfer)
            )}
          />
        </div>
        <Tanks />
        <div>
          <label> Notas: </label>
          <div className='dropdown-container'>
            <TextArea
              className='section-dropdown'
              value={ context.notes || '' }
              onChange={ notesHandler }
            />
          </div>
        </div>
        <div className='vehicle-form-buttons'>
          {
            !context.isCreating ?
              <>
                <Button
                  color='grey'
                  loading={ context.isLoadingAction }
                  onClick={ context.clear }
                >
                  Cancelar cambios
                </Button>
                <Button
                  color='red'
                  loading={ context.isLoadingAction }
                  onClick={ () => context.setShowModal(true) }
                >
                  Eliminar vehículo
                </Button>
              </>
            :
              <></>
          }
          <Button
            primary
            loading={ context.isLoadingAction }
            onClick={ context.saveVehicle }
          >
            {`Guardar ${!context.isCreating ? 'cambios' : ''}`}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export { VehicleForm }
