import React from 'react'
import { DetailsModal } from '../components/incidences_log/DetailsModal'
import { SearchBar } from '../components/incidences_log/SearchBar'
import { Table } from '../components/incidences_log/Table'
import { IncidencesLogProvider } from '../contexts/IncidencesLogContext'

const IncidencesLog = () => {
  return (
    <IncidencesLogProvider>
      <div className="screen-incidences-logs">
        <h1>Seguimiento de incidencias</h1>
        <div className="content">
          <DetailsModal/>
          <SearchBar/>
          <Table/>
        </div>
      </div>
    </IncidencesLogProvider>
  )
}

export { IncidencesLog }
