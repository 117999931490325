import React from 'react'
import { useChangeUnitsContext } from '../../contexts/ChangeUnitsContext'
import { Container, Modal, Row } from 'react-bootstrap'
import { Icon } from 'semantic-ui-react'
import { Tabs } from './modal/Tabs'
import { TabsComponents } from './modal/TabsComponents'
import { ChangeUnitGeneralDataModal } from './modal/ChangeUnitGeneralDataModal'

const ChangeUnitsModal = () => {
  const context = useChangeUnitsContext()

  const handleClose = () => {
    context.cleanChangeUnitModal()
  }

  return (
    <Modal
      show={ context.showModal }
      className='change-units-modal'
      onHide={ handleClose }
    >
      <Modal.Body>
        <Icon name='close' onClick={ handleClose } />
        <Container>
          <Row>
            <Tabs />
          </Row>
          <hr />
          <ChangeUnitGeneralDataModal />
          <hr />
          <TabsComponents />
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export { ChangeUnitsModal }
