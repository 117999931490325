import React from 'react'
import { Button, Checkbox, Icon } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const ClientUnitCheck = () => {
  const context = useOrderContext()

  const canCheck = () => {
    return context.externo && context.clienteId
  }

  const handleCheck = () => {
    if (!context.hasClientUnit)
      context.setShowClientUnitModal(true)
    else
      context.setUnidadCliente(null)
    context.setHasClientUnit(!context.hasClientUnit)
  }

  const handleClick = () => {
    context.setShowClientUnitModal(true)
  }

  const changeConfirmation = () => {
    if (context.isEditing && !context.editionAllowed){
      context.setAction({ action: handleCheck })
      return context.setShowConfirmEdit(true)
    }
    handleCheck()
  }

  return (
    <div className="form-field">
      <label>¿Cargar en unidad de cliente?</label>
      <div className="check-container">
        <Checkbox
          onChange={ changeConfirmation }
          checked={ context.hasClientUnit }
          disabled={ !canCheck() }
        />
        {
          context.unidadCliente ?
          (
            <Button color="grey" onClick={ handleClick }>
              <Icon name="truck" />Editar unidad
            </Button>
          ) : (<></>)
        }
      </div>
    </div>
  )
}

export { ClientUnitCheck }
