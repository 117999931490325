import React from 'react'
import ReactDatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { Button } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../contexts/IncidencesLogContext'
import { PlantsDropdown } from '../PlantsDropdown'

const SearchBar = () => {
  const context = useIncidencesLogContext()

  const handleDatePickerChange = value => context.setDateRange(value)

  return (
    <div className="search-bar">
      <div className="form-field">
        <label>Planta:</label>
        <PlantsDropdown
          value={ context.plantId }
          setSelectedPlant={ context.setPlantId }
        />
      </div>
      <div className="form-field date">
        <label>Fecha:</label>
        <ReactDatePicker
          selectsRange
          isClearable
          locale={ es }
          startDate={ context.startDate }
          endDate={ context.endDate }
          onChange={ handleDatePickerChange }
        />
      </div>
      <Button
        primary
        onClick={ context.search }
        loading={ context.isWorking }
        disabled={ context.isWorking }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchBar }
