import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Icon, Input } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'
import { createInputValueHandler } from '../../../../helpers/input'

const MailingListModal = () => {
  const context = useOrdersInTransitIncidencesContext()

  const [currentEmail, setCurrentEmail] = useState('')

  const currentEmailHandler = createInputValueHandler(setCurrentEmail)

  const add = () => {
    if (currentEmail === '') return
    context.setEmails([...context.emails, currentEmail])
    setCurrentEmail('')
  }

  const remove = email => {
    context.setEmails(context.emails.filter(e => e !== email))
  }

  const confirmHandler = () => {
    context.add()
    setCurrentEmail('')
    context.setShowMailingList(false)
  }

  const cancelHandler = () => {
    setCurrentEmail('')
    context.setShowMailingList(false)
  }

  return (
    <Modal show={ context.showMailingList } onHide={ cancelHandler }>
      <Modal.Header>
        <Modal.Title>
          El incidente afecta al servicio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>El incidente de viaje afecta al servicio, además de la empresa transportista ingrese los correos a los que desea notificar:</p>
        {
          context.emails.map(e => (
            <Input
              key={ e }
              readOnly
              value={ e }
              icon={ <Icon name="delete" link onClick={ () => remove(e) }/> }
            />
          ))
        }
        <br/>
        <Input
          value={ currentEmail }
          onChange={ currentEmailHandler }
          icon={ <Icon name="add" link onClick={ add }/> }
        />
      </Modal.Body>
      <Modal.Footer>
      <Button color="grey" onClick={ cancelHandler }>
        Cancelar
      </Button>
      <Button primary onClick={ confirmHandler }>
        Confirmar
      </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { MailingListModal }
