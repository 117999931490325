import React from 'react'
import { useOrderContext } from '../../../contexts/OrderContext'

const ProductLabel = () => {
  const context = useOrderContext()

  const getProduct = () => {
    if (!context.detalles.length) return ''
    const id = context.detalles[0].productoId
    return context.products.find(p => p.value === id)?.text
  }

  return (
    <h6>Producto: { getProduct() }</h6>
  )
}

export { ProductLabel }
