import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const FreightName = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setFreightName)

  return (
    <div className='form-field'>
      <label>Nombre de flete:</label>
      <Input
        type='text'
        value={ context.freightName || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { FreightName }
