import axios from 'axios'
import { responseHandler, showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const endpointUrl = `${resources.urls.ergonApi}/incidencesLog`

const buildHeaders = token => ({ headers: { Authorization: token } })

const includeStartDate = date => date ? `&startDate=${date}` : ''
const includeEndDate = date => date ? `&endDate=${date}` : ''

const getIncidencesLogUrl = (plantId, startDate, endDate) => {
  const startDateParam = includeStartDate(startDate)
  const endDateParam = includeEndDate(endDate)
  return `${endpointUrl}?plantId=${plantId}${startDateParam}${endDateParam}`
}

const getIncidencesLog = async (token, plantId, startDate, endDate) => {
  const url = getIncidencesLogUrl(plantId, startDate, endDate)
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return []
  }
}

const getById = async (token, id) => {
  const url = `${endpointUrl}/${id}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return null
  }
}

const saveIncidence = async (token, params) => {
  try {
    const response = await axios.post(endpointUrl, params, buildHeaders(token))
    responseHandler(response.data)
    return !(response.data.error || response.data.errorCode)
  }
  catch (error) {
    showErrorAlert(error)
  }
}

export { getIncidencesLog, getById, saveIncidence }
