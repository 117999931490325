import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'

const DriverNavixyForm = ({
  driverInfo=null,
  navixyDrivers=[],
  selectedNavixyDriver=null,
  onSelectNavixyDriver=()=>{}
}) => {
  return (
    <>
      <div className="form-line">
        <span className="subtitle-text"> Operador: </span>
        <Dropdown
          fluid
          selection
          search
          options={ navixyDrivers }
          value={ selectedNavixyDriver }
          onChange={ (e, { value }) => onSelectNavixyDriver(value) }
          labelkey="first_name"
          placeholder="Seleccione un operador"
        />
      </div>
      <div className="form-line">
        <span className="subtitle-text"> Navixy tracker label: </span>
        <Input
          value={ driverInfo.navixyTrackerLabel }
          disabled={ true }
        />
      </div>
      <div className="form-line">
        <span className="subtitle-text"> Navixy tracker label guardado: </span>
        <Input
          value={ driverInfo.navixyTrackerLabelDb }
          disabled={ true }
        />
      </div>
      <sup>
        *Este es el valor que usa el sistema de bitácora para comunicarse con Navixy.
        presione Guardar para sobreescribir este valor con el valor del campo superior
      </sup>
    </>
  )
}

export { DriverNavixyForm }
