import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotAvailabilityTypes = () => {
  const context = useChangeUnitsContext()

  const typeHandler = createInputValueHandler(context.setNotAvailableType)
  
  return (
    <>
      {
        !context.isNotAvailable() ?
        (
          <div className='dropdown-container'>
            <label className='change-status-label'>Motivo no disponible:</label>
            <Dropdown
              fluid
              search
              placeholder='Seleccione un motivo'
              value={ context.notAvailableType }
              options={ context.notAvailabilityTypes }
              onChange={ typeHandler }
            />
          </div>
        ) : (<></>)
      }
    </>
  )
}

export { NotAvailabilityTypes }
