import React, { useState } from 'react'
import { useOrderContext } from '../../contexts/OrderContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'folio',       title: 'Folio'        },
  { path: 'cliente',     title: 'Cliente'      },
  { path: 'remision',    title: 'Remisión'     },
  { path: 'fechaPedido', title: 'Fecha Pedido' },
  { path: 'estado',      title: 'Estado'       }
]

const searchBy = ['folio', 'fechaPedido', 'cliente', 'remision']
const headerSearchBy = ['Folio', 'Cliente', 'Remision', 'Fecha pedido']

const Table = () => {
  const context = useOrderContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const filterData = e => {
    const filteredData = filterdata(context.orders, e, reloadDataOfTable)
    context.setOrders(filteredData)
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const selectOrder = row => {
    context.getOrder(row.folio)
  }

  return (
    <StyledTable
      headers={ headers }
      searchBy={ searchBy }
      headerSearchBy={ headerSearchBy }
      data={ context.orders }
      handleOnClickRow={ selectOrder }
      handleOnClickHeader={ filterData }
    />
  )
}

export { Table }
