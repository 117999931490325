import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const HoursInput = () => {
  const context = useOrderAssignationContext()

  const handleHours = createInputValueHandler(context.setHorasTraslado)

  return (
    <div className="form-field">
      <label>Hrs traslado:</label>
      <Input
        placeholder="Hrs Traslado"
        type="number"
        min={ 1 }
        value={ context.horasTraslado }
        onChange={ handleHours }
      />
    </div>
  )
}

export { HoursInput }
