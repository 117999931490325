import React from 'react'
import { Input } from 'semantic-ui-react'
import { useVehicleContext } from '../../../contexts/vehicleContext'

const TanksBrands = () => {
  const context = useVehicleContext()

  const getBrand = tank => tank?.marca || ''

  const getTankOneBrand = () => {
    const tank = context.findTankOne()
    return getBrand(tank)
  }

  const getTankTwoBrand = () => {
    const tank = context.findTankTwo()
    return getBrand(tank)
  }

  return (
    <div className='tanks-inputs'>
      <Input
        disabled
        value={ getTankOneBrand() }
      />
      <Input
        disabled
        value={ getTankTwoBrand() }
      />
    </div>
  )
}

export { TanksBrands }
