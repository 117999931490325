import React from 'react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLogContext'

const ShipmentCompany = () => {
  const context = useAuthorizedStaysLogContext()

  return (
    <div className="form-field">
      <label>Transportista:</label>
      <label>{ context.info?.transportista }</label>
    </div>
  )
}

export { ShipmentCompany }
