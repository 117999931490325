import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useAssignmentParticipationContext
} from '../../../../contexts/AssignmentParticipationContext'

const SearchButton = () => {
  const context = useAssignmentParticipationContext()

  return (
    <div className='search-container'>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchButton }
