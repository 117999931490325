import React from 'react'
import { SaveButton } from './incidences_form_actions/SaveButton'
import { DestroyButton } from './incidences_form_actions/DestroyButton'
import { CleanButton } from './incidences_form_actions/CleanButton'

const IncidencesFormActions = () => {
  return (
    <div className='form-actions'>
      <CleanButton/>
      <DestroyButton/>
      <SaveButton/>
    </div>
  )
}

export { IncidencesFormActions }
