import React from 'react'
import { Input } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'
import { createInputValueHandler } from '../../../helpers/input'

const CreditNoteInput = () => {
  const context = useIncidencesLogContext()

  const noteHandler = createInputValueHandler(context.setNotaCredito)

  return (
    <div className="field">
      <label>N° nota de crédito:</label>
      <Input
        placeholder="Nota de crédito"
        value={ context.notaCredito || '' }
        onChange={ noteHandler }
      />
    </div>
  )
}

export { CreditNoteInput }
