import React from 'react'
import { Input } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const SecretKeyParkimovilInput = () => {
  const context = usePlantsContext()
  const secretkeyHanlder = createInputValueHandler(context.setSecretkeyParkimovil)

  return (
    <div className='field'>
      <label>Secret key de Parkimovil:</label>
      <Input
        value={ context.secretkeyParkimovil }
        onChange={ secretkeyHanlder }
      />
    </div>
  )
}

export { SecretKeyParkimovilInput }
