import React from 'react'
import { Input } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'
import { createInputValueHandler } from '../../../helpers/input'

const AplicationDateInput = () => {
  const context = useIncidencesLogContext()

  const handleDate = createInputValueHandler(context.setFechaAplicada)

  return (
    <div className="field">
      <label>Fecha aplicada:</label>
      <Input
        placeholder="Fecha aplicada"
        type="datetime-local"
        value={ context.fechaAplicada || '' }
        onChange={ handleDate }
      />
    </div>
  )
}

export { AplicationDateInput }
