import Swal from "sweetalert2"
import _ from "lodash"
import { getURLtoDownloadPDF } from "../services/ergonApi"
import { showErrorAlert } from "../helpers/notifications"
/**
 * @description Funcion para convetir un arreglo de objetos en un array para el dropdown de semantic UI
 * @param {Array} array El arreglo deseado a convertir
 * @param {String} name El nombre de la propiedad que se mostrara en la lista
 * @returns {Array} Regresa el arreglo para poder utilizar en el dropdown
 */
export function parseArrayObjectsToDropdownArray(array, name) {
  let aux = []
  try {
    let counter = 0
    array.map((obj) => {
      counter++
      aux.push({
        key: counter,
        text: typeof obj === "object" ? obj[name] : obj,
        value: obj,
      })
      return null
    })
  } catch (err) {
    console.log(err)
  } finally {
    return aux
  }
}

export function filterArrayByText(array, text, keys) {
  let aux = []
  text = String(text).toUpperCase()
  try {
    aux = array.filter((item) => {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let value = String(_.get(item, key, 'Sin asignar')).toUpperCase()
        if (value.indexOf(text) !== -1) {
          return value.indexOf(text) > -1
        }
      }
      return null
    })
  } catch (err) {
    console.log(err)
    aux = array
  } finally {
    return aux
  }
}

/**
 * @description Función para añadir un elemento al arreglo o eliminarlo en caso de que ya se encuentre en el arreglo.
 * @param {Array} array Arreglo que se desea validar si existe o no el elemento
 * @param {*} element Elemento para añadir o eliminar
 */
export function addOrRemoveElementFromArray(array, element) {
  try {
    let exists = array.find((e) => e.id === element.id)
    if (exists) {
      array = array.filter((e) => e.id !== exists.id)
    } else {
      array.push(element)
    }
  } catch (err) {
    console.log(err)
  } finally {
    return array
  }
}

/**
 * @description constante para validar si el campo esta vacio y de esa manera colocar un error
 * @param {*} value valor que se desea comprobar que sea diferente a nulo
 * @returns un booleano
 */
export const errorFields = (value) => {
  let errorValidated = false
  if (value == null) {
    errorValidated = true
  }
  return errorValidated
}

export const Toast = Swal.mixin({
  icon: "success",
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer)
    toast.addEventListener("mouseleave", Swal.resumeTimer)
  },
})

export const Swal1 = Swal.mixin({
  icon: "error",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: false,
  didOpen: (Swal1) => {
    Swal1.addEventListener("mouseenter", Swal.stopTimer)
    Swal1.addEventListener("mouseleave", Swal.resumeTimer)
  },
})

/**
 * @description Funcion para filtrar la informacion en base a que titulo de la tabla de presione
 * @param {Array} data Arreglo de objetos que se va a filtrar
 * @param {String} e Atributo del cual se va a filtrar, como nombre, apellido, id, etc...
 * @returns {Array} Regresa el arreglo de objetos filtrado por el atributo seleccionado
 */
export function filterdata(data, e, reloadUsers) {
  let auxPathData
  if(data.length === 0) {
    return data
  }

  data.map((o,i)=>{
    auxPathData=_.get(o, e, 'Sin asignar')
    if (auxPathData === undefined || auxPathData === "" || auxPathData === "0") {
      o= "Sin valor"
    }
    return null
  })
  let newarray = []
  let order = reloadUsers ? 'desc' : 'asc'
  let checkStructure = _.get(data[0], e, 'Sin asignar')
  if (typeof checkStructure === "string" || Number.isInteger(checkStructure) || Array.isArray(auxPathData)) {
    newarray = _.orderBy(data, e, order)
  }else{
    return data
  }
  return newarray
}

const base64ToBlob = (base64, type = "application/octet-stream") => {
  const binStr = atob(base64)
  const len = binStr.length
  const arr = new Uint8Array(len)
  for (let i = 0; i < len; i++)
    arr[ i ] = binStr.charCodeAt(i)
  return new Blob([ arr ], { type: type })
}

export const openPDF = base64 => {
  const blob = base64ToBlob(base64, 'application/pdf')
  const url = URL.createObjectURL(blob)
  const pdfWindow = window.open("")
  if (pdfWindow === null) return
  pdfWindow.document.write("<iframe width='100%' height='99%' src='" + url + "'></iframe>")
}

export const handleChecklistDownload = async (token ,idNavixyTask) => {
  const response = await getURLtoDownloadPDF(token, idNavixyTask)
  if (response.errorCode === 0)
    showErrorAlert(response.message)
  else
    openPDF(response.url)
}

export function splitOrdersByDetails(orders) {
  const aux = []
  orders.forEach(
    (order) => {
      order.detallePedido.forEach(
        (detail) => aux.push({
          ...order,
          remision: detail.remision,
          producto: detail.producto,
          cantidadReal: detail.cantidadReal,
          domicilio: order.cargaUnidadCliente ? 'Libre abordo en planta' : detail.referenciaDestino,
          unidadMedida: detail.unidadMedida,
          obsEmbarque: detail.obsEmbarque,
          obsPlanta: detail.obsPlanta,
          temLlegada: detail.temLlegada,
          temSalida: detail.temSalida,
          cantidadProg: detail.cantidad
        }),
      )
    },
  )
  return aux
}
