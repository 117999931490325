import React from 'react'
import { Form } from 'semantic-ui-react'

import { DescriptionIncidence } from './form/DescriptionIncidence'
import { ApplicableTo } from './form/ApplicableTo'
import { Status } from './form/Status'
import { IncidencesFormActions } from './form/IncidencesFormActions'

const IncidencesForm = () => {
  return (
    <Form>
      <DescriptionIncidence/>
      <ApplicableTo/>
      <Status/>
      <IncidencesFormActions/>
    </Form>
  )
}

export { IncidencesForm }
