import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'
import { createInputValueHandler } from '../../../../helpers/input'

const ArrivalTemperatureInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const inputHandler = (
    createInputValueHandler(contextModal.setArrivalTemperature)
  )
  const getValue = () => contextModal.arrivalTemperature

  return (
    <>
      <div>Temp. Llegada:</div>
      <Input
        value={ getValue() }
        onChange={ inputHandler }
      />
    </>
  )
}

export { ArrivalTemperatureInput }
