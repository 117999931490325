import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import {
  useInactivityDetailReportContext
} from '../../contexts/InactivityDetailReportContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'transportistaAlias',            title: 'Transportista' },
  { path: 'economico',                     title: 'Económico'     },
  { path: 'fechaInicio',                   title: 'Desde'         },
  { path: 'fechaFinal',                    title: 'Hasta'         },
  { path: 'motivoNoDisponibleDescripcion', title: 'Motivo ND'     },
  { path: 'dias',                          title: 'Días inactiva' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useInactivityDetailReportContext()
  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setInactivityDetailData(
      filterdata(context.inactivityDetailData, e, reloadData)
    )
    setReloadData(!reloadData)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.inactivityDetailData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
      />
    </div>
  )
}

export { Table }
