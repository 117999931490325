import React from 'react'
import { Dropdown, Form } from 'semantic-ui-react'

const MainForm = ({
  options = [],
  onSelectUnit = () => {},
  selectedUnit = {}
}) => {
  return (
    <Form>
      <div className="form-field">
        <span>Seleccione una unidad:</span>
        <Dropdown
          placeholder='Seleccione una unidad'
          fluid
          selection
          search
          options={ options }
          loading={ false }
          onChange={ (e, data) => onSelectUnit(data.value) }
        />
      </div>
      <div className="form-field">
        <span>Económico: </span>
        <span>{selectedUnit?.economico}</span>
      </div>
      <div className="form-field">
        <span>Placas unidad: </span>
        <span>{selectedUnit?.plates}</span>
      </div>
      <div className="form-field">
        <span>Placas tanque: </span>
        <span>{selectedUnit?.platesTank}</span>
      </div>
      <div className="form-field">
        <span>Estado de la unidad: </span>
        <span>{ selectedUnit?.state }</span>
      </div>
      <div className="form-field">
        <span>Operador: </span>
        <span>{selectedUnit?.driver}</span>
      </div>
      <div className="form-field">
        <span>Transportista: </span>
        <span>{selectedUnit?.shipmentCompany}</span>
      </div>
      <div className='alert alert-danger'>
        <span>Forzar la terminación de un pedido por este medio provocará la perdida de trazabilidad del sistema Navixy</span>
      </div>
    </Form>
  )
}

export { MainForm }
