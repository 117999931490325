import React from 'react'
import { useIncidencesContext } from '../../../contexts/IncidencesContext'
import { createInputValueHandler } from '../../../helpers/input'
import { Radio } from 'semantic-ui-react'

const ApplicableTo = () => {
  const context = useIncidencesContext()
  const applicableTo = createInputValueHandler(context.setApplicableTo)

  return (
    <div className='form-field'>
      <label>Aplicable a:</label>
      <div className='radio-group'>
        <Radio
          label='Transportista'
          value={ 1 }
          checked={ context.applicableTo === 1 }
          onChange={ applicableTo }
        />
        <Radio
          label='Cliente'
          value={ 2 }
          checked={ context.applicableTo === 2 }
          onChange={ applicableTo }
        />
        <Radio
          label='Ergon'
          value={ 3 }
          checked={ context.applicableTo === 3 }
          onChange={ applicableTo }
        />
        <Radio
          label='Otro'
          value={ 4 }
          checked={ context.applicableTo === 4 }
          onChange={ applicableTo }
        />
      </div>
    </div>
  )
}

export { ApplicableTo }
