import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { getGeneralInfo } from '../../../services/ergonApi'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../contexts/OrdersInTransitInfoModalContext'
import { OrderNavixyTable } from './OrderNavixyTable'

const OrderNavixy = () => {
  const context                       = useOrdersInTransitContext()
  const { tasks, setTasks }           = useOrdersInTransitInfoModalContext()
  const [isLoading, setIsLoading]     = useState(false)
  const [navixyError, setNavixyError] = useState(null)

  const assignStatus = label => {
    if (!label) return null
    switch (label) {
      case 'assigned': return 'ASIGNADO'
      case 'done':     return 'TERMINADO'
      case 'failed':   return 'NO COMPLETADO'
      case 'delayed':  return 'DEMORADO'
      case 'arrived':  return 'ARRIBADO'
      default:         return 'DEFECTUOSO'
    }
  }

  const formatToTable = response => response.map((task, i) => ({
      numero: i + 1,
      etapa: task.form ? task.form.label.replace(/[^a-zA-Z ]/g, '') : 'Sin asignar',
      domicilio: task?.location?.address || 'Sin asignar',
      fechaProgramada: task.from || 'Sin asignar',
      estado: assignStatus(task.status) || 'Sin asignar',
      formId: task?.form?.id
    })
  )

  const buildUrl = () => (
    `/pedido/tareas/${context.selectedOrder.folio}`
  )

  const handleResponse = response => {
    if (response.errorCode) return setNavixyError(response.message)
    setTasks(formatToTable(response))
  }

  const search = () => {
    setIsLoading(true)
    getGeneralInfo(context.credentials.token, buildUrl())
      .then(handleResponse)
      .catch(error => console.error('OrderNavixy :: search : ', error))
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {
        tasks ? <OrderNavixyTable /> : <></>
      }
      {
        !tasks && !navixyError ?
          <div className='navixy-container-button'>
            <Button
              primary
              onClick={ search }
              loading={ isLoading }
            >
              Cargar información de Navixy
            </Button>
          </div>
        : <></>
      }
      {
        navixyError ?
          <div className="navixy-container-button">
            <label>{ navixyError }</label>
          </div>
        : <></>
      }
    </>
  )
}

export { OrderNavixy }
