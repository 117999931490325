import React from 'react'
import { PlantsDropdown } from '../../PlantsDropdown'
import {
  useFreightsConsultContext
} from '../../../contexts/FreightsConsultContext'

const OriginDropdown = () => {
  const context = useFreightsConsultContext()

  return (
    <div className='origin-container'>
      <label className='title'>Origen:</label>
      <PlantsDropdown
        clearable
        value={ context.originIdFilter }
        setSelectedPlant={ context.setOriginIdFilter }
      />
    </div>
  )
}

export { OriginDropdown }
