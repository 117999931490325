import React from 'react'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { useOrdersInTransitInfoModalContext } from '../../../contexts/OrdersInTransitInfoModalContext'

const ModalToUnassignDriver = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const handleOnClickCancel = () => (
    contextModal.setShowModalToUnassignDriver(false)
  )

  return (
    <Modal
      centered
      enforceFocus
      show={ contextModal.showModalToUnassignDriver }
      onHide={ handleOnClickCancel }
    >
      <Modal.Header closeButton>
        <Modal.Title>Desasignar operador</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Al desasignar operador, se realizarán las siguientes acciones:
        <ul style={{ marginBottom:5, paddingLeft:30 }}>
            <li>La unidad asignada pasara a <b>disponible</b></li>
            <li>El pedido pasara a estado <b>sin asignar</b></li>
            <li><b>Se eliminarán</b> las tareas de navixy, correspondientes a este pedido</li>
        </ul>
        ¿Está seguro que desea desasignar operador?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="grey"
          onClick={ handleOnClickCancel }
          style={{ fontSize: 12 }}
        >
          Cancelar
        </Button>
        <Button
          loading={ contextModal.isConfirming }
          onClick={ contextModal.unassignDriver }
          color='red'
          style={{ fontSize: 12 }}
        >
          Confirmar desasignación
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalToUnassignDriver }
