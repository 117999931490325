import React from 'react'
import { PlantsProvider } from '../contexts/PlantsContext'
import { Table } from '../components/plants_crud/Table'
import { PlantForm } from '../components/plants_crud/PlantForm'

const PlantsCrud = () => {
  return (
    <PlantsProvider>
      <div className='screen-plants-crud'>
        <h1>Plantas</h1>
        <div className='content'>
          <PlantForm />
          <Table     />
        </div>
      </div>
    </PlantsProvider>
  )
}

export { PlantsCrud }
