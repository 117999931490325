import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { CustomDimmer } from '../components/CustomDimmer'
import { getPlantSchedule, savePlantSchedule } from '../services/schedule'
import { useSelector } from 'react-redux'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import {
  DAYS,
  DAY_NAMES,
  buildDaysShifts,
  padSchedule
} from '../services/scheduleRules'

const ScheduleContext = createContext()

const ScheduleProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isWorking,     setIsWorking]     = useState(false)
  const [isLoading,     setIsLoading]     = useState(false)
  const [selectedPlant, setSelectedPlant] = useState('')
  const [saveParams,    setSaveParams]    = useState([])
  const [plantSchedule, setPlantSchedule] = useState([])

  const handleError = ({ message }) => showErrorAlert(message)

  useEffect(() => {
    if (selectedPlant)
    currentPlantSchedule()
    setPlantSchedule(DAY_NAMES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant])

  const clear = () => {
    setSaveParams([])
    setSelectedPlant('')
    setPlantSchedule(DAY_NAMES)
    setIsWorking(false)
    setIsLoading(false)
  }

  const currentPlantSchedule = () => {
    setIsWorking(true)
    getPlantSchedule(selectedPlant, credentials.token)
      .then(response => preparePlantSchedule(response))
      .catch(error => handleError(error.data))
      .finally(() => setIsWorking(false))
  }

  const preparePlantSchedule = schedules => {
    const guarantedSchedule = []
    DAYS.forEach(day => {
      const daySchedules = schedules.filter(s => s.dia === day)
      guarantedSchedule.push(...padSchedule(day, daySchedules))
    })
    const daysWithShifts = buildDaysShifts(guarantedSchedule)
    setPlantSchedule(daysWithShifts)
  }

  const getTime = (currentValue, newValue, isStartTime) => (
    isStartTime ? newValue : currentValue
  )

  const getShift = (shift, shiftObject, value, isStartTime) => {
    if (shiftObject?.tipoTurnoId !== shift) return shiftObject
    return {
      ...shiftObject,
      horaInicio: getTime(shiftObject?.horaInicio, value, isStartTime),
      horaFin: getTime(shiftObject?.horaFin, value, !isStartTime)
    }
  }

  const setEditedShift = (day, shift, isStartTime, value) => {
    const updatedSchedule = plantSchedule.map(p => {
      if (p.day !== day) return p
      return {
        ...p,
        morningShift: getShift(shift, p.morningShift, value, isStartTime),
        eveningShift: getShift(shift, p.eveningShift, value, isStartTime),
        nightShift:   getShift(shift, p.nightShift, value, isStartTime)
      }
    })
    setPlantSchedule(updatedSchedule)
  }

  const prepareSaveParams = () => (
    plantSchedule.map(d => (
      saveParams.push(d.morningShift, d.eveningShift, d.nightShift)
    ))
  )

  const getShiftInfo = shift => ({
    horaInicio: shift.horaInicio.toString(),
    horaFin:    shift.horaFin.toString(),
    isInverted: shift.horaFin < shift.horaInicio
  })

  const getCollisionFormat = () => (
    plantSchedule.map(p => ([
      getShiftInfo(p.morningShift),
      getShiftInfo(p.eveningShift),
      getShiftInfo(p.nightShift)
    ]))
  )

  const collisionEvaluator = (value, s) => (
    (!s.isInverted && (value > s.horaInicio && value < s.horaFin)) ||
    (s.isInverted  && (value > s.horaInicio || value < s.horaFin))
  )

  const hasConfig = schedule => (
    schedule.horaInicio !== '00:00' || schedule.horaFin !== '00:00'
  )

  const searchCollision = (schedule, index = 0) => {
    if (index >= schedule.length) return false
    if (!hasConfig(schedule[index])) return false
    const value = schedule[index].horaInicio
    const hasCollision = schedule
      .filter((_, i) => i !== index)
      .some(s => collisionEvaluator(value, s))
    if (hasCollision) return true
    return searchCollision(schedule, index + 1)
  }

  const hasCollisions = () => {
    const evaluationArrays = getCollisionFormat()
    return evaluationArrays.some(s => searchCollision(s, 0))
  }

  const save = () => {
    if (!selectedPlant)
      return showErrorAlert('Favor de seleccionar una planta')
    if (hasCollisions()) return showErrorAlert('Existe colisión entre turnos')
    setIsWorking(true)
    setIsLoading(true)
    prepareSaveParams()
    savePlantSchedule(selectedPlant, saveParams, credentials.token)
      .then(data => showOkAlert(data.message))
      .catch(error => handleError(error))
      .finally(() => clear())
  }

  const context = {
    isWorking,
    plantSchedule,
    selectedPlant,

    save,
    setSelectedPlant,
    setEditedShift
  }

  return (
    <AuthProvider>
      <ScheduleContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </ScheduleContext.Provider>
    </AuthProvider>
  )
}

const useScheduleContext = () => useContext(ScheduleContext)

export { ScheduleProvider, useScheduleContext }
