import React from 'react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'

const LinkModalChecklist = ({ unit }) => {

  const context = useParkedUnitsContext()

  const handleModal = () => {
    context.setCurrentUnit(unit)
    context.setShowModal(true)
  }

  if (!unit?.idNavixy)
    return 'Sin checklist'
  else
    return (
      <strong className="link" onClick={ handleModal } >
        Mostrar
      </strong>
    )
}

export { LinkModalChecklist }
