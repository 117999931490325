import React from 'react'
import {
  AuthorizedFreightsProvider
} from '../contexts/AuthorizedFreightsContext'
import { ClientDropdown } from '../components/authorized_freights/ClientDropdown'
import { SectionOne } from '../components/authorized_freights/SectionOne'
import { SectionTwo } from '../components/authorized_freights/SectionTwo'
import {
  ConfirmDestroyModal
} from '../components/authorized_freights/ConfirmDestroyModal'
import {
  IsInternalCheckbox
} from '../components/authorized_freights/IsInternalCheckbox'

const AuthorizedFreights = () => {
  return (
    <AuthorizedFreightsProvider>
      <div className='screen-freights'>
        <h1>Registro de fletes autorizados</h1>
        <ConfirmDestroyModal />
        <div className='bar'>
          <ClientDropdown />
          <IsInternalCheckbox />
        </div>
        <div className='content'>
          <SectionOne />
          <SectionTwo />
        </div>
      </div>
    </AuthorizedFreightsProvider>
  )
}

export { AuthorizedFreights }
