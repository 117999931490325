import React from 'react'
import { Form } from 'semantic-ui-react'
import { AliasInput } from './form/AliasInput'
import { MainContactInput } from './form/MainContactInput'
import { EmailsInput } from './form/EmailsInput'
import { StateRadio } from './form/StateRadio'
import { DoubleTankRadio } from './form/DoubleTankRadio'
import { PhoneInput } from './form/PhoneInput'
import { ApiKeyParkimovilInput } from './form/ApiKeyParkimovilInput'
import { SecretKeyParkimovilInput } from './form/SecretKeyParkimovilInput'
import { TypePlantRadio } from './form/TypePlantRadio'
import { RelatedPlantDropdown } from './form/RelatedPlantDropdown'
import { GeofencePlant } from './form/GeofencePlant'
import { NotesInput } from './form/NotesInput'
import { YardForm } from './form/yard_form/YardForm'
import { YardsTable } from './form/YardsTable'
import { Actions } from './form/Actions'

const PlantForm = () => {
  return (
    <Form className='form'>
      <div className='double-column'>
        <div>
          <AliasInput />
          <MainContactInput />
          <EmailsInput />
        </div>
        <div>
          <StateRadio />
          <DoubleTankRadio />
          <PhoneInput />
        </div>
      </div>
      <div className='single-column'>
        <ApiKeyParkimovilInput />
        <SecretKeyParkimovilInput />
        <TypePlantRadio />
        <RelatedPlantDropdown />
        <GeofencePlant />
        <NotesInput />
      </div>
      <YardForm />
      <YardsTable />
      <Actions />
    </Form>
  )
}

export { PlantForm }
