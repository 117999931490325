import React from 'react'
import { RemissionDropdown } from './stay_claim_section_two/RemissionDropdown'
import { Client } from './stay_claim_section_two/Client'
import { Destiny } from './stay_claim_section_two/Destiny'
import { ArrivalDateInput } from './stay_claim_section_two/ArrivalDateInput'
import { UnloadingDateInput } from './stay_claim_section_two/UnloadingDateInput'
import { StayDaysInput } from './stay_claim_section_two/StayDaysInput'
import { Evidence } from './stay_claim_section_two/Evidence'
import { NotesInput } from './stay_claim_section_two/NotesInput'
import { SaveButton } from './stay_claim_section_two/actions/SaveButton'

const StayClaimSectionTwo = () => {

  return (
    <div className='section-two'>
      <RemissionDropdown />
      <Client />
      <Destiny />
      <ArrivalDateInput />
      <UnloadingDateInput />
      <StayDaysInput />
      <Evidence />
      <NotesInput />
      <SaveButton />
    </div>
  )
}

export { StayClaimSectionTwo }
