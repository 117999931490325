import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const PlateTankTwoInput = ({ plateTankTwo = '', setPlateTankTwo = () => {} }) => {
  const plateTankTwoHandler = createInputValueHandler(setPlateTankTwo)

  return (
    <div className="client-unit-modal-field">
      <label>Placas TQ2:</label>
      <Input
        value={ plateTankTwo }
        onChange={ plateTankTwoHandler }
        placeholder="Placas TQ2"
      />
    </div>
  )
}

export { PlateTankTwoInput }
