import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { downloadExcel } from '../services/excelButton'
import { showErrorAlert } from '../helpers/notifications'
import { useSelector } from 'react-redux'

const ExcelButton = ({
  endpoint,
  fileName = 'Información de catálogo',
  icon = 'file alternate'
}) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isLoading, setIsLoading] = useState(false)

  const createBlob = arraybuffer => (
    new Blob([ arraybuffer ], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
  )

  const handleDownload = arraybuffer => {
    const blob    = createBlob(arraybuffer)
    const link    = document.createElement('a')
    link.href     = URL.createObjectURL(blob)
    link.download = `${fileName}.xlsx`
    link.click()
  }

  const handleClickDownload = () => {
    setIsLoading(true)
    downloadExcel(credentials?.token, endpoint)
      .then(handleDownload)
      .catch(error => showErrorAlert(error?.message))
      .finally(() => setIsLoading(false))
  }

  return (
    <Button
      primary
      id='c-excel-button'
      loading={ isLoading }
      disabled={ isLoading }
      onClick={ handleClickDownload }
    >
      <Icon name={ icon } />
      Descargar XLS
    </Button>
  )
}

export { ExcelButton }
