import React, { useRef } from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'
import { showErrorAlert } from '../../../../helpers/notifications'

const UploadButton = () => {
  const context = useOrdersInTransitStayContext()

  const fileInputRef = useRef(null)

  const handleChooseFile = e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return
    if (selectedFile.type !== 'application/pdf')
      return showErrorAlert('Solo se pueden subir archivos PDF')
    const reader = new FileReader()
    reader.onload = () => context.upload(reader.result)
    reader.readAsDataURL(selectedFile)
  }

  const onClick = () => {
    fileInputRef.current.click()
  }

  return (
    <>
      <input
        type="file"
        ref={ fileInputRef }
        onChange={ handleChooseFile }
      />
      <Button
        primary
        loading={ context.isWorking }
        disabled={ context.isWorking || !context.selectedOrderDetail }
        onClick={ onClick }
      >
        Adjuntar PDF
      </Button>
    </>
  )
}

export { UploadButton }
