import React from 'react'
import { InitialInventoryProvider } from '../contexts/InitialInventoryContext'
import {
  PlantDropdown
} from '../components/initial_inventory/initial_inventory_form/PlantDropdown'
import {
  InventoryDate
} from '../components/initial_inventory/initial_inventory_form/InventoryDate'
import { SaveButton } from '../components/initial_inventory/SaveButton'
import {
   InventoryInput
} from '../components/initial_inventory/initial_inventory_form/inventoryInput'

const InitialInventory = () => {

  return (
    <InitialInventoryProvider>
      <div className='screen-initial-inventory'>
        <h1>Inventario inicial</h1>
        <div className='content'>
          <PlantDropdown />
          <InventoryDate />
          <InventoryInput />
          <SaveButton />
        </div>
      </div>
    </InitialInventoryProvider>
  )
}

export { InitialInventory }
