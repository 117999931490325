import React from 'react'
import { ExcelButton } from '../../ExcelButton'
import { useUnitUseContext } from '../../../contexts/UnitUseContext'

const XLSDownloadButton = () => {
  const context = useUnitUseContext()

  return (
    <div className="download-button-container">
      { context.unitUseData?.length ? (
        <div>
            <ExcelButton
              endpoint={ context.excelEndpoint }
              fileName='Reporte_de_uso'
            />
          </div>
        ) : (<></>)
      }
    </div>
  )
}

export { XLSDownloadButton }
