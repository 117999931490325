import React from 'react'
import { TanksProvider } from '../contexts/TanksContext'
import { FirstContainer } from '../components/tanks/form/FirstContainer'
import { SecondContainer } from '../components/tanks/form/SecondContainer'
import { Table } from '../components/tanks/Table'
import { Form } from 'semantic-ui-react'
import {
  ExcelDownloadButton
} from '../components/tanks/form/actions/ExcelDownloadButton'
import { ConfirmDestroyModal } from '../components/tanks/ConfirmDestroyModal'

const Tanks = () => {

  return (
    <TanksProvider>
      <div className='screen-tanks'>
        <ConfirmDestroyModal />
        <div className='header'>
          <h1 className='title'>Tanques</h1>
          <ExcelDownloadButton />
        </div>
        <div className='content'>
          <Form>
            <FirstContainer />
            <SecondContainer />
          </Form>
          <Table />
        </div>
      </div>
    </TanksProvider>
  )
}

export { Tanks }
