import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../contexts/OrderAssignationContext'

const UnassignModal = () => {
  const context = useOrderAssignationContext()

  const handleOnClickCancel = () => context.setShowUnassignModal(false)
  const handleOnClickConfirm = () => context.unassign()

  return (
    <Modal
      centered
      enforceFocus
      show={ context.showUnassignModal }
      onHide={ handleOnClickCancel }
    >
      <Modal.Header closeButton>
        <Modal.Title>Desasignar operador</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Al desasignar operador, se realizaran las siguientes acciones:
        <ul>
            <li>La unidad asignada pasara a <b>disponible</b></li>
            <li>El pedido pasara a estado <b>sin asignar</b></li>
            <li><b>Se eliminaran</b> las tareas de navixy, correspondientes a este pedido</li>
        </ul>
        ¿Está seguro que desea desasignar operador?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="grey"
          onClick={ handleOnClickCancel }
        >
          Cancelar
        </Button>
        <Button
          color="red"
          loading={ context.isWorking }
          onClick={ handleOnClickConfirm }
        >
          Confirmar desasignación
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { UnassignModal }
