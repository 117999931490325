import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import { numberFormat } from '../../../../helpers/number'

const SecondTankPlateLabel = () => {
  const context = useOrdersInTransitContext()

  const getMeasure  = () => context.orderDetails.info.unidadMedidaTanqueDos
  const getPlacas   = () => context.orderDetails.info.placasTanqueDos
  const getCapacity = () => context.orderDetails.info.capacidadTanqueDos ?
    `(${numberFormat(context.orderDetails.info.capacidadTanqueDos)} ${getMeasure()})` : ''

  const getText = () => getPlacas() ? `${getPlacas()} ${getCapacity()}` : 'Sin asignar'

  return (
    <div>
      <div>Placas TQ2:</div>
      <div>
        { getText() }
      </div>
    </div>
  )
}

export { SecondTankPlateLabel }
