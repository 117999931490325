import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const AuthorizedDate = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setAuthorizedDate)

  return (
    <div className='form-field'>
      <label>Fecha de autorización:</label>
      <Input
        type='date'
        value={ context.authorizedDate || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { AuthorizedDate }
