import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const DriverInput = ({ driver = '', setDriver = () => {} }) => {
  const driverHandler = createInputValueHandler(setDriver)

  return (
    <div className="client-unit-modal-field">
      <label>Operador:</label>
      <Input
        value={ driver }
        onChange={ driverHandler }
        placeholder="Operador"
      />
    </div>
  )
}

export { DriverInput }
