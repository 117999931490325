import React from 'react'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import {
  useOrdersInTransitInfoModalContext
} from '../../../contexts/OrdersInTransitInfoModalContext'

const ModalToFinishOrderByRealQuantity = () => {

  const contextModal = useOrdersInTransitInfoModalContext()

  const handleOnClickCancel = () => {
    contextModal.setShowModalToFinishOrderByRealQuantity(false)
  }

  return (
    <Modal
      centered
      enforceFocus
      show={ contextModal.showModalToFinishOrderByRealQuantity }
      onHide={ handleOnClickCancel }
    >
      <Modal.Header closeButton>
        <Modal.Title>Terminación de pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se ha capturado Candidad real para este pedido,
        se cambiará el estado a "Terminado" ¿Está seguro que desea continuar?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='grey'
          style={{ fontSize: 12 }}
          onClick={ handleOnClickCancel }
        >
          Cancelar
        </Button>
        <Button
          color='red'
          style={{ fontSize: 12 }}
          loading={ contextModal.isConfirming }
          onClick={ contextModal.saveChanges }
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalToFinishOrderByRealQuantity }
