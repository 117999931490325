import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const IncidenceDateField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Fecha incidencia:</label>
      <label>{ context.selectedIncidence?.fechaIncidencia }</label>
    </div>
  )
}

export { IncidenceDateField }
