import React from 'react'
import { InactivityReportProvider } from '../contexts/InactivityReportContext'
import { Icon } from 'semantic-ui-react'
import { Table } from '../components/inactivity_report/Table'
import { SearchBar } from '../components/inactivity_report/SearchBar'
import {
  XLSDownloadButton
} from '../components/inactivity_report/actions/XLSDownloadButton'

const InactivityReport = () => {
  return (
    <InactivityReportProvider>
      <div className='screen-inactivity-report'>
        <h1>Reporte de porcentaje de inactividad</h1>
        <XLSDownloadButton />
        <div className='content'>
          <div><Icon name='filter'/>Filtrar por:</div>
          <SearchBar />
          <Table />
        </div>
      </div>
    </InactivityReportProvider>
  )
}

export { InactivityReport }
