import axios from 'axios'
import { dateToYYYYMMDD } from '../helpers/date'
import { showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const getUrl = () => `${resources.urls.ergonApi}/authorizedStaysLog`

const buildHeaders = token => ({
  headers: { Authorization: token }
})

const getSearchUrl = params => {
  const plantId = `plantId=${params.plantId}`
  const startDate = dateToYYYYMMDD(params.startDate)
  const endDate = dateToYYYYMMDD(params.endDate)
  const textStartDate = params.startDate ? `&startDate=${startDate}` : ''
  const textEndDate = params.endDate ? `&endDate=${endDate}` : ''
  return `${getUrl()}?${plantId}${textStartDate}${textEndDate}`
}

const search = async (token, params) => {
  const url = getSearchUrl(params)
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return []
  }
}

const getById = async (token, id) => {
  const url = `${getUrl()}/${id}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return {}
  }
}

export default {
  search,
  getById
}
