import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { usePlantsContext } from '../../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../../helpers/input'

const YardDropdown = () => {
  const context = usePlantsContext()
  const yardNameHandler = createInputValueHandler(context.setYardName)

  const typeYardHandler = (_, { value }) => {
    const geofence = context.typeYardGeofences.find(g => g.value === value)
    context.setCurrentYard(geofence)
    context.setYardName(geofence.text)
  }

  return (
    <div className='field'>
      <label>Patio/Pensón:</label>
      <div>
        <Dropdown
          fluid
          search
          selection
          value={ context.currentYard?.key || '' }
          options={ context.typeYardGeofences }
          onChange={ typeYardHandler }
          placeholder='seleccione una geocerca'
        />
        <Input
          placeholder='Nombre'
          value={ context.yardName }
          onChange={ yardNameHandler }
        />
      </div>
    </div>
  )
}

export { YardDropdown }
