import React from 'react'
import { Input } from 'semantic-ui-react'
import { useVehicleContext } from '../../../contexts/vehicleContext'

const TanksCapacities = () => {
  const context = useVehicleContext()

  const getCapacity = tank => tank?.capacidad || ''
  const getMeasureUnit = tank => tank?.unidadMedidaAbreviacion || ''

  const getTankOneCapacity = () => {
    const tank = context.findTankOne()
    return `${getCapacity(tank)} ${getMeasureUnit(tank)}`
  }

  const getTankTwoCapacity = () => {
    const tank = context.findTankTwo()
    return `${getCapacity(tank)} ${getMeasureUnit(tank)}`
  }

  return (
    <div className='tanks-inputs'>
      <Input
        disabled
        value={ getTankOneCapacity() }
      />
      <Input
        disabled
        value={ getTankTwoCapacity() }
      />
    </div>
  )
}

export { TanksCapacities }
