import React, { useEffect } from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'
import { sumMonths } from '../../../../../helpers/date'

const DEFAULT_EXPIRATION = 6

const ExpirationDate = () => {
  const context = useAuthorizedFreightsContext()

  const estimateExpirationDate = () => {
    if (context.isEditing) return
    const estimatedDate = sumMonths(context.authorizedDate, DEFAULT_EXPIRATION)
    context.setExpirationDate(estimatedDate)
  }

  useEffect(() => {
    if (context.authorizedDate) estimateExpirationDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.authorizedDate])

  const InputHandler = createInputValueHandler(context.setExpirationDate)

  return (
    <div className='form-field'>
      <label>Fecha de vigencia:</label>
      <Input
        type='date'
        value={ context.expirationDate || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { ExpirationDate }
