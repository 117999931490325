import React from 'react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { PlantsDropdown } from '../../PlantsDropdown'

const PlantDropdown = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Planta:</label>
      <PlantsDropdown setSelectedPlant={ context.setSelectedPlantId } />
    </div>
  )
}

export { PlantDropdown }
