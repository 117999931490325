import React from 'react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const Plant = () => {
  const context = useStayClaimContext()

  return (
    <div className='item'>
      <label>Planta:</label>
      <label className='value'>
        { context.plant || 'Sin asignar' }
      </label>
    </div>
  )
}

export { Plant }
