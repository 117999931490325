import React from 'react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'

const InputDriver = () => {
  const context = useParkedUnitsContext()

  return (
    <div className="form-field">
      <label>Operador:</label>
      <label>{ context.unitInfo.operador }</label>
    </div>
  )
}

export { InputDriver }
