import React from 'react'
import { ImageContainer } from '../../ImageContainer'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { createFileInputHandler } from '../../../helpers/input'

const ImageEvidence = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createFileInputHandler(context.setImage)

  return (
    <ImageContainer
      source={ context.image }
      onChangeImage={ handleInput }
      isLoading={ context.isLoadingImage }
    />
  )
}

export { ImageEvidence }
