import React from 'react'
import { Button } from 'semantic-ui-react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'

const ButtonDownloadChecklist = () => {
  const context = useParkedUnitsContext()

  const handleClick = () => context.downloadChecklist(context.unitInfo.idNavixy)

  return (
    <Button
      color="grey"
      disabled={ !context.unitInfo.idNavixy || context.isWorking }
      loading={ context.isWorking }
      onClick={ handleClick }
    >
      Último checklist
    </Button>
  )
}

export { ButtonDownloadChecklist }
