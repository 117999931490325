import React from 'react'
import { useUsersContext } from '../../contexts/UsersContext'
import { Radio } from 'semantic-ui-react'

const LevelOptions = () => {
  const context = useUsersContext()

  const radioHandler = (_, { value }) => context.setLevel(value)

  return (
    <div>
      <label>Nivel: </label>
      <div className='options'>
        <div>
          <Radio
            value={ 1 }
            checked={ context.level === 1 }
            onChange={ radioHandler }
          />
          <label> Central </label>
        </div>
        <div>
          <Radio
            value={ 2 }
            checked={ context.level === 2 }
            onChange={ radioHandler }
          />
          <label> Planta </label>
        </div>
      </div>
    </div>
  )
}

export { LevelOptions }
