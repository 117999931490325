import React from 'react'
import { SearchBar } from '../components/daily_status/SearchBar'
import { Table } from '../components/daily_status/Table'
import { DailyStatusProvider } from '../contexts/DailyStatusContext'

const DailyStatus = () => {
  return (
    <DailyStatusProvider>
      <h1>Reporte de estatus diario</h1>
      <div className="screen-daily-status">
        <SearchBar/>
        <Table/>
      </div>
    </DailyStatusProvider>
  )
}

export { DailyStatus }
