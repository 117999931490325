import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'
import { createInputValueHandler } from '../../../../helpers/input'

const options = { parseToFloat: true }

const RealQuantityInput = () => {
  const context = useOrdersInTransitContext()
  const contextModal = useOrdersInTransitInfoModalContext()

  const getValue = () => contextModal.realQuantity
  const handleInput = (
    createInputValueHandler(contextModal.setRealQuantity, options)
  )
  const hasDetails = () => context.orderDetails.details?.length
  const isDisabled = () => !hasDetails()

  return (
    <>
      <div>Cantidad real:</div>
      <Input
        type="number"
        step={ 0.01 }
        min={ 0.0 }
        lang="es-MX"
        placeholder="Cantidad real"
        disabled={ isDisabled() }
        value={ getValue() }
        onChange={ handleInput }
      />
    </>
  )
}

export { RealQuantityInput }
