import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const DriverDropdown = () => {
  const context = useOrderAssignationContext()

  useEffect(() => {
    if (!context.folio) return
    const { id, name } = getCurrentDriver()
    context.setOperadorId(id)
    context.setOperador(name)
    // eslint-disable-next-line
  }, [context.folio, context.unitType, context.unidadId])

  const getUnitList = () => {
    if (context.unitType === 1) return context.parkedUnits
    if (context.unitType === 2) return context.outsideUnits
    return context.unitsWithoutGPS
  }

  const getUnitDriverId = () => {
    const units = getUnitList()
    const unit = units.find(u => u.value === context.unidadId)
    return unit?.operadorId
  }

  const findDriverById = id => context.drivers.find(d => d.value === id)

  const getUnitDriver = () => {
    const driverId = getUnitDriverId()
    const driver   = findDriverById(driverId)
    return { id: driver?.value || '', name: driver?.text || '' }
  }

  const handleDriver = (_, { value }) => {
    context.setOperadorId(value)
    const driverName = findDriverById(value)?.text
    context.setOperador(driverName)
  }

  const isOrderUnit = () => context.orderUnitId === context.unidadId

  const getOrderDriver = () => ({
    id:   context.orderDriverId,
    name: context.orderDriverName
  })

  const getCurrentDriver = () => (
    isOrderUnit() ? getOrderDriver() : getUnitDriver()
  )

  return (
    <div className='form-field'>
      <label>Operador:</label>
      <Dropdown
        openOnFocus={ false }
        clearable
        placeholder='Seleccione un operador'
        fluid
        selection
        search
        options={ context.drivers }
        value={ context.operadorId }
        onChange={ handleDriver }
      />
    </div>
  )
}

export { DriverDropdown }
