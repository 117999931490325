import React from 'react'
import { Button } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const SaveButton = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="row">
      <Button
        primary
        onClick={ context.save }
      >
        Guardar
      </Button>
    </div>
  )
}

export { SaveButton }
