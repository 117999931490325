import React from 'react'
import { StyledTable } from '../../StyledTable'
import { useOrdersInTransitContext } from '../../../contexts/OrdersInTransitContext'
import { useOrdersInTransitInfoModalContext } from '../../../contexts/OrdersInTransitInfoModalContext'

const headers = [
  { path: 'remision',     title: 'Remisión'      },
  { path: 'producto',     title: 'Producto'      },
  { path: 'domicilio',    title: 'Domicilio'     },
  { path: 'razonSocial',  title: 'Razón Social'  },
  { path: 'coordenadas',  title: 'Lat. Long.'    },
  { path: 'cantidadReal', title: 'Cantidad real' },
  { path: 'UnidadMedida', title: 'U. M'          }
]

const OrderDetailsTable = () => {

  const { orderDetails } = useOrdersInTransitContext()
  const contextModal     = useOrdersInTransitInfoModalContext()

  const handleShowMap = row => {
    const url = `https://www.google.com.sa/maps/search/${row.coordenadas}`
    window.open(url, '_blank')
  }

  const handleOnClickRow = (detail, index) => (
    contextModal.handleSelectDetail(detail, index)
  )

  return (
    <div>
      <StyledTable
        enableMap
        search={ false }
        data={ orderDetails.details }
        handleOnClickRow={ handleOnClickRow }
        handleOnMap={ handleShowMap }
        headers={ headers }
      />
    </div>
  )
}

export { OrderDetailsTable }
