import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { getClients, getFreights } from '../services/freightsConsult'
import { showErrorAlert } from '../helpers/notifications'

const FreightsConsultContext = createContext()

const FreightsConsultProvider = ({ children }) => {

  const { credentials } = useSelector((state) => state.auth)
  const [isWorking,         setIsWorking        ] = useState(false)
  const [isInternal,        setIsInternal       ] = useState(false)
  const [originIdFilter,    setOriginIdFilter   ] = useState(null)
  const [clientIdFilter,    setClientIdFilter   ] = useState(null)
  const [clientsDataFilter, setClientsDataFilter] = useState([])
  const [freightsData,      setFreightsData     ] = useState([])
  const excelEndpoint = `/freightsConsult/downloadExcel?origenPlantaId=${originIdFilter}&esExterno=${!isInternal}&clienteId=${clientIdFilter}`

  useEffect(() => {
    if (credentials) loadClients()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const loadClients = async () => (
    setClientsDataFilter(await getClients(credentials.token))
  )

  const prepareParams = () => ({
    isExternal: !isInternal,
    origin: originIdFilter,
    client: clientIdFilter
  })

  const search = async () => {
    if (!originIdFilter && !clientIdFilter)
      return showErrorAlert('Favor de seleccionar un Origen o Cliente.')
    setIsWorking(true)
    setFreightsData(await getFreights(prepareParams(), credentials.token))
    setIsWorking(false)
  }

  const context = {
    isWorking,
    isInternal,
    originIdFilter,
    clientIdFilter,
    clientsDataFilter,
    freightsData,
    excelEndpoint,
    setIsInternal,
    setOriginIdFilter,
    setClientIdFilter,
    setFreightsData,
    search
  }

  return (
    <AuthProvider>
      <FreightsConsultContext.Provider value={ context }>
        { children }
      </FreightsConsultContext.Provider>
    </AuthProvider>
  )
}

const useFreightsConsultContext = () => useContext(FreightsConsultContext)

export { FreightsConsultProvider, useFreightsConsultContext }
