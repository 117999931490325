import React from 'react'
import { Radio } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const OrderProgrammedSwitch = () => {
  const context = useOrderContext()

  return (
    <div className="form-field">
      <label>Programado:</label>
      <div className='radio-group'>
        <Radio
          label='Si'
          name='programmed'
          checked={ context.programado }
          onChange={ () => context.setProgramado(true) }
        />
        <Radio
          label='No'
          name='programmed'
          checked={ !context.programado }
          onChange={ () => context.setProgramado(false) }
        />
      </div>
    </div>
  )
}

export { OrderProgrammedSwitch }
