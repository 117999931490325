import React from 'react'
import { useUploadEvidenceContext }
  from '../../../contexts/UploadEvidenceContext'
import { Form, TextArea } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const AreaToIntervene = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createInputValueHandler(context.setAreaToIntervene)

  return (
    <div className="big-container">
      <label>Área a intervenir:</label>
      <Form>
        <TextArea
          type='text'
          value={ context.areaToIntervene }
          onChange={ handleInput }
          placeholder='Ingrese área a internvenir'
        />
      </Form>
    </div>
  )
}

export { AreaToIntervene }
