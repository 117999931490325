import axios from 'axios'
import resources from '../resources'

const buildUrl = (url = '') => (
  `${resources.urls.ergonApi}/authorizedFreights/${url}`
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const clients = async token => {
  const { data } = await axios.get(buildUrl('clients'), buildHeaders(token))
  return data
}

const geofenceDestinations = async (token, id) => {
  const { data } = await axios.get(
    buildUrl(`clientDestinations/${id}`),
    buildHeaders(token)
  )
  return data
}

const federativeEntities = async token => {
  const { data } = await axios.get(
    buildUrl('federativeEntities'),
    buildHeaders(token)
  )
  return data
}

const saveFreight = async (params, token) => {
  const { data } = await axios.post(
    buildUrl(),
    params,
    buildHeaders(token)
  )
  return data
}

const getFreightData = async token => {
  const { data } = await axios.get(buildUrl(), buildHeaders(token))
  return data
}

const getFreightById = async (id, token) => {
  const { data } = await axios.get(buildUrl(id), buildHeaders(token))
  return data
}

const updateFreight = async (id, params, token) => {
  const { data } = await axios.put(buildUrl(id), params, buildHeaders(token))
  return data
}

const deleteFreight = async (id, token) => {
  const { data } = await axios.delete(buildUrl(id), buildHeaders(token))
  return data
}

const getPDF = async (uid, token) => {
  const { data } = await axios.get(
    buildUrl(`downloadEvidence/${uid}`), {
      ...buildHeaders(token),
      responseType: 'arraybuffer'
    }
  )
  return data
}

export {
  clients,
  geofenceDestinations,
  federativeEntities,
  saveFreight,
  getFreightData,
  getFreightById,
  updateFreight,
  deleteFreight,
  getPDF
}
