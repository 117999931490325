import React from 'react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const StatsInfo = () => {
  const context = useOrderAssignationContext()

  const getTotalOrders = () => context.filteredOrders.length
  const getTotalAssigned = () => (
    context.filteredOrders.filter(o => o.isAssigned === 1).length
  )
  const getTotalUnassigned = () => getTotalOrders() - getTotalAssigned()

  return (
    <div className="stats-info">
      <label>Pedidos: { getTotalOrders() }</label>
      <label>Asignados: { getTotalAssigned() }</label>
      <label>Sin Asignar: { getTotalUnassigned() }</label>
    </div>
  )
}

export { StatsInfo }
