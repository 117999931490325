import React, { createContext, useContext, useEffect, useState } from 'react'
import { CustomDimmer } from '../components/CustomDimmer'
import { AuthProvider } from './authContext'
import service from '../services/authorizationStaysLog'
import downloadService from '../services/ordersInTransitStays'
import { useSelector } from 'react-redux'
import { showErrorAlert } from '../helpers/notifications'

const AuthorizedStaysLogContext = createContext()

const AuthorizedStaysLogProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlant, setSelectedPlant] = useState('')
  const [[startDate, endDate], setDateRange] = useState([null, null])
  const [authorizations, setAuthorizations] = useState([])
  const [selectedAuthorization, setSelectedAuthorization] = useState(null)
  const [info, setInfo] = useState({})

  const getToken = () => credentials.token

  useEffect(() => {
    if (!selectedAuthorization) return
    getById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuthorization])

  const search = async () => {
    if (!selectedPlant) return showErrorAlert('Seleccione una planta')
    setInfo({})
    setIsLoading(true)
    const params = {
      plantId: selectedPlant,
      startDate: startDate,
      endDate: endDate
    }
    const response = await service.search(getToken(), params)
    setAuthorizations(response)
    setIsLoading(false)
  }

  const getById = async () => {
    setIsLoading(true)
    const response = await service.getById(getToken(), selectedAuthorization.id)
    setInfo(response)
    setIsLoading(false)
  }

  const generateDownload = data => {
    const filename  = `reclamo_de_estadia_${info.id}.pdf`
    const blob      = new Blob([data])
    const windowUrl = window.URL.createObjectURL(blob)
    const link      = document.createElement('a')
    link.href       = windowUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const download = async () => {
    setIsLoading(true)
    const response = await downloadService.download(info.id, getToken())
    if (response) generateDownload(response)
    setIsLoading(false)
  }

  const context = {
    authorizations,
    setAuthorizations,
    setSelectedPlant,
    info,
    startDate,
    endDate,
    setDateRange,
    selectedAuthorization,
    setSelectedAuthorization,
    search,
    getById,
    download,
    isLoading
  }

  return (
    <AuthProvider>
      <AuthorizedStaysLogContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading }/>
        { children }
      </AuthorizedStaysLogContext.Provider>
    </AuthProvider>
  )
}

const useAuthorizedStaysLogContext = () => useContext(AuthorizedStaysLogContext)

export { AuthorizedStaysLogProvider, useAuthorizedStaysLogContext }
