import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components/CustomDimmer'
import { dateToYYYYMMDD } from '../helpers/date'
import { showErrorAlert } from '../helpers/notifications'
import { getShipmentCompanies, getUnitStatus } from '../services/dailyStatus'

const DailyStatusContext = createContext()

const DailyStatusProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [units, setUnits] = useState([])
  const [shipmentCompanies, setShipmentCompanies] = useState([])
  const [plantId, setPlantId] = useState('')
  const [shipmentCompanyId, setShipmentCompanyId] = useState('')
  const [[startDate, endDate], setDateRange] = useState([null, null])

  useEffect(() => {
    if (credentials) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const load = async () => {
    setIsLoading(true)
    setShipmentCompanies(await getShipmentCompanies(credentials.token))
    setIsLoading(false)
  }

  const getParams = () => ({
    plantId,
    shipmentCompanyId: shipmentCompanyId || null,
    startDate: startDate ? dateToYYYYMMDD(startDate) : null,
    endDate: endDate ? dateToYYYYMMDD(endDate) : null
  })

  const search = async  () => {
    if (plantId < 1) return showErrorAlert('Seleccione una planta')
    setIsLoading(true)
    const token = credentials.token
    setUnits(await getUnitStatus(token, getParams()))
    setIsLoading(false)
  }

  const context = {
    units,
    setUnits,
    plantId,
    setPlantId,
    shipmentCompanyId,
    setShipmentCompanyId,
    startDate,
    endDate,
    setDateRange,
    shipmentCompanies,
    search
  }

  return (
    <AuthProvider>
      <DailyStatusContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </DailyStatusContext.Provider>
    </AuthProvider>
  )
}

const useDailyStatusContext = () => useContext(DailyStatusContext)

export { DailyStatusProvider, useDailyStatusContext }
