import React from 'react'
import { Radio } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const OrderTypeSwitch = () => {
  const context = useOrderContext()

  const changeType = () => {
    setCase(1)
    context.setClienteId(0)
    context.setExterno(!context.externo)
    context.setUnidadCliente(null)
    context.setHasClientUnit(false)
    context.loadDestination(0)
  }

  const changeConfirmation = () => {
    if (context.isEditing && !context.editionAllowed){
      context.setAction({ action: changeType })
      return context.setShowConfirmEdit(true)
    }
    changeType()
  }

  const setCase = () => {
    if (!context.externo) return
    context.setCaso(1)
  }

  const canEdit = () => (
    !context.isEditing || (context.isEditing && !context.asignado)
  )

  return (
    <div className="form-field">
      <label>Tipo pedido:</label>
      <div className='radio-group'>
        <Radio
          label='Interno'
          name='orderType'
          disabled={ !canEdit() }
          checked={ !context.externo }
          onClick={ changeConfirmation }
        />
        <Radio
          label='Externo'
          name='orderType'
          disabled={ !canEdit() }
          checked={ context.externo }
          onClick={ changeConfirmation }
        />
      </div>
    </div>
  )
}

export { OrderTypeSwitch }
