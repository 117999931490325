import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'
import { usePlantsContext } from '../../../contexts/PlantsContext'

const MainContactInput = () => {
  const context = usePlantsContext()
  const contactHandler = createInputValueHandler(context.setMainContact)

  return (
    <div className='field'>
      <label>Contacto principal:</label>
      <Input
        value={ context.mainContact }
        onChange={ contactHandler }
      />
    </div>
  )
}

export { MainContactInput }
