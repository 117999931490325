import React from 'react'
import { Button } from 'semantic-ui-react'
import { ExcelButton } from '../ExcelButton'
import { useIncidencesReportContext } from '../../contexts/IncidencesReportContext'

const Actions = () => {
  const context = useIncidencesReportContext()
  return (
    <div className='actions'>
      <Button
        primary
        loading={ context.isLoading }
        onClick={ context.search }
      >
        Buscar
      </Button>
      <ExcelButton
        endpoint='/incidenceReport/download_excel'
        fileName='Incidencias'
      />
    </div>
  )
}

export { Actions }
