import React from 'react'
import { Order } from './stay_claim_section_one/Order'
import { Plant } from './stay_claim_section_one/Plant'
import { Operator } from './stay_claim_section_one/Operator'
import { Economic } from './stay_claim_section_one/Economic'
import { Plates } from './stay_claim_section_one/Plates'
import { TankPlates } from './stay_claim_section_one/TankPlates'
import { TankPlatesTwo } from './stay_claim_section_one/TankPlatesTwo'

const StayClaimSectionOne = () => {

  return (
    <div className='section-one'>
      <Order />
      <Plant />
      <Operator />
      <Economic />
      <Plates />
      <TankPlates />
      <TankPlatesTwo />
    </div>
  )
}

export { StayClaimSectionOne }
