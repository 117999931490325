import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const TemperatureInput = () => {
  const context = useOrderContext()

  const inputHandler = createInputValueHandler(context.setTemperaturaCompromiso)

  return (
    <div className="form-field">
      <label>Temp. compromiso:</label>
      <Input
        placeholder="temperatura compromiso"
        value={ context.temperaturaCompromiso }
        onChange={ inputHandler }
      />
    </div>
  )
}

export { TemperatureInput }
