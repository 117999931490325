import React from 'react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'

const Resume = () => {
  const context = useParkedUnitsContext()

  const getTotalUnits = () => context.units.length
  const getAvailableUnits = () => (
    context.units.filter(u => u.idEstadoUnidad === 1).length
  )

  return (
    <div className="resume">
      <label>
        <b>Unidades:</b>
        <span>{ getTotalUnits() }</span>
      </label>
      <label>
        <b>Disponibles:</b>
        <span>{ getAvailableUnits() }</span>
      </label>
    </div>
  )
}

export { Resume }
