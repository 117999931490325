import React from 'react'
import { useScheduleContext } from '../../contexts/ScheduleContext'
import { Day } from './schedule_form/Day'

const DaySchedule = () => {
  const context = useScheduleContext()

  return (
    <div className='week-container'>
      {
        context.plantSchedule.map((day, index) => (
          <Day day={ day } key={ index } />
        ))
      }
    </div>
  )
}

export { DaySchedule }
