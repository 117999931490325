import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const RefineryExitDateInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const getValue = () => contextModal.fechaSalidaRefineria || ''
  const handleInput = e => contextModal.setFechaSalidaRefineria(e.target.value)

  return (
    <div className="input">
      <div>Fecha de salida de la refinería:</div>
      <Input
        type="datetime-local"
        value={ getValue() }
        onChange={ handleInput }
      />
    </div>
  )
}

export { RefineryExitDateInput }
