import React from 'react'
import { Form, TextArea } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const PlantNotesInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const getValue = () => contextModal.plantRemarks || ''
  const handleInput = e => contextModal.setPlantRemarks(e.target.value)

  return (
    <div className='notas-pedido'>
      <div>Observaciones en planta:</div>
      <Form>
        <TextArea
          placeholder="Obs. Planta"
          value={ getValue() }
          onChange={ handleInput }
        />
      </Form>
    </div>
  )
}

export { PlantNotesInput }
