import React from 'react'
import { Alert } from 'react-bootstrap'
import { useVehicleContext } from '../../contexts/vehicleContext'

const ConfiguredVehicles = () => {
  const { vehicles, webfleetVehicles } = useVehicleContext()

  const isContextReady = () => vehicles.length && webfleetVehicles.length

  const configuredUnits = () => vehicles.filter(v => v.isConfigured).length

  const calculatePercentage = () => {
    if (isContextReady())
      return Math.round((configuredUnits() / webfleetVehicles.length) * 100)
  }

  return (
    <Alert variant='warning' >
      Unidades configuradas {configuredUnits()}/<b>{webfleetVehicles.length}</b>
      {" "}({calculatePercentage() || 0}%).
    </Alert>
  )
}

export { ConfiguredVehicles }
