import React from 'react'
import { createInputValueHandler } from '../../../helpers/input'
import { Dropdown } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const RemissionDropdown = () => {
  const context = useStayClaimContext()
  const handleChange = createInputValueHandler(context.onSelectDestinations)

  return (
    <div className='item'>
      <label>Remisión:</label>
      <Dropdown
        search
        selection
        placeholder='Selecciona una remisión'
        value={ context.remissionId }
        options={ context.destinations }
        onChange={ handleChange }
        id='remission-dropdown'
      />
    </div>
  )
}

export { RemissionDropdown }
