import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const TravelTimeLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => (
    context.orderDetails.info.tiempoTraslado || 'Sin asignar'
  )

  return (
    <div>
      <div>Horas traslado:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { TravelTimeLabel }
