import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ScreenWithNavItems } from '../components/ScreenWithNavItems'
import { CustomDimmer } from '../components/CustomDimmer'
import { getAuthFromStorage } from '../redux/actions'
import { getGeneralInfo } from '../services/ergonApi'
import { Table } from '../components/orders_today_monitor/Table'
import { ProgressBar } from '../components/orders_today_monitor/ProgressBar'
import { Header } from '../components/orders_today_monitor/Header'
import { showErrorAlert } from '../helpers/notifications'

const REFRESH_TIME = 300000

const OrdersTodaysMonitor = () => {
  const history = useHistory()
  const { credentials } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [orders,      setOrders]      = useState({})
  const [splitOrders, setSplitOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading,   setIsLoading]   = useState(false)
  const [progress,    setProgress]    = useState(0)

  useEffect(() => {
    if (credentials === null)
      dispatch(getAuthFromStorage())
    else if (credentials === false)
      history.push('/')
    else {
      getOrdersTodays()
      const refreshInterval = setInterval(() => getOrdersTodays(), REFRESH_TIME)
      return () => clearInterval(refreshInterval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const isLastPageReached = () => {
    const totalPages = splitOrders.length - 1
    return currentPage >= totalPages
  }

  const nextPage = () => {
    if (isLastPageReached()) return setCurrentPage(0)
    setCurrentPage(currentPage + 1)
  }

  const tick = () => setProgress(time => time + 0.1)

  useEffect(()=> {
    const interval = setInterval(tick, 30)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (progress >= 100) {
      nextPage()
      setProgress(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const getOrdersTodays = async () => {
    setIsLoading(true)
    const response = await getGeneralInfo(credentials.token, '/monitor')
    if (response.errorCode)
      showErrorAlert(response.message)
    else {
      setOrders(response)
      setSplitOrders(response.splitOrders)
    }
    setIsLoading(false)
  }

  return (
    <ScreenWithNavItems
      user={ credentials?.user }
      open={ false }
      userActions={ credentials?.actions }
    >
      <CustomDimmer visible={ isLoading } />
      <Header
        currentPage={ currentPage }
        pages={ splitOrders.length }
        orders={ orders }
      />
      <ProgressBar
        progress={ progress }
        hasMultiplePages={ splitOrders.length > 1 }
      />
      <Table
        paginatedOrders={ splitOrders }
        currentPage={ currentPage }
      />
    </ScreenWithNavItems>
  )
}

export { OrdersTodaysMonitor }
