import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const DestinationArrivalDate = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    context.stayInfo.entradaDestino || 'Sin entrada registrada'
  )

  return (
    <div className="form-field">
      <label>Llegada a destino:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { DestinationArrivalDate }
