import axios from 'axios'
import { showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const endpointUrl = `${resources.urls.ergonApi}/dailyUnitStatus`

const buildHeaders = token => ({ headers: { Authorization: token } })

const includeStartDate = date => date ? `&startDate=${date}` : ''
const includeEndDate = date => date ? `&endDate=${date}` : ''
const includeShipmentCompany = id => id ? `&shipmentCompanyId=${id}` : ''

const getUrl = (plantId, shipmentCompanyId, startDate, endDate) => {
  const startDateParam = includeStartDate(startDate)
  const endDateParam = includeEndDate(endDate)
  const companyIdParam = includeShipmentCompany(shipmentCompanyId)
  return `${endpointUrl}?plantId=${plantId}${companyIdParam}${startDateParam}${endDateParam}`
}

const getUnitStatus = async (token, params) => {
  const { plantId, shipmentCompanyId, startDate, endDate } = params
  const url = getUrl(plantId, shipmentCompanyId, startDate, endDate)
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return []
  }
}

const getShipmentCompanies = async token => {
  const url = `${endpointUrl}/shipmentCompanies`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return []
  }
}

export { getUnitStatus, getShipmentCompanies }
