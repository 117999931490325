import React from 'react'
import { Carrier } from './search_bar/Carrier'
import { Dates } from './search_bar/Dates'
import { SearchButton } from './search_bar/actions/SearchButton'

const SearchBar = () => {

  return (
    <div className='search-bar'>
      <Carrier />
      <Dates />
      <SearchButton />
    </div>
  )
}

export { SearchBar }
