import React from 'react'
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react'
import { useOrderContext } from '../../contexts/OrderContext'
import { createInputValueHandler } from '../../helpers/input'
import resources from '../../resources'
import { PlantsDropdown } from '../PlantsDropdown'

const SearchBar = () => {
  const context = useOrderContext()

  const quantityHandler = createInputValueHandler(context.setSelectedFilterQuantity)

  return (
    <div className='search-bar'>
      <label><Icon name='filter'/>Filtrar por:</label>
      <Form size="mini">
        <label>Planta:</label>
        <PlantsDropdown
          clearable
          setSelectedPlant={ context.setSelectedFilterPlant }
        />
        <label>Folios: </label>
        <Dropdown
          placeholder="Seleccione"
          fluid
          selection
          clearable
          search
          options={ resources.numbersToFilterByQuantity }
          value={ context.selectedFilterQuantity }
          onChange={ quantityHandler }
        />
        <Button
          primary
          loading={ context.isWorking }
          onClick={ context.searchOrders }
          disabled={ context.isWorking }
        >
          Filtrar
        </Button>
      </Form>
    </div>
  )
}

export { SearchBar }
