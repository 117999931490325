import React, { useState } from 'react'
import { useTravelsLogContext } from '../../contexts/TravelsLogContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'transportista', title: 'Transportista' },
  { path: 'economico',     title: 'Económico' },
  { path: 'fechaViaje',    title: 'Fecha viaje' },
  { path: 'remision',      title: 'Remisión' },
  { path: 'estado',        title: 'Estado' },
  { path: 'esperaPatio',   title: 'Espera en patio' },
  { path: 'esperaCliente', title: 'Espera con cliente' }
]

const searchBy = [
  'transportista',
  'economico',
  'estado',
  'remision',
  'fechaViaje'
]

const Table = () => {
  const context = useTravelsLogContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleSort = (data, e) => {
    context.setUnits(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  return (
    <div className="table">
      <StyledTable
        searchBy={ searchBy }
        data={ context.units }
        headers={ headers }
        handleOnClickRow={ () => {} }
        handleOnClickHeader={ e => handleSort(context.units, e) }
      />
    </div>
  )
}

export { Table }
