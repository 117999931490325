import { convertBase64 } from "./encoder"

const validLimit = (limit, value) => {
  if (!limit) return true
  return limit >= value.length
}

const validNumbersOnly = (isNumbersOnly, value) => {
  if (isNumbersOnly === undefined || !isNumbersOnly) return true
  return /^\d+$/.test(value)
}

const createInputValueHandler = (setter, options = {}) => (
  (_, { value }) => {
    if (!validLimit(options.limit, value)) return
    if (!validNumbersOnly(options.isNumbersOnly, value)) return
    if (options.parseToFloat) return setter(parseFloat(value))
    setter(value)
  }
)

const createFileInputHandler = setter => (
  value => convertBase64(value).then(result => setter(result))
)

export { createInputValueHandler, createFileInputHandler }
