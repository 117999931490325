import React from 'react'

const NavixyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className="header__logo-img"
    >
      <path className="header__logo-img--circle"
        d="M28 0a25 25 0 0122 25L28 12V0zm-6 12L0 25A25 25 0 0122 0v12zm27 20a25 25 0 01-48 0l24-14 24 14z">
      </path>
    </svg>
  )
}

export { NavixyIcon }
