import React from 'react'
import { createInputValueHandler } from '../../../helpers/input'
import { TextArea } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const NotesInput = () => {
  const context = useStayClaimContext()
  const inputHandler = createInputValueHandler(context.setNotes)

  return (
    <div className='item'>
      <label>Notas:</label>
      <TextArea
        type='text'
        value={ context.notes }
        onChange={ inputHandler }
        placeholder='Favor de ingresar notas sobre su estadía'
      />
    </div>
  )
}

export { NotesInput }
