import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'
import { handleChecklistDownload } from '../../../../utils/common'
import { subtractTimezone } from '../../../../helpers/date'
import { Button } from 'semantic-ui-react'

const LastCheckList = () => {
  const context = useOrdersInTransitContext()
  const contextModal = useOrdersInTransitInfoModalContext()

  const hasDate = () => (
    context.orderDetails.info.ultimoChecklist !== 'Sin fecha'
  )

  const addZero = date => String(date).padStart(2, '0')

  const formatDate = date => {
    const newDate = subtractTimezone(date)
    const year    = newDate.getUTCFullYear()
    const month   = addZero(newDate.getUTCMonth() + 1)
    const day     = addZero(newDate.getUTCDate())
    const hours   = addZero(newDate.getUTCHours())
    const minutes = addZero(newDate.getUTCMinutes())
    return `${day}-${month}-${year} ${hours}:${minutes}`
  }

  const handleLoadStateDownloadingChecklist = async () => {
    contextModal.setIsDownloadingCheckList(true)
    await handleChecklistDownload(
      context.credentials.token,
      context.orderDetails.info.idChecklist
    )
    contextModal.setIsDownloadingCheckList(false)
  }

  const formatedCheckListDate = () => (
    hasDate() ?
      formatDate(context.orderDetails.info.ultimoChecklist) :
      context.orderDetails.info.ultimoChecklist
  )

  return (
    <div id="lastChecklistToDownload">
      <p>
        <strong>Último Checklist: </strong>
        { formatedCheckListDate() }
      </p>
      {
        hasDate() ?
          <Button
            inverted
            disabled={ contextModal.isDownloadingChecklist }
            loading={ contextModal.isDownloadingChecklist }
            color="red"
            onClick={ handleLoadStateDownloadingChecklist }
          >
            Descargar
          </Button>
        : <></>
      }
    </div>
  )
}

export { LastCheckList }
