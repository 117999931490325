import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'

const FreightCostTolls = () => {
  const context = useOrdersInTransitContext()

  return (
    <div>
      <div>Costo x casetas:</div>
      <div>
        { context.orderDetails.info.fleteCostoCasetas || 'Sin asignar' }
      </div>
    </div>
  )
}

export { FreightCostTolls }
