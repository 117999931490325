import React, { useState } from 'react'
import { useIncidencesLogContext } from '../../contexts/IncidencesLogContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'folio',                  title: 'Folio' },
  { path: 'fechaIncidencia',        title: 'Fecha incidencia' },
  { path: 'representanteComercial', title: 'Representante comercial' },
  { path: 'remision',               title: 'Remisión' },
  { path: 'cliente',                title: 'Cliente' },
  { path: 'producto',               title: 'Producto' },
  { path: 'transportista',          title: 'Transportista' },
  { path: 'economico',              title: 'Económico' },
  { path: 'operador',               title: 'Operador' },
  { path: 'tipoIncidencia',         title: 'Tipo incidencia' },
  { path: 'fechaCompromiso',        title: 'Fecha compromiso' }
]

const searchBy = [
  'folio',
  'economico',
  'transportista',
  'operador'
]
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useIncidencesLogContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const onSelect = incidence => {
    context.selectIncidence(incidence.incidenciaId)
  }

  const handleSort = (data, e) => {
    context.setIncidences(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  return (
    <div className="table">
      <StyledTable
        searchBy={ searchBy }
        data={ context.incidences }
        headers={ headers }
        headerSearchBy={ headerSearchBy }
        handleOnClickRow={ onSelect }
        handleOnClickHeader={ e => handleSort(context.incidences, e) }
      />
    </div>
  )
}

export { Table }
