import React from 'react'
import { TankPhoto } from './first_container/TankPhoto'
import { TankStatus } from './first_container/TankStatus'

const FirstContainer = () => {
  return (
    <div className='first-container'>
      <TankPhoto />
      <TankStatus />
    </div>
  )
}

export { FirstContainer }
