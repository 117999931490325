import React from 'react'
import ListOfActions from './ListOfActions'

const MainMenuSection = ({ title, visible, actions }) => (
  visible ?
    <section>
      <div className="section-title">
        <label>{ title }</label>
      </div>
      <ListOfActions actions={ actions }/>
    </section>
  :
  <></>
)

export default MainMenuSection
