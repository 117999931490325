import React, { useState } from 'react'
import { StyledTable } from '../../StyledTable'
import { DownloadTaskButton } from './DownloadTaskButton'
import {
  useOrdersInTransitInfoModalContext
} from '../../../contexts/OrdersInTransitInfoModalContext'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'

const headers = [
  { path: 'numero',            title: 'No.'            },
  { path: 'etapa',             title: 'Etapa'          },
  { path: 'domicilio',         title: 'Domicilio'      },
  { path: 'fechaProgramada',   title: 'Fecha (Navixy)' },
  { path: 'estado',            title: 'Estado'         },
  { path: 'downloadChecklist', title: 'Documento PDF'  }
]

const OrderNavixyTable = () => {
  const { credentials } = useOrdersInTransitContext()
  const { tasks } = useOrdersInTransitInfoModalContext()
  const [selectedTaskId, setSelectedTaskId] = useState(null)

  const isWaitingDownload = id => selectedTaskId === id

  const renderButton = id => {
    if (isWaitingDownload(id)) return 'Descargando...'
    return (
      <DownloadTaskButton
        taskId={ id }
        token={ credentials.token }
        setSelectedTaskId={ setSelectedTaskId }
      />
    )
  }

  const buildData = () => tasks.map(t => ({
    ...t,
    downloadChecklist: renderButton(t.formId)
  }))

  return (
    <StyledTable
      search={ false }
      headers={ headers }
      data={ buildData() }
    />
  )
}

export { OrderNavixyTable }
