import React from 'react'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'

const DownloadPDFButton = ({ invoiceId }) => {
  const context = useChangeUnitsContext()

  const handlePDF = async () => {
    context.setIdInvoiceSelected(invoiceId)
    context.downloadPDF(invoiceId)
  }

  return (
    <p onClick={ handlePDF } >
      <strong style={ { textDecorationLine: 'underline' } }>
        Descargar
      </strong>
    </p>
  )
}

export { DownloadPDFButton }
