import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const AddButton = () => {
  const context = useOrdersInTransitContext()
  const contextModal = useOrdersInTransitInfoModalContext()

  const hasDetails = () => context.orderDetails.details?.length
  const isDisabled = () => !hasDetails() || contextModal.isDeleted()
  const save = contextModal.onSaveDetail

  return (
    <Button
      icon
      primary
      onClick={ save }
      disabled={ isDisabled() }
    >
      <Icon name="plus" />
    </Button>
  )
}

export { AddButton }
