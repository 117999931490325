import React from 'react'
import { Button, Form, TextArea } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { useOrdersInTransitInfoModalContext } from '../../../contexts/OrdersInTransitInfoModalContext'

const ModalToFinishOrder = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const handleCancelModal = () => {
    contextModal.setShowModalToFinishOrder(false)
  }

  return (
    <Modal
      centered
      enforceFocus
      show={ contextModal.showModalToFinishOrder }
      onHide={ handleCancelModal }
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmación de terminación de pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }} >
        <div style={{ paddingBottom: 5 }}>
          ¿Está seguro que desea terminar el pedido?
        </div>
        Pasar el pedido al estado terminado, realizará las siguientes acciones:
        <ul style={{ marginBottom: 5, paddingLeft: 30 }} >
          <li>La unidad y operador, asignados pasaran al estado <b>disponible</b></li>
          <li>El pedido pasara a estado <b>terminado</b></li>
        </ul>
        {
          contextModal.isClientUnit() ? (
            <div style={{ width: "100%", marginTop: 15 }} >
              <div style={{ marginBottom: 5 }} >Notas de terminación:</div>
              <Form style={{ width: "100%" }}>
                <TextArea
                  placeholder="Ejemplo: fecha y hora de salida de planta"
                  onChange={(_, { value }) => {
                    contextModal.setTerminationNotes(value)
                  }}
                  style={{
                    width: "100%",
                    margin: 0,
                    maxHeight: 40,
                    minHeight: 29,
                  }}
                />
              </Form>
            </div>
          ) : (<></>)
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='grey'
          onClick={ handleCancelModal }
          style={{ fontSize: 12 }}
        >
          Cancelar
        </Button>
        <Button
          loading={ contextModal.isConfirming }
          onClick={ contextModal.onConfirmFinishOrder }
          color='red'
          style={{ fontSize: 12 }}
        >
          Confirmar terminación
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalToFinishOrder }
