import React from 'react'
import { PlantsDropdown } from '../../PlantsDropdown'
import { usePlantsContext } from '../../../contexts/PlantsContext'

const RelatedPlantDropdown = () => {
  const context = usePlantsContext()

  return (
    <div className='field'>
      <label>Planta relacionada:</label>
      <div>
        <PlantsDropdown
          clearable
          value={ context.relatedPlant }
          setSelectedPlant={ context.setRelatedPlant }
        />
      </div>
    </div>
  )
}

export { RelatedPlantDropdown }
