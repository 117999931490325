import React from 'react'
import { FreightName } from './form/FreightName'
import { FreightByTon } from './form/FreightByTon'
import { FreightTollsCost } from './form/FreightTollsCost'

const Form = ({ order }) => {
  return (
    <div>
      <section>
        <h1>Confirmación de Notificación de Pedido</h1>
        <p>
          Estimado { order?.transportistaNombre },
          hemos asignado el siguiente pedido, favor de <b>confirmarlo.</b>
        </p>
      </section>
      <div className='content'>
        <div className='single-row'>
          <label>Pedido:</label>
          <label>{ order?.folio }</label>
        </div>
        <div className='single-row'>
          <label>Planta:</label>
          <label>{ order?.plantaAlias }</label>
        </div>
        <div className='double-row'>
          <div>
            <label>Fecha carga:</label>
            <label>{ order?.fechaCargaReal }</label>
          </div>
          <div>
            <label>Fecha planeada de entrega:</label>
            <label>{ order?.fechaCompromiso }</label>
          </div>
        </div>
        <div className='single-row'>
          <label>Operador asignado:</label>
          <label>{ order?.nombreOperador }</label>
        </div>
        <div className='double-row'>
          <div>
            <label>Unidad asignada:</label>
            <label>{ order?.economico }</label>
          </div>
          <div>
            <label>Fecha de asignación del pedido:</label>
            <label>{ order?.fechaAsignada } - (America/Mexico_City)</label>
          </div>
        </div>
        <div className='double-row'>
          <div>
            <label>Hrs traslado:</label>
            <label>{ order?.tiempoTraslado }</label>
          </div>
          <div>
            <label>Kms traslado:</label>
            <label>{ order?.kmTraslado }</label>
          </div>
        </div>
        <div className='double-row'>
          <div>
            <label>Contacto obra:</label>
            <label>{ order?.contactoObra }</label>
          </div>
          <div>
            <label>Teléfono contacto:</label>
            <label>{ order?.telefonoContacto }</label>
          </div>
        </div>
        <div className='single-row'>
          <label>Notas:</label>
          <label>{ order?.notas }</label>
        </div>
        <div className='single-row'>
          <label>Notas relevantes:</label>
          <label>{ order?.notaFlete }</label>
        </div>
        <FreightName order={ order } />
        <div className='double-row'>
          <FreightByTon order={ order } />
          <FreightTollsCost order={ order } />
        </div>
      </div>
    </div>
  )
}

export { Form }
