import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { Button } from 'semantic-ui-react'

const SaveButton = () => {
  const context = useTanksContext()

  const buttonLabel = () => context.isEditing ? 'Guardar cambios' : 'Guardar'

  const save = () => context.isEditing ? context.update() : context.save()

  return (
    <>
      <Button
        primary
        disabled={ context.isWorking }
        loading={ context.isWorking }
        onClick={ save }
      >
        { buttonLabel() }
      </Button>
    </>
  )
}

export { SaveButton }
