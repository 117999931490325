import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import {
  AssignmentParticipation,
  AuthorizedFreights,
  AuthorizedStaysLog,
  ChangeUnits,
  CheckUnitStatus,
  Client,
  Credentials,
  DailyStatus,
  Driver,
  DriverEnterprise,
  FreightsConsult,
  Geofence,
  InactivityDetailReport,
  InactivityReport,
  IncidencesLog,
  IncidencesReport,
  IncidenceTypesCrud,
  InitialInventory,
  Login,
  Main,
  NoAvailabilityTypesCrud,
  OrderAssignation,
  OrderConfirmation,
  OrderCRUD,
  OrderFinish,
  OrdersInTransit,
  OrdersTodaysMonitor,
  ParkedUnits,
  ParkimovilErrorLog,
  PlantsCrud,
  Product,
  UnitUseReport,
  UploadEvidence,
  Schedule,
  StayClaim,
  Tanks,
  TravelsLog,
  Users,
  VehicleCrud,
  YardAccessLog
} from './screens'
import store from './redux/store'
import 'react-datetime/css/react-datetime.css'
import 'react-datepicker/dist/react-datepicker.css'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/"                            component={ Login } exact         />
          <Route path="/asignacionPedidos"           component={ OrderAssignation    } />
          <Route path="/cambiarUnidades"             component={ ChangeUnits         } />
          <Route path="/cargaEvidencias/:token"      component={ UploadEvidence      } />
          <Route path="/clientes"                    component={ Client              } />
          <Route path="/confirmacionPedido/:token"   component={ OrderConfirmation   } />
          <Route path="/consultaEstadiasAutorizadas" component={ AuthorizedStaysLog  } />
          <Route path="/consultaFletes"              component={ FreightsConsult     } />
          <Route path="/consultarEstadoUnidad"       component={ CheckUnitStatus     } />
          <Route path="/credenciales"                component={ Credentials         } />
          <Route path="/estatusDiario"               component={ DailyStatus         } />
          <Route path="/geocercas"                   component={ Geofence            } />
          <Route path="/fletesAutorizados"           component={ AuthorizedFreights  } />
          <Route path="/inicio"                      component={ Main                } />
          <Route path="/inventarioInicial"           component={ InitialInventory    } />
          <Route path="/logAccessosPatio"            component={ YardAccessLog       } />
          <Route path="/logErroresParkimovil"        component={ ParkimovilErrorLog  } />
          <Route path="/motivosNoDisponible"         component={ NoAvailabilityTypesCrud } />
          <Route path="/operadores"                  component={ Driver              } />
          <Route path="/ordersTodays"                component={ OrdersTodaysMonitor } />
          <Route path="/participacionAsignaciones"   component={ AssignmentParticipation } />
          <Route path="/pedidos"                     component={ OrderCRUD           } />
          <Route path="/pedidosTransitoV2"           component={ OrdersInTransit     } />
          <Route path="/plantas"                     component={ PlantsCrud          } />
          <Route path="/productos"                   component={ Product             } />
          <Route path="/reclamoEstadia"              component={ StayClaim           } />
          <Route path="/reporteInactividadDetallado" component={ InactivityDetailReport } />
          <Route path="/reporteInactividad"          component={ InactivityReport    } />
          <Route path="/reporteIncidencias"          component={ IncidencesReport    } />
          <Route path="/reporteUsoUnidad"            component={ UnitUseReport       } />
          <Route path="/reporteViajes"               component={ TravelsLog          } />
          <Route path="/seguimientoIncidencias"      component={ IncidencesLog       } />
          <Route path="/tanks"                       component={ Tanks               } />
          <Route path="/terminacionPedidos"          component={ OrderFinish         } />
          <Route path="/tiposIncidencias"            component={ IncidenceTypesCrud  } />
          <Route path="/transportistas"              component={ DriverEnterprise    } />
          <Route path="/turnos"                      component={ Schedule            } />
          <Route path="/unidades"                    component={ VehicleCrud         } />
          <Route path="/unidadesPatio"               component={ ParkedUnits         } />
          <Route path="/usuarios"                    component={ Users               } />
          <Route path="*">
            <Redirect to="/inicio" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
