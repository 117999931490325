import React from 'react'
import { PlantsDropdown } from '../PlantsDropdown'
import { useChangeUnitsContext } from '../../contexts/ChangeUnitsContext'

const PlantDropdown = () => {
  const context = useChangeUnitsContext()

  return (
    <div className='plants'>
      <label>Planta:</label>
      <PlantsDropdown
        value={ context.plantId }
        setSelectedPlant={ context.setPlantId }
      />
    </div>
  )
}

export { PlantDropdown }
