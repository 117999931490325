import React from 'react'
import { Form } from 'semantic-ui-react'
import { ClientDropdown } from './order_form/ClientDropdown'
import { ClientUnitCheck } from './order_form/ClientUnitCheck'
import { OrderFormActions } from './OrderFormActions'
import { OrderFormDetails } from './OrderFormDetails'
import { OrderProgrammedSwitch } from './order_form/OrderProgrammedSwitch'
import { OrderStatusSwitch } from './order_form/OrderStatusSwitch'
import { OrderTypeSwitch } from './order_form/OrderTypeSwitch'
import { IdInput } from './order_form/IdInput'
import { OrderDateInput } from './order_form/OrderDateInput'
import { ShipmentDateInput } from './order_form/ShipmentDateInput'
import { ContactInput } from './order_form/ContactInput'
import { PlantDropdown } from './order_form/PlantDropdown'
import { RequestedDateInput } from './order_form/RequestedDateInput'
import { CaseDropdown } from './order_form/CaseDropdown'
import { TemperatureInput } from './order_form/TemperatureInput'
import { ContactPhoneInput } from './order_form/ContactPhoneInput'
import { NotesInput } from './order_form/NotesInput'
import { RequestedOrderDateInput } from './order_form/RequestedOrderDateInput'

const OrderForm = () => {
  return (
    <Form size="mini">
      <div className="row">
        <div className="col">
          <IdInput />
          <OrderDateInput />
          <OrderTypeSwitch />
          <ClientDropdown />
          <ShipmentDateInput />
          <RequestedOrderDateInput/>
          <ClientUnitCheck />
          <ContactInput />
        </div>
        <div className="col">
          <PlantDropdown />
          <RequestedDateInput />
          <OrderStatusSwitch />
          <OrderProgrammedSwitch />
          <CaseDropdown />
          <TemperatureInput />
          <ContactPhoneInput />
        </div>
      </div>
      <NotesInput />
      <OrderFormDetails />
      <OrderFormActions />
    </Form>
  )
}

export { OrderForm }
