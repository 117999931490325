import React from 'react'
import { PlantsDropdown } from '../../PlantsDropdown'
import { Form } from 'semantic-ui-react'
import {
  useInitialInventoryContext
 } from '../../../contexts/InitialInventoryContext'

const PlantDropdown = () => {
  const context = useInitialInventoryContext()

  return (
    <div className='plant-container'>
      <Form>
        <label>Planta:</label>
        <PlantsDropdown
          value={ context.selectedPlant }
          setSelectedPlant={ context.setSelectedPlant }
        />
      </Form>
    </div>
  )
}

export { PlantDropdown }
