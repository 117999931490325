import React from 'react'
import { ExcelButton } from '../../../ExcelButton'
import {
  useFreightsConsultContext
} from '../../../../contexts/FreightsConsultContext'

const XLSDownloadButton = () => {
  const context = useFreightsConsultContext()

  return (
    <div className='excel-container'>
      {
        context.freightsData?.length ?
        (
          <ExcelButton
            endpoint={ context.excelEndpoint }
            fileName='Consulta_de_fletes'
          />
        ) : (<></>)
      }
    </div>
  )
}

export { XLSDownloadButton }
