import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useInitialInventoryContext
} from '../../contexts/InitialInventoryContext'

const SaveButton = () => {
  const context = useInitialInventoryContext()

  return (
    <div className='save-button'>
      <Button
        primary
        loading={ context.isWorking }
        onClick={ context.save }
      >
        Guardar
      </Button>
    </div>
  )
}

export { SaveButton }
