import React from 'react'
import { Button } from 'semantic-ui-react'
import { useIncidencesContext } from '../../../../contexts/IncidencesContext'

const DestroyButton = () => {
  const context = useIncidencesContext()

  const destroy = () => {
    context.setShowConfirmDestroy(true)
  }

  const canDestroy = () => context.isEditing

  return (
    <>
      {
        canDestroy() ?
        (
          <Button
            color='red'
            loading={ context.isWorking }
            onClick={ destroy }
            >
              Eliminar
          </Button>
        ) : (<></>)
      }
    </>
  )
}

export { DestroyButton }
