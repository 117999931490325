import React from 'react'
import { Input } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../../contexts/StayClaimContext'

const PDFButton = () => {
  const context = useStayClaimContext()

  const inputHandler = (e, inputData) =>
    context.preparePDFBase64(e.target.files[0], inputData)

  return (
    <>
      <label htmlFor='c-pdf'>Seleccionar</label>
      <Input
        id='c-pdf'
        type='file'
        onChange={ inputHandler }
        accept='application/pdf, image/png, image/jpeg'
      />
    </>
  )
}

export { PDFButton }
