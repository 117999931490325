const convertBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

const getBase64Content = base64 => base64 ? base64.split(',')[1] : ''

const fromBase64ToBuffer = base64 => (
  base64 ? Buffer.from(getBase64Content(base64), 'base64') : ''
)

module.exports = {
  convertBase64,
  getBase64Content,
  fromBase64ToBuffer
}
