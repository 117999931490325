import React from 'react'
import { YardDropdown } from './YardDropdown'
import { ConfiguredYards } from './ConfiguredYards'
import { AddYardButton } from './AddYardButton'

const YardForm = () => {
  return (
    <div className='yard-form'>
      <YardDropdown />
      <div className='field'>
        <ConfiguredYards />
        <AddYardButton   />
      </div>
    </div>
  )
}

export { YardForm }
