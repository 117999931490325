import React from 'react'
import {
  useAssignmentParticipationContext
} from '../../../contexts/AssignmentParticipationContext'

const KPI = () => {
  const context = useAssignmentParticipationContext()

  return (
    <div className='container'>
      <label className='title'>KPI: </label>
      <label>{ context.kpi }</label>
    </div>
  )
}

export { KPI }
