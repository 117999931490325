import React from 'react'
import { Form } from 'semantic-ui-react'
import { ActionsInput } from './ActionsInput'
import { AddButton } from './AddButton'
import { AffectedCheck } from './AffectedCheck'
import { DateInput } from './DateInput'
import { IncidenceTypeDropdown } from './IncidenceTypeDropdown'
import { NoDesiredCheck } from './NoDesiredCheck'
import { NotesInput } from './NotesInput'
import { QuantityLostInput } from './QuantityLostInput'

const IncidencesForm = () => {
  return (
    <Form size="mini">
      <div className='col'>
        <DateInput/>
        <IncidenceTypeDropdown/>
        <NotesInput/>
      </div>
      <div className='col'>
        <div>
          <AffectedCheck/>
          <NoDesiredCheck/>
        </div>
        <QuantityLostInput/>
        <ActionsInput/>
      </div>
      <div className='col'>
        <AddButton/>
      </div>
    </Form>
  )
}

export { IncidencesForm }
