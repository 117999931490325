import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const Distance = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setDistance)

  return (
    <div className='form-field'>
      <label>Distancia en km:</label>
      <Input
        type='number'
        value={ context.distance || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { Distance }
