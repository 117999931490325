import React from 'react'
import { TanksPlates } from './TanksPlates'
import { TanksBrands } from './TanksBrands'
import { TanksModels } from './TanksModels'
import { TanksCapacities } from './TanksCapacities'

const Tanks = () => {
  return (
    <>
      <div>
        <label> Placas TQ: </label>
        <TanksPlates />
      </div>
      <div>
        <label> Marca: </label>
        <TanksBrands />
      </div>
      <div>
        <label> Modelo: </label>
        <TanksModels />
      </div>
      <div>
        <label> Capacidad: </label>
        <TanksCapacities />
      </div>
    </>
  )
}

export { Tanks }
