import React from 'react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'
import { ButtonDownloadChecklist } from './ButtonDownloadChecklist'

const InputChecklist = () => {
  const context = useParkedUnitsContext()

  return (
    <div className="form-field">
      <label>Último checklist:</label>
      <label>{ context.unitInfo.fechaUltimoChecklist }</label>
      <ButtonDownloadChecklist/>
    </div>
  )
}

export { InputChecklist }
