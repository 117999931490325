import React from 'react'
import { useSelector } from 'react-redux'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'
import { LinkModalChecklist } from './LinkModalChecklist'
import { Resume } from './Resume'

const searchBy = [
  'fechaIngreso',
  'transportista',
  'geocerca',
  'economico',
  'operador',
  'estado'
]

const headerSearchBy = [
  'Fecha Ingreso',
  'Geocerca',
  'Transportista',
  'Económico',
  'Operador',
  'Estado'
]

const headers = [
  { path: 'fechaIngreso',   title: 'Fecha Ingreso' },
  { path: 'geocerca',       title: 'Geocerca' },
  { path: 'transportista',  title: 'Transportista' },
  { path: 'economico',      title: 'Económico' },
  { path: 'operador',       title: 'Operador' },
  { path: 'fechaChecklist', title: 'Último Checklist' },
  { path: 'checklist',      title: 'Checklist' },
  { path: 'estado',         title: 'Estado' }
]

const Table = () => {
  const { credentials } = useSelector(state => state.auth)
  const context = useParkedUnitsContext()

  const getUnits = () => (
    context.units.map(u => (
      { ...u, checklist: <LinkModalChecklist unit={ u } /> }
    ))
  )

  const onSelect = row => {
    context.getById(row.bitacoraId)
  }

  const onClickHeader = e => {
    handleFilterData(context.units, e)
  }

  const handleFilterData = (data, e) => {
    context.setUnits(filterdata(data, e))
  }

  return (
    <div className="table-container">
      <Resume/>
      <StyledTable
        searchBy={ searchBy }
        headers={ headers }
        headerSearchBy={ headerSearchBy }
        token={ credentials?.token }
        data={ getUnits() }
        handleOnClickRow={ onSelect }
        handleOnClickHeader={ onClickHeader }
      />
    </div>
  )
}

export { Table }
