import React from 'react'
import {
  UploadEvidenceProvider
} from '../contexts/UploadEvidenceContext'
import {
  UploadEvidenceFormSectionOne
} from '../components/no_availability_evidence/UploadEvidenceFormSectionOne'
import {
  UploadEvidenceFormSectionTwo
} from '../components/no_availability_evidence/UploadEvidenceFormSectionTwo'
import {
  CodeVerification
} from '../components/no_availability_evidence/CodeVerification'
import {
  ReactivateConfirmationModal
} from '../components/no_availability_evidence/ReactivateConfirmationModal'

const UploadEvidence = () => {

  return (
    <UploadEvidenceProvider>
      <div className='screen-upload-evidence'>
        <CodeVerification />
        <ReactivateConfirmationModal />
        <UploadEvidenceFormSectionOne />
        <UploadEvidenceFormSectionTwo />
      </div>
    </UploadEvidenceProvider>
  )
}

export { UploadEvidence }
