import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../contexts/OrdersInTransitStayContext'
import { AprovedStay } from './order_stay_timings/AprovedStay'
import { ClaimedStay } from './order_stay_timings/ClaimedStay'
import {
  DestinationArrivalDate
} from './order_stay_timings/DestinationArrivalDate'
import { DestinationExitDate } from './order_stay_timings/DestinationExitDate'
import { DestinationTime } from './order_stay_timings/DestinationTime'
import { DetailInput } from './order_stay_timings/DetailsInput'
import { OrderDetailsDropdown } from './order_stay_timings/OrderDetailsDropdown'
import { ParkingArrivalDate } from './order_stay_timings/ParkingArrivalDate'
import { ParkingExitDate } from './order_stay_timings/ParkingExitDate'
import { ParkingTime } from './order_stay_timings/ParkingTime'
import { StayActions } from './order_stay_timings/StayActions'

const OrderStayTimings = () => {
  const context = useOrdersInTransitStayContext()

  const getMessage = () => {
    if (!context.orderDetails.length)
      return '(No hay reclamos de estadía para este pedido)'
    if (!context.isAlreadyAproved) return ''
    return '(Esta autorización ya ha sido aprobada)'
  }

  return (
    <div className="orders-in-transit-stay-tab">
      <span>
        <h3>Tiempos de estadía</h3>
        <h5>{ getMessage() }</h5>
      </span>
      <hr/>
      <form>
        <div className="col">
          <OrderDetailsDropdown/>
          <ParkingArrivalDate/>
          <ParkingExitDate/>
          <ParkingTime/>
          <DetailInput/>
        </div>
        <div className="col">
          <DestinationArrivalDate/>
          <DestinationExitDate/>
          <DestinationTime/>
          <ClaimedStay/>
          <AprovedStay/>
        </div>
      </form>
      <StayActions/>
    </div>
  )
}

export { OrderStayTimings }
