import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'
import { createInputValueHandler } from '../../../helpers/input'

const CreditNoteDetailsInput = () => {
  const context = useIncidencesLogContext()

  const detailsHandler = createInputValueHandler(
    context.setSeguimientoNotaCredito
  )

  return (
    <div className="field textarea">
      <label>Seguimiento de la nota de crédito:</label>
      <TextArea
        placeholder="Nota de crédito"
        value={ context.seguimientoNotaCredito || '' }
        onChange={ detailsHandler }
      />
    </div>
  )
}

export { CreditNoteDetailsInput }
