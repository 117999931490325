import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import {
  create,
  destroy,
  getAll,
  getById,
  getTypePlant,
  getTypeYard,
  update
} from '../services/plantsCrud'
import { CustomDimmer } from '../components/CustomDimmer'

const PlantsContext = createContext()

const PlantsProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)
  const [plants, setPlants] = useState([])
  const [yards, setYards] = useState([])
  const [id, setId] = useState('')
  const [alias, setAlias] = useState('')
  const [mainContact, setMainContact] = useState('')
  const [state, setState] = useState(1)
  const [doubleTank, setDoubleTank] = useState(0)
  const [phone, setPhone] = useState('')
  const [apikeyParkimovil, setApikeyParkimovil] = useState('')
  const [secretkeyParkimovil, setSecretkeyParkimovil] = useState('')
  const [typePlant, setTypePlant] = useState(1)
  const [relatedPlant, setRelatedPlant] = useState('')
  const [geofencePlant, setGeofencePlant] = useState('')
  const [notes, setNotes] = useState('')
  const [yardName, setYardName] = useState('')
  const [typePlantGeofences, setTypePlantGeofences] = useState([])
  const [typeYardGeofences, setTypeYardGeofences] = useState([])
  const [currentYard, setCurrentYard] = useState(null)
  const [isCreating, setIsCreating] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isWorking, setIsWorking] = useState(false)

  const [emails, setEmails] = useState([])

  useEffect(() => {
    if (credentials) {
      getAllPlants()
      getTypePlantGeofences()
      getTypeYardGeofences()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const hasError = response => response.errorCode || response.status

  const getAllPlants = async () => {
    getAll(credentials.token)
      .then(response => {
        if (hasError(response)) return showErrorAlert(response.message)
        setPlants(response)
      })
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const getTypePlantGeofences = async () => {
    const response = await getTypePlant(credentials.token)
    if (hasError(response)) return showErrorAlert(response.message)
    setTypePlantGeofences(response)
  }

  const getTypeYardGeofences = async () => {
    const response = await getTypeYard(credentials.token)
    if (hasError(response)) return showErrorAlert(response.message)
    setTypeYardGeofences(response)
  }

  const splitEmails = e => e?.split(';') || []

  const onSelectPlant = async plantId => {
    const plant = await getById(plantId, credentials.token)
    if (hasError(plant)) return showErrorAlert(plant.message)
    setId(plant.id)
    setAlias(plant.alias)
    setMainContact(plant.nombreContacto)
    setEmails(splitEmails(plant.correoContacto))
    setState(plant.estadoId)
    setDoubleTank(plant.cargaTQxTQ)
    setPhone(plant.telefonoContacto || '')
    setApikeyParkimovil(plant.apiKeyParkimovil || '')
    setSecretkeyParkimovil(plant.secretKeyParkimovil || '')
    setTypePlant(plant.tipoPlanta)
    setRelatedPlant(plant.plantaRelacionadaId)
    setGeofencePlant(plant.geocercaId)
    setNotes(plant.notas || '')
    setYards(plant.patios)
    setIsCreating(false)
  }

  const clearData = () => {
    setAlias('')
    setMainContact('')
    setEmails([])
    setState(1)
    setDoubleTank(false)
    setPhone('')
    setApikeyParkimovil('')
    setSecretkeyParkimovil('')
    setTypePlant(1)
    setRelatedPlant('')
    setGeofencePlant('')
    setNotes('')
    setYards('')
    clearYardForm()
    setIsCreating(true)
  }

  const hasSelectedYard = () => currentYard && yardName

  const clearYardForm = () => {
    setCurrentYard(null)
    setYardName('')
  }

  const formatCurrentYard = () => ({
    geocercaId: currentYard.key,
    geocercaNombre: currentYard.text,
    patioPlantaNombre: yardName
  })

  const addYard = () => {
    if (!hasSelectedYard()) return showErrorAlert('Especifique un patio')
    setYards([...yards, formatCurrentYard()])
    clearYardForm()
  }

  const handleResponse = response => {
    if (hasError(response)) {
      setIsWorking(false)
      return showErrorAlert(response.message)
    }
    showOkAlert(response.message)
    clearData()
    getAllPlants()
    setIsWorking(false)
  }

  const hasRequiredParams = () => alias && mainContact && geofencePlant

  const formatYards = () => {
    if (!yards.length) return null
    return yards.map(y => ({
      geocercaId: y.geocercaId,
      patioNombre: y.patioPlantaNombre
    }))
  }

  const saveParamEmails = () => emails.join(';') || ''

  const buildParams = () => ({
    alias: alias,
    nombreContacto: mainContact,
    correoContacto: saveParamEmails(),
    telefonoContacto: phone,
    geocercaId: geofencePlant,
    notas: notes,
    estadoId: state,
    cargaTQxTQ: doubleTank,
    tipoPlanta: typePlant,
    plantaRelacionadaId: relatedPlant,
    apiKeyParkimovil: apikeyParkimovil,
    secretKeyParkimovil: secretkeyParkimovil,
    patios: formatYards()
  })

  const save = async () => {
    if (!hasRequiredParams()) return showErrorAlert('Faltan datos')
    setIsWorking(true)
    if (isCreating) {
      const response = await create(buildParams(), credentials.token)
      return handleResponse(response)
    }
    const response = await update(buildParams(), id, credentials.token)
    handleResponse(response)
  }

  const deletePlant = async () => {
    setIsWorking(true)
    const response = await destroy(id, credentials.token)
    handleResponse(response)
  }

  const context = {
    alias,
    apikeyParkimovil,
    credentials,
    currentYard,
    doubleTank,
    emails,
    geofencePlant,
    mainContact,
    notes,
    plants,
    phone,
    relatedPlant,
    secretkeyParkimovil,
    state,
    typePlant,
    typePlantGeofences,
    typeYardGeofences,
    isCreating,
    isWorking,
    yardName,
    yards,

    addYard,
    clearData,
    deletePlant,
    onSelectPlant,
    save,
    setAlias,
    setApikeyParkimovil,
    setCurrentYard,
    setDoubleTank,
    setEmails,
    setGeofencePlant,
    setMainContact,
    setNotes,
    setPhone,
    setPlants,
    setRelatedPlant,
    setSecretkeyParkimovil,
    setState,
    setTypePlant,
    setYards,
    setYardName
  }

  return (
    <AuthProvider>
      <PlantsContext.Provider value={ context }>
        <CustomDimmer visible={isLoading} />
        { children }
      </PlantsContext.Provider>
    </AuthProvider>
  )
}

const usePlantsContext = () => useContext(PlantsContext)

export { PlantsProvider, usePlantsContext }
