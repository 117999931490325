import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import { numberFormat } from '../../../../helpers/number'

const TankPlateLabel = () => {
  const context = useOrdersInTransitContext()

  const getMeasure  = () => context.orderDetails.info.unidadMedidaTanqueUno
  const getPlacas   = () => context.orderDetails.info.placasTanque
  const getCapacity = () => context.orderDetails.info.capacidadTanqueUno ?
    `(${numberFormat(context.orderDetails.info.capacidadTanqueUno)} ${getMeasure()})` : ''

  const getText = () => getPlacas() ? `${getPlacas()} ${getCapacity()}` : 'Sin asignar'

  return (
    <div>
      <div>Placas TQ1:</div>
      <div>
        { getText() }
      </div>
    </div>
  )
}

export { TankPlateLabel }
