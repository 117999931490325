import axios from 'axios'
import resources from '../resources'

const buildUrl = (id = '') => (
  `${resources.urls.ergonApi}/schedules/${id}`
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getPlantSchedule =  async (id, token) => {
  const { data } = await axios.get(
    buildUrl(id),
    buildHeaders(token)
  )
  return data
}

const savePlantSchedule = async (id, params, token) => {
  const { data } = await axios.post(
    buildUrl(id),
    params,
    buildHeaders(token)
  )
  return data
}

export {
  getPlantSchedule,
  savePlantSchedule
}
