const getFilterQuantities = () => [
  {
    key: 50,
    text: '50',
    value: 50
  },
  {
    key: 100,
    text: '100',
    value: 100
  },
  {
    key: 200,
    text: '200',
    value: 200
  },
  {
    key: 500000,
    text: 'Todos',
    value: 500000
  }
]

export { getFilterQuantities }
