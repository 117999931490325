const scaleToFit = (innerWidth, innerHeight, outerWidth, outerHeight) => {
  const widthScale = outerWidth / innerWidth
  const heightScale = outerHeight / innerHeight
  const scale = Math.min(widthScale, heightScale)
  const scaledWidth = innerWidth * scale
  const scaledHeight = innerHeight * scale
  return {
    width: scaledWidth,
    height: scaledHeight
  }
}

export default scaleToFit
