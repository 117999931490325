import React from 'react'
import { Form } from 'semantic-ui-react'
import { RealQuantityInput } from './order_details_form/RealQuantityInput'
import { TicketInput } from './order_details_form/TicketInput'
import { AddButton } from './order_details_form/AddButton'
import { FinishOrderButton } from './order_details_form/FinishOrderButton'
import { UnasignOrderButton } from './order_details_form/UnasignOrderButton'
import { SaveButton } from './order_details_form/SaveButton'
import { TotalWeightInput } from './order_details_form/TotalWeightInput'
import { ContainerWeightInput } from './order_details_form/ContainerWeightInput'
import { RealWeightInput } from './order_details_form/RealWeightInput'
import {
  RequestedTemperatureInput
} from './order_details_form/RequestedTemperatureInput'
import {
  StartTemperatureInput
} from './order_details_form/StartTemperatureInput'
import {
  ArrivalTemperatureInput
} from './order_details_form/ArrivalTemperatureInput'

const OrderDetailForm = () => {
  return (
    <div id="orderDetailsForm">
      <div>
        <Form size="mini">
          <RealQuantityInput/>
          <TicketInput/>
          <AddButton/>
          <div className='actions'>
            <FinishOrderButton/>
            <UnasignOrderButton/>
            <SaveButton/>
          </div>
        </Form>
      </div>
      <div>
        <Form size="mini">
          <TotalWeightInput/>
          <ContainerWeightInput/>
          <RealWeightInput/>
        </Form>
      </div>
      <div>
        <Form size="mini">
          <RequestedTemperatureInput/>
          <StartTemperatureInput/>
          <ArrivalTemperatureInput/>
        </Form>
      </div>
    </div>
  )
}

export { OrderDetailForm }
