import React from 'react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const Operator = () => {
  const context = useStayClaimContext()

  return (
    <div className='item'>
      <label>Operador:</label>
      <label className='value'>
        { context.operator || 'Sin asignar' }
      </label>
    </div>
  )
}

export { Operator }
