import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const ProductsButton = () => {
  const context = useOrderAssignationContext()

  const isDisabled = () => context.folio <= 0
  const handleClick = () => {
    context.getDetails()
    context.setShowProductsModal(true)
  }

  return (
    <div className="form-field">
      <label>Productos:</label>
      <Button
        primary
        disabled={ isDisabled() }
        onClick={ handleClick }
      >
        Mostrar
      </Button>
    </div>
  )
}

export { ProductsButton }
