import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { getGeneralInfo } from '../services/ergonApi'
import { useSelector } from 'react-redux'
import {
  lends,
  returns,
  setAvailable,
  setNotAvailable,
  getUnitEvidenceHistory,
  getPDF
} from '../services/changeUnits'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'

const ChangeUnitsContext = createContext()

const ChangeUnitsProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [currentPlantId,        setCurrentPlantId]        = useState('')
  const [estimatedAvailability, setEstimatedAvailability] = useState('')
  const [evidenceHistoryData,   setEvidenceHistoryData]   = useState([])
  const [idInvoiceSelected,     setIdInvoiceSelected]     = useState(0)
  const [isLoading,             setIsLoading]             = useState(false)
  const [isWorking,             setIsWorking]             = useState(false)
  const [notAvailableDate,      setNotAvailableDate]      = useState('')
  const [notAvailableNote,      setNotAvailableNote]      = useState('')
  const [notAvailableType,      setNotAvailableType]      = useState('')
  const [notAvailabilityTypes,  setNotAvailabilityTypes]  = useState([])
  const [plantId,               setPlantId]               = useState('')
  const [plantsToLend,          setPlantsToLend]          = useState([])
  const [selectedTab,           setSelectedTab]           = useState('base')
  const [selectedUnit,          setSelectedUnit]          = useState(null)
  const [showModal,             setShowModal]             = useState(false)
  const [units,                 setUnits]                 = useState([])
  const [showButtonToLoadEvidences, setShowButtonToLoadEvidences] = useState(true)
  const urlAvailabilityTypes = '/changeUnits/getNoAvailabilityTypes/'

  useEffect(() => {
    if (!plantId) return
      getUnits()
      getPlantsToLend()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantId])

  const buildUrl = () => `/changeUnits/units/${plantId}`

  const handleError = ({ message }) => showErrorAlert(message)

  const cleanChangeUnitModal = () => {
    setShowModal(false)
    setCurrentPlantId('')
    setSelectedTab('base')
    setNotAvailableDate('')
    setNotAvailabilityTypes([])
    setNotAvailableType('')
    setEstimatedAvailability('')
    setNotAvailableNote('')
    setShowButtonToLoadEvidences(true)
    setEvidenceHistoryData([])
  }

  const isSamePlant = () => context.plantId === context.currentPlantId

  const getUnits = () => {
    getGeneralInfo(credentials.token, buildUrl())
      .then(response => setUnits(response))
      .catch(handleError)
  }

  const getPlantsToLend = () => {
    getGeneralInfo(credentials.token, '/changeUnits/plantsToLend')
      .then(response => setPlantsToLend(response))
      .catch(handleError)
  }

  const hasError = ({ data }) => data.errorCode || data.status > 400

  const handleActionResponse = response => {
    if (hasError(response)) return handleError(response.data)
    setShowModal(false)
    setCurrentPlantId('')
    getUnits()
    showOkAlert(response.data.message)
  }

  const lendUnit = () => {
    if (!currentPlantId) return showErrorAlert('Debes seleccionar una planta')
    if (isSamePlant()) return showErrorAlert('La unidad ya pertenece a la planta')
    setIsLoading(true)
    lends(selectedUnit.unidadId, currentPlantId, credentials.token)
      .then(handleActionResponse)
      .catch(error => handleError(error.data))
      .finally(() => setIsLoading(false))
  }

  const returnsUnit = () => {
    setIsLoading(true)
    returns(selectedUnit.unidadId, credentials.token)
      .then(handleActionResponse)
      .catch(error => handleError(error.data))
      .finally(() => setIsLoading(false))
  }

  const onSelectUnit = async unit => {
    setSelectedUnit(unit)
    setSelectedTab('base')
    setShowModal(true)
    setNotAvailabilityTypes(await getNoAvailableTypes())
  }

  const getNoAvailableTypes = async () => {
    return await getGeneralInfo(credentials.token, urlAvailabilityTypes)
  }

  const getNoAvailableParams = () => ({
    motivoNoDisponibleId: notAvailableType,
    fecha: notAvailableDate,
    fechaEstimada: estimatedAvailability,
    notas: notAvailableNote
  })

  const isNotAvailable = () => (
    selectedUnit?.estadoUnidadNombre === 'No disponible' ||
    selectedUnit?.estadoUnidadNombre === 'Reactivación'
  )

  const saveNotAvailableState = () => {
    if (!notAvailableDate)
    return showErrorAlert('Debes ingresar una fecha inicial no disponible')
    if (!notAvailableType)
    return showErrorAlert('Debes selecionar un motivo de no disponible')
    if (!estimatedAvailability)
    return showErrorAlert('Debes ingresar una fecha de disponibilidad estimada')
    if (!notAvailableNote)
    return showErrorAlert('Debes escribir una nota')
    setIsWorking(true)
    const params = getNoAvailableParams()
    setNotAvailable(selectedUnit.unidadId, params, credentials.token)
      .then(handleActionResponse)
      .catch(error => handleError(error.data))
      .finally(() => {
        cleanChangeUnitModal()
        setIsWorking(false)
      })
  }

  const saveAvailableState = () => {
    setIsWorking(true)
    setAvailable(selectedUnit.unidadId, credentials.token)
      .then(handleActionResponse)
      .catch(error => handleError(error.data))
      .finally(() => setIsWorking(false))
  }

  const unitEvidenceHistory = async () => {
    setIsWorking(true)
    await getUnitEvidenceHistory(selectedUnit.unidadId, credentials?.token)
      .then(response => setEvidenceHistoryData(response.data))
      .catch(error => handleError(error.data))
      .finally(() => {
        setIsWorking(false)
        setShowButtonToLoadEvidences(false)
      })
  }

  const generateDownload = (data, invoiceId) => {
    const filename  = `evidencia_${invoiceId}.pdf`
    const blob      = new Blob([data])
    const windowUrl = window.URL.createObjectURL(blob)
    const link      = document.createElement('a')
    link.href       = windowUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const downloadPDF = invoiceId => {
    getPDF(invoiceId, credentials.token)
      .then(response => generateDownload(response.data, invoiceId))
      .catch(error => handleError(error.data))
      .finally(() => setIdInvoiceSelected(0))
  }

  const context = {
    currentPlantId,
    estimatedAvailability,
    evidenceHistoryData,
    idInvoiceSelected,
    isLoading,
    isWorking,
    notAvailableDate,
    notAvailableNote,
    notAvailableType,
    notAvailabilityTypes,
    plantId,
    plantsToLend,
    selectedTab,
    selectedUnit,
    showButtonToLoadEvidences,
    showModal,
    units,

    cleanChangeUnitModal,
    isNotAvailable,
    lendUnit,
    onSelectUnit,
    returnsUnit,
    saveAvailableState,
    saveNotAvailableState,
    setCurrentPlantId,
    setSelectedTab,
    setShowModal,
    setPlantId,
    setUnits,
    setNotAvailableDate,
    setNotAvailableType,
    setEstimatedAvailability,
    setNotAvailableNote,
    setIdInvoiceSelected,
    downloadPDF,
    unitEvidenceHistory
  }

  return (
    <AuthProvider>
      <ChangeUnitsContext.Provider value={ context }>
        { children }
      </ChangeUnitsContext.Provider>
    </AuthProvider>
  )
}

const useChangeUnitsContext = () => useContext(ChangeUnitsContext)

export { ChangeUnitsProvider, useChangeUnitsContext }
