import { getColors } from './colors'
import { getFilterQuantities } from './filterQuantities'
import { getImages } from './images'

const getApiUrl = () => {
  if (process.env.REACT_APP_ERGON_ENV === 'production')
    return 'https://ergon.movomx.com/api'
  if (process.env.REACT_APP_ERGON_ENV === 'test')
    return 'https://test.ergon.movomx.com/api'
  return process.env.REACT_APP_API_URL || 'https://test.ergon.movomx.com/api'
}

export default {
  version: 'V 2.3.0',
  appname: 'Bitácora Ergon',
  googleMapApikey: 'AIzaSyBC5mNhMJRTWFC88QLh_3Ijt_VMkoEvwT0',
  images: getImages(),
  colors: getColors(),
  urls: { ergonApi: getApiUrl() },
  numbersToFilterByQuantity : getFilterQuantities(),
  superAdminActions: [
    {
      id: 11,
      name: 'Credenciales',
      route: '/credenciales'
    },
    {
      id: 12,
      name: 'Consultar estado de unidad',
      route: '/consultarEstadoUnidad'
    },
    {
      id: 44,
      name: 'Terminación de pedidos',
      route: '/terminacionPedidos'
    },
    {
      id: 45,
      name: 'Historial de errores Parkimovil',
      route: '/logErroresParkimovil'
    },
    {
      id: 46,
      name: 'Historial de accesos a patios',
      route: '/logAccessosPatio'
    },
    {
      id: 47,
      name: 'Motivos no disponibilidad',
      route: '/motivosNoDisponible'
    },
    {
      id: 48,
      name: 'Tipos de incidencias',
      route: '/tiposIncidencias'
    }
  ],
  actionsForPlantAdim: [
    {
      id: 9,
      name: 'Usuarios',
      route: '/usuarios'
    },
    {
      id: 1,
      name: 'Transportistas',
      route: '/transportistas'
    },
    {
      id: 2,
      name: 'Unidades transporte',
      route: '/unidades'
    },
    {
      id: 62,
      name: 'Tanques',
      route: '/tanks'
    },
    {
      id: 3,
      name: 'Operadores',
      route: '/operadores'
    },
    {
      id: 8,
      name: 'Plantas',
      route: '/plantas'
    },
    {
      id: 5,
      name: 'Clientes',
      route: '/clientes'
    },
    {
      id: 4,
      name: 'Geocercas especiales',
      route: '/geocercas'
    },

    {
      id: 6,
      name: 'Productos',
      route: '/productos'
    },
    {
      id: 7,
      name: 'Turnos',
      route: '/turnos'
    },
    {
      id: 63,
      name: 'Fletes autorizados',
      route: '/fletesAutorizados'
    }
  ],
  commonActions: [
    {
      id: 21,
      name: 'Pedidos',
      route: '/pedidos'
    },
    {
      id: 22,
      name: 'Asignar pedidos',
      route: '/asignacionPedidos'
    },
    {
      id: 23,
      name: 'Unidades en patio',
      route: '/unidadesPatio'
    },
    {
      id: 30,
      name: 'Inventario inicial',
      route: '/inventarioInicial'
    },
    {
      id: 49,
      name: 'Cambiar estatus/base de unidades',
      route: '/cambiarUnidades'
    }
  ],
  commonQueries: [
    {
      id: 25,
      name: 'Pedidos en tránsito',
      route: '/pedidosTransitoV2'
    },
    {
      id: 43,
      name: 'Monitoreo de ordenes',
      route: '/ordersTodays'
    },
    {
      id: 51,
      name: 'Seguimiento de incidencias',
      route: '/seguimientoIncidencias'
    },
    {
      id: 56,
      name: 'Consulta de estadías autorizadas',
      route: '/consultaEstadiasAutorizadas'
    },
    {
      id:64,
      name: ' Consulta de fletes',
      route: '/consultaFletes'
    },
    {
      id: 52,
      name: 'Reporte de estado de unidades',
      route: '/estatusDiario'
    },
    {
      id: 57,
      name: 'Reporte de % de inactividad',
      route: '/reporteInactividad'
    },
    {
      id: 58,
      name: 'Reporte de inactividad detallado',
      route: '/reporteInactividadDetallado'
    },
    {
      id: 53,
      name: 'Reporte de incidencias',
      route: '/reporteIncidencias'
    },
    {
      id: 59,
      name: 'Reporte de uso unidad',
      route: '/reporteUsoUnidad'
    },
    {
      id: 54,
      name: 'Reporte de viajes',
      route: '/reporteViajes'
    },
    {
      id: 60,
      name: 'Reporte participación asignaciones',
      route: '/participacionAsignaciones'
    }
  ],
  actionOne: [
    {
      id: 23,
      name: 'Unidades en patio',
      route: '/unidadesPatio'
    }
  ],
  actionTwo: [
    {
      id: 21,
      name: 'Pedidos',
      route: '/pedidos'
    }
  ],
  actionTree: [
    {
      id: 22,
      name: 'Asignar pedidos',
      route: '/asignacionPedidos'
    }
  ],
  actionFour: [
    {
      id: 30,
      name: 'Inventario Inicial',
      route: '/inventarioInicial'
    }
  ],
  actionFive: [
    {
      id: 49,
      name: 'Cambiar estatus/base de unidades',
      route: '/cambiarUnidades'
    }
  ]
}
