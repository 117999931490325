import React from 'react'
import { StyledTable } from '../../components/StyledTable'

const headers = [
  { path: 'remision',      title: 'Remisión' },
  { path: 'cliente',       title: 'Cliente' },
  { path: 'domicilio',     title: 'Domicilio' },
  { path: 'producto',      title: 'Producto' },
  { path: 'prog',          title: 'Prog' },
  { path: 'carga',         title: 'Carga' },
  { path: 'compromiso',    title: 'Compromiso' },
  { path: 'transportista', title: 'Transportista' },
  { path: 'operador',      title: 'Operador' },
  { path: 'economico',     title: 'Económico' },
  { path: 'placasTracto',  title: 'Placas Tracto' },
  { path: 'placasTQ',      title: 'Placas TQ' },
  { path: 'estatusDeOV',   title: 'Estatus de OV' }
]

const Table = ({ paginatedOrders = [], currentPage = 0 }) => {
  return (
    <div className="table-orders-todays-monitor">
      {
        !paginatedOrders.length ?
        (
          <div className="not-orders-todays">
            <span className="not-orders-todays-content">
              Sin datos que mostrar
            </span>
          </div>
        ) :
        (
          <StyledTable
            headers={ headers }
            search={ false }
            data={ paginatedOrders[currentPage] }
            fontSize={ "1.2rem" }
            truncate
          />
        )
      }
    </div>
  )
}

export { Table }
