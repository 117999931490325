import React from 'react'
import { OrderInfo } from './OrderInfo'
import { OrderDetailsTable } from './OrderDetailsTable'
import { OrderNavixy } from './OrderNavixy'
import {
  useOrdersInTransitInfoModalContext
} from '../../../contexts/OrdersInTransitInfoModalContext'
import { OrderDetailForm } from './OrderDetailsForm'
import { OrderIncidences } from './OrderIncidences'
import {
  OrdersInTransitIncidencesProvider
} from '../../../contexts/OrdersInTransitIncidencesContext'
import {
  OrdersInTransitStayProvider
} from '../../../contexts/OrdersInTransitStayContext'
import { OrderStayTimings } from './OrderStayTimings'

const OrderOptions = () => {
  const context = useOrdersInTransitInfoModalContext()

  const isSelected = tab => tab === context.selectedTab

  return (
    <>
      <OrderInfo />
      {
        isSelected('info') ? (
          <div className='order-details-tab'>
            <OrderDetailsTable />
            <OrderDetailForm   />
          </div>
        ) : (<></>)
      }
      {
        isSelected('stay') ? (
          <OrdersInTransitStayProvider>
            <OrderStayTimings/>
          </OrdersInTransitStayProvider>
        ) : (<></>)
      }
      {
        isSelected('incidences') ? (
          <OrdersInTransitIncidencesProvider>
            <OrderIncidences />
          </OrdersInTransitIncidencesProvider>
        ) : (<></>)
      }
      {
        isSelected('navixy') ? (
            <OrderNavixy />
        ) : (<></>)
      }
    </>
  )
}

export { OrderOptions }
