import axios from "axios";
import resources from "../resources";

export const signIn = async (email, password) => {
  let response = null;
  try {
    let { data } = await axios.post(
      resources.urls.ergonApi + "/usuario/login",
      {
        correo: email,
        password: password,
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
};
export const getWebfleetAreas = async (token) => {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/geocerca/webfleetAreas",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
};

export const getAvailableStatus = async (token) => {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/estados",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
};

export const getGeofenceTypes = async (token) => {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/tipoGeocercas",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
};

export async function getGeofences(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/geocerca", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
/**
 * @description Implementation method to save data of a geofence in the db, it can be a new geofence or edit an existing one
 * @param {String} token The required token for authorization header.
 * @param {String } areaname the name of the area.
 * @param {Number} status the status id of the geofence
 * @param {Number} type the type id of the geofence
 * @param {String} latitude the latitude of the geofence
 * @param {String} longitude the longitude of the geofence
 * @param {Number} radius  the radius of the geofence
 * @param {String} notes the extra notes to put in the geofence
 * @param {Number} idArea the id of the existen area in case of edit if is a new area it can be null or undefined
 */
export async function saveGeofence(
  token,
  areaname,
  status,
  type,
  latitude,
  longitude,
  radius,
  notes,
  relationId,
  idArea
) {
  let response = null;
  try {
    let obj = {
      nombre: areaname,
      estado: status,
      tipo: type,
      latitud: latitude,
      longitud: longitude,
      radio: radius,
      relacionId: relationId ? relationId : null,
      notas: notes ? notes : null,
    };
    let request;
    if (idArea) {
      request = await axios.put(
        resources.urls.ergonApi + "/geocerca/" + idArea,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(resources.urls.ergonApi + "/geocerca", obj, {
        headers: {
          Authorization: token,
        },
      });
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

/**
 * @description Implementation method to delete a geofence from the db.
 * @param {*} token the required token for authorization header
 * @param {*} id the id of the geofence that would like to delete
 */
export async function deleteGeofence(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/geocerca/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getPlants(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/planta", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

/**
 * @description Implementation method to save or edit a plant from the api
 * @param {String} token The required token for authorization header.
 * @param {String} alias The alias that will have the plant.
 * @param {Number} status the status id that will have the plant.
 * @param {String} contact the contact name of the plant.
 * @param {String} email  the email contact for the plant.
 * @param {String} telephone  the telephone contact for the plant.
 * @param {Number} geofence  the geofence id that would be the location of the plant
 * @param {String} notes the extra notes for the plant.
 * @param {Number} idPlant hte id of the plant in case of edit if is new area it can be null or undefined
 */
export async function savePlant(
  token,
  alias,
  status,
  contact,
  email,
  telephone,
  geofence,
  notes,
  yards,
  idPlant,
  loadTQxTQ,
  emails,
  plantType,
  relatedPlant,
  apiKeyParkimovil,
  secretKeyParkimovil
) {
  let response = null;
  try {
    let obj = {
      alias: alias,
      nombreContacto: contact,
      correoContacto: email,
      telefonoContacto: telephone,
      geocerca: geofence,
      estado: status,
      patios: yards ? yards : [],
      notas: notes ? notes : null,
      cargaTQxTQ: loadTQxTQ,
      correosInforme: emails,
      tipoPlanta: plantType,
      plantaRelacionada: relatedPlant,
      apiKeyParkimovil: apiKeyParkimovil,
      secretKeyParkimovil: secretKeyParkimovil
    };
    let request;
    if (idPlant) {
      request = await axios.put(
        resources.urls.ergonApi + "/planta/" + idPlant,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(resources.urls.ergonApi + "/planta", obj, {
        headers: {
          Authorization: token,
        },
      });
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

/**
 * @description Implementation method to delete a plant from the db.
 * @param {*} token the required token for authorization header
 * @param {*} id the id of the plant that would like to delete
 */
export async function deletePlant(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/planta/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getPermissions(token) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/permisos",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getUsers(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/usuario", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getLevels(token) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/niveles",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getWeightUnits(token) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/unidadMedidas",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getProfiles(token) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/perfiles",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getUserDetails(token, id) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/usuario/" + id, {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveUser(token, obj) {
  let response = null;
  try {
    let request;
    let formData = new FormData();
    if (obj.imagen && typeof obj.imagen === "object") {
      formData.append("imagen", obj.imagen);
    }
    formData.append("correoElectronico", obj.correoElectronico);
    formData.append("nombre", obj.nombre);
    formData.append("apellidoPaterno", obj.apellidoPaterno);
    formData.append("apellidoMaterno", obj.apellidoMaterno);
    formData.append("celular", obj.celular);
    formData.append("planta", obj.planta);
    formData.append("nivel", obj.nivel);
    formData.append("perfil", obj.perfil);
    if (obj && obj.contrasena) {
      formData.append("contrasena", obj.contrasena);
    }
    if (obj && Array.isArray(obj.permisos)) {
      formData.append("permisos", JSON.stringify(obj.permisos));
    }
    formData.append("notas", obj.notas);
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/usuario/" + obj.id,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(
        resources.urls.ergonApi + "/usuario",
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function deleteUser(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/usuario/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveDriverEnterprise(token, obj) {
  let response = null;
  try {
    let request;
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/transportista/" + obj.id,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(
        resources.urls.ergonApi + "/transportista",
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

/**
 * @description Implementation method to delete a plant from the db.
 * @param {*} token the required token for authorization header
 * @param {*} id the id of the plant that would like to delete
 */
export async function deleteDriverEnterprise(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/transportista/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getDriverEnterprises(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/transportista", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getVehicles(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/unidad", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function deleteVehicle(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/unidad/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getProducts(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/producto", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveProduct(token, obj) {
  let response = null;
  try {
    let request;
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/producto/" + obj.id,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(resources.urls.ergonApi + "/producto", obj, {
        headers: {
          Authorization: token,
        },
      });
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function deleteProduct(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/producto/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getImageFromServer(token, path) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/obtenerImagen",
      {
        headers: {
          Authorization: token,
        },
        params: {
          path: path,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveVehicleImage(token, image, id) {
  let response = null;
  try {
    let formData = new FormData();
    formData.append("image", image);
    let { data } = await axios.post(
      resources.urls.ergonApi + "/unidad/foto/" + id,
      formData,
      {
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function saveUserImage(token, image, id) {
  let response = null;
  try {
    let formData = new FormData();
    formData.append("image", image);
    let { data } = await axios.post(
      resources.urls.ergonApi + "/usuario/foto/" + id,
      formData,
      {
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getDrivers(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/operador", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveDriver(token, obj) {
  let response = null
  try {
    let request
    let formData = new FormData()
    if (obj.imagenFile) formData.append('imagenFile', obj.imagenFile)
    formData.append('nombre', obj.nombre)
    formData.append('estado', obj.estado.id)
    formData.append('celular', obj.celular)
    formData.append('licencia', obj.licencia)
    formData.append('vigenciaLicencia', obj.vigenciaLicencia)
    formData.append('idNavixy', obj.idNavixy)
    formData.append('transportista', obj.transportista.id)
    formData.append('unidad', obj.unidad)
    formData.append('navixyTrackerId', obj.navixyTrackerId)
    formData.append('navixyTrackerLabel', obj.navixyTrackerLabel)
    formData.append('aptoMedico', obj.aptoMedico)
    formData.append('sinGps', obj.sinGps)
    formData.append('notas', obj.notas)
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + '/operador/' + obj.id,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
    } else {
      request = await axios.post(
        resources.urls.ergonApi + '/operador',
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
    }

    response = request.data
  } catch (err) {
    console.log(err)
    response = {
      errorCode: 1,
      message: 'Ocurrio un error al realizar la petición. Vuelva a intentar',
    }
  } finally {
    return response
  }
}

export async function deleteDriver(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + '/operador/' + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: 'Ocurrio un error al realizar la petición. Vuelva a intentar',
    };
  } finally {
    return response;
  }
}

export async function getClients(token, id) {
  let response = null;
  let url = resources.urls.ergonApi + "/cliente";
  if (id) {
    url += `/${id}`;
  }
  try {
    let { data } = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveClient(token, obj) {
  let response = null;
  try {
    let request;
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/cliente/" + obj.id,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(resources.urls.ergonApi + "/cliente", obj, {
        headers: {
          Authorization: token,
        },
      });
    }
    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function deleteClient(token, id) {
  let response = null;
  try {
    let { data } = await axios.delete(
      resources.urls.ergonApi + "/cliente/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getSchedulesTypes(token) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/constante/tipoTurnos",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getScheduleByPlant(token, id) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/turno/" + id, {
      headers: {
        Authorization: token,
      },
    });
    if (data.errorCode === 11001) {
      response = [];
    } else {
      response = data;
    }
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveSchedule(token, obj) {
  let response = null;
  try {
    let request;
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/turno/" + obj.id,
        obj,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(resources.urls.ergonApi + "/turno", obj, {
        headers: {
          Authorization: token,
        },
      });
    }
    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getGeneralInfo(token, url, parameters/* ={numQuantity:50,plant:null,textName:null} */) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + url, {
      headers: {
        Authorization: token,
      },
      params: parameters,

    });
    response = data;
  } catch (err) {
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
/**
 * @description Its a general function to finish tasks in to the ERGON API.
 * @param {String} token is the token that is generated when the login is success, its for authorization porpuse.
 * @param {String} url The url for the endpoint without the baseURL.
 * @param {String} id  id to select the order i want to finish.
 * @param {Object} obj The object that is going to be save, only has status, usually is 6.
 * @returns {Object} An object with the status of the request.
 */
export async function finishOrder(token, url, obj, id) {
  console.log(resources.urls.ergonApi + url + id, obj);
  let response = null;
  try {
    let request;
    if (id) {
      request = await axios.put(resources.urls.ergonApi + url + id, obj, {
        headers: {
          Authorization: token,
        },
      });
    }
    console.log(request);
    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
/**
 * @description Its a general function to save objects in to the ERGON API.
 * @param {String} token is the token that is generated when the login is success, its for authorization porpuse.
 * @param {String} url The url for the endpoint without the baseURL.
 * @param {Object} obj The object that is going to be save.
 * @returns {Object} An object with the status of the request.
 */
export async function saveAnObject(token, url, obj) {
  let response = null;
  try {
    let request;
    if (obj.id) {
      let id = obj.id;
      request = await axios.put(resources.urls.ergonApi + url + id, obj, {
        headers: {
          Authorization: token,
        },
      });
    } else {
      request = await axios.post(resources.urls.ergonApi + url, obj, {
        headers: {
          Authorization: token,
        },
      });
    }
    response = request.data;
  } catch (err) {
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
/**
 * @description Its a general function to delete an object from the ERGON API.
 * @param {String} token  is the required token that is generated when the login is success, its for auth porpuse.
 * @param {String} url The  Url for the endpoint without the base url.
 * @param {Number} id the id for the object that we want to delete
 * @returns { Object} An Object with the status of the request.
 */
export async function deletteAnObject(token, url, id) {
  let response = null;
  try {
    let { data } = await axios.delete(resources.urls.ergonApi + url + id, {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getUnitWithoutGPS(token) {
  let response = null;
  try {

  let { data } = await axios.get(`${resources.urls.ergonApi}/unidad/unidadSinGPS`, {
    headers: {
      Authorization: token,
    },
  });
  response = data;

  } catch (err) {
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}
export async function getUnitWithoutGpsByID(token,id) {
  let response = null;
  try {

  let { data } = await axios.get(`${resources.urls.ergonApi}/unidad/unidadSinGpsByID`, {
    params: {
      id: id,
    },
    headers: {
      Authorization: token,
    },
  });
  response = data;

  } catch (err) {
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getUnitsInYard(token, plant) {
  let response = null;
  try {
    let { data } = await axios.get(`${resources.urls.ergonApi}/unidad/unidadesEnPatio`, {
      params: {
        planta: plant,
      },
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getCredentials(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/credenciales", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveCredentials(token, obj) {
  let response = null;
  try {
    let request;
    request = await axios.post(resources.urls.ergonApi + "/credenciales", obj, {
      headers: {
        Authorization: token,
      },
    });

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function saveVehicle(token, obj) {
  let response = null;
  try {
    let request;
    let formData = new FormData();
    if (obj.imagen && typeof obj.imagen === "object") {
      formData.append("imagen", obj.imagen);
    }
    if (obj.economico) {
      formData.append("economico", obj.economico);
    }
    if (obj.placas) {
      formData.append("placas", obj.placas);
    }
    if (obj.marca) {
      formData.append("marca", obj.marca);
    }
    if (obj.modelo) {
      formData.append("modelo", obj.modelo);
    }
    if (obj.color) {
      formData.append("color", obj.color);
    }
    if (obj.capacidad) {
      formData.append("capacidad", obj.capacidad);
    }
    if (obj.unidadMedida) {
      formData.append("unidadMedida", obj.unidadMedida);
    }
    if (obj.placasTanque) {
      formData.append("placasTanque", obj.placasTanque);
    }
    if (obj.placasTanqueDos) {
      formData.append("placasTanqueDos", obj.placasTanqueDos);
    }
    if (obj.transportista) {
      formData.append("transportista", obj.transportista);
    }
    if (obj.traspasos) {
      formData.append("traspasos", obj.traspasos || 0);
    }


    if (obj.estado) {
      formData.append("estado", obj.estado);
    }
    if (obj.notas) {
      formData.append("notas", obj.notas);
    }
    if (obj.objectuid) {
      formData.append("objectuid", obj.objectuid);
    }
    if (obj.id) {
      request = await axios.put(
        resources.urls.ergonApi + "/unidad/" + obj.id,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } else {
      request = await axios.post(
        resources.urls.ergonApi + "/unidad",
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    }

    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getPedidos(token) {
  let response = null;
  try {
    let { data } = await axios.get(resources.urls.ergonApi + "/pedido", {
      headers: {
        Authorization: token,
      },
    });
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getPedido(token, num) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/pedido/tareas/" + num,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function acceptOrder(token, obj) {
  let response = null;
  try {
    let request = await axios.post(
      resources.urls.ergonApi + "/pedido/aceptarOrden",
      obj,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = request.data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export async function getURLtoDownloadPDF(token, id) {
  let response = null;
  try {
    let { data } = await axios.get(
      resources.urls.ergonApi + "/pedido/downloadTaskPDF/",
      {
        headers: {
          Authorization: token,
        },
        params: {
          id: id
        }
      }
    );
    response = data;
  } catch (err) {
    console.log(err);
    response = {
      errorCode: 1,
      message: "Ocurrio un error al realizar la petición. Vuelva a intentar",
    };
  } finally {
    return response;
  }
}

export const confirmOrder = async (token, params) => {
  let response = null
  try {
    const { data } = await axios.post(
      `${resources.urls.ergonApi}/orderConfirmation/confirm`,
      params,
      { headers: { Authorization: token } }
    )
    response = data
  } catch (error) {
    console.error(error)
    response = {
      errorCode: 1,
      message: 'Ocurrio un error al realizar la petición. Vuelva a intentar'
    }
  } finally {
    return response
  }
}
