import React from 'react'
import { Button } from 'semantic-ui-react'

const FinishOrderButton = ({ onClick = () => {} }) => {
  return (
    <Button color="red" size="mini" onClick={ onClick }>
      Terminar pedido
    </Button>
  )
}

export { FinishOrderButton }
