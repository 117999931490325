import React from 'react'
import { Table } from '../components/vehicleCrud/Table'
import { VehicleForm } from '../components/vehicleCrud/VehicleForm'
import { VehicleProvider } from '../contexts/vehicleContext'
import { ConfiguredVehicles } from '../components/vehicleCrud/ConfiguredVehicles'

const VehicleCrud = () => {
  return (
    <VehicleProvider>
      <div className='vehicle-screen-alert'>
        <ConfiguredVehicles />
      </div>
      <h1>Unidades</h1>
      <div className='vehicle-screen-container'>
        <VehicleForm />
        <Table />
      </div>
    </VehicleProvider>
  )
}

export { VehicleCrud }
