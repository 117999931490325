import { GET_AUTHENTICATION, LOGOUT_AND_CLEAN_DATA } from './types'

const getAuthFromStorage = () => async dispatch => {
  let credentials = null
  try {
    credentials = await sessionStorage.getItem('credentials')
    if (credentials)
      credentials = await JSON.parse(credentials)
    else
      credentials = false
  }
  catch(err) {
    credentials = false
  }
  finally {
    dispatch({ type: GET_AUTHENTICATION, payload: credentials })
  }
}

const logoutFromReduxAndStorage = () => async dispatch => {
  let credentials = null
  try {
    credentials = null
  }
  catch(err) {
    credentials = false
  }
  finally {
    dispatch({ type: LOGOUT_AND_CLEAN_DATA, payload: credentials })
  }
}

export { getAuthFromStorage, logoutFromReduxAndStorage }
