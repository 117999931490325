import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const ShipmentCompanyField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Transportista:</label>
      <label>{ context.selectedIncidence?.transportista }</label>
    </div>
  )
}

export { ShipmentCompanyField }
