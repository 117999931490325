import React from 'react'
import { TabIcon } from './TabIcon'

const TabOption = ({ option, selected = '', onClick = () => {} }) => {
  const isThisOption = () => option.key === selected
  const getClasses = () => `tab-option ${isThisOption() ? 'selected' : ''}`

  return (
    <div className={ getClasses() } onClick={ () => onClick(option.key) }>
      <label>{ option.label }</label>
      <TabIcon icon={ option.icon }/>
    </div>
  )
}

export { TabOption }
