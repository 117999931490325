import React from 'react'
import { Input } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'
import { createInputValueHandler } from '../../../helpers/input'

const CreditNoteQuantityInput = () => {
  const context = useIncidencesLogContext()

  const montoHandler = createInputValueHandler(context.setMontoNotaCredito)

  return (
    <div className="field">
      <label>Monto de la nota de crédito:</label>
      <Input
        type="number"
        step={ 1 }
        min={ 0.0 }
        lang="es-MX"
        placeholder="Monto de la nota de crédito"
        value={ context.montoNotaCredito || '' }
        onChange={ montoHandler }
      />
    </div>
  )
}

export { CreditNoteQuantityInput }
