import React from 'react'
import { VIN } from './second_container/VIN'
import { TankPlate } from './second_container/TankPlate'
import { Carrier } from './second_container/Carrier'
import { Brand } from './second_container/Brand'
import { Model } from './second_container/Model'
import { Capacity } from './second_container/Capacity'
import { MeasureUnit } from './second_container/MeasureUnit'
import { Notes } from './second_container/Notes'
import { FormActions } from './FormActions'

const SecondContainer = () => {
  return (
    <div className='second-container'>
      <VIN />
      <TankPlate />
      <Carrier />
      <Brand />
      <Model />
      <Capacity />
      <MeasureUnit />
      <Notes />
      <FormActions />
    </div>
  )
}

export { SecondContainer }
