import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../contexts/OrderContext'

const ConfirmUnassignModal = () => {
  const context = useOrderContext()

  const confirmHandler = () => {
    context.unassign()
    context.setShowConfirmUnassign(false)
  }

  const cancelHandler = () => {
    context.setShowConfirmUnassign(false)
  }

  return (
    <Modal show={ context.showConfirmUnassign }>
      <Modal.Header closeButton>
        <Modal.Title>
          Desasignar operador
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Al desasignar operador, se realizaran las siguientes acciones:</p>
        <ul>
          <li>La unidad asignada pasara a disponible</li>
          <li>El pedido pasara a estado sin asignar</li>
          <li>Se eliminaran las tareas de navixy, correspondientes a este pedido</li>
        </ul>
        <p>¿Esta seguro que desea desasignar operador?</p>
      </Modal.Body>
      <Modal.Footer>
      <Button
        color="grey"
        onClick={ cancelHandler }
      >
        Cancelar
      </Button>
      <Button
        onClick={ confirmHandler }
        color={"red"}
      >
        Confirmar desasignación
      </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmUnassignModal }
