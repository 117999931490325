import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'
import { createInputValueHandler } from '../../../../helpers/input'

const StartTemperatureInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const inputHandler = (
    createInputValueHandler(contextModal.setOutletTemperature)
  )
  const getValue = () => contextModal.outletTemperature

  return (
    <>
      <div>Temp. Salida Planta:</div>
      <Input
        value={ getValue() }
        onChange={ inputHandler }
      />
    </>
  )
}

export { StartTemperatureInput }
