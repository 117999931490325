import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const ParkingExitDate = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    context.stayInfo.salidaPatio || 'Sin asignación registrada'
  )

  return (
    <div className="form-field">
      <label>Fecha asignación:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { ParkingExitDate }
