import React from 'react'
import { PlantsDropdown } from '../PlantsDropdown'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { useAuthorizedStaysLogContext } from '../../contexts/AuthorizedStaysLogContext'
import { Button, Form } from 'semantic-ui-react'

const SearchBar = () => {
  const context = useAuthorizedStaysLogContext()

  const handleDatePickerChange = value => context.setDateRange(value)

  return (
    <div className="search-bar">
      <div className="form-field">
        <label>Planta:</label>
        <PlantsDropdown setSelectedPlant={ context.setSelectedPlant }/>
      </div>
      <div className="form-field">
        <label>Fecha:</label>
        <Form size="mini">
          <DatePicker
            selectsRange
            isClearable
            locale={ es }
            startDate={ context.startDate }
            endDate={ context.endDate }
            onChange={ handleDatePickerChange }
          />
        </Form>
      </div>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchBar }
