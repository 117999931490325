import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import service from '../services/ordersInTransitStays'
import { useOrdersInTransitContext } from './OrdersInTransitContext'

const OrdersInTransitStayContext = createContext()

const OrdersInTransitStayProvider = ({ children }) => {
  const screenContext = useOrdersInTransitContext()
  const { credentials } = useSelector(state => state.auth)

  const [isWorking, setIsWorking] = useState(false)
  const [orderDetails, setOrderDetails] = useState([])
  const [selectedOrderDetail, setSelectedOrderDetail] = useState('')
  const [isAlreadyAproved, setIsAlreadyAproved] = useState(false)

  const [stayInfo,        setStayInfo       ] = useState({})
  const [id,              setId             ] = useState(0)
  const [estadiaAprobada, setEstadiaAprobada] = useState(0)

  useEffect(() => {
    if (!credentials) return
    load()
    // eslint-disable-next-line
  }, [screenContext.selectedOrder.folio])

  useEffect(() => {
    if (!credentials) return
    if (!selectedOrderDetail) return
    getStayInfo()
    // eslint-disable-next-line
  }, [selectedOrderDetail])

  const load = async () => {
    setIsWorking(true)
    await getOrderDetails()
    setIsWorking(false)
  }

  const getOrderDetails = async () => {
    const id = screenContext.selectedOrder.folio
    const response = await service.getOrderDetailsList(id, credentials.token)
    setOrderDetails(response)
    if (response.length === 1)
      setSelectedOrderDetail(response[0].value)
  }

  const getStayInfo = async () => {
    const id = selectedOrderDetail
    const response = await service.getById(id, credentials.token)
    setId(response.id || 0)
    setEstadiaAprobada(response.diasAprobados || 0)
    setStayInfo(response)
    setIsAlreadyAproved(!!response.diasAprobados)
  }

  const upload = async file => {
    setIsWorking(true)
    const params = { id: selectedOrderDetail, file: file }
    await service.upload(params, credentials.token)
    setIsWorking(false)
  }

  const save = async () => {
    setIsWorking(true)
    const params = { id: selectedOrderDetail, aprovedDays: estadiaAprobada }
    const response = await service.save(params, credentials.token)
    if (response && orderDetails.length !== 1) clean()
    setIsWorking(false)
  }

  const clean = () => {
    setSelectedOrderDetail('')
    setStayInfo({})
    setId(0)
    setEstadiaAprobada(0)
    setIsAlreadyAproved(false)
  }

  const generateDownload = data => {
    const filename  = `reclamo_de_estadia_${id}.pdf`
    const blob      = new Blob([data])
    const windowUrl = window.URL.createObjectURL(blob)
    const link      = document.createElement('a')
    link.href       = windowUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const download = async () => {
    setIsWorking(true)
    const id = selectedOrderDetail
    const response = await service.download(id, credentials.token)
    if (response) generateDownload(response)
    setIsWorking(false)
  }

  const context = {
    isWorking,
    orderDetails,
    selectedOrderDetail, setSelectedOrderDetail,
    isAlreadyAproved,
    stayInfo,
    id,
    estadiaAprobada, setEstadiaAprobada,
    upload,
    save,
    download
  }

  return (
    <OrdersInTransitStayContext.Provider value={ context }>
      { children }
    </OrdersInTransitStayContext.Provider>
  )
}

const useOrdersInTransitStayContext = () => (
  useContext(OrdersInTransitStayContext)
)

export {
  OrdersInTransitStayProvider,
  useOrdersInTransitStayContext
}
