import React from 'react'
import { usePlantsContext } from '../../contexts/PlantsContext'

const ConfiguredPlants = () => {
  const context = usePlantsContext()
  return (
    <div className='configured-plants'>
      <label>Plantas configuradas: { context.plants.length } </label>
    </div>
  )
}

export { ConfiguredPlants }
