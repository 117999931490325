import React from 'react'
import { OriginDropdown } from './search_bar/OriginDropdown'
import { ClientDropdown } from './search_bar/ClientDropdown'
import { SearchButton } from './search_bar/actions/SearchButton'
import { IsInternalCheckbox } from './search_bar/actions/IsInternalCheckbox'
import { XLSDownloadButton } from './search_bar/actions/XLSDownloadButton'

const SearchBar = () => {
  return (
    <div className='search-bar'>
      <div className='filters'>
        <OriginDropdown />
        <ClientDropdown />
      </div>
      <div className='actions'>
        <div className='action-filters'>
          <IsInternalCheckbox />
          <SearchButton />
        </div>
        <div className='excel'>
          <XLSDownloadButton />
        </div>
      </div>
    </div>
  )
}

export { SearchBar }
