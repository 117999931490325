import React from 'react'
import { TabOption } from './TabOption'

const TabOptions = ({ options = [], selected, onClick = () => {} }) => {
  return (
    <div className='c-tab-options'>
      {
        options.map(o => (
          <TabOption
            key={ o.key }
            option={ o }
            selected={ selected }
            onClick={ onClick }
          />
        ))
      }
    </div>
  )
}

export { TabOptions }
