import React from 'react'
import { IncidencesProvider } from '../contexts/IncidencesContext'
import { IncidencesForm } from '../components/incidence_types_crud/IncidencesForm'
import { ConfirmDestroyModal } from '../components/incidence_types_crud/ConfirmDestroyModal'
import { Table } from '../components/incidence_types_crud/Table'

const IncidenceTypesCrud = () => {

  return (
    <IncidencesProvider>
      <div className='screen-incidence-types-crud'>
        <h1>Tipos de incidencia</h1>
        <div className='content'>
          <ConfirmDestroyModal/>
          <IncidencesForm/>
          <Table/>
        </div>
      </div>
    </IncidencesProvider>
  )
}

export { IncidenceTypesCrud }
