import React from 'react'
import { createInputValueHandler } from '../../../helpers/input'
import { Input } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const UnloadingDateInput = () => {
  const context = useStayClaimContext()
  const inputHandler = createInputValueHandler(context.setUnloadingDate)

  return (
    <div className='item'>
      <label>Fecha de descarga:</label>
      <div className='time'>
        <Input
          type='datetime-local'
          value={ context.unloadingDate }
          onChange={ inputHandler }
        />
      </div>
    </div>
  )
}

export { UnloadingDateInput }
