import React from 'react'
import { useIncidencesContext } from '../../contexts/IncidencesContext'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'descripcion', title: 'Descripción' },
  { path: 'estado', title: 'Estado' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useIncidencesContext()

  return (
    <div className='table-incidence-types'>
      <StyledTable
        Search
        headerSearchBy={ headerSearchBy }
        data={ context.incidenceTypes }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickRow={ context.onSelectType }
      />
    </div>
  )
}

export { Table }
