import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

const Actions = ({
  close = () => {},
  confirm = () => {},
  canConfirm = false
}) => {
  return (
    <Modal.Footer>
      <Button
        color="grey"
        onClick={ () => close() }
      >
        Cancelar
      </Button>
      <Button
        color="blue"
        onClick={ confirm }
        disabled={ !canConfirm }
      >
        Confirmar
      </Button>
    </Modal.Footer>
  )
}

export { Actions }
