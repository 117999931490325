import React from 'react'
import { SaveButton } from '../actions/SaveButton'
import { CleanButton } from '../actions/CleanButton'
import { DestroyButton } from '../actions/DestroyButton'

const ActionButtons = () =>
  <div className='action-buttons'>
    <CleanButton/>
    <DestroyButton/>
    <SaveButton/>
  </div>

export { ActionButtons }
