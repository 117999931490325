import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const isTest = () => process.env.REACT_APP_ERGON_ENV === 'test'
const isProduction = () => process.env.REACT_APP_ERGON_ENV === 'production'

const getUrlConfirmation = () => {
  const URL = 'http://ergon.movomx.com'
  if (isTest()) return `${URL}:8082/#/confirmacionPedido/`
  if (isProduction()) return `${URL}/#/confirmacionPedido/`
  return `${process.env.REACT_APP_SITE_URL}/#/confirmacionPedido/`
}

const ConfirmationLink = () => {
  const context = useOrdersInTransitContext()

  const getUrl = () =>
    `${getUrlConfirmation()}${context.orderDetails.info.token}`

  const isConfirmationPendingOrder = () =>
    context.orderDetails.info.estado === 'Por confirmar'

  if (isConfirmationPendingOrder())
    return (
      <div>
        <div>Link de confirmación: </div>
        <div className="linkConfirmationOrderInfoTab">
          <a href={ getUrl() } target="_blank" rel="noopener noreferrer">
            { getUrlConfirmation() }...
          </a>
        </div>
      </div>
    )
  return <></>
}

export { ConfirmationLink }
