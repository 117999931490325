import React from 'react'
import { Icon } from 'semantic-ui-react'
import { UnitUseProvider } from '../contexts/UnitUseContext'
import { SearchBar } from '../components/unit_use_report/SearchBar'
import { Table } from '../components/unit_use_report/Table'
import {
  XLSDownloadButton
} from '../components/unit_use_report/actions/XLSDownloadButton'

const UnitUseReport = () => {

  return (
    <UnitUseProvider>
      <div className='screen-unit-use-report'>
        <h1>Reporte de uso de unidad</h1>
        <XLSDownloadButton />
        <div className='content'>
          <div><Icon name='filter'/>Filtrar por:</div>
          <SearchBar />
          <Table />
        </div>
      </div>
    </UnitUseProvider>
  )
}

export { UnitUseReport }
