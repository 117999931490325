import React from 'react'
import { Button } from 'semantic-ui-react'
import { useUnitUseContext } from '../../../../contexts/UnitUseContext'

const SearchButton = () => {
  const context = useUnitUseContext()

  return(
    <div className='search-container'>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchButton }
