import React, { useState } from 'react'
import axios from "axios"
import resources from "../resources"
import { Swal1 } from '../utils/common'
import { Icon } from 'semantic-ui-react'

const clean = text => text.replace('=""', '').replace('""', '')

export const ExcelLink = ({ children, token, headers, data, filename }) => {

  const [wating, setWaiting] = useState(false)

  const cleanData = () => (
    data.map(r => ({
      ...r,
      remision:        clean(r.remision),
      CelOperador:     clean(r.CelOperador),
      fechaCarga:      clean(r.fechaCargaPlaneada),
      fechaCompromiso: clean(r.fechaCompromiso),
      fechaRequerida:  clean(r.fechaRequerida)
    }))
  )

  const handleClick = async () => {
    const params   = { headers: headers, data: cleanData() }
    const config   = { responseType: 'arraybuffer', headers: { Authorization: token } }
    const endpoint = `${resources.urls.ergonApi}/pedido/excel`
    try {
      setWaiting(true)
      const result   = await axios.post(endpoint, params, config)
      const blob     = new Blob([result.data])
      const url      = window.URL.createObjectURL(blob)
      const link     = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (error) {
      Swal1.fire({ title: 'Ocurrió un error al intentar descargar el archivo.' });
    }
    finally {
      setWaiting(false)
    }
  }

  return (
    wating ?
    (
      <button className='downloadFileButton busy'>
        <Icon loading name='circle notch' />
      </button>
    ) :
    (
      <button
        onClick={ handleClick }
        className='downloadFileButton'
        target="_self"
      >
        { children }
      </button>
    )
  )
}
