import axios from 'axios'
import resources from '../resources'

const buidlUrl = (id = '') => `${resources.urls.ergonApi}/plants/${id}`

const buildHeader = token => ({ headers: { Authorization: token }})

const getAll = async token => {
  const { data } = await axios.get(buidlUrl(), buildHeader(token))
  return data
}

const getById = async (plantId, token) => {
  const { data } = await axios.get(buidlUrl(plantId), buildHeader(token))
  return data
}

const create = async (params, token) => {
  const { data } = await axios.post(buidlUrl(), params, buildHeader(token))
  return data
}

const update = async (params, id, token) => {
  const { data } = await axios.put(buidlUrl(id), params, buildHeader(token))
  return data
}

const destroy = async (id, token) => {
  const { data } = await axios.delete(buidlUrl(id), buildHeader(token))
  return data
}

const getTypePlant = async token => {
  const {
    data
  } = await axios.get(buidlUrl('geofences/plant'), buildHeader(token))
  return data
}

const getTypeYard = async token => {
  const {
    data
  } = await axios.get(buidlUrl('geofences/yard'), buildHeader(token))
  return data
}

export {
  getAll,
  getById,
  create,
  update,
  destroy,
  getTypePlant,
  getTypeYard
}
