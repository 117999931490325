import React from 'react'
import { DownloadPDFButton } from './DownloadPDFButton'
import { StyledTable } from '../../../StyledTable'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'

const headers = [
  { path: 'invoiceId',         title: 'Folio no disponibilidad' },
  { path: 'fechaNoDisponible', title: 'Fecha no disponibilidad' },
  { path: 'descargaPDF',       title: 'PDF'                     }
]

const EvidenceTable = () => {
  const context = useChangeUnitsContext()

  const buildData = () => context.evidenceHistoryData.map(e => ({
    invoiceId: e.unidadNoDisponibleId,
    fechaNoDisponible: e.fechaNoDisponible,
    descargaPDF: renderButton(e.unidadNoDisponibleId)
  }))

  const isWaitingToDownload = id => context.idInvoiceSelected === id

  const renderButton = id => {
    if (isWaitingToDownload(id))
      return 'Descargando...'
    else
      return (
        <DownloadPDFButton
          invoiceId={ id }
        />
      )
  }

  return (
    <div className= 'evidence-history-table' >
      <StyledTable
        search={ false }
        headers={ headers }
        data={ buildData() }
      />
    </div>
  )
}

export { EvidenceTable }
