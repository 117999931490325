import React from 'react'
import ReactDatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { Button } from 'semantic-ui-react'
import { PlantsDropdown } from '../PlantsDropdown'
import { useDailyStatusContext } from '../../contexts/DailyStatusContext'
import { ShipmentCompaniesDropdown } from '../ShipmentCompaniesDropdown'

const SearchBar = () => {
  const context = useDailyStatusContext()

  const handleDatePickerChange = value => context.setDateRange(value)

  return (
    <div className="search-bar">
      <div className="form-field">
        <label>Planta:</label>
        <PlantsDropdown
          value={ context.plantId }
          setSelectedPlant={ context.setPlantId }
        />
      </div>
      <div className="form-field">
        <label>Transportista:</label>
        <ShipmentCompaniesDropdown
          value={ context.shipmentCompanyId }
          setSelectedCompany={ context.setShipmentCompanyId }
        />
      </div>
      <div className="form-field date">
        <label>Fecha:</label>
        <ReactDatePicker
          selectsRange
          isClearable
          locale={ es }
          startDate={ context.startDate }
          endDate={ context.endDate }
          onChange={ handleDatePickerChange }
        />
      </div>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchBar }
