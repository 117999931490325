import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const FreightDropdown = () => {
  const context = useOrderAssignationContext()

  const inputHandler = createInputValueHandler(context.setFreightId)

  const handleData = () => context.parseToDropdown(context.freightsData)

  return (
    <div className='form-field'>
      <label className='title'>Selecciona un flete:</label>
      <Dropdown
        value={ context.freightId }
        options={ handleData() }
        onChange={ inputHandler }
        selection
        search
        clearable
        placeholder='Seleccione un flete'
      />
    </div>
  )
}

export { FreightDropdown }
