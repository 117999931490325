import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { useDispatch, useSelector } from 'react-redux'
import { ScreenWithNavItems } from '../components/ScreenWithNavItems'
import { RadioButtonGroup } from '../components/RadioButtonGroup'
import { CustomDimmer } from '../components/CustomDimmer'
import { ModalToElimate } from '../components/ModalToElimate'
import { StyledTable } from '../components/StyledTable'
import { getAuthFromStorage } from '../redux/actions'
import {
  getWebfleetAreas,
  getAvailableStatus,
  getGeofenceTypes,
  getGeofences,
  deleteGeofence,
  getClients,
  saveAnObject,
  getGeneralInfo,
} from '../services/ergonApi'
import {
  Dropdown,
  Input,
  TextArea,
  Form,
  Popup,
  Button,
} from 'semantic-ui-react'
import resources from '../resources'
import { Alert, Modal } from 'react-bootstrap'
import {
  parseArrayObjectsToDropdownArray,
  Toast,
  Swal1,
  filterdata,
} from '../utils/common'
import '../customStyles.css'
import { ExcelButton } from '../components/ExcelButton'

const AnyReactComponent = ({ text }) => (
  <Popup
    content={text}
    trigger={
      <img style={{ width: 30, height: 30 }} src={resources.images.marker} alt="img" />
    }
  />
)

const Geofence = () => {
  const history = useHistory()
  const { credentials } = useSelector((state) => state.auth)
  const [idArea, setIdArea] = useState(null)
  const [webfleetAreas, setWebfleetAreas] = useState([])
  const [availableStatus, setAvailableStatus] = useState([])
  const [geofencesTyes, setGeofencesTyes] = useState([])
  const [geofences, setGeofences] = useState([])
  const [selectedGeofenceType, setSelectedGeofenceType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [geofenceName, setGeofenceName] = useState("")
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedArea, setSelectedArea] = useState({
    name: "Sin seleccionar",
    latitude: 24.66118,
    longitude: -107.446321,
    radius: 275,
  })
  const [sendingData, setSendingData] = useState(false)
  const [notes, setNotes] = useState("")
  const [dropDownOptions, setDropDownOptions] = useState([])
  const [, setTRadionName] = useState("")
  const [clients, setClients] = useState([])
  const [plants, setPlants] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)
  const [selectedGeofenceTypeAux, setSelectedGeofenceTypeAux] = useState(null)
  const [selectedStatusAux, setSelectedStatusAux] = useState(null)
  const [loadingDimmer, setLoadingDimmer] = useState(true)
  const [navixyGoogleAdress, setNavixyGoogleAdress] = useState("")
  const [geofenceTypeChange, setGeofenceTypeChange] = useState({})
  const [openModalType, setOpenModalType] = useState(false)
  const [geofenceRelationAux, setGeofenceRelationAux] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setLoadingDimmer(true)
    async function fetchWebfleetAreas(token) {
      let webfleetAreasRes = await getWebfleetAreas(token)
      let availableStatusRes = await getAvailableStatus(token)
      let geofencesTypesRes = await getGeofenceTypes(token)
      let clientsRes = await getClients(token)
      let plantResponse = await getGeneralInfo(token, '/plantsByUser')
      await fetchGeofencesFromApi()
      if (webfleetAreasRes.errorCode) {
        Swal1.fire({
          title: webfleetAreas.message,
        })
      } else {
        let parseAreas = parseArrayObjectsToDropdownArray(
          webfleetAreasRes,
          "areaname"
        )
        let parseTypesClients = parseArrayObjectsToDropdownArray(
          clientsRes,
          "alias"
        )
        setWebfleetAreas(parseAreas)
        setAvailableStatus(availableStatusRes)
        setGeofencesTyes(geofencesTypesRes)
        setSelectedGeofenceType(geofencesTypesRes[0])
        setSelectedStatus(availableStatusRes[0])
        setSelectedGeofenceTypeAux(geofencesTypesRes[0])
        setSelectedStatusAux(availableStatusRes[0])
        setTRadionName(geofencesTypesRes[0].nombre)
        setClients(parseTypesClients)
        setDropDownOptions(parseTypesClients)
        setPlants(plantResponse.map(p => ({ ...p, id: p.value, value: p })))
      }
    }
    if (credentials == null) {
      dispatch(getAuthFromStorage())
    } else if (!credentials) {
      history.push("/")
    } else {
      fetchWebfleetAreas(credentials.token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => {
    loadTable()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDataOfTable])

  const sendData = async () => {
    setSendingData(true)
    let geofence = {
      id: idArea,
      nombre: geofenceName,
      estado: selectedStatus.id,
      tipo: selectedGeofenceType.id,
      latitud: selectedArea.latitude,
      longitud: selectedArea.longitude,
      radio: selectedArea.radius,
      coordenadas: selectedArea.coordenadas,
      relacionId: (selectedType.id || selectedType.key) || null,
      notas: notes ? notes : null,
      webfleetId: selectedArea.areauid,
    }
    let response = await saveAnObject(
      credentials.token,
      "/geocerca/",
      geofence
    )
    await fetchGeofencesFromApi()
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      })
    } else {
      Swal1.fire({
        title: response.message,
      })
    }
    cleanForms()
    setSendingData(false)
  }

  const cleanForms = () => {
    setGeofenceTypeChange({})
    setNavixyGoogleAdress("")
    setGeofenceName("")
    setNotes("")
    setSelectedArea({
      name: "Sin seleccionar",
      latitude: 24.66118,
      longitude: -107.446321,
      radius: 275,
    })
    setSelectedType(null)
    setIdArea(null)
    setSelectedStatus(selectedStatusAux)
    setSelectedGeofenceType(selectedGeofenceTypeAux)
    setGeofenceRelationAux(null)
  }

  async function fetchGeofencesFromApi() {
    let geofencesRes = await getGeofences(credentials.token)
    if (!geofencesRes.errorCode) {
      setGeofences(geofencesRes)
    }
    setLoadingDimmer(false)
  }
  async function handleDeleteButtonClick() {
    let response = await deleteGeofence(credentials.token, idArea)
    setShowDeleteConfirmation(false)
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      })
    } else {
      Swal1.fire({
        title: response.message,
      })
    }
    await fetchGeofencesFromApi()
    cleanForms()
    loadTable()
  }
  const getAreaFromWebfleet = areauid =>
    webfleetAreas.find(area => area.value.areauid === areauid)?.value

  async function setEditValues(data) {
    let domicilio = await getGeneralInfo(
      credentials.token,
      `/geocerca/direccion?latitud=${data.latitud}&longitud=${data.longitud}`
    )
    const areaFromWebfleet = getAreaFromWebfleet(data.webfleetId)
    const { areaCenter, coordenadas } = await getCoordinates(areaFromWebfleet.areauid)
    if (domicilio) setNavixyGoogleAdress(domicilio.direccion)
    else setNavixyGoogleAdress("Sin domicilio")
    setGeofenceRelationAux(data ? data.relacion : null)
    setGeofenceTypeChange(data ? data.tipo : {})
    handleDropdownOptions(data)
    setIdArea(data.id)
    setSelectedGeofenceType(data.tipo)
    setSelectedStatus(data.estado)
    setGeofenceName(data.nombre)
    setSelectedArea({
      name:        data.nombre,
      areaname:    data.nombre,
      latitude:    areaFromWebfleet.latitude  || Number(areaCenter.latitude),
      longitude:   areaFromWebfleet.longitude || Number(areaCenter.longitude),
      radius:      areaFromWebfleet.radius    || Number(areaCenter.radio),
      coordenadas: coordenadas.length ? formatCoords(coordenadas) : null
    })
    let aux = null
    if (data.relacion) {
      if (data.tipo && data.tipo.id === 1) {
        aux = clients.find((o) => o.value.alias === data.relacion.alias)
        setDropDownOptions(clients)
      } else {
        aux = plants.find(p => p.text === data.relacion.alias)
        setDropDownOptions(plants)
      }
    }

    setSelectedType(aux ? aux.value : null)
    setNotes(data.notas)
  }

  function handleChangeGeofenceName(value) {
    setGeofenceName(value)
  }

  const formatCoords = coords => coords.map(({ latitude, longitude }) => (
    { latitude, longitude }
  ))

  const getCoordinates = async areauid =>
    await getGeneralInfo(credentials.token, `/geocerca/coordenadas/${areauid}`)

  async function handleChangeArea(geofence) {
    if (geofence.type === 3) {
      let data = await getCoordinates(geofence.areauid)
      if (data.errorCode) return Swal1.fire({ title: data.message })
      geofence = {
        ...geofence,
        latitude: data.areaCenter.latitude,
        longitude: data.areaCenter.longitude,
        radius: data.areaCenter.radio,
        coordenadas: formatCoords(data.coordenadas)
      }
    }
    setSelectedArea(geofence)
    setGeofenceName(geofence.areaname)
  }
  function typeRadio(typetext) {
    if (typetext === "" || typetext === "Cliente") {
      return <div>holas</div>
    } else {
      return <div>no</div>
    }
  }

  function handleRadioClick(value) {
    const { id } = geofenceTypeChange
    if (id) {
      if (id === value?.id) {
      } else {
        setOpenModalType(true)
      }
    }
    setSelectedGeofenceType(value)
    setTRadionName(value.nombre)
    typeRadio(value.nombre)
    handleDropdownOptions(value)
  }

  function handleDropdownOptions(value) {
    setDropDownOptions([])
    if (value.id === 1) {
      setDropDownOptions(clients)
    } else {
      if (value.id === 2 || value.id === 3) {
        setDropDownOptions(plants)
      } else {
        setDropDownOptions([])
      }
    }
  }
  function handleChangeType(data) {
    setSelectedType(data)
    if (
      geofenceRelationAux &&
      geofenceRelationAux.id &&
      data &&
      data.id &&
      data.id !== geofenceRelationAux.id
    ) {
      setOpenModalType(true)
    }
  }
  function handleFilterData(data, e) {
    setGeofences(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }
  const formatData = () => geofences.filter(g =>
    g.nombre !== 'Libre a bordo en planta'
  )

  function loadTable() {
    return (
      <StyledTable
        headers={[
          { path: "nombre", title: "Nombre Geocerca" },
          { path: "tipo.nombre", title: "Tipo" },
          { path: "relacion.alias", title: "Dato Relacionado" },
          { path: "estado.nombre", title: "Estado" },
        ]}
        searchBy={["nombre", "tipo.nombre", "relacion.alias", "estado.nombre"]}
        headerSearchBy={[
          "Nombre Geocerca",
          "Tipo",
          "Dato Relacionado",
          "Estado",
        ]}
        data={ formatData() }
        handleOnClickRow={(data) => setEditValues(data)}
        handleOnClickHeader={(e) => handleFilterData(geofences, e)}
      />
    )
  }
  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <span style={styles.titleText}>Geocercas especiales</span>
      <div style={styles.mainContainer}>
        <ModalToElimate
          textContent="Una vez eliminada la información no se podrá recuperar. ¿ Esta seguro que desea eliminar?"
          title="Confirmación para eliminar geocerca"
          showModal={showDeleteConfirmation}
          handleOnClickCancel={() => setShowDeleteConfirmation(false)}
          handleOnClickConfirm={() => handleDeleteButtonClick()}
        />
        <CustomDimmer visible={loadingDimmer} />
        <div style={styles.formContainer}>
          <Form size="mini">
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> {"Geocerca webfleet:"} </span>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ display: "flex", width: "45%" }}>
                  <Dropdown
                    clearable
                    placeholder="Seleccione una geocerca"
                    fluid
                    selection
                    search
                    text={selectedArea.areaname}
                    value={selectedArea}
                    options={webfleetAreas}
                    onChange={(e, data) => handleChangeArea(data.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    marginLeft: "5%",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={styles.subtitleTextTipo}> Estado: </span>
                  <RadioButtonGroup
                    options={availableStatus}
                    selectedValue={selectedStatus}
                    onSelectoption={(value) => setSelectedStatus(value)}
                    labelKey="nombre"
                  />
                </div>
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Nombre: </span>
              <Input
                value={geofenceName}
                onChange={(e, { value }) => handleChangeGeofenceName(value)}
                style={{ width: "100%", margin: 0, borderRadius: 5 }}
              />
            </div>
            <div style={styles.formLine2}>
              <span style={styles.subtitleTextTipo}> Tipo: </span>
              <div style={{ display: "flex", width: "100%" }}>
                <RadioButtonGroup
                  options={geofencesTyes}
                  selectedValue={selectedGeofenceType}
                  onSelectoption={(value) => handleRadioClick(value)}
                  labelKey="nombre"
                />
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Relacionada a: </span>
              <div style={{ display: "flex", width: "100%" }}>
                <Dropdown
                  clearable
                  placeholder={"Selecciona Cliente / Planta a relacionar..."}
                  fluid
                  selection
                  search
                  options={dropDownOptions}
                  value={selectedType}
                  onChange={(e, data) => handleChangeType(data.value)}
                />
              </div>
            </div>
          </Form>
          <div style={styles.formLine}>
            <span style={styles.subtitleText}> Instrucciones de domicilio: </span>
            <Form style={{ width: "100%" }}>
              <TextArea
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                style={{ width: "100%", margin: 0, height: "70%" }}
              />
            </Form>
          </div>
          <div style={styles.formLine}>
            <span style={styles.subtitleText2}> Domicilio Navixy/google: </span>
            <span style={{width:"100%",fontSize:12,display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
            {navixyGoogleAdress ? navixyGoogleAdress : "sin domicilio"}
            </span>
          </div>
          <div style={styles.formMapComponent}>
            <span style={styles.subtitleText}> Mapa : </span>
            <div style={styles.mapContainer}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: resources.googleMapApikey }}
                center={{
                  lat: selectedArea.latitude,
                  lng: selectedArea.longitude,
                }}
                defaultZoom={15}
              >
                <AnyReactComponent
                  lat={selectedArea.latitude}
                  lng={selectedArea.longitude}
                  text={
                    "area: " +
                    selectedArea.areaname +
                    "\n radio: " +
                    selectedArea.radius
                  }
                />
              </GoogleMapReact>
            </div>
          </div>
          <div style={styles.buttonContainer}>
            {idArea ? (
              <Button
                color="grey"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => cleanForms()}
              >
                Cancelar cambios
              </Button>
            ) : (
              <></>
            )}
            {idArea ? (
              <Button
                color="red"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Eliminar geocerca
              </Button>
            ) : (
              <></>
            )}
            <Button
              primary
              loading={sendingData}
              style={{ margin: 0, fontSize: 12 }}
              onClick={ sendData }
            >
              {" "}
              {idArea ? "Guardar cambios" : "Guardar"}
            </Button>
          </div>
        </div>

        <div style={styles.tableContainer}>
          <div
            style={ styles.geofencesInformation }
          >
            <Alert
              variant={"warning"}
              style={{
                fontSize: 12,
                padding: 5,
                marginRight: 5,
                marginBottom: 0,
                width: "100%",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Total de geocercas configuradas : {geofences.length}
            </Alert>
            <ExcelButton
              endpoint='/geocerca/download_excel'
              fileName='Geocercas'
            />
          </div>
          <div style={{ overflow: "auto" }}>{loadTable()}</div>
        </div>
        <Modal show={openModalType} onHide={() => setOpenModalType()}>
          <Modal.Header closeButton>
            <Modal.Title>Tipo y relacion de geocerca</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Al cambiar de tipo o relacion una geocerca <b>ya existente</b>,
            puede provocar que los <b>pedidos ya registrados </b>a esa geocerca,
            tengan algunas incongruencias al momento de mostrarse.
          </Modal.Body>
          <Modal.Footer>
            <Button primary onClick={() => setOpenModalType()}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ScreenWithNavItems>
  )
}

const styles = {
  mainContainer: {
    display: "flex",
    height: "90%",
    width: "100%",
    padding: 10,
    justifyContent: "space-between",
  },
  titleText: {
    fontSize: "3rem",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "50%",
    padding: 10,
    borderRadius: 5,
  },
  formLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  formLine2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  subtitleText: {
    width: "25%",
    fontSize: 12,
    textAlign: "right",
    marginRight: 10,
  },
  subtitleText2: {
    width: "25%",
    fontSize: 12,
    textAlign: "right",
    marginRight: 10,
    fontWeight: "bold",
  },
  subtitleTextTipo: {
    width: "25%",
    fontSize: 12,
    textAlign: "right",
    marginRight: 5,
  },
  formMapComponent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 15,
    flex: 1,
  },
  mapContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: "white",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tableContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "45%",
    padding: 10,
  },
  geofencesInformation: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: 'flex-end',
    flexDirection: "row",
    marginBottom: 5
  }
}

export { Geofence }
