import axios from 'axios'
import resources from '../resources'

const buildUrl = (id = '') => (
  `${resources.urls.ergonApi}/initialInventory/${id}`
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getPlantInventory =  async (id, token) => {
  const { data } = await axios.get(
    buildUrl(id),
    buildHeaders(token)
  )
  return data
}

const saveInventory = async (params, token) => {
  await axios.post(
    buildUrl(),
    params,
    buildHeaders(token)
  )
}

export {
  getPlantInventory,
  saveInventory
}
