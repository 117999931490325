import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useTanksContext } from '../../contexts/TanksContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'niv',                title: 'NIV'           },
  { path: 'placas',             title: 'Placas'        },
  { path: 'transportistaAlias', title: 'Transportista' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useTanksContext()

  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setTanksData(filterdata(context.tanksData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.tanksData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
        handleOnClickRow={ context.onSelectRow }
      />
    </div>
  )
}

export { Table }
