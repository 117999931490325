import React from 'react'
import { Assignations } from './indicators/Assignment'
import { Units } from './indicators/Units'
import { KPI } from './indicators/KPI'

const Indicators = () => {

  return (
    <div className='indicators-container'>
      <Assignations />
      <Units />
      <KPI />
    </div>
  )
}

export { Indicators }
