import React from 'react'
import { useOrderContext } from '../../contexts/OrderContext'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'cantidad',     title: 'Cantidad' },
  { path: 'destino',      title: 'Destino' },
  { path: 'razonSocial',  title: 'Razón Social' },
  { path: 'remision',     title: 'Remisión' },
  { path: 'cantidadReal', title: 'Cantidad real' },
  { path: 'unidadMedida', title: 'UM' }
]

const OrderFormTable = ({ onSelectDetail = () => {} }) => {
  const context = useOrderContext()

  const matchDetail = (detail, row) => (
    detail.productoId  === row.productoId &&
    detail.destinoId   === row.destinoId &&
    detail.razonSocial === row.razonSocial &&
    detail.cantidad    === row.cantidad
  )

  const onDeleteProduct = row => {
    const newDetails = context.detalles.filter(d => !matchDetail(d, row))
    context.setDetalles(newDetails)
  }

  return (
    <StyledTable
      headers={ headers }
      search={ false }
      data={ context.detalles }
      handleOnClickRow={ onSelectDetail }
      handleOnDelete={ onDeleteProduct }
      enableDelete={ true }
    />
  )
}

export { OrderFormTable }
