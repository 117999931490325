import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Button } from 'semantic-ui-react'

const DownloadPdfButton = () => {
  const context = useUploadEvidenceContext()

  return (
    <Button
      className='download-button'
      loading={ context.isWorking }
      onClick={ context.download }
    >
      Descargar PDF
    </Button>
  )
}

export { DownloadPdfButton }
