import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Input } from 'semantic-ui-react'

const Invoice = () => {
  const context = useUploadEvidenceContext()

  return (
    <div className='head-container'>
        <label>Folio no disponibilidad:</label>
        <Input
          type='text'
          disabled
          value={ context.invoice }
        />
      </div>
  )
}

export { Invoice }
