import axios from 'axios'
import resources from '../resources'

const buildUrl = endpoint => `${resources.urls.ergonApi}${endpoint}`

const buildHeader = token => ({
  headers: { Authorization: token },
  responseType: 'arraybuffer'
})

const downloadExcel = async (token, endpoint) => {
  const { data } = await axios.get(buildUrl(endpoint), buildHeader(token))
  return data
}

export { downloadExcel }
