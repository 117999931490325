import React from 'react'
import { TextArea } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const ActionsInput = () => {
  const context = useOrdersInTransitIncidencesContext()

  const actionsHandler = (_, { value }) => {
    context.setIsEditing(true)
    context.setAcciones(value)
  }

  return (
    <div className='form-field text-area'>
      <label>Acciones inmediatas:</label>
      <TextArea
        placeholder="Acciones inmediatas"
        value={ context.acciones }
        onChange={ actionsHandler }
        maxLength="5000"
      />
    </div>
  )
}

export { ActionsInput }
