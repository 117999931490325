import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const WrongDriverField = () => {
  const context = useIncidencesLogContext()

  const getMessage = () => (
    context.selectedIncidence?.noDeseado ? 'X' : ''
  )

  return (
    <div className="field check">
      <label>¿Operador no deseado?:</label>
      <label>{ getMessage() }</label>
    </div>
  )
}

export { WrongDriverField }
