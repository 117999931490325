import React, { createContext, useContext, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { showErrorAlert } from '../helpers/notifications'
import { CustomDimmer } from '../components/CustomDimmer'
import { getInactivityData } from '../services/inactivityReport'
import { dateToYYYYMMDD } from '../helpers/date'

const InactivityReportContext = createContext()

const InactivityReportProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isLoading,            setIsLoading      ] = useState(false)
  const [carrierId,            setCarrierId      ] = useState(null)
  const [[startDate, endDate], setDateRange      ] = useState([null, null])
  const [periodDays,           setPeriodDays     ] = useState(0)
  const [inactivityData,       setInactivityData ] = useState([])
  const excelEndpoint =
    `/inactivityPercentage/downloadExcel?shipmentCompanyId=${carrierId}
    &beginDate=${(dateToYYYYMMDD(startDate))}
    &endDate=${(dateToYYYYMMDD(endDate))}`.trim().replace(/\s+/g,"")

  const prepareParams = () => (
    {
      shipmentCompanyId: carrierId,
      beginDate: dateToYYYYMMDD(startDate),
      endDate: dateToYYYYMMDD(endDate)
    }
  )

  const hasError = response => response.errorCode || response.status

  const handleResponse = data => {
    if (hasError(data))
      return showErrorAlert('Hubo un error, favor de intentar de nuevo')
    setInactivityData(data)
  }

  const getPeriodDays = () => {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    setPeriodDays(differenceInDays)
  }

  const search = async () => {
    if (!startDate || !endDate)
      return showErrorAlert('Debes seleccionar un rango de fechas')
    setIsLoading(true)
    getPeriodDays()
    getInactivityData(prepareParams(), credentials.token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const context = {
    isLoading,
    carrierId,
    startDate,
    endDate,
    periodDays,
    inactivityData,
    excelEndpoint,
    setCarrierId,
    setDateRange,
    search,
    setInactivityData
  }

  return (
    <AuthProvider>
      <InactivityReportContext.Provider value={ context }>
      <CustomDimmer visible={ isLoading }/>
        { children }
      </InactivityReportContext.Provider>
    </AuthProvider>
  )
}

const useInactivityReportContext = () => useContext(InactivityReportContext)

export { InactivityReportProvider, useInactivityReportContext }
