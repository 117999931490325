import React from 'react'
import ReactDatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { Button } from 'semantic-ui-react'
import { PlantsDropdown } from '../PlantsDropdown'
import { ShipmentCompaniesDropdown } from '../ShipmentCompaniesDropdown'
import { useTravelsLogContext } from '../../contexts/TravelsLogContext'
import { ExcelButton } from '../ExcelButton'

const SearchBar = () => {
  const context = useTravelsLogContext()

  const handleDatePickerChange = value => context.setDateRange(value)

  const includeStartDate = date => date ? `&startDate=${date}` : ''
  const includeEndDate = date => date ? `&endDate=${date}` : ''

  const getUrl = () => {
    const plantId = `plantId=${context.plantId}`
    const startDate = includeStartDate(context.startDate)
    const endDate = includeEndDate(context.endDate)
    const companyId = `&shipmentCompanyId=${context.shipmentCompanyId}`
    return `/travelsLog/download?${plantId}${companyId}${startDate}${endDate}`
  }

  return (
    <div className="search-bar">
      <div className="form-field">
        <label>Planta:</label>
        <PlantsDropdown
          value={ context.plantId }
          setSelectedPlant={ context.setPlantId }
        />
      </div>
      <div className="form-field">
        <label>Transportista:</label>
        <ShipmentCompaniesDropdown
          value={ context.shipmentCompanyId }
          setSelectedCompany={ context.setShipmentCompanyId }
        />
      </div>
      <div className="form-field date">
        <label>Fecha:</label>
        <ReactDatePicker
          selectsRange
          isClearable
          locale={ es }
          startDate={ context.startDate }
          endDate={ context.endDate }
          onChange={ handleDatePickerChange }
        />
      </div>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
      <ExcelButton
        endpoint={ getUrl() }
        fileName='reporte_de_viajes'
      />
    </div>
  )
}

export { SearchBar }
