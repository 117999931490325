import React from 'react'
import { createInputValueHandler } from '../../../helpers/input'
import { Input } from 'semantic-ui-react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const ArrivalDateInput = () => {
  const context = useStayClaimContext()
  const inputHandler = createInputValueHandler(context.setArrivalDate)

  return (
    <div className='item'>
      <label>Fecha de llegada:</label>
      <div className='time'>
        <Input
          type='datetime-local'
          value={ context.arrivalDate }
          onChange={ inputHandler }
        />
      </div>
    </div>
  )
}

export { ArrivalDateInput }
