import React from 'react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { Button } from 'semantic-ui-react'

const Actions = () => {
  const context = usePlantsContext()

  const getText = () => `Guardar ${!context.isCreating ? 'cambios' : ''}`

  return (
    <div className='actions'>
      {
        !context.isCreating ? (
          <>
            <Button
              color='grey'
              loading={ context.isWorking }
              onClick={ context.clearData }
            >
              Cancelar cambios
            </Button>
            <Button
              color='red'
              loading={ context.isWorking }
              onClick={ context.deletePlant }
            >
              Eliminar planta
            </Button>
          </>
        ) : (<></>)
      }
      <Button
        primary
        loading={ context.isWorking }
        onClick={ context.save }
      >
        { getText() }
      </Button>
    </div>
  )
}

export { Actions }
