import React, { useState } from 'react'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'
import { ExcelButton } from '../ExcelButton'

const headers = [
  { path: 'nombre',              title: 'Nombre Completo' },
  { path: 'transportista.alias', title: 'Transportista' },
  { path: 'economico',           title: 'Económico' },
  { path: 'navixyTrackerLabel',  title: 'Navixy tracker label' }
]

const searchBy = ['nombre','transportista.alias','economico', 'navixyTrackerLabel']
const headersSeachBy = ['Nombre Completo', 'Transportista','Económico', 'Navixy tracker label']

const DriversTable = ({
  drivers=[],
  setDrivers=()=>{},
  clearFilterText='',
  onClickRow=()=>{},
  token=''
}) => {

  const [sortOrder, setSortOrder] = useState(false)

  const handleFilterData = (data, e) => {
    setDrivers(filterdata(data, e, sortOrder))
    setSortOrder(!sortOrder)
  }

  return (
    <div className="table-container">
      <div className='drivers-excel-button'>
        <ExcelButton
          endpoint='/operador/download_excel'
          fileName='Operadores'
        />
      </div>
      <div className='table'>
        <StyledTable
          headers={ headers }
          searchBy={ searchBy }
          headerSearchBy={ headersSeachBy }
          data={ drivers }
          handleOnClickRow={ onClickRow }
          handleOnClickHeader={ e => handleFilterData(drivers, e) }
          clearFilter={ clearFilterText }
        />
      </div>
    </div>
  )
}

export { DriversTable }
