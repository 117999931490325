import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const LegalNameDropdown = ({ legalName = null, setLegalName = () => {} }) => {
  const context = useOrderContext()

  useEffect(() => {
    if (context.caso !== 2 || !context.detalles.length) return
    const legalName = context.legalNames
      .find(d => d.value === context.detalles[0].razonSocial)
      ?.value
    setLegalName(legalName)
  }, [context.caso, context.detalles, context.legalNames, setLegalName])

  const legalNameSelectHandler = createInputValueHandler(setLegalName)

  const isUnchoosableLegalName = () => {
    if (context.caso !== 2) return false
    return context.detalles.length > 0
  }

  return (
    <div className="form-field">
      <label>Razon social:</label>
      <Dropdown
        placeholder="Razon social"
        fluid
        selection
        search
        options={ context.legalNames }
        value={ legalName }
        disabled={ isUnchoosableLegalName() }
        onChange={ legalNameSelectHandler }
      />
    </div>
  )
}

export { LegalNameDropdown }
