import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Dropdown } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const MaintenanceType = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createInputValueHandler(context.setMaintenanceId)

  return (
    <div className="dropdown-form-field">
      <label>Tipo de mantenimiento:</label>
      <Dropdown
        search
        selection
        placeholder='Selecciona un tipo de manto'
        value={ context.maintenanceId }
        options={ context.maintenanceType }
        onChange={ handleInput }
        className='section-dropdown'
      />
    </div>
  )
}

export { MaintenanceType }
