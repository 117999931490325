import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotAvailableNote = () => {
  const context = useChangeUnitsContext()

  const notAvailableNote = createInputValueHandler(context.setNotAvailableNote)

  return (
    <>
      {
        !context.isNotAvailable() ?
        (
          <div className='note-form'>
            <label className='change-status-label-note'>Notas:</label>
            <TextArea
              className='notes-input-box'
              value={ context.notAvailableNote }
              onChange={ notAvailableNote }
            />
          </div>
        ) : (<></>)
      }
    </>
  )
}

export { NotAvailableNote }
