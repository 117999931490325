import React from 'react'
import { Table } from './form/section_two/Table'
import { ExpirationCheckbox } from './form/section_two/ExpirationCheckbox'

const SectionTwo = () => {
  return (
    <div className='section-two'>
      <div className='table-header'>
        <h6>Cotización de fletes autorizados</h6>
        <ExpirationCheckbox />
      </div>
      <Table />
    </div>
  )
}

export { SectionTwo }
