import React from 'react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLogContext'

const StaysDays = () => {
  const context = useAuthorizedStaysLogContext()

  return (
    <div className="form-field">
      <label>Estadía calculada:</label>
      <label>{ context.info?.estadiaCalculada } días</label>
    </div>
  )
}

export { StaysDays }
