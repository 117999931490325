const split = value => {
  if (value.length <= 3) return value
  const decimals = value.toString().split('.')[1]
  return value
    .toString()
    .split('.')[0]
    .split('')
    .reverse()
    .join('')
    .replace(/.{3}/g, "$& ")
    .split('')
    .reverse()
    .join('') +
    (decimals ? `.${decimals}` : '')
}

const toCurrency = value => `$${split(value.toString())}`

export { toCurrency }
