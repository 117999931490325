import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'
import { createInputValueHandler } from '../../../../helpers/input'

const QuantityLostInput = () => {
  const context = useOrdersInTransitIncidencesContext()

  const quantityHandler = createInputValueHandler(context.setToneladasFaltantes)
  return (
    <div className='form-field'>
      <label>Toneladas faltantes:</label>
      <Input
        value={ context.toneladasFaltantes }
        type="number"
        step={ 0.01 }
        min={ 0.0 }
        onChange={ quantityHandler }
      />
    </div>
  )
}

export { QuantityLostInput }
