import React from 'react'
import { Header, Label } from 'semantic-ui-react'

const SideHeaders = () => {

  return (
    <div className='side-headers'>
      <Header>Turno</Header>
      <Label>Matutino</Label>
      <Label>Vespertino</Label>
      <Label>Nocturno</Label>
    </div>
  )
}

export { SideHeaders }
