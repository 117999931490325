import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const CapacityInput = ({ capacity = '', setCapacity = () => {} }) => {
  const capacityHandler = createInputValueHandler(setCapacity)

  return (
    <div className="client-unit-modal-field">
      <label>Capacidad:</label>
      <Input
        type="number"
        step={0.1}
        inputMode="decimal"
        min={0.0}
        value={ capacity }
        onChange={ capacityHandler }
        placeholder="Capacidad"
      />
    </div>
  )
}

export { CapacityInput }
