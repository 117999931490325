import React from 'react'
import { Input } from 'semantic-ui-react'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotAvailableDate = () => {
  const context = useChangeUnitsContext()
  
  const notAvailableDate = createInputValueHandler(context.setNotAvailableDate)

  return (
    <>
      {
        !context.isNotAvailable() ?
        (
          <div>
            <label className='change-status-label'>Fecha no disponible:</label>
            <Input
              type='datetime-local'
              value={ context.notAvailableDate}
              onChange={ notAvailableDate }
            />
          </div>
        ) : (<></>)
      }
    </>
  )
}

export { NotAvailableDate }
