import React from 'react'
import { Radio } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const OrderStatusSwitch = () => {
  const context = useOrderContext()

  const canChoose = () => (
    !context.isEditing || (context.isEditing && context.pendienteLiberar)
  )

  return (
    <div className="form-field">
      <label>Estatus OV:</label>
      <div className='radio-group'>
        <Radio
          label='No liberado'
          name='status'
          disabled={ !canChoose() }
          checked={ context.pendienteLiberar }
          onChange={ () => context.setPendienteLiberar(true) }
        />
        <Radio
          label='Liberado'
          name='status'
          disabled={ !canChoose() }
          checked={ !context.pendienteLiberar }
          onChange={ () => context.setPendienteLiberar(false) }
        />
      </div>
    </div>
  )
}

export { OrderStatusSwitch }
