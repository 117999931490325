import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const DestinationField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field textarea">
      <label>Destino:</label>
      <label>{ context.selectedIncidence?.destino || 'No especificado' }</label>
    </div>
  )
}

export { DestinationField }
