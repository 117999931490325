import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const PlateTankInput = ({ plateTank = '', setPlateTank = () => {} }) => {
  const plateTankHandler = createInputValueHandler(setPlateTank)

  return (
    <div className="client-unit-modal-field">
      <label>Placas TQ1:</label>
      <Input
        value={ plateTank }
        onChange={ plateTankHandler }
        placeholder="Placas TQ1"
      />
    </div>
  )
}

export { PlateTankInput }
