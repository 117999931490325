import resources from '../resources'

const isSuperAdmin    = user => user?.perfil?.id === 3
const isAdmin         = user => user?.perfil?.id === 1
const isRegularUser   = user => user?.perfil?.id === 2
const hasCentralLevel = user => user?.nivel?.id  === 1

const hasAccessToScreen = (credentials, location) => {
  if (!credentials) return false
  if (location === '/') return true
  if (location === '/inicio') return true

  const arrayWithActionsOfRegistros = []
  const availableQueriesScreens = []
  const user = credentials.user
  const actions = credentials.actions

  if (isRegularUser(user)) {
    if (actions) {
      actions.forEach(a => {
        if(a.id === 5)
          availableQueriesScreens.push(resources.commonQueries[0])
        else if(a.id === 11)
          availableQueriesScreens.push(resources.commonQueries[1])
      })
      actions.forEach(a => {
        if (a.id === 2)
          arrayWithActionsOfRegistros.push(resources.actionTwo[0])
        if (a.id === 3)
          arrayWithActionsOfRegistros.push(resources.actionTree[0])
        if (a.id === 1)
          arrayWithActionsOfRegistros.push(resources.actionOne[0])
        if (a.id === 10)
          arrayWithActionsOfRegistros.push(resources.actionFour[0])
        if (a.id === 13)
          arrayWithActionsOfRegistros.push(resources.actionFive[0])
      })
    }
  }
  const optionsAvailable = [
    ...(isSuperAdmin(user) ? resources.superAdminActions : []),
    ...(isSuperAdmin(user) || (hasCentralLevel(user) && isAdmin(user)) ? resources.actionsForPlantAdim : []),
    ...(!isRegularUser(user) ? resources.commonActions : []),
    ...(!isRegularUser(user) ? resources.commonQueries : []),
    ...(isRegularUser(user) ? arrayWithActionsOfRegistros : []),
    ...(isRegularUser(user) ? user?.perfil?.id ? availableQueriesScreens : resources.commonQueries : []),
  ]
  return optionsAvailable.find(r => r.route === location)
}

export default hasAccessToScreen
