import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotesInput = () => {
  const context = usePlantsContext()
  const notesHandler = createInputValueHandler(context.setNotes)

  return (
    <div className='field'>
      <label>Notas:</label>
      <div>
        <TextArea
          value={ context.notes }
          onChange={ notesHandler }
        />
      </div>
    </div>
  )
}

export { NotesInput }
