import React from 'react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { PlantsDropdown } from '../../PlantsDropdown'

const PlantFinishDropdown = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Planta termino viaje:</label>
      <PlantsDropdown
        value={ context.plantaTerminoViaje }
        setSelectedPlant={ context.setPlantaTerminoViaje }
      />
    </div>
  )
}

export { PlantFinishDropdown }
