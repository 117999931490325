import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const CleanButton = () => {
  const context = useOrderContext()

  return (
    <>
    {
      context.isEditing ?
      (
        <Button
          color="grey"
          loading={ context.isWorking }
          onClick={ context.clean }
          disabled={ context.isWorking }
        >
          Cancelar cambios
        </Button>
      ) : (<></>)
    }
    </>
  )
}

export { CleanButton }
