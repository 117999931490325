import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const UnasignOrderButton = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const isUnasignable = () => (
    contextModal.canFinishOrder() &&
    contextModal.isConfirmed() &&
    !contextModal.isDeleted()
  )

  const onClick = () => contextModal.setShowModalToUnassignDriver(true)

  if (!isUnasignable()) return <></>
  return (
    <Button
      color="red"
      onClick={ onClick }
    >
      Desasignar operador
    </Button>
  )
}

export { UnasignOrderButton }
