import axios from 'axios'
import resources from '../resources'

const buildUrl = (id = '') => (`${resources.urls.ergonApi}/tanks/${id}`)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getMeasureUnits = async token => {
  const { data } = await axios.get(buildUrl('measureUnits'), buildHeaders(token))
  return data
}

const listTanks = async token => {
  const { data } = await axios.get(buildUrl(), buildHeaders(token))
  return data
}

const saveTank = async (params, token) => {
  const { data } = await axios.post(buildUrl(), params, buildHeaders(token))
  return data
}

const getTankData = async (id, token) => {
  const { data } = await axios.get(buildUrl(id), buildHeaders(token))
  return data
}

const updateTank = async (id, params, token) => {
  const { data } = await axios.put(buildUrl(id), params, buildHeaders(token))
  return data
}

const deleteTank = async (id, token) => {
  const { data } = await axios.delete(buildUrl(id), buildHeaders(token))
  return data
}

export {
  getMeasureUnits,
  listTanks,
  saveTank,
  getTankData,
  updateTank,
  deleteTank
}
