import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'
import resources from '../../../resources'

const chuncks = resources.numbersToFilterByQuantity

const RecordsDropdown = () => {
  const context = useOrderAssignationContext()

  const chunkHandler = createInputValueHandler(context.setSelectedChunk)

  return (
    <div className="form-field">
      <label>Folios:</label>
      <Dropdown
        className="chunks-dropdown"
        options={ chuncks }
        placeholder="Cantidad de resultados"
        value={ context.selectedChunk }
        onChange={ chunkHandler }
        fluid
        search
        selection
        clearable
      />
    </div>
  )
}

export { RecordsDropdown }
