import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const DestroyButton = () => {
  const context = useOrderContext()

  const destroy = () => {
    context.setShowConfirmDestroy(true)
  }

  const unasign = () => {
    context.setShowConfirmUnassign(true)
  }

  const isSuperAdmin = () => (
    context.credentials.user.perfil.nombre === 'superAdmin'
  )

  const canEdit = () => {
    if (context.isWorking) return false
    if (context.unidadCliente) return true
    if (isSuperAdmin()) return true
    return !context.isEditing || (context.isEditing && !context.asignado)
  }

  const hasUnassingPermissions = () => {
    const credentials = JSON.parse(sessionStorage.getItem('credentials'))
    return !!credentials?.actions?.find(a => a.id === 9)
  }

  const canDestroy = () => (
    context.isEditing      &&
    !context.isUnasignable &&
    isSuperAdmin()
  )

  const canUnasign = () => (
    context.isEditing
      && context.isUnasignable
      && (isSuperAdmin() || hasUnassingPermissions())
  )

  return (
    <>
      {
        canUnasign() ?
        (
          <Button
            color="red"
            loading={ context.isWorking }
            onClick={ unasign }
          >
            Desasignar operador
          </Button>
        ) : (<></>)
      }
      {
        canDestroy() ?
        (
          <Button
            color="red"
            loading={ context.isWorking }
            onClick={ destroy }
            disabled={ !canEdit() }
          >
            Eliminar
          </Button>
        ) : (<></>)
      }
    </>
  )
}

export { DestroyButton }
