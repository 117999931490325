import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const State = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setStateId)

  return (
    <div className='form-field'>
      <label>Estado:</label>
      <Dropdown
        search
        selection
        value={ context.stateId }
        options={ context.stateData }
        onChange={ InputHandler }
        placeholder='Seleccione un estado...'
      />
    </div>
  )
}

export { State }
