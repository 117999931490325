import React from 'react'
import {
  useAssignmentParticipationContext
} from '../../../contexts/AssignmentParticipationContext'

const Assignations = () => {
  const context = useAssignmentParticipationContext()

  return (
    <div className='container'>
      <label className='title'>Asignaciones: </label>
      <label>{ context.assignations }</label>
    </div>
  )
}

export { Assignations }
