import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const CompromiseDateField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Fecha compromiso:</label>
      <label>{ context.selectedIncidence?.fechaCompromiso }</label>
    </div>
  )
}

export { CompromiseDateField }
