import React from 'react'
import { Form, Icon } from 'semantic-ui-react'
import { PlantsDropdown } from '../PlantsDropdown'
import es from 'date-fns/locale/es'
import DatePicker from 'react-datepicker'
import { Actions } from './Actions'
import { ShipmentCompaniesDropdown } from '../ShipmentCompaniesDropdown'
import {
  useIncidencesReportContext
} from '../../contexts/IncidencesReportContext'

const SearchBar = () => {
  const context = useIncidencesReportContext()

  const handleDate = value => {
    context.setTo(value[1])
    context.setFrom(value[0])
  }

  return (
    <Form size='mini' className='search-bar'>
      <div> <Icon name='filter'/>Filtrar por: </div>
      <div className='content'>
        <div className='inputs'>
          <div className='plants'>
            <label>Planta:</label>
            <PlantsDropdown
              defaultOption
              setSelectedPlant={ context.setPlantId }
            />
          </div>
          <div className='shipment-companies'>
            <label>Transportista:</label>
            <ShipmentCompaniesDropdown
              defaultOption
              value={ context.shipmentCompanyId }
              setSelectedCompany={ context.setShipmentCompanyId }
            />
          </div>
          <div className='date'>
            <label>Fecha:</label>
            <DatePicker
              selectsRange
              isClearable
              locale={ es }
              startDate={ context.from }
              endDate={ context.to }
              onChange={ handleDate }
            />
          </div>
        </div>
        <Actions />
      </div>
    </Form>
  )
}

export { SearchBar }
