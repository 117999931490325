import React from 'react'

const FreightByTon = ({ order }) => {
  return (
    <div className='single-row'>
      <label>Costo por tonelada:</label>
      <label>{ order?.fleteCostoTonelada || 'Sin asignar' }</label>
    </div>
  )
}

export { FreightByTon }
