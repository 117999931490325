import React from 'react'
import { Form } from 'semantic-ui-react'

import { DescriptionMotive } from './form/DescriptionMotive'
import { RequireEvidence } from './form/RequireEvidence'
import { InMaintenance } from './form/InMaintenance'
import { InCleaning } from './form/InCleaning'
import { Status } from './form/Status'
import { MotivesFormActions } from './form/MotivesFormActions'

const MotivesForm = () => {
  return (
    <Form>
      <DescriptionMotive/>
      <RequireEvidence/>
      <InMaintenance/>
      <InCleaning/>
      <Status/>
      <MotivesFormActions/>
    </Form>
  )
}

export { MotivesForm }
