import React from 'react'
import { Carrier } from './search_bar/Carrier'
import { Dates } from './search_bar/Dates'
import { SearchButton } from './search_bar/actions/SearchButton'
import { PeriodDays } from './search_bar/PeriodDays'

const SearchBar = () => {

  return (
    <div className='search-bar'>
      <Carrier />
      <Dates />
      <SearchButton />
      <PeriodDays />
    </div>
  )
}

export { SearchBar }
