import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const ClientInput = () => {
  const context = useOrderContext()

  const getClient = () => (
    context.clients.find(c => c.value === context.clienteId)?.text
  )

  return (
    <div className="client-unit-modal-field">
      <label>Cliente:</label>
      <Input
        disabled
        value={ getClient() }
        placeholder="Cliente"
      />
    </div>
  )
}

export { ClientInput }
