import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const OrderDateLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.fechaRegistro

  return (
    <div>
      <div>Fecha registro:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { OrderDateLabel }
