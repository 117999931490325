import React from 'react'
import { ImageContainer } from '../../../ImageContainer'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createFileInputHandler } from '../../../../helpers/input'

const TankPhoto = () => {
  const context = useTanksContext()

  const handleInput = createFileInputHandler(context.setTankPhoto)

  return (
    <div className="tank-photo-container">
      <ImageContainer
        source={ context.tankPhoto }
        onChangeImage={ handleInput }
        isLoading={ context.isLoadingImage }
      />
    </div>
  )
}

export { TankPhoto }
