import axios from 'axios'
import { showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const URL = `${resources.urls.ergonApi}/parkedUnits/`

const buildHeaders = token => ({
  headers: { Authorization: token }
})

const getStatuses = async token => {
  const url = `${URL}statuses`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return []
  }
}

const search = async (token, plantId, statusId) => {
  const url = `${URL}?plantId=${plantId}&statusId=${statusId}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return []
  }
}

const getChecklists = async (token, unitId) => {
  const url = `${URL}getChecklists?unitId=${unitId}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return []
  }
}

const getById = async (token, logId, plantId) => {
  const url = `${URL}getUnitById?parkedUnitId=${logId}&plantId=${plantId}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return []
  }
}

const save = async (token, params) => {
  try {
    const response = await axios.post(URL, params, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return null
  }
}

const sendChecklistTask = async (token, unitId) => {
  const url = `${URL}sendChecklistTask/${unitId}`
  try {
    const response = await axios.post(url, null, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error)
    return null
  }
}

export default {
  getStatuses,
  search,
  getChecklists,
  getById,
  save,
  sendChecklistTask
}
