import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../reducers'

const initialState = {}
const middleware = [thunk]

const composeCreator = () => {
  if (window.__REDUX_DEVTOOLS_EXTENSION__)
    return window.__REDUX_DEVTOOLS_EXTENSION__()
  return compose
}

const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middleware), composeCreator())
)

export default store
