import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'
import { hoursFormat } from '../../../../helpers/date'

const DestinationTime = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    hoursFormat(context.stayInfo.tiempoDestino || 0)
  )

  return (
    <div className="form-field">
      <label>Estadía en destino:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { DestinationTime }
