import React from 'react'
import { Form } from 'semantic-ui-react'
import { ChangeStatusActions } from './ChangeStatusAction'
import { EstimatedAvailability } from '../../change_units_status_form/EstimateAvailability'
import { NotAvailabilityTypes } from '../../change_units_status_form/NotAvailabilityTypes'
import { NotAvailableNote } from '../../change_units_status_form/NotAvailableNote'
import { NotAvailableDate } from '../../change_units_status_form/NotAvailableDate'

const ChangeStatusForm = () => {

  return (
    <Form
      size='mini'
      className='change-status-form'
    >
      <NotAvailableDate/>
      <NotAvailabilityTypes/>
      <EstimatedAvailability/>
      <NotAvailableNote/>
      <ChangeStatusActions />
    </Form>
  )
}

export { ChangeStatusForm }
