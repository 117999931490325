import React from 'react'
import { PDFButton } from './actions/PDFButton'

const Evidence = () => {
  return (
    <div className='item'>
      <label>Evidencia:</label>
      <div className='pdf-button'>
        <PDFButton />
      </div>
    </div>
  )
}

export { Evidence }
