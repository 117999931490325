import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { filterdata } from '../../utils/common'
import {
  useIncidencesReportContext
} from '../../contexts/IncidencesReportContext'

const headers = [
  { path: 'plantaAlias',        title: 'Planta'        },
  { path: 'transportistaAlias', title: 'Transportista' },
  { path: 'economico',          title: 'Económico'     },
  { path: 'fechaIncidencia',    title: 'Fecha'         },
  { path: 'tipoDescripcion',    title: 'Tipo'          },
  { path: 'notas',              title: 'Notas'         }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useIncidencesReportContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleFilterData = e => {
    context.setIncidences(filterdata(context.incidences, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.incidences }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleFilterData }
      />
    </div>
  )
}

export { Table }
