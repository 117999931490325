import React from 'react'
import { Progress } from 'semantic-ui-react'

const ProgressBar = ({ progress = 0, hasMultiplePages = false }) => {
  return (
    hasMultiplePages ?
      <div className="container-progress-bar-otm">
        <Progress percent={ progress } />
      </div>
    :
      <></>
  )
}

export { ProgressBar }
