import Swal from "sweetalert2"
import { Toast } from "../utils/common"

const showOkAlert = message => Toast.fire({ title: message })
const showErrorAlert = message => (
  Swal.fire({
    icon: 'error',
    showConfirmButton: false,
    text: message,
    showDenyButton: true,
    denyButtonText: 'Ok'
  })
)

const hasError = response => (
  response.errorCode || response.error || response.status
)

const responseHandler = response => {
  if (hasError(response))
    return showErrorAlert(response.error || response.message)
  showOkAlert(response.message)
}

export {
  hasError,
  showOkAlert,
  showErrorAlert,
  responseHandler
}
