import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'
import { FreightName } from './FreightName'
import { FreightByTon } from './FreightByTon'
import { FreightCostTolls } from './FreightCostTolls'
import { OrderIdLabel } from './order_info/OrderIdLabel'
import { ClientLabel } from './order_info/ClientLabel'
import { PlantLabel } from './order_info/PlantLabel'
import { OrderTypeLabel } from './order_info/OrderTypeLabel'
import { OrderDateLabel } from './order_info/OrderDateLabel'
import { RequestedDateLabel } from './order_info/RequestedDateLabel'
import { ShipmentDateLabel } from './order_info/ShipmentDateLabel'
import { RealShipmentDateLabel } from './order_info/RealShipmentDateLabel'
import { DeliverDateLabel } from './order_info/DeliverDateLabel'
import { TravelTimeLabel } from './order_info/TravelTimeLabel'
import { RealDeliverDateInput } from './order_info/RealDeliverDateInput'
import { RefineryExitDateInput } from './order_info/RefineryExitDateInput'
import { LastCheckList } from './order_info/LastCheckList'
import { OrderStateLabel } from './order_info/OrderStateLabel'
import { ShipmentCompanyLabel } from './order_info/ShipmentCompanyLabel'
import { UnitNameLabel } from './order_info/UnitNameLabel'
import { UnitPlateLabel } from './order_info/UnitPlateLabel'
import { TankPlateLabel } from './order_info/TankPlateLabel'
import { SecondTankPlateLabel } from './order_info/SecondTankPlateLabel'
import { CapacityLabel } from './order_info/CapacityLabel'
import { TravelDistanceLabel } from './order_info/TravelDistanceLabel'
import { DriverLabel } from './order_info/DriverLabel'
import { PlantArrivalDateInput } from './order_info/PlantArrivalDateInput'
import { PlantExitDateInput } from './order_info/PlantExitDateInput'
import { FinishNotesLabel } from './order_info/FinishNotesLabel'
import { ConfirmationLink } from './order_info/ConfirmationLink'
import { OrderNotesInput } from './order_info/OrderNotesInput'
import { ShipmentNotesInput } from './order_info/ShpmentNotesInput'
import { FreightNotesInput } from './order_info/FreightNotesInput'
import { PlantNotesInput } from './order_info/PlantNotesInput'

const OrderInfo = () => {
  const { orderDetails } = useOrdersInTransitContext()

  if (!orderDetails.info) return <></>
  return (
    <div className='order-info-tab'>
      <div className='row'>
        <div className='column'>
          <OrderIdLabel/>
          <PlantLabel/>
          <OrderTypeLabel/>
          <ClientLabel/>
          <OrderDateLabel/>
          <RequestedDateLabel/>
          <ShipmentDateLabel/>
          <RealShipmentDateLabel/>
          <DeliverDateLabel/>
          <TravelTimeLabel/>
          <RealDeliverDateInput/>
          <RefineryExitDateInput/>
          <LastCheckList/>
        </div>
        <div className='column'>
          <OrderStateLabel/>
          <ShipmentCompanyLabel/>
          <UnitNameLabel/>
          <UnitPlateLabel/>
          <TankPlateLabel/>
          <SecondTankPlateLabel/>
          <CapacityLabel/>
          <TravelDistanceLabel/>
          <DriverLabel/>
          <FreightName />
          <FreightByTon />
          <FreightCostTolls />
          <PlantArrivalDateInput/>
          <PlantExitDateInput/>
          <FinishNotesLabel/>
          <ConfirmationLink/>
        </div>
      </div>
      <hr/>
      <div className='row'>
        <div className='column'>
          <OrderNotesInput/>
          <ShipmentNotesInput/>
        </div>
        <div className='column'>
          <FreightNotesInput/>
          <PlantNotesInput/>
        </div>
      </div>
    </div>
  )
}

export { OrderInfo }
