import React from 'react'
import { Button } from 'semantic-ui-react'
import { useMotivesContext } from '../../../../contexts/MotivesContext'

const SaveButton = () => {
  const context = useMotivesContext()

  const saveButtonLabel = () => (
    context.isEditing ? 'Guardar cambios' : 'Guardar'
  )

  const save = () => {
    context.isEditing ? context.edit() : context.save()
  }

  return (
    <Button
      primary
      loading={ context.isWorking }
      onClick={ save }
      >
        { saveButtonLabel() }
    </Button>
  )
}

export { SaveButton }
