import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const SaveButton = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const onClick = contextModal.save
  const canSave = () => (
    (contextModal.isOrderWithoutGPSFinished() ||
    contextModal.hasSaveableStatus() ||
    contextModal.isSuperadmin()) &&
    !contextModal.isDeleted()
  )
  const isDisabled = () => !canSave()

  return (
    <Button
      primary
      onClick={ onClick }
      disabled={ isDisabled() }
    >
      Guardar cambios
    </Button>
  )
}

export { SaveButton }
