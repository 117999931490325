import React, { useState } from 'react'
import { ConfiguredPlants } from './ConfiguredPlants'
import { StyledTable } from '../StyledTable'
import { usePlantsContext } from '../../contexts/PlantsContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'plantaAlias',    title: 'Alias'              },
  { path: 'geocercaNombre', title: 'Geocerca planta'    },
  { path: 'cuentaPatios',   title: '# de Patio/Pensión' },
  { path: 'nombreContacto', title: 'Nombre contacto'    }
]

const toSearch = headers.map(h => h.path)

const Table = () => {
  const context = usePlantsContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleFilterData = e => {
    context.setPlants(filterdata(context.plants, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const onSelectPlant = plant => context.onSelectPlant(plant.id)

  return (
    <div className='table-container'>
      <ConfiguredPlants />
      <div className='table'>
        <StyledTable
          search
          data={ context.plants }
          headers={ headers }
          searchBy={ toSearch }
          handleOnClickRow={ onSelectPlant }
          handleOnClickHeader={ handleFilterData }
        />
      </div>
    </div>
  )
}

export { Table }
