import React from 'react'
import { PlantsDropdown } from '../../PlantsDropdown'
import { Dropdown } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'
import {
  useFreightsConsultContext
} from '../../../contexts/FreightsConsultContext'

const ClientDropdown = () => {
  const context = useFreightsConsultContext()

  const clientLabel = () => context.isInternal ? 'Planta:' : 'Cliente:'

  const InputHandler = createInputValueHandler(context.setClientIdFilter)

  return (
    <div className='client-container'>
      <label className='title'>{ clientLabel() }</label>
      {
        context.isInternal ?
          <PlantsDropdown
            clearable
            value={ context.clientIdFilter }
            setSelectedPlant={ context.setClientIdFilter }
            className='value'
          />
        :
          <Dropdown
            search
            selection
            clearable
            value={ context.clientIdFilter }
            options={ context.clientsDataFilter }
            onChange={ InputHandler }
            className='value'
            placeholder='Seleccione un cliente...'
          />
      }
    </div>
  )
}

export { ClientDropdown }
