import React from 'react'
import { Input } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const PhoneInput = () => {
  const context = usePlantsContext()
  const phoneHandler = createInputValueHandler(context.setPhone)

  return (
    <div className='field'>
      <label>Teléfono:</label>
      <Input
        value={ context.phone }
        onChange={ phoneHandler }
      />
    </div>
  )
}

export { PhoneInput }
