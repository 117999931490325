import React from 'react'
import { CleanButton } from './actions/CleanButton'
import { DeleteButton } from './actions/DeleteButton'
import { SaveButton } from './actions/SaveButton'

const FormActions = () => {
  return (
    <div className='form-actions'>
      <CleanButton />
      <DeleteButton />
      <SaveButton />
    </div>
  )
}

export { FormActions }
