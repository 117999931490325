import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'
import { createInputValueHandler } from '../../../../helpers/input'

const OrderDetailsDropdown = () => {
  const context = useOrdersInTransitStayContext()

  const claimHandler = createInputValueHandler(context.setSelectedOrderDetail)

  const canChange = () => context.orderDetails.length > 1

  return (
    <div className="form-field">
      <label>Reclamo de estadía:</label>
      <Dropdown
        disabled={ !canChange() }
        placeholder="Seleccione un reclamo de estadía"
        options={ context.orderDetails }
        value={ context.selectedOrderDetail }
        fluid
        search
        selection
        onChange={ claimHandler }
      />
    </div>
  )
}

export { OrderDetailsDropdown }
