import React from 'react'
import { Button } from 'semantic-ui-react'

const UnasignOrderButton = ({ order = {}, onClick = () => {} }) => {

  const hasPendingState = () => (
    ['Asignado en Espera', 'Por confirmar'].includes(order.estado)
  )

  return (
    hasPendingState() ?
      <Button color="red" size="mini" onClick={ onClick }>
        Desasignar pedido
      </Button> : <></>
  )
}

export { UnasignOrderButton }
