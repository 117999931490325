import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useAuthContext } from '../contexts/authContext'
import { getGeneralInfo } from '../services/ergonApi'

const ShipmentCompaniesDropdown = ({
  value = '',
  defaultOption = false,
  setSelectedCompany = () => {},
  clareable,
  disabled
}) => {
  const { credentials } = useAuthContext()
  const [shipmentCompanies, setShipmentCompanies] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (credentials) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const assignCompany = company => {
    setIsDisabled(true)
    setSelectedCompany(company.key)
  }

  const assignOption = companies => {
    const withOption = [{ key: 0, text: 'Todas', value: 0 }, ...companies]
    setShipmentCompanies(withOption)
  }

  const handleResponse = data => {
    if (data.length === 1) return assignCompany(data[0])
    if (defaultOption) return assignOption(data)
    setShipmentCompanies(data)
  }

  const load = () => {
    getGeneralInfo(credentials.token, '/shipmentCompaniesByUser')
      .then(handleResponse)
      .catch(error => console.error('LOAD SHIPMENT COMPANIES : ', error))
  }

  const onChangeCompany = (_, { value }) => setSelectedCompany(value)

  const getValue = () => value || 0

  return (
    <Dropdown
      fluid
      search
      selection
      value={ getValue() }
      options={ shipmentCompanies }
      clearable={ clareable }
      disabled={ disabled || isDisabled }
      onChange={ onChangeCompany }
      placeholder='Seleccione transportista'
      id='c-shipment-companies-dropdown'
    />
  )
}

export { ShipmentCompaniesDropdown }
