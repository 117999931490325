import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const TicketInput = ({ ticket = '', setTicket = () => {} }) => {
  const ticketHandler = createInputValueHandler(setTicket)

  return (
    <div className="form-field">
      <label>Remisión:</label>
      <Input
        placeholder="Remisión"
        value={ ticket }
        onChange={ ticketHandler }
      />
    </div>
  )
}

export { TicketInput }
