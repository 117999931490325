import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const QuantityInput = ({
  quantity = 1,
  setQuantity = () => {},
  measure = 'ton',
  setMeasure = () => {}
}) => {
  const context = useOrderContext()

  const quantityHandler      = createInputValueHandler(setQuantity)
  const measureSelectHandler = createInputValueHandler(setMeasure)

  return (
    <div className="form-field">
      <label>Cantidad:</label>
      <div className="quantity-field">
        <Input
          placeholder="Cantidad"
          value={ quantity }
          onChange={ quantityHandler }
        />
        <Dropdown
          placeholder="Unidad medida"
          fluid
          selection
          search
          options={ context.measureUnits }
          value={ measure }
          onChange={ measureSelectHandler }
        />
      </div>
    </div>
  )
}

export { QuantityInput }
