import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const RealQuantityInput = ({ realQuantity = '', setRealQuantity = () => {} }) => {
  const realQuantityHandler = createInputValueHandler(setRealQuantity)

  return (
    <div className="form-field">
      <label>Cantidad real cargada:</label>
      <Input
        placeholder="Cantidad real cargada"
        value={ realQuantity }
        onChange={ realQuantityHandler }
      />
    </div>
  )
}

export { RealQuantityInput }
