import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const CleanButton = () => {
  const context = useAuthorizedFreightsContext()

  return (
    <div>
      {
        context.isEditing ?
        (
          <Button
            color='grey'
            disabled={ context.isWorking }
            loading={ context.isWorking }
            onClick={ context.clean }
          >
            Cancelar cambios
          </Button>
        ) : (<></>)
      }
    </div>
  )
}

export { CleanButton }
