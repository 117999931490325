import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { StyledTable } from '../../StyledTable'

const headers = [
  { path: 'cantidad',     title: 'Cantidad'     },
  { path: 'unidadMedida', title: 'U. M'         },
  { path: 'producto',     title: 'Producto'     },
  { path: 'domicilio',    title: 'Domicilio'    },
  { path: 'razonSocial',  title: 'Razón Social' },
  { path: 'remision',     title: 'Remisión'     }
]

const ProductsTableModal = () => {
  const context = useOrderAssignationContext()

  const cancelHandler = () => context.setShowProductsModal(false)

  return (
    <Modal
      className="screen-order-assignation products-table-modal"
      show={ context.showProductsModal }
    >
      <Modal.Header>
        <Modal.Title>
          Detalle del pedido
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <StyledTable
        headers={ headers }
        data={ context.products }
        search={ false }
      />
      </Modal.Body>
      <Modal.Footer>
      <Button
        primary
        onClick={ cancelHandler }
      >
        Cerrar
      </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ProductsTableModal }
