import React from  'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const PlateInput = ({ plate = '', setPlate = () => {} }) => {
  const plateHandler = createInputValueHandler(setPlate)

  return (
    <div className="client-unit-modal-field">
      <label>Placas unidad:</label>
      <Input
        value={ plate }
        onChange={ plateHandler }
        placeholder="Placas unidad"
      />
    </div>
  )
}

export { PlateInput }
