import React from 'react'
import {
  AssignmentParticipationProvider
} from '../contexts/AssignmentParticipationContext'
import { SearchBar } from '../components/assignment_participation/SearchBar'
import {
  XLSDownloadButton
} from '../components/assignment_participation/actions/XLSDownloadButton'
import { Icon } from 'semantic-ui-react'
import { Table } from '../components/assignment_participation/Table'
import { Indicators } from '../components/assignment_participation/Indicators'

const AssignmentParticipation = () => {

  return (
    <AssignmentParticipationProvider>
      <div className='screen-assignment-participation'>
        <h1>Reporte de participación en asignaciones</h1>
        <XLSDownloadButton />
        <div className='content'>
          <div><Icon name='filter'/>Filtrar por:</div>
          <div className='head'>
            <SearchBar />
            <Indicators />
          </div>
          <Table />
        </div>
      </div>
    </AssignmentParticipationProvider>
  )
}

export { AssignmentParticipation }
