import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const FinishNotesLabel = () => {
  const context = useOrdersInTransitContext()

  const getLabel = () => context.orderDetails.info.notasTerminacion
  const isUnitWithoutGPS = () => context.orderDetails.info.unidadSinGPS

  if (isUnitWithoutGPS())
    return (
      <div>
        <div>Notas terminación:</div>
        <div>{ getLabel() }</div>
      </div>
    )
  return <></>
}

export { FinishNotesLabel }
