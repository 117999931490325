import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import { Dropdown } from 'semantic-ui-react'
import { getAuthFromStorage, logoutFromReduxAndStorage } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import SidebarContent from './main_menu/SidebarContent'
import resources from '../resources'
import '../customStyles.css'
import hasAccessToScreen from '../utils/accessValidations'

const ScreenWithNavItems = ({ user, open = false, children, userActions }) => {
  const [sidebarOpen, setSidebarOpen] = useState(open)
  const history = useHistory()
  const dispatch = useDispatch()

  const { credentials } = useSelector(state => state.auth)
  const location = useLocation()

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push('/')
    else if (!hasAccessToScreen(credentials, location.pathname))
      history.push('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const onLogout = () => {
    history.replace('/')
    sessionStorage.removeItem('credentials')
    dispatch(logoutFromReduxAndStorage())
  }

  const getPlantInfoLabel = () => {
    if (user?.nivel?.id === 1) return 'Central'
    if (!user?.planta) return 'Multi-planta'
    return user.planta
  }

  const getUsername = () => user?.nombre || ''
  const getRole     = () => user?.perfil?.nombre || ''
  const toggle      = () => setSidebarOpen(!sidebarOpen)
  const goHome      = () => history.push('/inicio')

  return (
    <Sidebar
      sidebar={ SidebarContent(user, userActions) }
      styles={{ sidebar: { width: "300px"} }}
      onSetOpen={ toggle }
      open={ sidebarOpen }
    >
      <div className="c-main-layout">
        <div className="sidebar-button">
          <img
            onClick={ toggle }
            src={ resources.images.menu }
            alt="Menu logo"
          />
          <span onClick={ goHome }>
            { resources.appname }
          </span>
        </div>
        <div className="plant-info">
          <img src={ resources.images.planta } alt="Planta icon"/>
          <span>{ getPlantInfoLabel() }</span>
        </div>
        <div className="user-menu">
          <Dropdown
            text={ getUsername() }
            floating
            labeled
            button
            icon="user"
            className="icon"
          >
            <Dropdown.Menu>
              <Dropdown.Header
                icon="eye"
                content={ getRole() }
              />
              <Dropdown.Divider />
              <Dropdown.Item icon="setting" text="Configuracion" />
              <Dropdown.Item
                icon="sign-out"
                text="Cerrar sesión"
                onClick={ onLogout }
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="app-container">
        { children }
      </div>
    </Sidebar>
  )
}

export { ScreenWithNavItems }
