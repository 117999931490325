import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import { numberFormat } from '../../../../helpers/number'

const CapacityLabel = () => {
  const context = useOrdersInTransitContext()

  const getMeasureUnit = () => context.orderDetails.info.unidadMedida || ''

  const buildCapacity = capacity => {
    if (!capacity) return null
    return `${numberFormat(capacity)} ${getMeasureUnit()}`
  }

  const getLabel = () => (
    buildCapacity(context.orderDetails.info.capacidad) || 'Sin asignar'
  )

  const hasTanksInfo = () =>
    context.orderDetails.info.capacidadTanqueUno 
      || context.orderDetails.info.capacidadTanqueDos

  if (hasTanksInfo()) return <></>

  return (
    <div>
      <div>Capacidad:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { CapacityLabel }
