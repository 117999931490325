import React from 'react'
import '../customStyles.css'
import { Header, Dimmer, Transition } from 'semantic-ui-react'
import resources from '../resources'

export const CustomDimmer = ({ visible }) => {
  return (
    <Transition
      animation="fade"
      duration={ 500 }
      unmountOnHide={ true }
      visible={ visible }
    >
      <Dimmer page active={ true }>
        <Header as="h2" icon inverted>
          <img
            id="laodingIcon"
            src={ resources.images.loadingImage }
            alt="ergon-logo"
          />
          <div>Cargando Información</div>
          <Header.Subheader>Espere un momento por favor...</Header.Subheader>
        </Header>
      </Dimmer>
    </Transition>
  )
}
