import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useUsersContext } from '../../contexts/UsersContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'nombre',       title: 'Nombre completo' },
  { path: 'nivelNombre',  title: 'Nivel'           },
  { path: 'perfilNombre', title: 'Perfil'          }
]

const toSearch = headers.map(h => h.path)

const Table = () => {
  const context = useUsersContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleFilterData = (data, e) => {
    context.setUsers(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        data={ context.users }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickRow={ context.onSelectUser }
        handleOnClickHeader={ e => handleFilterData(context.users, e) }
      />
    </div>
  )
}

export { Table }
