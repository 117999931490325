import React from "react"
import { useMotivesContext } from "../../contexts/MotivesContext"
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'descripcion', title: 'Descripción' },
  { path: 'estado', title: 'Estado' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useMotivesContext()

  return (
    <div className="table-no-availability">
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.noAvailabilityTypes }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickRow={ context.onSelectType }
      />
    </div>
  )
}

export { Table }
