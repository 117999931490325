import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const ShipmentDateInput = () => {
  const context = useOrderContext()

  const shipmentDateHandler = createInputValueHandler(context.setFechaCarga)

  return (
    <div className="form-field">
      <label>Fecha planeada de carga:</label>
      <Input
        type="datetime-local"
        value={ context.fechaCarga }
        onChange={ shipmentDateHandler }
      />
    </div>
  )
}

export { ShipmentDateInput }
