import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotesInput = () => {
  const context = useOrderAssignationContext()

  const handleNotes = createInputValueHandler(context.setNotas)

  return (
    <div className="form-field text-area">
      <label>Notas:</label>
      <TextArea
        placeholder="Notas"
        value={ context.notas }
        onChange={ handleNotes }
      />
    </div>
  )
}

export { NotesInput }
