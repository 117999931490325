import React, { createContext, useContext, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { getGeneralInfo } from '../services/ergonApi'
import { showErrorAlert } from '../helpers/notifications'
import { removeWhiteSpaces } from '../helpers/string'
import { dateToYYYYMMDD } from '../helpers/date'

const IncidencesReportContext = createContext()

const IncidencesReportProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [incidences, setIncidences] = useState([])
  const [plantId, setPlantId] = useState('')
  const [shipmentCompanyId, setShipmentCompanyId] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const buildParams = () => (`
    ?plantId=${plantId || ''}
    &shipmentCompanyId=${shipmentCompanyId || ''}
    &from=${dateToYYYYMMDD(from)}
    &to=${dateToYYYYMMDD(to)}
  `)

  const buildEndpoint = () => removeWhiteSpaces(`/incidenceReport${buildParams()}`)

  const hasError = response => response.errorCode || response.status

  const handleResponse = data => {
    if (hasError(data)) showErrorAlert(data.message)
    setIncidences(data)
  }

  const hasSelectedDateRange = () => (!from && !to) || (from && to)

  const search = () => {
    if (!hasSelectedDateRange()) return showErrorAlert('Debes seleccionar un rango de fechas')
    setIsLoading(true)
    getGeneralInfo(credentials.token, buildEndpoint())
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const context = {
    incidences,
    plantId,
    shipmentCompanyId,
    from,
    to,
    isLoading,

    setIncidences,
    setPlantId,
    setShipmentCompanyId,
    setFrom,
    setTo,

    search
  }

  return (
    <AuthProvider>
      <IncidencesReportContext.Provider value={ context }>
        { children }
      </IncidencesReportContext.Provider>
    </AuthProvider>
  )
}

const useIncidencesReportContext = () => useContext(IncidencesReportContext)

export { IncidencesReportProvider, useIncidencesReportContext }
