import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const PlantLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.planta

  return (
    <div className='single-line'>
      <div>Planta:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { PlantLabel }
