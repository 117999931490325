import React from 'react'
import { Input } from 'semantic-ui-react'
import { useVehicleContext } from '../../../contexts/vehicleContext'

const TanksModels = () => {
  const context = useVehicleContext()

  const getModel = tank => tank?.modelo || ''

  const getTankOneModel = () => {
    const tank = context.findTankOne()
    return getModel(tank)
  }

  const getTankTwoModel = () => {
    const tank = context.findTankTwo()
    return getModel(tank)
  }

  return (
    <div className='tanks-inputs'>
      <Input
        disabled
        value={ getTankOneModel() }
      />
      <Input
        disabled
        value={ getTankTwoModel() }
      />
    </div>
  )
}

export { TanksModels }
