import React from 'react'
import { TextArea } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const NotesInput = () => {
  const context = useOrdersInTransitIncidencesContext()

  const notesHandler = (_, { value }) => {
    context.setIsEditing(true)
    context.setNotas(value)
  }

  return (
    <div className='form-field text-area'>
      <label>Descripción de la incidencia:</label>
      <TextArea
        placeholder="Descripción de la incidencia"
        value={ context.notas }
        onChange={ notesHandler }
        maxLength="5000"
      />
    </div>
  )
}

export { NotesInput }
