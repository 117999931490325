import axios from 'axios'
import resources from '../resources'

const buildUrl = params => (
  `${resources.urls.ergonApi}/assignmentParticipationReport
    ?plantId=${params.plantId}
    &beginDate=${params.beginDate}
    &endDate=${params.endDate}`.trim().replace(/\s+/g,"")
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getAssignmentData = async (params, token) => {
  const { data } = await axios.get(
    buildUrl(params),
    buildHeaders(token)
  )
  return data
}

export { getAssignmentData }
