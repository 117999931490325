import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const ClientLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.cliente

  return (
    <div className='single-line'>
      <div>Cliente:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { ClientLabel }
