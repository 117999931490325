import React from 'react'
import { Input } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const AliasInput = () => {
  const context = usePlantsContext()
  const aliasHandler = createInputValueHandler(context.setAlias)

  return (
    <div className='field'>
      <label>Alias:</label>
      <Input
        value={ context.alias }
        onChange={ aliasHandler }
      />
    </div>
  )
}

export { AliasInput }
