import React from 'react'
import { ExcelButton } from '../../../ExcelButton'
import { useTanksContext } from '../../../../contexts/TanksContext'

const ExcelDownloadButton = () => {
  const context = useTanksContext()

  return (
    <div className="download-button-container">
      <ExcelButton
        endpoint={ context.excelEndpoint }
        fileName='Tanques'
      />
    </div>
  )
}

export { ExcelDownloadButton }
