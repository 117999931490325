import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const DeliverDateLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => (
    context.orderDetails.info.fechaCompromiso || 'Sin asignar'
  )

  return (
    <div>
      <div>Fecha planeada de entrega:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { DeliverDateLabel }
