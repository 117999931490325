import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const options = [
  { key: 1, text: 'Con GPS en patio',       value: 1 },
  { key: 2, text: 'Con GPS fuera de patio', value: 2 },
  { key: 3, text: 'Sin GPS transportista',  value: 3 }
]

const UnitTypeDropdown = () => {
  const context = useOrderAssignationContext()

  const handleUnitType = (_, { value }) => {
    context.setUnitType(value)
    context.setUnidadId('')
    context.setPlacasTanque('')
    context.setPlacasTanqueDos('')
  }

  return (
    <div className="form-field">
      <label>Tipo de unidad a asignar:</label>
      <Dropdown
        fluid
        placeholder="Seleccione tipo de unidad"
        selection
        search
        value={ context.unitType }
        options={ options }
        onChange={ handleUnitType }
      />
    </div>
  )
}

export { UnitTypeDropdown }
