import React from 'react'
import { FreightsConsultProvider } from '../contexts/FreightsConsultContext'
import { SearchBar } from '../components/freights_consult/SearchBar'
import { Table } from '../components/freights_consult/Table'

const FreightsConsult = () => {

  return (
    <FreightsConsultProvider>
      <div className='screen-freights-consult'>
        <h1 className='title'>Consulta de fletes</h1>
        <SearchBar />
        <div className='content'>
          <Table />
        </div>
      </div>
    </FreightsConsultProvider>
  )
}

export { FreightsConsult }
