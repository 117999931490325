import React, { useEffect, useState } from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const ShipmentCompanyInput = () => {
  const context = useOrderAssignationContext()

  const [company, setCompany] = useState('')

  useEffect(() => {
    const name = getTransportista()
    setCompany(name || '')
    // eslint-disable-next-line
  }, [context.unitType, context.unidadId])

  const getList = () => {
    if (context.unitType === 1) return context.parkedUnits
    if (context.unitType === 2) return context.outsideUnits
    return context.unitsWithoutGPS
  }

  const getTransportista = () => (
    getList().find(u => u.value === context.unidadId)?.transportista
  )

  return (
    <div className="form-field">
      <label>Transportista:</label>
      <Input
        placeholder="Transportista"
        disabled
        value={ company }
      />
    </div>
  )
}

export { ShipmentCompanyInput }
