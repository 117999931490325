import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'
import { createInputValueHandler } from '../../../helpers/input'

const InputNotes = () => {
  const context = useParkedUnitsContext()

  const handleNotas = createInputValueHandler(context.setNotas)

  return (
    <div className="form-field textarea-field">
      <label>Notas:</label>
      <TextArea
        value={ context.notas }
        onChange={ handleNotas }
      />
    </div>
  )
}

export { InputNotes }
