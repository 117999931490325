import React from 'react'
import { FreightName } from './left_container/FreightName'
import { Destination } from './left_container/Destination'
import { Origin } from './left_container/Origin'
import { State } from './left_container/State'
import { Evidence } from './left_container/Evidence'

const LeftContainer = () => {
  return (
    <div className='left-container'>
      <FreightName />
      <Origin />
      <Destination />
      <State />
      <Evidence />
    </div>
  )
}

export { LeftContainer }
