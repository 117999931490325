import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const SaveButton = () => {
  const context = useOrderContext()

  const saveButtonLabel = () => (
    context.isEditing ? 'Guardar cambios' : 'Guardar'
  )

  const save = () => {
    if (!context.isEditing) return context.save()
    context.edit()
  }

  const canEdit = () => {
    if (context.isWorking) return false
    if (context.unidadCliente && context.estado !== 6) return true
    return !context.isEditing || (context.isEditing && !context.asignado)
  }

  return (
    <Button
      primary
      loading={ context.isWorking }
      onClick={ save }
      disabled={ !canEdit() }
    >
      { saveButtonLabel() }
    </Button>
  )
}

export { SaveButton }
