import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const orderCases = [
  { key: 1, text: "1 cliente / 1 destino", value: 1 },
  { key: 2, text: "1 cliente / n destino", value: 2 },
  { key: 3, text: "1 Destino /n Razones Sociales", value: 3 }
]

const CaseDropdown = () => {
  const context = useOrderContext()

  const caseHandler = createInputValueHandler(context.setCaso)

  const canChange = () => context.externo && !context.hasClientUnit

  return (
    <div className="form-field">
      <label>Caso:</label>
      <Dropdown
        disabled={ !canChange() }
        placeholder="Seleccione un caso"
        options={ orderCases }
        value={ context.caso }
        fluid
        search
        selection
        onChange={ caseHandler }
      />
    </div>
  )
}

export { CaseDropdown }
