import React from 'react'
import { TabOptions } from '../../tab/TabOptions'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'

const options = [
  { key: 'base', label: 'Cambiar base', icon: 'exchange' },
  { key: 'status', label: 'Cambiar estado', icon: 'random' },
  { key: 'historial', label: 'Historial de evidencias', icon: 'list' }
]

const Tabs = () => {
  const context = useChangeUnitsContext()
  return (
    <TabOptions
      options={ options }
      selected={ context.selectedTab }
      onClick={ context.setSelectedTab }
    />
  )
}

export { Tabs }
