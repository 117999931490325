import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const UnitPlateLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.placas || 'Sin asignar'

  return (
    <div>
      <div>Placas unidad:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { UnitPlateLabel }
