import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import {
  useInactivityReportContext
} from '../../contexts/InactivityReportContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'transportistaAlias',   title: 'Transportista'        },
  { path: 'economico',            title: 'Económico'            },
  { path: 'diasPatio',            title: 'Espera patio (días)'  },
  { path: 'calculoPatio',         title: '(%)'                  },
  { path: 'diasMantenimiento',    title: 'Mantenimiento (días)' },
  { path: 'calculoMantenimiento', title: '(%)'                  },
  { path: 'diasOtros',            title: 'Otros (días)'         },
  { path: 'calculoOtros',         title: '(%)'                  },
  { path: 'diasTotal',            title: 'Total (días)'         },
  { path: 'calculoTotal',         title: '(%)'                  }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useInactivityReportContext()
  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setInactivityData(filterdata(context.inactivityData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.inactivityData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
      />
    </div>
  )
}

export { Table }
