import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const NotesInput = () => {
  const context = useOrderContext()

  const notesHandler = createInputValueHandler(context.setNotas)

  return (
    <div className="form-field">
      <label>Notas:</label>
      <TextArea
        placeholder="Notas"
        value={ context.notas }
        onChange={ notesHandler  }
      />
    </div>
  )
}

export { NotesInput }
