import React from 'react'
import { Form } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import {
  useInactivityDetailReportContext
} from '../../../contexts/InactivityDetailReportContext'

const Dates = () => {
  const context = useInactivityDetailReportContext()

  const handleDatePickerChange = value => context.setDateRange(value)

  return (
    <div className='dates-container'>
      <Form size="mini">
        <label>Fecha: </label>
        <DatePicker
          selectsRange
          isClearable
          locale={ es }
          startDate={ context.startDate }
          endDate={ context.endDate }
          onChange={ handleDatePickerChange }
        />
      </Form>
    </div>
  )
}

export { Dates }
