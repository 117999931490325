import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Form, TextArea } from 'semantic-ui-react'

const Note = () => {
  const context = useUploadEvidenceContext()

  return (
    <div className="big-container">
      <label>Nota de no disponible:</label>
      <Form>
        <TextArea
          type='text'
          className='disabled-textarea'
          disabled
          value={ context.note || 'Sin asignar' }
        />
      </Form>
    </div>
  )
}

export { Note }
