import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useOrderContext } from '../../contexts/OrderContext'
import { Actions } from './client_unit_modal/Actions'
import { CapacityInput } from './client_unit_modal/CapacityInput'
import { ClientInput } from './client_unit_modal/ClientInput'
import { ClientUnitsDropdown } from './client_unit_modal/ClientUnitsDropdown'
import { DestinationInput } from './client_unit_modal/DestinationInput'
import { DriverInput } from './client_unit_modal/DriverInput'
import { Header } from './client_unit_modal/Header'
import { PlateInput } from './client_unit_modal/PlateInput'
import { PlateTankInput } from './client_unit_modal/PlateTankInput'
import { PlateTankTwoInput } from './client_unit_modal/PlateTankTwoInput'
import { UnitNameInput } from './client_unit_modal/UnitNameInput'

const ClientUnitModal = () => {
  const context = useOrderContext()

  const [unitName,     setUnitName]     = useState('')
  const [plate,        setPlate]        = useState('')
  const [plateTank,    setPlateTank]    = useState('')
  const [plateTankTwo, setPlateTankTwo] = useState('')
  const [capacity,     setCapacity]     = useState('')
  const [driver,       setDriver]       = useState('')
  const [destination,  setDestination]  = useState('')

  useEffect(() => {
    if (!context.showClientUnitModal) return
    clientUnitHandler(context.unidadCliente)
    // eslint-disable-next-line
  }, [context.showClientUnitModal])

  const clientUnitHandler = unit => {
    setUnitName(unit?.economico || '')
    setPlate(unit?.placas || '')
    setPlateTank(unit?.placasTanque || '')
    setPlateTankTwo(unit?.placasTanqueDos || '')
    setCapacity(unit?.capacidad || '')
    setDriver(unit?.operador || '')
    setDestination(unit?.destino || '')
  }

  const getClient = () => (
    context.clients.find(c => c.value === context.clienteId)?.text
  )

  const clean = () => {
    setUnitName('')
    setPlate('')
    setPlateTank('')
    setPlateTankTwo('')
    setCapacity('')
    setDriver('')
    setDestination('')
  }

  const close = (isClientUnitSelected = false) => {
    clean()
    context.setShowClientUnitModal(false)
    context.setHasClientUnit(isClientUnitSelected || !!context.unidadCliente)
  }

  const confirm = () => {
    const clientUnit = {
      cliente: getClient(),
      economico: unitName,
      placas: plate,
      placasTanque: plateTank,
      placasTanqueDos: plateTankTwo || null,
      capacidad: capacity || null,
      operador: driver,
      destino: destination || null
    }
    context.setUnidadCliente(clientUnit)
    close(true)
  }

  const isEditingAsignedOrder = () => context.isEditing && context.estado === 6

  const canConfirm = () => {
    if (isEditingAsignedOrder()) return false
    return unitName.trim().length &&
      plate.trim().length &&
      plateTank.trim().length &&
      driver.trim().length
  }

  return (
    <Modal
      backdrop="static"
      show={ context.showClientUnitModal }
      onHide={ clean }
    >
      <Header />
      <Modal.Body>
        <ClientUnitsDropdown onClick={ clientUnitHandler } />
        <ClientInput />
        <UnitNameInput
          unitName={ unitName }
          setUnitName={ setUnitName }
        />
        <PlateInput
          plate={ plate }
          setPlate={ setPlate }
        />
        <PlateTankInput
          plateTank={ plateTank }
          setPlateTank={ setPlateTank }
        />
        <PlateTankTwoInput
          plateTankTwo={ plateTankTwo }
          setPlateTankTwo={ setPlateTankTwo }
        />
        <CapacityInput
          capacity={ capacity }
          setCapacity={ setCapacity }
        />
        <DriverInput
          driver={ driver }
          setDriver={ setDriver }
        />
        <DestinationInput
          destination={ destination }
          setDestination={ setDestination }
        />
      </Modal.Body>
      <Actions
        close={ close }
        confirm={ confirm }
        canConfirm={ canConfirm() }
      />
    </Modal>
  )
}

export { ClientUnitModal }
