import axios from 'axios'
import { responseHandler, showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const URL = `${resources.urls.ergonApi}/stayTimings`

const buildHeaders = token => ({ headers: { Authorization: token } })

const getOrderDetailsList = async (id, token) => {
  const url = `${URL}/orderDetails/${id}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return []
  }
}

const getById = async (id, token) => {
  const url = `${URL}/${id}`
  try {
    const response = await axios.get(url, buildHeaders(token))
    return response.data
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return {}
  }
}

const save = async (params, token) => {
  const url = `${URL}`
  try {
    const response = await axios.post(url, params, buildHeaders(token))
    responseHandler(response.data)
    return true
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return false
  }
}

const download = async (id, token) => {
  const url = `${URL}/download/${id}`
  try {
    const response = await axios.get(url, {
      headers: { Authorization: token },
      responseType: 'arraybuffer'
    })
    return response.data
  }
  catch (error) {
    showErrorAlert('El transportista no ha incluido un PDF')
    return null
  }
}

const upload = async (params, token) => {
  const url = `${URL}/upload`
  try {
    const response = await axios.post(url, params, buildHeaders(token))
    responseHandler(response.data)
    return true
  }
  catch (error) {
    showErrorAlert(error.error || error.message)
    return false
  }
}

export default {
  getOrderDetailsList,
  getById,
  save,
  download,
  upload
}
