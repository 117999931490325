import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const DestinationExitDate = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    context.stayInfo.salidaDestino || 'Sin salida registrada'
  )

  return (
    <div className="form-field">
      <label>Salida de destino:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { DestinationExitDate }
