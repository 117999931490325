import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const ProductField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Producto:</label>
      <label>{ context.selectedIncidence?.producto }</label>
    </div>
  )
}

export { ProductField }
