import React, { useEffect } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const DestinationDropdown = ({ destinationId = '', setDestinationId = () => {} }) => {
  const context = useOrderContext()

  useEffect(() => {
    if (!context.destinations.length) return
    const id = context.destinations[0].value
    setDestinationId(id)
  }, [context.destinations, setDestinationId])

  useEffect(() => {
    if (context.caso !== 3 || !context.detalles.length) return
    const id = context.destinations
      .find(d => d.value === context.detalles[0].destinoId)
      ?.value
    setDestinationId(id)
  }, [context.caso, context.detalles, context.destinations, setDestinationId])

  const destinationSelectHandler = createInputValueHandler(setDestinationId)

  const isUnchoosableDestination = () => {
    if (context.caso !== 3) return false
    return context.detalles.length > 0
  }

  return (
    <div className="form-field">
      <label>Destino:</label>
      {
        context.unidadCliente ?
        <Input
          value="Libre a bordo en planta"
          disabled={ true }
        /> :
        <Dropdown
          placeholder="Destino"
          fluid
          selection
          search
          options={ context.destinations }
          value={ destinationId }
          disabled={ isUnchoosableDestination() }
          onChange={ destinationSelectHandler }
        />
      }
    </div>
  )
}

export { DestinationDropdown }
