import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useUnitUseContext } from '../../contexts/UnitUseContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'transportistaAlias', title: 'Transportista'   },
  { path: 'economico',          title: 'Económico'       },
  { path: 'totalViajes',        title: 'Total de viajes' },
  { path: 'calculoUso',         title: '(%) Uso de unidad' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useUnitUseContext()
  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setUnitUseData(filterdata(context.unitUseData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.unitUseData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
      />
    </div>
  )
}

export { Table }
