import React, { createContext, useContext, useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { CustomDimmer } from '../components/CustomDimmer'
import { getOrderInfo, saveOrderClaim } from '../services/stayClaim'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import scaleToFit from '../helpers/image.js'

const StayClaimContext = createContext()

const imageTypes = ['png', 'jpeg', 'jpg']

const StayClaimProvider = ({ children }) => {

  const location = useLocation()
  const parts = location.pathname.split('/')
  const getAuth = () => { setToken(parts[parts.length - 1]) }

  const [isLoading,     setIsLoading]     = useState(true)
  const [isWorking,     setIsWorking]     = useState(false)
  const [token,         setToken]         = useState('')
  const [order,         setOrder]         = useState('')
  const [plant,         setPlant]         = useState('')
  const [operator,      setOperator]      = useState('')
  const [economic,      setEconomic]      = useState('')
  const [plates,        setPlates]        = useState('')
  const [tankPlates,    setTankPlates]    = useState('')
  const [tankPlatesTwo, setTankPlatesTwo] = useState('')
  const [remissionId,   setRemissionId]   = useState('')
  const [destinations,  setDestinations]  = useState([])
  const [client,        setClient]        = useState('')
  const [destiny,       setDestiny]       = useState('')
  const [arrivalDate,   setArrivalDate]   = useState('')
  const [unloadingDate, setUnloadingDate] = useState('')
  const [stayDays,      setStayDays]      = useState(0)
  const [PDFBase64,     setPDFBase64]     = useState('')
  const [notes,         setNotes]         = useState('')
  const [isClaimed,     setIsClaimed]     = useState(true)

  useEffect(() => {
    getAuth()
    load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const load = () => {
    if (!token) return
    getData()
    setIsLoading(false)
    setIsWorking(false)
  }

  const clear = () => {
    load()
    setRemissionId('')
    setClient('')
    setDestiny('')
    setArrivalDate('')
    setUnloadingDate('')
    setStayDays(0)
    setPDFBase64('')
    setNotes('')
    setIsClaimed(true)
  }

  const handleDropdown = d => {
    setDestinations(d.map(e => (
      {
        key: e.key,
        text: e.text || 'Sin remisión',
        value: e.value,
        client: e.clienteNombre,
        destiny: e.referenciaDestino,
        days: e.diasEstadia,
        arrival: e.fechaLlegada,
        unload: e.fechaDescarga,
        notes: e.notas,
        claimed: e.esReclamada
      }
    )))
  }

  const getData = async () => {
    const response = await getOrderInfo(token)
    if (response.errorCode) return showErrorAlert('Página no es valida')
    setOrder(response.folio                   || '')
    setPlant(response.plantaAlias             || '')
    setOperator(response.operadorNombre       || '')
    setEconomic(response.economico            || '')
    setPlates(response.placas                 || '')
    setTankPlates(response.placasTanque       || '')
    setTankPlatesTwo(response.placasTanqueDos || '')
    handleDropdown(response.destinos          || '')
  }

  const onSelectDestinations = id => {
    const remission = destinations.find(r => r.key === id)
    setRemissionId(remission.value    || '')
    setClient(remission.client        || '')
    setDestiny(remission.destiny      || '')
    setArrivalDate(remission.arrival  || '')
    setUnloadingDate(remission.unload || '')
    setStayDays(remission.days        ||  0)
    setNotes(remission.notes          || '')
    setIsClaimed(!!remission.claimed)
  }

  const getType = value => value.split('.').reverse()[0]

  const isImage = value => {
    const extension = getType(value)
    return imageTypes.includes(extension)
  }

  const preparePDFBase64 = (pdf, inputData) => {
    const fileReader = new FileReader()
    fileReader.onload = async fileLoadedEvent => {
      let base64Data
      if (isImage(inputData.value)) {
        const result = fileLoadedEvent.target.result
        base64Data = await imageToPdf(result, getType(inputData.value))
      }
      else
        base64Data = fileLoadedEvent.target.result
      setPDFBase64(base64Data)
    }
    fileReader.readAsDataURL(pdf)
  }

  const imageToPdf = async (buffer, type) => {
    const pdfDoc = await PDFDocument.create()
    let image
    if (/jpe?g/i.test(type)) image = await pdfDoc.embedJpg(buffer)
    else if (/png/i.test(type)) image = await pdfDoc.embedPng(buffer)
    else throw Error('Seleccione una imagen válida')
    const page = pdfDoc.addPage()
    const pageSize = page.getSize()
    const size = scaleToFit(
      image.width, image.height,
      pageSize.width, pageSize.height
    )
    page.drawImage(image, { width: size.width, height: size.height, x: 5, y: 5 })
    let base64Chunk = await pdfDoc.saveAsBase64()
    return 'data:application/pdfbase64,' + base64Chunk
  }

  const addFileBuffer = () => {
    const content = PDFBase64.split(',')[1]
    saveParams.evidencia = Buffer.from(content, 'base64')
  }

  const saveParams = {
    productoPedidoId: remissionId,
    fechaLlegada: arrivalDate,
    fechaDescarga: unloadingDate,
    diasEstadia: stayDays,
    notas: notes
  }

  const save =  async () => {
    if (!order) return showErrorAlert('Página no es valida')
    if (!remissionId)
      return showErrorAlert('Favor de seleccionar una remisión')
    if (!arrivalDate)
      return showErrorAlert('Favor de ingresar fecha y hora de llegada')
    if (!unloadingDate)
      return showErrorAlert('Favor de ingresar fecha y hora de descarga')
    if (!parseInt(stayDays))
      return showErrorAlert('Favor de ingresar días de estadía')
    if (PDFBase64) addFileBuffer()
    setIsWorking(true)
    saveOrderClaim(token, saveParams)
      .then(response => {
        if (response.errorCode) {
          setIsWorking(false)
          return showErrorAlert(response)
        }
        showOkAlert(response.message)
        clear()
      })
      .catch(() => {
        setIsWorking(false)
        return showErrorAlert('El archivo es demasiado grande')
      })
  }

  const context = {
    isLoading,
    isWorking,
    order,
    plant,
    operator,
    economic,
    plates,
    tankPlates,
    tankPlatesTwo,
    remissionId,
    isClaimed,
    client,
    destiny,
    destinations,
    arrivalDate,
    unloadingDate,
    stayDays,
    notes,
    setRemissionId,
    onSelectDestinations,
    setArrivalDate,
    setUnloadingDate,
    setStayDays,
    setNotes,
    preparePDFBase64,
    save
  }

  return (
    <StayClaimContext.Provider value={ context }>
      <CustomDimmer visible={ isLoading } />
        { children }
    </StayClaimContext.Provider>
  )
}

const useStayClaimContext = () => useContext(StayClaimContext)

export { useStayClaimContext, StayClaimProvider }
