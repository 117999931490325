import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const AddButton = () => {
  const context = useOrdersInTransitIncidencesContext()

  const handleClick = () => {
    if (context.afectaServicio)
      return context.setShowMailingList(true)
    context.add()
  }

  return (
    <Button
      primary
      loading={ context.isWorking }
      onClick={ handleClick }
    >
      <Icon name="plus"/>
    </Button>
  )
}

export { AddButton }
