import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components/CustomDimmer'
import {
  deletteAnObject,
  getGeneralInfo,
  saveAnObject
} from '../services/ergonApi'
import { responseHandler, showErrorAlert } from '../helpers/notifications'
import { getOrderFreights } from '../services/orderAssignation'

const OrderAssignationContext = createContext()

const OrderAssignationProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlantId, setSelectedPlantId] = useState('')
  const [selectedChunk, setSelectedChunk] = useState(50)
  const [isWorking, setIsWorking] = useState(false)
  const [orders, setOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [statusList, setStatusList] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(0)
  const [showProductsModal, setShowProductsModal] = useState(false)
  const [products, setProducts] = useState([])
  const [unitType, setUnitType] = useState(1)
  const [parkedUnits, setParkedUnits] = useState([])
  const [outsideUnits, setOutsideUnits] = useState([])
  const [unitsWithoutGPS, setUnitsWithoutGPS] = useState([])
  const [drivers, setDrivers] = useState([])
  const [shipmentCompanies, setShipmentCompanies] = useState([])
  const [showUnitStateModal, setShowUnitStateModal] = useState(false)
  const [showUnassignModal, setShowUnassignModal] = useState(false)
  const [hasBeenUnassigned, setHasBeenUnassigned] = useState(false)

  const [folio,              setFolio             ] = useState('')
  const [cliente,            setCliente           ] = useState('')
  const [caso,               setCaso              ] = useState('')
  const [fechaRequerida,     setFechaRequerida    ] = useState('')
  const [plantaTerminoViaje, setPlantaTerminoViaje] = useState('')
  const [fechaCompromiso,    setFechaCompromiso   ] = useState('')
  const [fechaCarga,         setFechaCarga        ] = useState('')
  const [horasTraslado,      setHorasTraslado     ] = useState(0)
  const [kmTraslado,         setKmTraslado        ] = useState(0)
  const [notas,              setNotas             ] = useState('')
  const [notaFlete,          setNotaFlete         ] = useState('')
  const [operadorId,         setOperadorId        ] = useState('')
  const [operador,           setOperador          ] = useState('')
  const [placasTanque,       setPlacasTanque      ] = useState('')
  const [placasTanqueDos,    setPlacasTanqueDos   ] = useState('')
  const [tanqueIdUno,        setTanqueIdUno       ] = useState('')
  const [tanqueIdDos,        setTanqueIdDos       ] = useState('')
  const [transportista,      setTransportista     ] = useState('')
  const [unidad,             setUnidad            ] = useState('')
  const [unidadId,           setUnidadId          ] = useState('')
  const [estadoId,           setEstadoId          ] = useState(0)
  const [selectedTimezone,   setSelectedTimezone  ] = useState(0)
  const [editOffset,         setEditOffset        ] = useState(false)
  const [freightsData,       setFreightsData      ] = useState([])
  const [freightId,          setFreightId         ] = useState(null)
  const [checkTankOne,       setCheckTankOne      ] = useState(false)
  const [checkTankTwo,       setCheckTankTwo      ] = useState(false)
  const [fechaCargaReal,     setFechaCargaReal    ] = useState('')
  const [orderUnitId,        setOrderUnitId       ] = useState('')
  const [orderDriverId,      setOrderDriverId     ] = useState('')
  const [orderDriverName,    setOrderDriverName   ] = useState('')

  useEffect(() => {
    if (credentials) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => {
    if (isWorking) return
    setFilteredOrders(getFilteredOrders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, isWorking])

  const load = async () => {
    setIsLoading(true)
    setStatusList(await getStatus())
    setDrivers(await getDrivers())
    setShipmentCompanies(await getShipmentCompanies())
    setIsLoading(false)
  }

  const loadUnits = async () => {
    const units = await getUnits()
    setParkedUnits(units.patio)
    setOutsideUnits(units.fueraPatio)
    setUnitsWithoutGPS(units.sinGPS)
  }

  const getStatus = async () => (
    await getGeneralInfo(credentials.token, '/orderAssignation/status')
  )

  const getOrder = async id => (
    await getGeneralInfo(credentials.token, `/orderAssignation/order/${id}`)
  )

  const getProducts = async () => {
    const url = '/orderAssignation/details'
    const params = { id: folio }
    return await getGeneralInfo(credentials.token, url, params)
  }

  const getUnits = async () => {
    const url = '/orderAssignation/units'
    const params = { plantId: selectedPlantId }
    return await getGeneralInfo(credentials.token, url, params)
  }

  const search = async () => {
    if (selectedPlantId <= 0) return showErrorAlert('Seleccione una planta')
    setIsWorking(true)
    clean()
    const url = '/orderAssignation/orders'
    const params = { plantId: selectedPlantId, records: selectedChunk }
    const response = await getGeneralInfo(credentials.token, url, params)
    setOrders(response)
    await loadUnits()
    setIsWorking(false)
  }

  const getDrivers = async () => (
    await getGeneralInfo(credentials.token, '/orderAssignation/drivers')
  )

  const getShipmentCompanies = async () => (
    await getGeneralInfo(credentials.token, '/orderAssignation/shipmentCompanies')
  )

  const getFilteredOrders = () => {
    if (selectedStatus <= 0) return orders
    return orders.filter(o => o.estadoId === selectedStatus)
  }

  const parseToDropdown = () => (
    freightsData.map(({key, text, value}) => ({ key, text, value }))
  )

  const getOrderById = async id => {
    setFreightsData([])
    setFreightId(null)
    setEditOffset(false)
    const order = await getOrder(id)
    const freights = await getOrderFreights(id, credentials.token)
    setFreightsData(freights)
    setFolio(order.folio || '')
    setCliente(order.cliente || '')
    setCaso(order.caso || '')
    setFechaRequerida(order.fechaRequerida || '')
    setPlantaTerminoViaje(order.plantaTerminoViaje || '')
    setFechaCompromiso(order.fechaCompromiso || '')
    setFechaCarga(order.fechaCarga || '')
    setFechaCargaReal(order.fechaCargaReal || '')
    setHorasTraslado(order.horasTraslado || 0)
    setKmTraslado(order.kmTraslado || 0)
    setNotas(order.notas || '')
    setNotaFlete(order.notaFlete || '')
    setOrderDriverId(order.operadorId || '')
    setOperadorId(order.operadorId || '')
    setOperador(order.operador || '')
    setOrderDriverName(order.operador || '')
    setPlacasTanque(order.placasTanque || '')
    setPlacasTanqueDos(order.placasTanqueDos || '')
    setTransportista(order.transportista || '')
    setUnidad(order.unidad || '')
    setUnitType(order.isUnitWithoutGPS ? 3 : 1)
    setUnidadId(order.unidadId || '')
    setOrderUnitId(order.unidadId || '')
    setEstadoId(order.estadoId || 0)
    setSelectedTimezone(order?.offsetTime || 0)
    setHasBeenUnassigned(order.estadoId === 1 && order.fechaCompromiso)
    setFreightId(order.fleteAutorizadoId)
    setCheckTankOne(!!order.placasTanque)
    setCheckTankTwo(!!order.placasTanqueDos)
  }

  const getDetails = async () => {
    setProducts(await getProducts())
  }

  const clean = () => {
    setFolio('')
    setCliente('')
    setCaso('')
    setFechaRequerida('')
    setPlantaTerminoViaje('')
    setFechaCompromiso('')
    setFechaCarga('')
    setFechaCargaReal('')
    setHorasTraslado(0)
    setKmTraslado(0)
    setNotas('')
    setNotaFlete('')
    setOperadorId('')
    setOperador('')
    setPlacasTanque('')
    setPlacasTanqueDos('')
    setTanqueIdUno('')
    setTanqueIdDos('')
    setCheckTankOne(false)
    setCheckTankTwo(false)
    setTransportista('')
    setUnidad('')
    setUnidadId('')
    setFreightId('')
    setSelectedTimezone(0)
    setShowUnassignModal(false)
    setEstadoId(0)
    setEditOffset(false)
    setHasBeenUnassigned(false)
  }

  const handleTanksProps = () => ({
    placasTanque:    checkTankOne ? placasTanque : null,
    placasTanqueDos: checkTankTwo ? placasTanqueDos : null,
    tanqueIdUno:     checkTankOne ? tanqueIdUno : null,
    tanqueIdDos:     checkTankTwo ? tanqueIdDos : null,
  })

  const getAssignParams = () => ({
    folio: folio,
    unidadId: unidadId,
    fechaCompromiso: fechaCompromiso,
    fechaCargaReal: fechaCargaReal,
    horasTraslado: horasTraslado,
    kmTraslado: kmTraslado,
    plantaTerminoViaje: plantaTerminoViaje,
    operador: operador,
    operadorId: operadorId,
    notas: notas || null,
    notaFlete: notaFlete || null,
    timeOffset: selectedTimezone,
    estadoId: estadoId,
    editOffset: editOffset,
    fleteAutorizadoId: freightId,
    ...handleTanksProps()
  })

  const save = async () => {
    if (!plantaTerminoViaje)
      return showErrorAlert('Debe seleccionar una planta de termino de viaje')
    if (!unidadId)
      return showErrorAlert('Debe seleccionar una unidad')
    if (!operadorId)
      return showErrorAlert('Debe seleccionar un operador')
    if (!horasTraslado)
      return showErrorAlert('Debe especificar una cantidad de horas de traslado')
    if (!kmTraslado)
      return showErrorAlert('Debe especificar una distancia de traslado')
    if (!checkTankOne && !checkTankTwo)
      return showErrorAlert('Debe asignar por lo menos un tanque')
    if (!freightId)
      return showErrorAlert('Debe asignar una cotización de flete autorizado')
    const url = '/orderAssignation/'
    const params = getAssignParams()
    setIsWorking(true)
    const response = await saveAnObject(credentials.token, url, params)
    responseHandler(response)
    if (!response.error)
      setTimeout(search, 2000)
    setIsWorking(false)
  }

  const unassign = async () => {
    setIsWorking(true)
    const url = '/pedido/cancelarConfirmacion/'
    const response = await deletteAnObject(credentials.token, url, context.folio)
    responseHandler(response)
    if (!response.error) {
      setShowUnassignModal(false)
      setTimeout(search, 2000)
    }
    setIsWorking(false)
  }

  const context = {
    selectedChunk,      setSelectedChunk,
    selectedPlantId,    setSelectedPlantId,
    showProductsModal,  setShowProductsModal,
    unitType,           setUnitType,
    selectedTimezone,   setSelectedTimezone,
    showUnitStateModal, setShowUnitStateModal,
    showUnassignModal,  setShowUnassignModal,
    selectedStatus,     setSelectedStatus,
    isWorking,
    getDetails,
    products,
    parkedUnits,
    outsideUnits,
    unitsWithoutGPS,
    drivers,
    shipmentCompanies,
    search,
    clean,
    save,
    unassign,
    orders,
    filteredOrders,
    statusList,
    getOrderById,
    folio,
    cliente,
    caso,
    fechaRequerida,
    plantaTerminoViaje, setPlantaTerminoViaje,
    fechaCompromiso,    setFechaCompromiso,
    fechaCarga,         setFechaCarga,
    fechaCargaReal,     setFechaCargaReal,
    horasTraslado,      setHorasTraslado,
    kmTraslado,         setKmTraslado,
    notas,              setNotas,
    notaFlete,          setNotaFlete,
    operadorId,         setOperadorId,
    operador,           setOperador,
    placasTanque,       setPlacasTanque,
    placasTanqueDos,    setPlacasTanqueDos,
    transportista,      setTransportista,
    unidad,             setUnidad,
    unidadId,           setUnidadId,
    editOffset,         setEditOffset,
    estadoId,
    checkTankOne,
    checkTankTwo,
    setTanqueIdUno,
    setTanqueIdDos,
    freightId,
    freightsData,
    setFreightId,
    setCheckTankOne,
    setCheckTankTwo,
    parseToDropdown,
    hasBeenUnassigned,
    orderUnitId,
    orderDriverId, setOrderDriverId,
    orderDriverName
  }

  return (
    <AuthProvider>
      <OrderAssignationContext.Provider value={context}>
        <CustomDimmer visible={isLoading} />
        { children }
      </OrderAssignationContext.Provider>
    </AuthProvider>
  )
}

const useOrderAssignationContext = () => useContext(OrderAssignationContext)

export { OrderAssignationProvider, useOrderAssignationContext }
