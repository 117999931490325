import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'
import { isSuperadmin } from '../../../helpers/sessionCredentials'

const RequestedDateInput = () => {
  const context = useOrderContext()

  const dateHandler = createInputValueHandler(context.setFechaRequerida)

  const canEdit = () => !context.isEditing || isSuperadmin()

  return (
    <div className="form-field">
      <label>Fecha requerida:</label>
      <Input
        type="datetime-local"
        value={ context.fechaRequerida }
        onChange={ dateHandler }
        disabled={ !canEdit() }
      />
    </div>
  )
}

export { RequestedDateInput }
