import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import {
  useInitialInventoryContext
} from '../../../contexts/InitialInventoryContext'
import { createInputValueHandler } from '../../../helpers/input'

const InventoryDate = () => {
  const context = useInitialInventoryContext()
  const dateInventory = createInputValueHandler(context.setDateInventory)

  return (
    <div className='inventory-input'>
      <Form>
        <label>Fecha:</label>
        <Input
          type='date'
          value={ context.dateInventory }
          onChange={ dateInventory }
        />
      </Form>
    </div>
  )
}

export { InventoryDate }
