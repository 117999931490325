import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'

const TankStatus = () => {
  const context = useTanksContext()

  return (
    <div className="tank-status-container">
      <label className='title'>Estado:</label>
      <label className='s-value'>{ context.tankStatus || 'Disponible' }</label>
    </div>
  )
}

export { TankStatus }
