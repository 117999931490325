import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const CasoInput = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Caso:</label>
      <Input
        placeholder="Caso"
        disabled
        value={ context.caso }
      />
    </div>
  )
}

export { CasoInput }
