import React from 'react'
import { useUsersContext } from '../../contexts/UsersContext'
import { Button } from 'semantic-ui-react'

const FormActions = () => {
  const {
    clean,
    isCreating,
    save,
    setShowModal,
    isWorking
  } = useUsersContext()

  const getText = () => (
    `Guardar ${!isCreating ? 'Cambios' : '' }`
  )

  return (
    <div>
      {
        !isCreating ? (
          <>
            <Button
              loading={ isWorking }
              onClick={ clean }
            >
              Cancelar cambios
            </Button>
            <Button
              color='red'
              loading={ isWorking }
              onClick={ () => setShowModal(true) }
            >
              Eliminar usuario
            </Button>
          </>
        ) : (<></>)
      }
      <Button
        primary
        loading={ isWorking }
        onClick={ save }
      >
        { getText() }
      </Button>
    </div>
  )
}

export { FormActions }
