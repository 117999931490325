import React from 'react'
import {
  useInactivityReportContext
} from '../../../contexts/InactivityReportContext'
import { ExcelButton } from '../../ExcelButton'

const XLSDownloadButton = () => {
  const context = useInactivityReportContext()

  return (
    <div className="download-button-container">
      { context.inactivityData?.length ? (
        <div>
          <ExcelButton
            endpoint={ context.excelEndpoint }
            fileName='Reporte_de_inactividad'
          />
        </div>
        ) : (<></>)
      }
    </div>
  )
}

export { XLSDownloadButton }
