import React from 'react'
import { CleanButton } from './order_form/CleanButton'
import { DestroyButton } from './order_form/DestroyButton'
import { SaveButton } from './order_form/SaveButton'

const OrderFormActions = () => {
  return (
    <div className="form-actions">
      <CleanButton />
      <DestroyButton />
      <SaveButton />
    </div>
  )
}

export { OrderFormActions }
