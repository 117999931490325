import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { useMotivesContext } from '../../contexts/MotivesContext'

const ConfirmDestroyModal = () => {
  const context = useMotivesContext()

  const confirmHandler = () => {
    context.destroy()
    context.setShowConfirmDestroy(false)
  }

  const cancelHandler = () => context.setShowConfirmDestroy(false)

  return (
    <Modal show={ context.showConfirmDestroy }>
      <Modal.Header>
        <Modal.Title>
          Confirmación para eliminar el motivo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Una vez eliminada la información no se podrá recuperar. ¿Esta seguro que desea eliminar?
      </Modal.Body>
      <Modal.Footer>
      <Button
        color='grey'
        onClick={ cancelHandler }
      >
        Cancelar
      </Button>
      <Button
        onClick={ confirmHandler }
        color='red'
      >
        Confirmar
      </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmDestroyModal }
