import React from 'react'
import { FormUnit } from '../components/parked_units/FormUnit'
import {
  ModalLastChecklists
} from '../components/parked_units/ModalLastChecklists'
import { SearchBar } from '../components/parked_units/SearchBar'
import { Table } from '../components/parked_units/Table'
import { ParkedUnitsProvider } from '../contexts/ParkedUnitsContext'

const ParkedUnits = () => {
  return (
    <ParkedUnitsProvider>
      <h1>Unidades en patio</h1>
      <div className="screen-parked-units">
        <div>
          <SearchBar/>
          <Table/>
        </div>
        <FormUnit/>
        <ModalLastChecklists/>
      </div>
    </ParkedUnitsProvider>
  )
}

export { ParkedUnits }
