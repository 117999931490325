import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'


const DestroyButton = () => {
  const context = useAuthorizedFreightsContext()

  const destroy = () => context.setShowConfirmDestroy(true)

  const canDestroy = () => context.isEditing

  return (
    <div>
      {
        canDestroy() ?
        (
          <Button
            color='red'
            disabled={ context.isWorking }
            loading={ context.isWorking }
            onClick={ destroy }
          >
            Eliminar
          </Button>
        ) : (<></>)
      }
    </div>
  )
}

export { DestroyButton }
