import React from 'react'
import { Icon } from 'semantic-ui-react'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'razonSocial',        title: 'Razon social'         },
  { path: 'productoNombre',     title: 'Producto'             },
  { path: 'cantidad',           title: 'Cantidad'             },
  { path: 'valorMercancia',     title: 'Valor mercancia'      },
  { path: 'unidadMedidaNombre', title: 'Unidad medida'        },
  { path: 'notas',              title: 'Domicilio de entrega' },
  { path: 'coords',             title: 'Link'                 }
]

const Table = ({ products }) => {

  const getCoordenates = (product) =>  `${product.latitud},${product.longitud}`

  const buildUrl = (product) => (
    `https://www.google.com/maps/search/${getCoordenates(product)}`
  )

  const getLink = product => (
    <a
      href={ buildUrl(product) }
      target='_blank'
      rel='noopener noreferrer'
      title='Ver en mapa'
    >
      <Icon name='map' size='large' color='grey' />
    </a>
  )

  const withCoords = () => {
    if (!products) return []
    return products.map(p => ({
      ...p,
      coords: getLink(p)
    }))
  }

  return (
    <StyledTable
      search={ false }
      headers={ headers }
      data={ withCoords() }
    />
  )
}

export { Table }
