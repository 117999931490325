import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  useFreightsConsultContext
} from '../../../../contexts/FreightsConsultContext'

const IsInternalCheckbox = () => {
  const context = useFreightsConsultContext()

  const cleanInvolvedField = () => context.setClientIdFilter(null)

  const handleOnCheck = (_, { checked }) => {
    context.setIsInternal(checked)
    cleanInvolvedField()
  }

  return (
    <div className='is-internal'>
      <label>Fletes internos:</label>
      <Checkbox
        checked={ context.isInternal }
        onChange={ handleOnCheck }
        className='value'
      />
    </div>
  )
}

export { IsInternalCheckbox }
