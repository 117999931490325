import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Container, Row, Modal } from 'react-bootstrap'
import { useOrdersInTransitContext } from '../../contexts/OrdersInTransitContext'
import { OrderTabs } from './order_info_modal/OrderTabs'
import { OrderOptions } from './order_info_modal/OrderOptions'
import { ModalToUnassignDriver } from './order_info_modal/ModalToUnassignDriver'
import { ModalToFinishOrder } from './order_info_modal/ModalToFinishOrder'
import {
  useOrdersInTransitInfoModalContext
} from '../../contexts/OrdersInTransitInfoModalContext'
import {
  ModalToFinishOrderByRealQuantity
} from './order_info_modal/ModalToFinishOrderByRealQuantity'

const OrderInfoModal = () => {
  const context = useOrdersInTransitContext()
  const contextModal = useOrdersInTransitInfoModalContext()

  const handleClose = () => {
    context.setShowOrderInfoModal(false)
    context.search()
    contextModal.clearModal()
  }

  return (
    <>
      <ModalToUnassignDriver />
      <ModalToFinishOrder />
      <ModalToFinishOrderByRealQuantity />
      <Modal
        className='order-information-modal'
        show={ context.showOrderInfoModal }
        size='xl'
        centered
        onHide={ () => {} }
      >
        <Modal.Body>
          <Icon name='close' onClick={ handleClose } />
          <Container>
            <Row>
              <label className='no-gps-label'>
                { contextModal.isUnitWithoutGPS() ? 'Unidad sin GPS' : '' }
              </label>
            </Row>
            <Row>
              <OrderTabs />
            </Row>
            <hr />
            <OrderOptions />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export { OrderInfoModal }
