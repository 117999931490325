import React from 'react'
import { UploadPDFButton } from '../../actions/UploadPDFButton'

const Evidence = () => {
  return (
    <div className='form-field evidence-container'>
      <label>Evidencia:</label>
      <UploadPDFButton />
    </div>
  )
}

export { Evidence }
