import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const AddButton = ({ onClick = () => {}, editingRowPosition = null }) => {
  const context = useOrderContext()

  const canAdd = () => {
    if (editingRowPosition !== null) return true
    if (context.caso === 1) return !context.detalles.length
    return context.detalles.length <= context.detailsLimit
  }

  return (
    <Button
      loading={ context.isWorking }
      onClick={ onClick }
      disabled={ context.isWorking || !canAdd() }
    >
      Añadir
    </Button>
  )
}

export { AddButton }
