import React from 'react'
import { OrdersInTransitProvider } from '../contexts/OrdersInTransitContext'
import { SearchBar } from '../components/orders_in_transit/SearchBar'
import { Table } from '../components/orders_in_transit/Table'
import { InfoModalProvider } from '../contexts/OrdersInTransitInfoModalContext'
import { OrderInfoModal } from '../components/orders_in_transit/OrderInfoModal'

const OrdersInTransit = () => {
  return (
    <OrdersInTransitProvider>
      <div className='screen-traffic-per-shift-v2'>
        <h1>Pedidos en tránsito</h1>
        <InfoModalProvider>
          <OrderInfoModal />
        </InfoModalProvider>
        <SearchBar />
        <Table />
      </div>
    </OrdersInTransitProvider>
  )
}

export { OrdersInTransit }
