import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const TankCheckbox = () => {
  const context = useOrderAssignationContext()

  const handleCheck = (e, { checked }) => context.setCheckTankOne(checked)

  return (
    <div className='form-field'>
      <label>Placas TQ1:</label>
      <Checkbox
        label={ context.placasTanque }
        onChange={ handleCheck }
        checked={ context.checkTankOne }
      />
    </div>
  )
}

export { TankCheckbox }
