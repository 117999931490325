import React, { useState } from 'react'
import { Button, Dropdown, Form } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import resources from '../../resources'

const SearchBar = ({ search, isSearching }) => {

  const [[startDate, endDate], setDateRange] = useState([null, null])
  const [records, setRecords] = useState(50)
  const filterQuantity = resources.numbersToFilterByQuantity

  const handleDatePickerChange = value => setDateRange(value)
  const handleSelectOffset = (e, { value }) => setRecords(value)

  const handleClick = () => {
    search(startDate, endDate, records)
  }

  return (
    <div className='search-bar'>
      <div className='field'>
      <label>Fecha: </label>
        <Form size="mini">
          <DatePicker
            locale={ es }
            selectsRange={ true }
            startDate={ startDate }
            endDate={ endDate }
            onChange={ handleDatePickerChange }
            isClearable={ true }
          />
        </Form>
      </div>
      <div className='field'>
        <label className='fix'>Cantidad de resultados:</label>
        <Dropdown
          options={ filterQuantity }
          value={ records }
          onChange={ handleSelectOffset }
          fluid
          selection
          search
        />
      </div>
      <Button
        primary
        loading={ isSearching }
        disabled={ isSearching }
        onClick={ handleClick }
      >Buscar</Button>
    </div>
  )
}

export { SearchBar }
