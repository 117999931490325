import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const ClientInput = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Cliente/Planta:</label>
      <Input
        placeholder="Cliente/Planta"
        disabled
        value={ context.cliente }
      />
    </div>
  )
}

export { ClientInput }
