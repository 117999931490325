import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const FilterButton = () => {
  const context = useOrderAssignationContext()

  return (
    <Button
      primary
      loading={ context.isWorking }
      onClick={ context.search }
      disabled={ context.isWorking }
    >
      Filtrar
    </Button>
  )
}

export { FilterButton }
