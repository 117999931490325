const getImages = () => ({
  ergonLogo: require('../images/logo.png'),
  inrouteLogo: require('../images/inrouteLogo.png'),
  menu: require('../images/menu.png'),
  planta: require('../images/planta.png'),
  marker: require('../images/marker.png'),
  imageNotFound: require('../images/imageNotFound.png'),
  loadingImage: require('../images/ergon-logo-white.png')
})

export { getImages }
