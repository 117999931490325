import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../helpers/input'
import { PlantsDropdown } from '../PlantsDropdown'

const ClientDropdown = () => {
  const context = useAuthorizedFreightsContext()

  const clientLabel = () => context.isInternal ? 'Planta:' : 'Cliente:'

  const InputHandler = createInputValueHandler(context.setClientId)

  return (
    <div className='form-field'>
      <label>{ clientLabel() }</label>
      {
        context.isInternal ?
          <PlantsDropdown
            value={ context.clientId }
            setSelectedPlant={ context.setClientId }
            className='value'
          /> :
          <Dropdown
            search
            selection
            value={ context.clientId }
            options={ context.clientData }
            onChange={ InputHandler }
            className='value'
            placeholder='Seleccione un cliente...'
          />
      }
    </div>
  )
}

export { ClientDropdown }
