import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'

const VerifyButton = () => {
  const context = useUploadEvidenceContext()

  return (
    <Button
      primary
      loading={ context.isWorking }
      onClick={ context.verifySession }
    >
      Verificar
    </Button>
  )
}

export { VerifyButton }
