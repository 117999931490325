import React, { useEffect, useState } from 'react'
import { useAuthorizedStaysLogContext } from '../../contexts/AuthorizedStaysLogContext'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'remision',               title: 'Remisión' },
  { path: 'cliente',                title: 'Cliente' },
  { path: 'producto',               title: 'Producto' },
  { path: 'representanteComercial', title: 'Representante comercial' },
  { path: 'fechaCompromiso',        title: 'Fecha entrega' },
  { path: 'transportista',          title: 'Transportista' },
  { path: 'economico',              title: 'Económico' }
]

const searchBy = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useAuthorizedStaysLogContext()

  const [reload, setReload] = useState(false)
  const [orderedData, setOrderedData] = useState([])

  useEffect(() => {
    setOrderedData(context.authorizations)
  }, [context.authorizations])

  const onClickHeader = (d, e) => {
    const orderedData = filterdata(d, e, reload)
    context.setAuthorizations(orderedData)
    setReload(!reload)
  }

  return (
    <div className="table-container">
      <StyledTable
        headers={ headers }
        searchBy={ searchBy }
        headerSearchBy={ headerSearchBy }
        data={ context.authorizations || [] }
        handleOnClickRow={ context.setSelectedAuthorization }
        handleOnClickHeader={e => onClickHeader(orderedData, e) }
      />
    </div>
  )
}

export { Table }
