import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const CommitmentDateInput = () => {
  const context = useOrderAssignationContext()

  const handleDate = (_, { value }) => {
    context.setFechaCompromiso(value)
    context.setEditOffset(true)
  }

  return (
    <div className="form-field">
      <label>Fecha planeada de entrega:</label>
      <Input
        placeholder="Fecha planeada de entrega"
        type="datetime-local"
        value={ context.fechaCompromiso }
        onChange={ handleDate }
      />
    </div>
  )
}

export { CommitmentDateInput }
