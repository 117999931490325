import React from 'react'
import { Radio } from 'semantic-ui-react'

function renderTheRadioButtons(options, onSelectoption,labelKey,selectedValue,disabled) {
  let id =0
  return options.map(option => {
    id++
    return (
      <Radio
        disabled={ disabled }
        key={ id }
        label={ option[labelKey] }
        checked={ selectedValue ? selectedValue.id === option.id : false }
        onChange={ () => onSelectoption(option) }
        style={ { marginLeft: 5,marginBottom:5,marginTop:0,fontSize: 12 } }
      />
    )
  })
}

const getStyle = (orientation, customStyle=null) => {
  if (orientation ==='vertical') return styles.mainContainerVertical
  if (customStyle) return customStyle
  return styles.mainContainer
}

const RadioButtonGroup = ({
  options,
  orientation,
  onSelectoption,
  labelKey,
  selectedValue,
  disabled,
  customStyle
}) => {
  return (
    <div className='checkbox-group' style={ getStyle(orientation, customStyle) } >
      {
        renderTheRadioButtons(options, onSelectoption, labelKey, selectedValue, disabled)
      }
    </div>
  )
}

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  mainContainerVertical:
  {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}

export { RadioButtonGroup }
