import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { StyledTable } from '../StyledTable'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'
import { LinkDownloadChecklist } from './LinkDownloadChecklist'

const headers = [
  { path: 'fecha',     title: 'Fecha' },
  { path: 'checklist', title: 'Checklist' }
]

const ModalLastChecklists = () => {
  const context = useParkedUnitsContext()
  const { credentials } = useSelector(state => state.auth)

  const getData = () => (
    context.checklists.map(c => ({
      fecha: c.fecha,
      checklist: <LinkDownloadChecklist id={ c.idNavixy } />
    }))
  )

  const close = () => {
    context.setShowModal(false)
    context.setIsWorking(false)
  }

  return (
    <Modal show={ context.showModal } onHide={ () => {} } centered>
      <Modal.Body>
        <Icon name="close" onClick={ close } />
        <div className="parked-units-checklists-modal">
          <h4>Checklist</h4>
          <StyledTable
            search={ false }
            headers={ headers }
            token={ credentials?.token }
            data={ getData() }
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ModalLastChecklists }
