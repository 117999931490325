import React from 'react'
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  TextArea
} from 'semantic-ui-react'
import { ImageContainer } from '../ImageContainer'
import { RadioButtonGroup } from '../RadioButtonGroup'
import { DriverNavixyForm } from './DriverNavixyForm'

const DriverForm = ({
  driverInfo=null,
  setDriverInfo=()=>{},
  setSinGps=()=>{},
  sinGps=false,
  status=null,
  navixyDrivers=[],
  selectedNavixyDriver=null,
  onSelectNavixyDriver=()=>{},
  driverEnterprises=[],
  vehicles=[],
  sendingData=false,
  cancelChanges=()=>{},
  onDelete=()=>{},
  onClick=()=>{}
}) => {

  const onChange = (key, value) => {
    const driver = { ...driverInfo, [key]: value }
    setDriverInfo(driver)
  }

  const hasFoundUnit = () => (
    vehicles.find(v => v?.key === driverInfo.unidad)?.key || ''
  )

  return (
    <div className="form-container">
      <div className="form-line">
        <div className="image-container">
          <ImageContainer
            source={ driverInfo.imagenFile || driverInfo.imagenBase64 }
            onChangeImage={ file => onChange('imagenFile', file) }
          />
          <div className="form-line">
            <span className="subtitle-text-status"> Estado: </span>
            <RadioButtonGroup
              options={ status }
              selectedValue={ driverInfo.estado }
              onSelectoption={ value => onChange("estado", value) }
              labelKey="nombre"
            />
          </div>
        </div>
        <div className="form-style">
          <Form size="mini">
            <div className="form-line">
              <span className="subtitle-text"> Nombre Completo: </span>
              <Input
                value={ driverInfo.nombre }
                onChange={ (e, { value }) => onChange("nombre", value) }
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text"> Para unidad sin gps: </span>
              <Checkbox
                checked={ driverInfo.sinGps }
                onChange={ () => {
                  setSinGps(!driverInfo.sinGps)
                  onChange('sinGps', !driverInfo.sinGps)
                }}
              />
            </div>
            {
              !driverInfo.sinGps ? (
                <DriverNavixyForm
                  driverInfo={ driverInfo }
                  navixyDrivers={ navixyDrivers }
                  selectedNavixyDriver={ selectedNavixyDriver }
                  onSelectNavixyDriver={ onSelectNavixyDriver }
                />
              ) : (<></>)
            }
            <div className="form-line">
              <span className="subtitle-text"> Transportista: </span>
              <Dropdown
                options={ driverEnterprises }
                fluid
                selection
                search
                value={ driverInfo.transportista }
                onChange={ (e, data) => onChange("transportista", data.value) }
                labelkey="alias"
                placeholder="Seleccione un transportista"
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text"> Económico: </span>
              <Dropdown
                options={ vehicles }
                fluid
                selection
                search
                value={ hasFoundUnit() }
                onChange={ (_, { value }) => onChange("unidad", value) }
                labelkey="economico"
                placeholder="Seleccione una unidad"
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text">Celular: </span>
              <Input
                value={ driverInfo.celular }
                onChange={ (e, { value }) => onChange("celular", value) }
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text">Licencia: </span>
              <Input
                value={ driverInfo.licencia }
                onChange={ (e, { value }) => onChange("licencia", value) }
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text">Vigencia: </span>
              <Input
                type="date"
                value={ driverInfo.vigenciaLicencia }
                onChange={ (e, { value }) => onChange("vigenciaLicencia", value) }
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text"> Apto médico: </span>
              <Input
                value={ driverInfo.aptoMedico }
                onChange={ (e, { value }) => onChange("aptoMedico", value)}
              />
            </div>
            <div className="form-line">
              <span className="subtitle-text"> Notas : </span>
              <TextArea
                value={ driverInfo.notas }
                onChange={ event => onChange("notas", event.target.value) }
              />
            </div>
          </Form>
        </div>
      </div>

      <div className="buttons-container">
        {
          driverInfo.id ? (
            <Button
              color="grey"
              loading={ sendingData }
              className="button-action"
              onClick={ cancelChanges }
            >
              Cancelar cambios
            </Button>
          ) : (<></>)
        }
        {
          driverInfo.id ? (
            <Button
              color="red"
              loading={ sendingData }
              className="button-action"
              onClick={ () => onDelete(true) }
            >
              Eliminar
            </Button>
          ) : (<></>)
        }
        <Button
          primary
          loading={ sendingData }
          className="button-action"
          onClick={ onClick }
        >
          { driverInfo.id ? 'Guardar cambios' : 'Guardar' }
        </Button>
      </div>
    </div>
  )
}

export { DriverForm }
