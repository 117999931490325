import React from 'react'
import { Button } from "semantic-ui-react"
import { Modal } from 'react-bootstrap'

export const CustomModalToFinishOrder = ({ showModal, handleOnClickConfirm, handleOnClickCancel}) => {
  return (
    <Modal centered enforceFocus show={showModal} onHide={()=> handleOnClickCancel()}>
      <Modal.Header closeButton>
        <Modal.Title>Terminación de pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Esta seguro que desea terminar este pedido?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="grey"
          onClick={ () => handleOnClickCancel()}
          style={{ fontSize: 12 }}
        >
          Cancelar
        </Button>
        <Button onClick={() => handleOnClickConfirm()} color={"red"} style={{ fontSize: 12 }}>
          Confirmar terminación
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
