import React from 'react'
import { PlantsDropdown } from '../../../../PlantsDropdown'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'

const Origin = () => {
  const context = useAuthorizedFreightsContext()

  return (
    <div className='form-field'>
      <label>Seleccione origen:</label>
      <PlantsDropdown
        value={ context.originId }
        setSelectedPlant={ context.setOriginId }
      />
    </div>
  )
}

export { Origin }
