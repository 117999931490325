import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'

const ChangeUnitGeneralDataModal = () => {
  const context = useChangeUnitsContext()

  return (
    <Form
      size='mini'
      className='change-base-form'
    >
      <div>
        <label className='label-disabled'>Económico</label>
        <Input
          disabled
          value={ context.selectedUnit.economico }
        />
      </div>
      <div>
        <label className='label-disabled'>Transportista</label>
        <Input
          disabled
          value={ context.selectedUnit.transportistaNombre }
        />
      </div>
      <div>
        <label className='label-disabled'>Estatus actual</label>
        <Input
          disabled
          value={ context.selectedUnit.estadoUnidadNombre }
        />
      </div>
    </Form>
  )
}

export { ChangeUnitGeneralDataModal }
