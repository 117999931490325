import React from 'react'
import { ClientUnitModal } from '../components/order_crud/ClientUnitModal'
import { ConfirmDestroyModal } from '../components/order_crud/ConfirmDestroyModal'
import { ConfirmEditModal } from '../components/order_crud/ConfirmEditModal'
import { ConfirmUnassignModal } from '../components/order_crud/ConfirmUnassignModal'
import { OrderForm } from '../components/order_crud/OrderForm'
import { SearchBar } from '../components/order_crud/SearchBar'
import { Table } from '../components/order_crud/Table'
import { OrderProvider } from '../contexts/OrderContext'

const OrderCRUD = () => {
  return (
    <OrderProvider>
      <div className='screen-order-crud'>
        <h1>Registro de pedidos</h1>
        <div className='content'>
          <OrderForm />
          <div className='list'>
            <SearchBar />
            <Table />
          </div>
        </div>
        <ClientUnitModal />
        <ConfirmEditModal />
        <ConfirmDestroyModal />
        <ConfirmUnassignModal />
      </div>
    </OrderProvider>
  )
}

export { OrderCRUD }
