import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const ClientDropdown = () => {
  const context = useOrderContext()

  const [clients, setClients] = useState(context.clients)

  useEffect(() => {
    if (context.externo) return setClients(context.clients)
    setClients(context.plants)
  }, [context.externo, context.clients, context.plants])

  const canEdit = () => (
    !context.isEditing || (context.isEditing && !context.asignado)
  )

  const changeConfirmation = (_, { value }) => {
    if (context.detalles.length){
      context.setAction({ action: handleSelectClient, param: value })
      return context.setShowConfirmEdit(true)
    }
    handleSelectClient(value)
  }

  const getClientLabel = () => context.externo ? 'Cliente:' : 'Planta:'
  const getClientPlaceholder = () => (
    context.externo ? 'Seleccione cliente' : 'Seleccione planta'
  )

  const handleSelectClient = (value) => {
    context.setClienteId(value)
    context.loadDestination(value, !context.externo)
    context.loadLegalNames(value, !context.externo)
  }

  return (
    <div className="form-field">
      <label>{ getClientLabel() }</label>
      <Dropdown
        placeholder={ getClientPlaceholder() }
        fluid
        selection
        search
        disabled={ !canEdit() }
        options={ clients }
        value={ context.clienteId }
        onChange={ changeConfirmation }
      />
    </div>
  )
}

export { ClientDropdown }
