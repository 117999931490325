import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const RealShipmentDateInput = () => {
  const context = useOrderAssignationContext()

  const handleDate = (_, { value }) => {
    context.setFechaCargaReal(value)
    context.setEditOffset(true)
  }
  return (
    <div className="form-field">
      <label>Fecha de carga real:</label>
      <Input
        placeholder="Fecha de carga real"
        type="datetime-local"
        value={ context.fechaCargaReal }
        onChange={ handleDate }
      />
    </div>
  )
}

export { RealShipmentDateInput }
