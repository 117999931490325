import React from 'react'
import { Header } from 'semantic-ui-react'
import { ShiftInput } from './ShiftInput'

const Day = ({ day }) => {
  return (
    <div className='day-container'>
      <Header>{ day.dayName }</Header>
      <ShiftInput shift={ day.morningShift } day={ day.day } />
      <ShiftInput shift={ day.eveningShift } day={ day.day } />
      <ShiftInput shift={ day.nightShift } day={ day.day } />
    </div>
  )
}

export { Day }
