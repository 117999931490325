import React from 'react'
import { IncidencesForm } from './order_incidences/IncidencesForm'
import { MailingListModal } from './order_incidences/MailingListModal'
import { SaveButton } from './order_incidences/SaveButton'
import { SaveConfirmationModal } from './order_incidences/SaveConfirmationModal'
import { Table } from './order_incidences/Table'

const OrderIncidences = () => {
  return (
    <div className='orders-in-transit-incidences-tab'>
      <h3>Incidencias de viaje</h3>
      <Table/>
      <IncidencesForm/>
      <SaveButton/>
      <SaveConfirmationModal/>
      <MailingListModal/>
    </div>
  )
}

export { OrderIncidences }
