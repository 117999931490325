import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const ParkingArrivalDate = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    context.stayInfo.entradaPatio || 'Sin entrada registrada'
  )

  return (
    <div className="form-field">
      <label>Entrada a patio:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { ParkingArrivalDate }
