import React from 'react'
import { ScheduleProvider } from '../contexts/ScheduleContext'
import { SideHeaders } from '../components/schedule/schedule_form/SideHeaders'
import { SaveButton } from '../components/schedule/SaveButton'
import { DaySchedule } from '../components/schedule/DaySchedule'
import { PlantDropdown } from '../components/schedule/PlantDropdown'

const Schedule = () => {
  return (
    <ScheduleProvider>
      <div className='screen-schedule'>
        <h1>Turnos</h1>
        <div className='content'>
          <PlantDropdown />
          <div className='schedule-container'>
            <SideHeaders />
            <DaySchedule />
          </div>
          <SaveButton />
        </div>
      </div>
    </ScheduleProvider>
  )
}

export { Schedule }
