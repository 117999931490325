import React from 'react'
import { Button } from 'semantic-ui-react'
import { useFreightsConsultContext } from '../../../../contexts/FreightsConsultContext'

const SearchButton = () => {
  const context = useFreightsConsultContext()

  return (
    <div className='search-container'>
      <Button
        primary
        loading={ context.isWorking }
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchButton }
