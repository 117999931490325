import React from 'react';
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const ListOfActions = ({ actions }) => {
  const history = useHistory()

  return actions.map(action => (
    <Button
      key={ action.id }
      onClick={ () => history.push(action.route) }
      fluid
      className='main-menu-button'
    >
      { action.name }
    </Button>
  ))
}

export default ListOfActions
