import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const KmInput = () => {
  const context = useOrderAssignationContext()

  const handleKm = createInputValueHandler(context.setKmTraslado)

  return (
    <div className="form-field">
      <label>Km traslado:</label>
      <Input
        placeholder="KM Traslado"
        type="number"
        step={ 0.1 }
        min={ 0.0 }
        value={ context.kmTraslado }
        onChange={ handleKm }
      />
    </div>
  )
}

export { KmInput }
