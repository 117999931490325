import React from "react"
import { Alert } from "react-bootstrap"

const NavixyConfigMetter = ({drivers=[], navixyDrivers=[]}) => {

  const withGps = () => drivers.filter(d => !d?.sinGps)

  const calcPercent = () => (
    Math.round((withGps().length / navixyDrivers.length) * 100)
  )
  const labelMessage = () => (
    `Operadores configurados ${withGps().length}/${navixyDrivers.length} ${calcPercent()}%`
  )

  return (
    <Alert
      className="navixy-config-metter"
      variant={ "warning" }
    >
      { labelMessage() }
    </Alert>
  )
}

export { NavixyConfigMetter }
