import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'
import { createInputValueHandler } from '../../../../helpers/input'

const AprovedStay = () => {
  const context = useOrdersInTransitStayContext()

  const handleAprovedStays = createInputValueHandler(context.setEstadiaAprobada)

  return (
    <div className="form-field">
      <label>Estadía aprobada:</label>
      <Input
        value={ context.estadiaAprobada }
        onChange={ handleAprovedStays }
      />
      <label>días</label>
    </div>
  )
}

export { AprovedStay }
