import React from 'react'
import { StayClaimProvider } from '../contexts/StayClaimContext'
import { StayClaimSectionOne } from '../components/stay_claim/StayClaimSectionOne'
import { StayClaimSectionTwo } from '../components/stay_claim/StayClaimSectionTwo'
import { Form } from 'semantic-ui-react'

const StayClaim = () => {

  return (
    <StayClaimProvider>
      <div className='screen-stay-claim'>
        <h1>Reclamo de estadía</h1>
        <div className='content'>
          <Form>
            <StayClaimSectionOne />
            <StayClaimSectionTwo />
          </Form>
        </div>
      </div>
    </StayClaimProvider>
  )
}

export { StayClaim }
