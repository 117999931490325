import React from 'react'
import { usePlantsContext } from '../../../../contexts/PlantsContext'

const ConfiguredYards = () => {
  const context = usePlantsContext()
  return (
    <div className='configured-yards'>
      <label>Patios configurados: { context.yards.length } </label>
    </div>
  )
}

export { ConfiguredYards }
