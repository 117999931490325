import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { Button } from 'semantic-ui-react'

const DeleteButton = () => {
  const context = useTanksContext()

  const destroy = () => context.setShowConfirmDestroy(true)

  const canDestroy = () => context.isEditing

  return (
    <>
      {
        canDestroy() ?
        (
          <Button
            color='red'
            disabled={ context.isWorking }
            loading={ context.isWorking }
            onClick={ destroy }
          >
            Eliminar
          </Button>
        ) : (<></>)
      }
    </>
  )
}

export { DeleteButton }
