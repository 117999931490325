import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const UnitNameInput = ({ unitName = '', setUnitName = () => {} }) => {
  const unitNameHandler = createInputValueHandler(setUnitName)

  return (
    <div className="client-unit-modal-field">
      <label>Económico:</label>
      <Input
        value={ unitName }
        onChange={ unitNameHandler }
        placeholder="Económico"
      />
    </div>
  )
}

export { UnitNameInput }
