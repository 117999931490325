import React from 'react'
import { CleanButton } from './motives-form-actions/CleanButton'
import { SaveButton } from './motives-form-actions/SaveButton'
import { DestroyButton } from './motives-form-actions/DestroyButton'

const MotivesFormActions = () => {
  return(
    <div className='form-actions'>
      <CleanButton/>
      <DestroyButton/>
      <SaveButton/>
    </div>
  )
}

export { MotivesFormActions }
