import React from 'react'
import { useIncidencesContext } from '../../../contexts/IncidencesContext'
import { createInputValueHandler } from '../../../helpers/input'
import { Radio } from 'semantic-ui-react'

const Status = () => {
  const context = useIncidencesContext()
  const status = createInputValueHandler(context.setStatus)

  return (
    <div className='form-field'>
      <label>Estado:</label>
      <div className='radio-group'>
        <Radio
          label='Activo'
          value={ 1 }
          checked={ context.status === 1 }
          onChange={ status }
        />
        <Radio
          label='Inactivo'
          value={ 0 }
          checked={ context.status === 0 }
          onChange={ status }
        />
      </div>
    </div>
  )
}

export { Status }
