import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const ShipmentDateInput = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Fecha planeada de carga:</label>
      <Input
        placeholder="Fecha planeada de carga"
        type="datetime-local"
        value={ context.fechaCarga }
        disabled={ true }
      />
    </div>
  )
}

export { ShipmentDateInput }
