import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const CommerceRepresentantField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Representante comercial:</label>
      <label>{ context.selectedIncidence?.representanteComercial }</label>
    </div>
  )
}

export { CommerceRepresentantField }
