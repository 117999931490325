import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'
import { createInputValueHandler } from '../../../../helpers/input'

const ContainerWeightInput = () => {
  const context = useOrdersInTransitContext()
  const contextModal = useOrdersInTransitInfoModalContext()

  const handleInput = createInputValueHandler(contextModal.setTaraWeight)
  const getValue = () => contextModal.taraWeight
  const hasDetails = () => context.orderDetails.details?.length
  const isDisabled = () => !hasDetails()

  return (
    <>
      <div>Peso Tara:</div>
      <Input
        placeholder="Peso Tara"
        disabled={ isDisabled() }
        value={ getValue() }
        onChange={ handleInput }
      />
    </>
  )
}

export { ContainerWeightInput }
