import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { now } from '../helpers/date'
import { responseHandler, showErrorAlert } from '../helpers/notifications'
import { getGeneralInfo, saveAnObject } from '../services/ergonApi'
import { useOrdersInTransitContext } from './OrdersInTransitContext'

const OrdersInTransitIncidencesContext = createContext()

const OrdersInTransitIncidencesProvider = ({ children }) => {
  const screenContext = useOrdersInTransitContext()
  const { credentials } = useSelector(state => state.auth)

  const [incidences, setIncidences] = useState([])
  const [incidenceTypes, setIncidenceTypes] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [currentRow, setCurrentRow] = useState(-1)
  const [showMailingList, setShowMailingList] = useState(false)

  const [id,                 setId]                 = useState(0)
  const [fecha,              setFecha]              = useState(now())
  const [tipoIncidenciaId,   setTipoIncidenciaId]   = useState('')
  const [tipoIncidencia,     setTipoIncidencia]     = useState('')
  const [notas,              setNotas]              = useState('')
  const [afectaServicio,     setAfectaServicio]     = useState(false)
  const [noDeseado,          setNoDeseado]          = useState(false)
  const [toneladasFaltantes, setToneladasFaltantes] = useState(0)
  const [acciones,           setAcciones]           = useState('')
  const [emails,             setEmails]             = useState([])

  useEffect(() => {
    if (!credentials) return
    if (!screenContext.selectedOrder?.folio) return
    if (isEditing) return
    load()
    // eslint-disable-next-line
  }, [screenContext.selectedOrder.folio])

  const load = async () => {
    setIncidences(await getIncidences())
    setIncidenceTypes(await getIncidenceTypes())
  }

  const hasAccess = () => {
    if (!credentials) return false
    if (credentials.user.perfil.id === 3) return true
    return credentials.actions.some(a => a.id === 12)
  }

  const getOrderId = () => screenContext.selectedOrder?.folio || 0
  const getUrl = () => `/ordersInTransit/incidences/${getOrderId()}`

  const getIncidences = async () => {
    const response = await getGeneralInfo(credentials.token, getUrl())
    if (response.error || response.errorCode) {
      showErrorAlert(response.message)
      return []
    }
    return response
  }

  const getIncidenceTypes = async () => {
    const url = '/ordersInTransit/incidenceTypes/'
    const response = await getGeneralInfo(credentials.token, url)
    if (response.error || response.errorCode) {
      showErrorAlert(response.message)
      return []
    }
    return response
  }

  const fillForm = index => {
    const incidence = incidences[index]
    setCurrentRow(index)
    setId(incidence.id)
    setFecha(incidence.fecha)
    setTipoIncidenciaId(incidence.tipoIncidenciaId)
    setTipoIncidencia(incidence.tipoIncidencia)
    setNotas(incidence.notas || '')
    setAfectaServicio(incidence.afectaServicio)
    setNoDeseado(incidence.noDeseado)
    setToneladasFaltantes(incidence.toneladasFaltantes || 0)
    setAcciones(incidence.acciones || '')
    setEmails(incidence.emails || [])
    setIsEditing(true)
  }

  const incidencesNotEditing = () => (
    incidences.filter((_, index) => index !== currentRow)
  )

  const getIncidenceTypeText = () => (
    incidenceTypes.find(i => i.value === tipoIncidenciaId).text
  )

  const formatedDate = date => date.replace('T', ' ')

  const toNumber = value => {
    const number = Number(value)
    return isNaN(number) ? 0 : number
  }

  const add = () => {
    if (tipoIncidenciaId === '')
      return showErrorAlert('Seleccione el tipo de incidente')
    const newIncidence = {
      id,
      fecha: formatedDate(fecha),
      tipoIncidenciaId,
      tipoIncidencia: getIncidenceTypeText(),
      notas,
      afectaServicio,
      noDeseado,
      toneladasFaltantes: toNumber(toneladasFaltantes),
      acciones,
      emails
    }
    const incidencesFiltered = incidencesNotEditing()
    setIncidences([ ...incidencesFiltered, newIncidence ])
    clean()
  }

  const getSaveParams = () => (
    incidences.map(i => ({
      ...i,
      emails: i.emails?.join(';') || ''
    }))
  )

  const save = async () => {
    setShowSaveConfirmation(false)
    setIsWorking(true)
    const token = credentials.token
    const response = await saveAnObject(token, getUrl(), getSaveParams())
    responseHandler(response)
    setIsWorking(false)
  }

  const clean = () => {
    setCurrentRow(-1)
    setId(0)
    setFecha(now())
    setTipoIncidencia('')
    setTipoIncidenciaId('')
    setNotas('')
    setAfectaServicio(false)
    setNoDeseado(false)
    setToneladasFaltantes(0)
    setAcciones('')
    setEmails([])
    setIsEditing(false)
    setShowSaveConfirmation(false)
  }

  const context = {
    incidences,
    incidenceTypes,
    setIncidences,
    showMailingList,
    setShowMailingList,
    id,
    setId,
    fecha,
    setFecha,
    tipoIncidenciaId,
    setTipoIncidenciaId,
    tipoIncidencia,
    setTipoIncidencia,
    notas,
    setNotas,
    afectaServicio,
    setAfectaServicio,
    noDeseado,
    setNoDeseado,
    toneladasFaltantes,
    setToneladasFaltantes,
    acciones,
    setAcciones,
    emails,
    setEmails,
    isEditing,
    setIsEditing,
    isWorking,
    showSaveConfirmation,
    setShowSaveConfirmation,
    fillForm,
    add,
    save,
    hasAccess
  }

  return (
    <OrdersInTransitIncidencesContext.Provider value={ context }>
      { children }
    </OrdersInTransitIncidencesContext.Provider>
  )
}

const useOrdersInTransitIncidencesContext = () => (
  useContext(OrdersInTransitIncidencesContext)
)

export {
  OrdersInTransitIncidencesProvider,
  useOrdersInTransitIncidencesContext
}
