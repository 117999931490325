import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const IdInput = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Folio:</label>
      <Input
        placeholder="Folio"
        disabled
        value={ context.folio }
      />
    </div>
  )
}

export { IdInput }
