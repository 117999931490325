import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'

const FreightByTon = () => {
  const context = useOrdersInTransitContext()

  return (
    <div>
      <div>Flete x ton:</div>
      <div>
        { context.orderDetails.info.fleteCostoTonelada || 'Sin asignar' }
      </div>
    </div>
  )
}

export { FreightByTon }
