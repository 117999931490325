import React from 'react'
import { ClaimDownloadButton } from './ClaimDownloadButton'
import { SaveButton } from './SaveButton'
import { UploadButton } from './UploadButton'

const StayActions = () => {
  return (
    <div className="actions">
      <ClaimDownloadButton/>
      <UploadButton/>
      <SaveButton/>
    </div>
  )
}

export { StayActions }
