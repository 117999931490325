import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const FinishOrderButton = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const isFinishable = () => (
    contextModal.canFinishOrder() &&
    contextModal.hasFinishableStatus() &&
    !contextModal.isDeleted()
  )

  const onClick = () => contextModal.setShowModalToFinishOrder(true)

  if (!isFinishable()) return <></>
  return (
    <Button
      inverted
      color="red"
      onClick={ onClick }
    >
      Terminar tarea
    </Button>
  )
}

export { FinishOrderButton }
