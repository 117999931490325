import axios from 'axios'
import resources from '../resources'

const buildUrl = () => `${resources.urls.ergonApi}/stayClaim`

const buildHeaders = token => ({ headers: { Authorization: token } })

const getOrderInfo = async token => {
  const { data } = await axios.get(buildUrl(), buildHeaders(token))
  return data
}

const saveOrderClaim = async (token, params) => {
  const { data } = await axios.post(buildUrl(), params, buildHeaders(token))
  return data
}

export {
  getOrderInfo,
  saveOrderClaim
}
