import React from 'react'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

export const ModalToElimate = ({ showModal,title,textContent,deleting,saveConfirmation, handleOnClickConfirm , handleOnClickCancel}) =>{
  return (
      <Modal show={showModal} onHide={()=> handleOnClickCancel()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {textContent}
      </Modal.Body>
      <Modal.Footer>
      <Button
          color="grey"
          onClick={ () => handleOnClickCancel()}
          style={{ fontSize: 12 }}
        >
          Cancelar
          </Button>
        <Button loading={deleting} onClick={() => handleOnClickConfirm()} color={saveConfirmation ? "blue" : saveConfirmation === "cancelar" ? "gray" :"red"} style={{ fontSize: 12 }}>
          {saveConfirmation ? "Guardar" : saveConfirmation === "cancelar" ? "Cancelar" : "Eliminar"}
          </Button>
      </Modal.Footer>
    </Modal>
  )
}
