import React from 'react'
import { Input } from 'semantic-ui-react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'

const TankPlate = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setTankPlates)

  return (
    <div className='form-field'>
      <label>Placas de tanque:</label>
      <Input
        type='text'
        value={ context.tankPlates || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { TankPlate }
