import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const ExpirationCheckbox = () => {
  const context = useAuthorizedFreightsContext()

  const handleCheck = (_, { checked }) => (
    context.setOnlyExpired(checked)
  )

  return (
    <div className='expiration-container'>
      <label className='title'>Solo vigentes:</label>
      <Checkbox
        checked={ context.onlyExpired }
        onChange={ handleCheck }
        className='value'
      />
    </div>
  )
}

export { ExpirationCheckbox }
