import React from 'react'
import { handleChecklistDownload } from '../../../utils/common'

const DownloadTaskButton = ({ token, taskId, setSelectedTaskId }) => {

  const downloadTask = async () => {
    setSelectedTaskId(taskId)
    await handleChecklistDownload(token, taskId)
    setSelectedTaskId(null)
  }

  return (
    <p onClick={ downloadTask }>
      <strong>
        Descargar
      </strong>
    </p>
  )
}

export { DownloadTaskButton }
