import React from 'react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const TankPlates = () => {
  const context = useStayClaimContext()

  return (
    <div className='item'>
      <label>Placas tanque 1:</label>
      <label className='value'>
        { context.tankPlates || 'Sin asignar' }
      </label>
    </div>
  )
}

export { TankPlates }
