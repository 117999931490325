import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'

const IdInput = () => {
  const context = useOrderContext()

  return (
    <div className="form-field">
      <label>Folio:</label>
      <Input
        placeholder="Folio"
        disabled={ true }
        value={ context.folio }
      />
    </div>
  )
}

export { IdInput }
