import React from 'react'
import { Button } from 'semantic-ui-react'
import { EvidenceTable } from './EvidenceTable'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'

const ViewEvidenceHistory = () => {
  const context = useChangeUnitsContext()

  const showNoEvidenceText = () => (
    !context.evidenceHistoryData.length && !context.showButtonToLoadEvidences
  )

  return (
    <div className='evidence-history'>
      {
        context.evidenceHistoryData.length ? ( <EvidenceTable /> ) : ( <></> )
      }
      {
        context.showButtonToLoadEvidences ?
        (
          <div className='evidence-show-button'>
            <Button
              primary
              onClick={ context.unitEvidenceHistory }
              loading={ context.isWorking }
              className='evidence-button'
            >
              Cargar evidencias
            </Button>
          </div>
        )
        :
        ( <></> )
      }
      {
        showNoEvidenceText() ?
        ( <label className='no-evidence-history'>Sin evidencias</label> )
        :
        ( <></> )
      }
    </div>
  )
}

export { ViewEvidenceHistory }
