export default {
  numbersToFilterByQuantity : [
    {
      key: 50,
      text: '50',
      value: 50
    },
    {
      key: 100,
      text: '100',
      value: 100
    },
    {
      key: 200,
      text: '200',
      value: 200
    },
    {
      key: 500000,
      text: 'Todos',
      value: 500000
    }
  ],
  dateTypesDefault: [
    {
      key: 1,
      text: 'Fecha requerida',
      value: 1
    },
    {
      key: 3,
      text: 'Fecha de carga planeada',
      value: 3
    },
    {
      key: 6,
      text: 'Fecha de carga real',
      value: 6
    },
    {
      key: 4,
      text: 'Fecha de pedido',
      value: 4
    },
    {
      key: 5,
      text: 'Fecha asignada',
      value: 5
    },
    {
      key: 2,
      text: 'Fecha de entrega planeada',
      value: 2
    }
  ],
  headersToOrdersInTransitSearchBar: [
    { label: 'Turno',                           key: 'turno' },
    { label: 'Folio',                           key: 'folio' },
    { label: 'Cliente',                         key: 'cliente' },
    { label: 'Contacto',                        key: 'contacto' },
    { label: 'Remision',                        key: 'remision' },
    { label: 'Producto',                        key: 'producto' },
    { label: 'U. M',                            key: 'unidadMedida' },
    { label: 'Cant Prog Ton',                   key: 'cantRealTon' },
    { label: 'Cantidad Real Cargada',           key: 'cantidadRealCargada' },
    { label: 'Domicilio',                       key: 'destino' },
    { label: 'Transportista',                   key: 'transportista' },
    { label: 'Operador',                        key: 'chofer' },
    { label: 'Economico',                       key: 'economico' },
    { label: 'Cel Operador',                    key: 'CelOperador' },
    { label: 'Fecha Requerida',                 key: 'fechaRequerida' },
    { label: 'Fecha de carga planeada',         key: 'fechaCargaPlaneada' },
    { label: 'Fecha de carga real',             key: 'fechaCargaReal' },
    { label: 'Prog',                            key: 'programado' },
    { label: 'Estatus de carga',                key: 'estado' },
    { label: 'Fecha de entrega planeada',       key: 'fechaCompromiso' },
    { label: 'Fecha de entrega real',           key: 'fechaEntregaReal' },
    { label: 'Observaciones del embarque',      key: 'obsEmbarque' },
    { label: 'Nota flete',                      key: 'notaFlete' },
    { label: 'Temperatura llegada',             key: 'temLlegada' },
    { label: 'Fecha de solicitud de OV',        key: 'fechaSolicitud' },
    { label: 'Fecha registro',                  key: 'fechaRegistro' },
    { label: 'Fecha de salida de la refinería', key: 'fechaSalidaRefineria' },
    { label: 'Fecha de entrada a la planta',    key: 'fechaEntradaPlanta' },
    { label: 'Fecha de salida de la planta',    key: 'fechaSalidaPlanta' },
    { label: 'Kms traslado',                    key: 'kmsTraslado' },
    { label: 'Hrs traslado',                    key: 'hrsTraslado' }
  ]
}
