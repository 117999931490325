import React from 'react'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const DestinationInput = ({ destination = '', setDestination = () => {} }) => {
  const destinationHandler  = createInputValueHandler(setDestination)

  return (
    <div className="client-unit-modal-field">
      <label>Destino:</label>
      <Input
        value={ destination }
        onChange={ destinationHandler }
        placeholder="Destino"
      />
    </div>
  )
}

export { DestinationInput }
