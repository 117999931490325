const numberFormat = text => {
  if (!text) return text
  if (text.length <= 3) return text
  const decimals = text.toString().split('.')[1]
  return text
    .toString()
    .split('.')[0]
    .split('')
    .reverse()
    .join('')
    .replace(/.{3}/g, "$& ")
    .split('')
    .reverse()
    .join('') +
    (decimals ? `.${decimals}` : '')
}

export { numberFormat }
