import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const RealDeliverDateInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const getValue = () => contextModal.fechaEntregaReal || ''
  const handleInput = e => contextModal.setFechaEntregaReal(e.target.value)

  return (
    <div className="input">
      <div>Fecha de entrega real:</div>
      <Input
        type="datetime-local"
        value={ getValue() }
        onChange={ handleInput }
      />
    </div>
  )
}

export { RealDeliverDateInput }
