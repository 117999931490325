import React from 'react'
import {
  useInactivityReportContext
} from '../../../contexts/InactivityReportContext'

const PeriodDays = () => {
  const context = useInactivityReportContext()

  return (
    <div className='period-day-container'>
      <label className='title'>Días del periodo:</label>
      <label>{ context.periodDays } días</label>
    </div>
  )
}

export { PeriodDays }
