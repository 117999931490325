import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useInactivityReportContext
} from '../../../../contexts/InactivityReportContext'

const SearchButton = () => {
  const context = useInactivityReportContext()

  return (
    <div className='search-container'>
      <Button
        primary
        onClick={ context.search }
      >
        Buscar
      </Button>
    </div>
  )
}

export { SearchButton }
