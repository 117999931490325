import React from 'react'
import { useParkedUnitsContext } from '../../../contexts/ParkedUnitsContext'

const InputStatus = () => {
  const context = useParkedUnitsContext()

  return (
    <div className="form-field">
      <label>Estado:</label>
      <label>{ context.unitInfo.estado }</label>
    </div>
  )
}

export { InputStatus }
