import React from 'react'
import { useUploadEvidenceContext }
  from '../../../contexts/UploadEvidenceContext'
import { Input } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const AvailabilityDate = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createInputValueHandler(context.setAvailabilityDate)

  return (
    <div className="form-field">
      <label>Disponibilidad:</label>
      <Input
        type='datetime-local'
        value={ context.availabilityDate }
        onChange={ handleInput }
      />
    </div>
  )
}

export { AvailabilityDate }
