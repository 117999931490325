import React from 'react'
import { useStayClaimContext } from '../../../contexts/StayClaimContext'

const Order = () => {
  const context = useStayClaimContext()

  return (
    <div className='item'>
      <label>Pedido:</label>
      <label className='value-order'>
        { context.order || 'Sin asignar' }
      </label>
    </div>
  )
}

export { Order }
