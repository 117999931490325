import axios from 'axios'
import resources from '../resources'

const buidlUrl = (id = '') => `${resources.urls.ergonApi}/generalUnit/${id}`

const buildHeaders = token => ({ headers: { Authorization: token } })

const getById = async (token, id) => {
  const { data } = await axios.get(buidlUrl(id), buildHeaders(token))
  return data
}

const getTanks = async token => {
  const { data } = await axios.get(buidlUrl('tanks'), buildHeaders(token))
  return data
}

const create = async (token, params) => (
  await axios.post(buidlUrl(), params, buildHeaders(token))
)

const destroy = async (token, id) => (
  await axios.delete(buidlUrl(id), buildHeaders(token))
)

const update = async (token, id, params) => (
  await axios.put(buidlUrl(id), params, buildHeaders(token))
)

export { getById, getTanks, create, destroy, update }
