import axios from 'axios'
import resources from '../resources'

const buildUrl = (url = '') => (
  `${resources.urls.ergonApi}/freightsConsult${url}`
)

const buildHeaders = token => ({ headers: { Authorization: token } })

const getClients = async token => {
  const { data } = await axios.get(buildUrl('/clients'), buildHeaders(token))
  return data
}

const getFreights = async (params, token) => {
  const { data } = await axios.get(
    buildUrl(`?origenPlantaId=${params.origin}&esExterno=${params.isExternal}&clienteId=${params.client}`),
    buildHeaders(token)
  )
  return data
}

export {
  getClients,
  getFreights
 }
