import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'
import { createInputValueHandler } from '../../../../helpers/input'

const DateInput = () => {
  const context = useOrdersInTransitIncidencesContext()

  const dateHandler = createInputValueHandler(context.setFecha)

  return (
    <div className='form-field'>
      <label>Fecha:</label>
      <Input
        type="datetime-local"
        value={ context.fecha }
        onChange={ dateHandler }
      />
    </div>
  )
}

export { DateInput }
