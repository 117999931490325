import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { Button } from 'semantic-ui-react'

const CleanButton = () => {
  const context = useTanksContext()

  return (
    <>
      {
        context.isEditing ?
        (
          <Button
            color='grey'
            disabled={ context.isWorking }
            loading={ context.isWorking }
            onClick={ context.clean }
          >
            Cancelar cambios
          </Button>
        ) : (<></>)
      }
    </>
  )
}

export { CleanButton }
