import React, { useRef } from 'react'
import resources from '../resources'
import { Loader } from 'semantic-ui-react'


const ImageContainer = ({ source, name, onChangeImage, isLoading = false }) => {
  const fileInput = useRef(null)
  const imgRef    = useRef(null)

  function hanldeOnChange(e) {
    var reader = new FileReader()
    let file   = e.target.files[0]

    reader.onload = r => imgRef.current.src = reader.result

    reader.readAsDataURL(file)
    onChangeImage(file)
  }

  const hasImage = () => source || !isLoading

  const getSrc = () => source || resources.images.imageNotFound

  return(
    <div
      className='c-image-container'
      onClick={() => fileInput.current.click()}
    >
      {
        hasImage() ? <>
            <input
              id='c-image-container-input'
              ref={fileInput}
              type="file"
              accept="image/*"
              onChange={hanldeOnChange}
            />
            <img
              ref={ imgRef }
              src={ getSrc() }
              alt={name || 'Imagen'}
            />
          </>
        :
          <div className='c-image-container-loader' >
            <Loader active inline />
          </div>
      }
    </div>
  )
}

export { ImageContainer }
