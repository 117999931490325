import React from 'react'
import {
  useInactivityDetailReportContext
} from '../../../contexts/InactivityDetailReportContext'
import { ShipmentCompaniesDropdown } from '../../ShipmentCompaniesDropdown'

const Carrier = () => {
  const context = useInactivityDetailReportContext()

  return (
    <div className='carrier-container'>
      <label>Transportista: </label>
      <ShipmentCompaniesDropdown
        defaultOption
        value={ context.carrierId }
        setSelectedCompany={ context.setCarrierId }
      />
    </div>
  )
}

export { Carrier }
