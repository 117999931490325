import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const TankTwoCheckbox = () => {
  const context = useOrderAssignationContext()

  const handleCheck = (e, { checked }) => context.setCheckTankTwo(checked)

  const hasTank = () => !!context.placasTanqueDos

  return (
    <div className='form-field'>
      <label>Placas TQ2:</label>
      <Checkbox
        label={ context.placasTanqueDos }
        disabled={ !hasTank() }
        onChange={ handleCheck }
        checked={ context.checkTankTwo }
      />
    </div>
  )
}

export { TankTwoCheckbox }
