import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const SaveButton = () => {
  const context = useAuthorizedFreightsContext()

  const buttonLabel = () => context.isEditing ? 'Guardar cambios' : 'Añadir'

  const save = () => context.isEditing ? context.update() : context.save()

  return (
    <div className='save-button'>
      <Button
        primary
        loading={ context.isWorking }
        onClick={ save }
      >
        { buttonLabel() }
      </Button>
    </div>
  )
}

export { SaveButton }
