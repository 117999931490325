import React from "react"
import { ModalToElimate } from "../ModalToElimate"

const ModalDeleteConfirmation = ({
  visible=false,
  onConfirm=()=>{},
  onCancel=()=>{}
}) => {
  return (
    <ModalToElimate
      title="Confirmación para eliminar operador"
      textContent="Una vez eliminada la información no se podrá recuperar. ¿Está seguro que desea eliminar?"
      showModal={ visible }
      handleOnClickCancel={ onCancel }
      handleOnClickConfirm={ onConfirm }
    />
  )
}

export { ModalDeleteConfirmation }
