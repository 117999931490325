const fill = value => value < 10 ? `0${value}` : value

const dateNow = () => {
  const date    = new Date()
  const year    = date.getFullYear()
  const month   = fill(date.getMonth() + 1)
  const day     = fill(date.getDate())
  return `${year}-${month}-${day}`
}

const now = () => {
  const date    = new Date()
  const year    = date.getFullYear()
  const month   = fill(date.getMonth() + 1)
  const day     = fill(date.getDate())
  const hours   = fill(date.getHours())
  const minutes = fill(date.getMinutes())
  return `${year}-${month}-${day} ${hours}:${minutes}`
}

const toTimestamp = date => {
  const parts   = date.split(/[- T:]/)
  const year    = parseInt(parts[0])
  const month   = parseInt(parts[1]) - 1
  const day     = parseInt(parts[2])
  const hours   = parseInt(parts[3])
  const minutes = parseInt(parts[4])
  const dateObject = new Date(Date.UTC(year, month, day, hours, minutes, 0))
  return dateObject.getTime()
}

const dateGreaterThan = (date1, date2) => (
  toTimestamp(date1) > toTimestamp(date2)
)

const subtractTimezone = currentDate => {
  const date = new Date(currentDate)
  const zone = date.getTimezoneOffset() * 2
  return new Date(date.getTime() - (zone * 60 * 1000))
}

const dateToYYYYMMDD = date => {
  if (!date) return ''
  const year = date.getFullYear()
  const months = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${months}-${day}`
}

const dateToYYYYMMDDHHmm = date => {
  if (!date) return ''
  const year    = date.getFullYear()
  const months  = date.getMonth() + 1
  const day     = date.getDate()
  const hours   = fill(date.getHours())
  const minutes = fill(date.getMinutes())
  return `${year}-${months}-${day} ${hours}:${minutes}`
}

const hoursFormat = hours => {
  const capedHours = Math.floor(hours)
  const days = Math.floor(capedHours / 24)
  const remainingHours = capedHours % 24
  const daysText = days ? `${days} días` : ''
  const hoursText = `${remainingHours} hrs`
  return `${daysText} ${hoursText}`
}

const sumMonths = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  const year  = newDate.getFullYear()
  const month = completeTwoDigitFormat(newDate.getMonth() + 1)
  const day   = completeTwoDigitFormat(newDate.getDate())
  return `${year}-${month}-${day}`
}

const completeTwoDigitFormat = nn => {
  if (nn < 10) return '0' + nn
  return nn
}

export {
  dateNow,
  now,
  dateGreaterThan,
  subtractTimezone,
  dateToYYYYMMDD,
  dateToYYYYMMDDHHmm,
  hoursFormat,
  sumMonths
}
