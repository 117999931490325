import React from 'react'
import { Table } from '../components/no_availability_types_crud/Table'
import { MotivesForm } from '../components/no_availability_types_crud/MotivesForm'
import { MotivesProvider } from '../contexts/MotivesContext'
import { ConfirmDestroyModal } from '../components/no_availability_types_crud/ConfirmDestroyModal'

const NoAvailabilityTypesCrud = () => {
  return (
    <MotivesProvider>
      <div className='screen-no-availability-crud'>
        <h1>Motivos de no disponibilidad</h1>
        <div className='content'>
          <ConfirmDestroyModal/>
          <MotivesForm/>
          <Table/>
        </div>
      </div>
    </MotivesProvider>
  )
}

export { NoAvailabilityTypesCrud }
