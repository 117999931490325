import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../../contexts/AuthorizedFreightsContext'
import { createInputValueHandler } from '../../../../../helpers/input'

const Tolls = () => {
  const context = useAuthorizedFreightsContext()

  const InputHandler = createInputValueHandler(context.setTolls)

  return (
    <div className='form-field'>
      <label>Casetas:</label>
      <Input
        type='number'
        value={ context.tolls || '' }
        onChange={ InputHandler }
        placeholder='$ 0.00'
      />
    </div>
  )
}

export { Tolls }
