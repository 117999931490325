import React from 'react'
import { ChangeBaseForm } from './change_base_modal/ChangeBaseForm'
import { ChangeStatusForm } from './change_status_modal/ChangeStatusForm'
import { useChangeUnitsContext } from '../../../contexts/ChangeUnitsContext'
import {
  ViewEvidenceHistory
} from './change_evicende_history/ViewEvidenceHistory'

const TabsComponents = () =>  {
  const context = useChangeUnitsContext()

  const isSelected = tab => context.selectedTab === tab

  return (
    <>
      {
        isSelected('base') ? ( <ChangeBaseForm /> ) : ( <></> )
      }
      {
        isSelected('status') ? ( <ChangeStatusForm /> ) : ( <></> )
      }
      {
        isSelected('historial') ? ( <ViewEvidenceHistory /> ) : ( <></> )
      }
    </>
  )
}

export { TabsComponents }
