import React, { useState } from 'react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { StyledTable } from '../../StyledTable'
import { filterdata } from '../../../utils/common'

const headers = [
  { path: 'patioPlantaNombre', title: 'Patio'           },
  { path: 'geocercaNombre',    title: 'Nombre geocerca' },
  { path: 'button',            title: 'Eliminar'        }
]

const toSearch = headers.map(h => h.path)

const YardsTable = () => {
  const context = usePlantsContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleFilterData = e => {
    context.setYards(filterdata(context.yards, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const deleteYard = yardName => {
    const filteredYards = context.yards.filter(y => (
      y.patioPlantaNombre !== yardName
    ))
    context.setYards(filteredYards)
  }

  const renderButton = yardName => (
    <label
      id='delete-yard-button'
      onClick={ () => deleteYard(yardName) }
    >
      Eliminar
    </label>
  )

  const addDeleteButton = item => ({
    ...item,
    button: renderButton(item.patioPlantaNombre)
  })

  const withButton = () => {
    if (!context.yards.length) return []
    return context.yards.map(addDeleteButton)
  }

  return (
    <div className='yards-table'>
      <StyledTable
        search
        data={ withButton() }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleFilterData }
      />
    </div>
  )
}

export { YardsTable }
