import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const NoDesiredCheck = () => {
  const context = useOrdersInTransitIncidencesContext()

  const changeConfirmation = (_, { checked }) => (
    context.setNoDeseado(checked)
  )

  return (
    <div className='form-field'>
      <label>¿Operador no deseado?</label>
      <Checkbox
        onChange={ changeConfirmation }
        checked={ context.noDeseado }
      />
    </div>
  )
}

export { NoDesiredCheck }
