import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form,Input,Icon } from "semantic-ui-react";
import resources from "../resources";
import { signIn } from "../services/ergonApi";
import "../customStyles.css";
import '../style/Login.css';
import { showErrorAlert } from "../helpers/notifications";

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [hidenPassword, setHidenPassword] = useState(true);

  const doLogin = async () => {
    toggleLoading(true);
    let credentials = await signIn(email, password);
    if (credentials.errorCode)
      showErrorAlert(credentials.message)
    else {
      credentials = JSON.stringify(credentials);
      sessionStorage.setItem("credentials", credentials);
      history.push("/inicio");
      setEmail("");
      setPassword("");
    }
    toggleLoading(false);
  };

  return (
    <div className='screen-login'>
      <div className='inside-container'>
        <img
          src={resources.images.ergonLogo}
          alt="Logo Ergon"
          className='img-container'
        />
        <div className='form-container'>
          <Form>
            <Form.Field>
              <span>Correo electrónico</span>
              <input
                className='input-container'
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Form.Field>
            <Form.Field>
              <span>Contraseña</span>
              <Input
                icon={<Icon name={hidenPassword ? 'eye slash' : 'eye' }
                inverted circular link
                onClick={(e)=> setHidenPassword(!hidenPassword)}/>}
                type={hidenPassword ? "password" : 'text'}
                className='input-container'
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <Button
              loading={loading}
              primary
              className='button'
              fluid
              type="submit"
              onClick={() => doLogin()}
            >
              Ingresar
            </Button>
          </Form>
        </div>
      </div>
      <footer>
        <span className='version-text'>{resources.version}</span>
        <div className='powered-container'>
          <span>Powered by</span>
          <img
            src={resources.images.inrouteLogo}
            alt="Inroute Logo"
            className='powered-logo'
          />
        </div>
      </footer>
    </div>
  );
};

export { Login };
