import React from 'react'
import {
  IncidencesReportProvider
} from '../contexts/IncidencesReportContext'
import { SearchBar } from '../components/incidences_report/SearchBar'
import { Table } from '../components/incidences_report/Table'

const IncidencesReport = () => {
  return (
    <IncidencesReportProvider>
      <div className='screen-incidences-report'>
        <h1>Reporte de incidencias </h1>
        <SearchBar />
        <Table />
      </div>
    </IncidencesReportProvider>
  )
}

export { IncidencesReport }
