import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const UnitDropdown = () => {
  const context = useOrderAssignationContext()

  const [unitId, setUnitId] = useState('')
  const [units, setUnits] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setUnits(map(getUnits()))
    // eslint-disable-next-line
  }, [context.unidadId, context.parkedUnits])

  useEffect(() => {
    setUnitId(context.unidadId)
    if (loading) handleUnitType()
    handlePlates()
    // eslint-disable-next-line
  }, [units])

  useEffect(() => {
    if (context.unitType === 1)
      return setUnits(map(context.parkedUnits))
    if (context.unitType === 2)
      return setUnits(map(context.outsideUnits))
    setUnits(map(context.unitsWithoutGPS))
    // eslint-disable-next-line
  }, [context.unitType])

  const handleUnitType = () => {
    setLoading(false)
    const unitParked = context.parkedUnits.find(u => (
      u.value === context.unidadId
    ))
    if (unitParked) {
      setUnits(map(context.parkedUnits))
      return context.setUnitType(1)
    }
    const unitOutside = context.outsideUnits.find(u => (
      u.value === context.unidadId
    ))
    if (unitOutside) {
      setUnits(map(context.outsideUnits))
      return context.setUnitType(2)
    }
  }

  const handlePlates = () => {
    if (context.unidadId <= 0) return
    const unit = getUnits().find(u => u.value === context.unidadId)
    if (!unit) return
    context.setPlacasTanque(unit.placasTanque)
    context.setPlacasTanqueDos(unit.placasTanqueDos || '')
    context.setTanqueIdUno(unit.tanqueIdUno)
    context.setTanqueIdDos(unit.tanqueIdDos)
    context.setCheckTankOne(!!unit.tanqueIdUno)
    context.setCheckTankTwo(!!unit.tanqueIdDos)
  }

  const handleStatus = id => {
    const unit = getUnits().find(u => u.value === id)
    context.setShowUnitStateModal(unit?.estado === 2)
  }

  const handleUnit = (_, { value }) => {
    context.setUnidadId(value)
    handleStatus(value)
  }

  const map = array => array.map(i => ({
    key: i.key,
    text: i.text,
    value: i.value
  }))

  const getUnits = () => {
    if (context.unitType === 1) return context.parkedUnits
    if (context.unitType === 2) return context.outsideUnits
    return context.unitsWithoutGPS
  }

  return (
    <div className="form-field">
      <label>Unidad a asignar:</label>
      <Dropdown
        openOnFocus={ false }
        clearable
        placeholder="Seleccione una unidad"
        fluid
        selection
        search
        options={ units }
        value={ unitId }
        onChange={ handleUnit }
      />
    </div>
  )
}

export { UnitDropdown }
