import React from 'react'
import { SearchBar } from '../components/travels_log/SearchBar'
import { Table } from '../components/travels_log/Table'
import { TravelsLogProvider } from '../contexts/TravelsLogContext'

const TravelsLog = () => {
  return (
    <TravelsLogProvider>
      <h1>Reporte de viajes</h1>
      <div className="screen-travels-log">
        <SearchBar/>
        <Table/>
      </div>
    </TravelsLogProvider>
  )
}

export { TravelsLog }
