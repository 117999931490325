import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { useOrderContext } from '../../contexts/OrderContext'

const ConfirmEditModal = () => {
  const context = useOrderContext()

  const confirmHandler = () => {
    context.setShowConfirmEdit(false)
    context.setEditionAllowed(true)
    context.action.action(context.action.param)
    context.setDetalles([])
  }

  const cancelHandler = () => {
    context.setShowConfirmEdit(false)
  }

  return (
    <Modal show={ context.showConfirmEdit }>
      <Modal.Header closeButton>
        <Modal.Title>
          Confirmación para realizar cambios
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Una vez modificado este campo la lista de pedidos será eliminada
      </Modal.Body>
      <Modal.Footer>
      <Button
        color="grey"
        onClick={ cancelHandler }
      >
        Cancelar
      </Button>
      <Button
        onClick={ confirmHandler }
        color={"blue"}
      >
        Confirmar
      </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmEditModal }
