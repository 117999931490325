import React from 'react'
import { useUploadEvidenceContext } from '../../contexts/UploadEvidenceContext'
import { StyledTable } from '../StyledTable'

const headers = [ { path: 'evidencia', title: 'Evidencia' } ]

const Table = () => {
  const context = useUploadEvidenceContext()

  return (
    <StyledTable
      className='record-incidence-table'
      search={ false }
      headers={ headers }
      data={ context.evidencesData }
      handleOnClickRow={ context.onSelectEvidence }
    />
  )
}

export { Table }
