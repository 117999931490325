import React from 'react'
import { TextArea } from 'semantic-ui-react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const DetailInput = () => {
  const context = useOrdersInTransitStayContext()

  return (
    <div className="form-field multiline">
      <label>Observaciones:</label>
      <TextArea
        value={ context.stayInfo?.notas || '' }
        readOnly
      />
    </div>
  )
}

export { DetailInput }
