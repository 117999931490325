import React, { useEffect, useState } from 'react'
import { Label } from 'semantic-ui-react'
import {
  useOrderAssignationContext
} from '../../../contexts/OrderAssignationContext'

const FreightCosts = () => {
  const context = useOrderAssignationContext()

  const [freightByTon,   setFreightByTon  ] = useState(0)
  const [freightByTolls, setFreightByTolls] = useState(0)

  useEffect(() => {
    clearCosts()
  },[context.folio])

  useEffect(() => {
    if (context.freightId)
      clearCosts()
      findFreight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.freightId])

  const clearCosts = () => {
    setFreightByTon(0)
    setFreightByTolls(0)
  }

  const findFreight = () => {
    const found = context.freightsData.find(
      freight => freight.value === context.freightId
    )
    setFreightByTon(found?.costoTonelada || 0)
    setFreightByTolls(found?.costoCasetas || 0)
  }

  return (
    <div className='double-column'>
      <div className='form-field'>
        <label className='title'>Flete por tonelada:</label>
        <Label className='value'>$ { freightByTon }</Label>
      </div>
      <div className='form-field'>
        <label className='title'>Costo de casetas:</label>
        <Label className='value'>$ { freightByTolls }</Label>
      </div>
    </div>
  )
}

export { FreightCosts }
