import React from 'react'
import { Modal } from 'react-bootstrap'
import { Icon } from 'semantic-ui-react'
import { useIncidencesLogContext } from '../../contexts/IncidencesLogContext'
import { ActionsField } from './details_modal/ActionsField'
import { AplicationDateInput } from './details_modal/AplicationDateInput'
import { ClientField } from './details_modal/ClientField'
import {
  CommerceRepresentantField
} from './details_modal/CommerceRepresentantField'
import { CompromiseDateField } from './details_modal/CompromiseDateField'
import { CreditNoteDetailsInput } from './details_modal/CreditNoteDetailsInput'
import { CreditNoteInput } from './details_modal/CreditNoteInput'
import {
  CreditNoteQuantityInput
} from './details_modal/CreditNoteQuantityInput'
import { DestinationField } from './details_modal/DestinationField'
import { DriverField } from './details_modal/DriverField'
import { IdField } from './details_modal/IdField'
import { IncidenceDateField } from './details_modal/IncidenceDateField'
import { IncidenceTypeField } from './details_modal/IncidenceTypeField'
import { PlantField } from './details_modal/PlantaField'
import { ProductField } from './details_modal/ProductField'
import { QuantityLostField } from './details_modal/QuantityLostField'
import { RemissionField } from './details_modal/RemissionField'
import { ReportField } from './details_modal/ReportField'
import { SaveButton } from './details_modal/SaveButton'
import { ShipmentCompanyField } from './details_modal/ShipmentCompanyField'
import { UnitField } from './details_modal/UnitField'
import { WrongDriverField } from './details_modal/WrongDriverField'

const DetailsModal = () => {
  const context = useIncidencesLogContext()

  const handleClose = () => {
    context.setSelectedIncidence(null)
  }

  return (
    <Modal
      show={ !!context.selectedIncidence }
      size='xl'
      centered
      onHide={ () => {} }
    >
      <Modal.Body>
        <Icon name='close' onClick={ handleClose } />
        <div className="incidences-log-modal">
          <h3>Información de la incidencia</h3>
          <div className="row">
            <div className="col">
              <IdField/>
              <CommerceRepresentantField/>
              <PlantField/>
              <RemissionField/>
              <ClientField/>
              <ProductField/>
              <DestinationField/>
              <ShipmentCompanyField/>
              <DriverField/>
              <UnitField/>
              <CompromiseDateField/>
              <IncidenceDateField/>
              <IncidenceTypeField/>
              <ReportField/>
            </div>
            <div className="col">
              <CreditNoteInput/>
              <QuantityLostField/>
              <CreditNoteQuantityInput/>
              <CreditNoteDetailsInput/>
              <AplicationDateInput/>
              <WrongDriverField/>
              <ActionsField/>
            </div>
          </div>
          <SaveButton/>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { DetailsModal }
