import axios from 'axios'
import resources from '../resources'

const buidlUrl = (id = '') => `${resources.urls.ergonApi}/users/${id}`

const buildHeaders = token => ({
  headers: { Authorization: token }
})

const create = async (token, params) => (
  await axios.post(buidlUrl(), params, buildHeaders(token))
)

const update = async (token, params, id) => (
  await axios.put(buidlUrl(id), params, buildHeaders(token))
)

const destroyUser = async (token, id) => (
  await axios.delete(buidlUrl(id), buildHeaders(token))
)

export { create, update, destroyUser }
