import React from 'react'
import { Button } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../contexts/OrderAssignationContext'

const editableStatuses = [1, 4]
const unassignableStatuses = [4, 5]

const OrderAssignationFormActions = () => {
  const context = useOrderAssignationContext()

  const handleUnassign = () => context.setShowUnassignModal(true)
  const handleClean    = () => context.clean()
  const handleSave     = () => context.save()

  const canSave = () => (
    editableStatuses.includes(context.estadoId)
  )

  const canUnassign = () => (
    unassignableStatuses.includes(context.estadoId)
  )

  return (
    <div className="actions">
      {
        canUnassign() ?
          <Button
            inverted
            size="mini"
            color="red"
            onClick={ handleUnassign }
          >
            Desasignar operador
          </Button> : (<></>)
      }
      <Button
        color="grey"
        onClick={ handleClean }
      >
        Cancelar cambios
      </Button>
      <Button
        primary
        disabled={ !canSave() }
        onClick={ handleSave }
      >
        Guardar
      </Button>
    </div>
  )
}

export { OrderAssignationFormActions }
