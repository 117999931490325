import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const IncidenceTypeDropdown = () => {
  const context = useOrdersInTransitIncidencesContext()

  const incidenceTypeHandler = (_, { value }) => {
    context.setIsEditing(true)
    context.setTipoIncidenciaId(value)
  }

  return (
    <div className='form-field'>
      <label>Incidencia a reportar:</label>
      <Dropdown
        placeholder='Tipo de incidencia'
        fluid
        selection
        search
        options={ context.incidenceTypes }
        value={ context.tipoIncidenciaId }
        onChange={ incidenceTypeHandler }
      />
    </div>
  )
}

export { IncidenceTypeDropdown }
