import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'
import { createInputValueHandler } from '../../helpers/input'
import { PlantsDropdown } from '../PlantsDropdown'

const SearchBar = () => {
  const context = useParkedUnitsContext()

  const handleStatus = createInputValueHandler(context.setSelectedStatus)

  return (
    <div className="search-bar">
      <div className="form-field">
        <label>Planta:</label>
        <PlantsDropdown setSelectedPlant={ context.setSelectedPlant } />
      </div>
      <div className="form-field">
        <label>Estado:</label>
        <Dropdown
          fluid
          selection
          options={ context.statuses }
          value={ context.selectedStatus }
          onChange={ handleStatus }
        />
      </div>
    </div>
  )
}

export { SearchBar }
