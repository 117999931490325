import React from 'react'
import { useIncidencesLogContext } from '../../../contexts/IncidencesLogContext'

const PlantField = () => {
  const context = useIncidencesLogContext()

  return (
    <div className="field">
      <label>Planta:</label>
      <label>{ context.selectedIncidence?.planta }</label>
    </div>
  )
}

export { PlantField }
