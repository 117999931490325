import React from 'react'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'
import { Button } from 'semantic-ui-react'

const ChangeBaseActions = () => {
  const context = useChangeUnitsContext()
  return (
    <div className='actions'>
      {
        context.selectedUnit.esPrestada ?
          (
            <Button
              primary
              onClick={ context.returnsUnit }
              loading={ context.isLoading }
            >
              Devolver
            </Button>
          )
        :
          (
            <Button
              primary
              onClick={ context.lendUnit }
              loading={ context.isLoading }
            >
              Prestar
            </Button>
          )
      }
    </div>
  )
}

export { ChangeBaseActions }
