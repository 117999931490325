import React from 'react'
import { Plant } from './search_bar/Plant'
import { Dates } from './search_bar/Dates'
import {
  SearchButton
} from '../assignment_participation/search_bar/actions/SearchButton'


const SearchBar = () => {

  return (
    <div className='search-bar'>
      <Plant />
      <Dates />
      <SearchButton />
    </div>
  )
}

export { SearchBar }
