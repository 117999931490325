import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../contexts/OrdersInTransitContext'

const FreightName = () => {
  const context = useOrdersInTransitContext()

  return (
    <div>
      <div>Nombre de flete:</div>
      <div>{ context.orderDetails.info.fleteNombre || 'Sin asignar' }</div>
    </div>
  )
}

export { FreightName }
