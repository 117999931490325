import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const SaveButton = () => {
  const context = useOrdersInTransitStayContext()
  return (
    <Button
      primary
      loading={ context.isWorking }
      disabled={ context.isWorking || !context.selectedOrderDetail }
      onClick={ context.save }
    >
      Modificar estadía
    </Button>
  )
}

export { SaveButton }
