import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Form, TextArea } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const NoteOfMaintenance = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createInputValueHandler(context.setNoteOfMaintenance)

  return (
    <div className="big-container">
      <label>Nota de mantenimiento:</label>
      <Form>
        <TextArea
          type='text'
          value={ context.noteOfMaintenance }
          onChange={ handleInput }
          placeholder='Ingrese información de manteniento'
        />
      </Form>
    </div>
  )
}

export { NoteOfMaintenance }
