import React from 'react'
import { Icon } from 'semantic-ui-react'
import { FilterButton } from './search_bar/FilterButton'
import { PlantDropdown } from './search_bar/PlantDropdown'
import { RecordsDropdown } from './search_bar/RecordsDropdown'
import { StatsInfo } from './search_bar/StatsInfo'
import { StatusDropdown } from './search_bar/StatusDropdown'

const SearchBar = () => {
  return (
    <>
      <div className="filter-label">
        <Icon name="filter" />
        Filtrar por:
      </div>
      <div className="search-bar">
        <PlantDropdown/>
        <RecordsDropdown/>
        <FilterButton/>
        <StatusDropdown/>
      </div>
      <StatsInfo/>
    </>
  )
}

export { SearchBar }
