import React from 'react'
import { Icon } from 'semantic-ui-react'
import { NavixyIcon } from '../icons/NavixyIcon'

const TabIcon = ({ icon }) => {
  if (icon === 'navixy') return (<NavixyIcon/>)
  if (icon) return (<Icon name={ icon } />)
  return (<></>)
}

export { TabIcon }
