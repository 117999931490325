const getColors = () => ({
  lightGray: '#C9CAD9',
  strongGray: '#454955',
  green: '#9BB800',
  white: '#FFFFFF',
  blue: '#3993DD',
  textStrongGray: '#707070',
  primary: '#002a44',
  secondary: '#8095a2'
})

export { getColors }
