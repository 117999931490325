import React from 'react'
import { useOrdersInTransitContext } from '../../contexts/OrdersInTransitContext'
import { Button, Dropdown, Icon, Form } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { PlantsDropdown } from '../PlantsDropdown'
import { CSVLink } from 'react-csv'
import { ExcelLink } from '../ExcelLink'
import resources from '../../resources/ordersInTransit'
import { createInputValueHandler } from '../../helpers/input'
import { isSuperadmin } from '../../helpers/sessionCredentials'

const filterQuantity = resources.numbersToFilterByQuantity
const headers = resources.headersToOrdersInTransitSearchBar

const SearchBar = () => {
  const context = useOrdersInTransitContext()

  const toPrintFormat = () => {
    const superAdmin = isSuperadmin()
    return context.orders
      .filter(o => !o.borrado)
      .map(o => ({
        turno:                o.turno                 || 'Sin asignar',
        folio:                o.folio                 || 'Sin asignar',
        cliente:              o.cliente               || 'Sin asignar',
        contacto:             o.contacto              || 'Sin asignar',
        remision:             o.remision              || 'Sin asignar',
        producto:             o.producto              || 'Sin asignar',
        unidadMedida:         o.unidadMedida          || 'Sin asignar',
        cantRealTon:          o.cantidad,
        cantidadRealCargada:  o.cantidadReal,
        destino:              o.domicilio,
        transportista:        o.transportista,
        chofer:               o.operador              || 'Sin asignar',
        economico:            o.economico,
        CelOperador:          o.celularOperador       || 'Sin asignar',
        fechaRequerida:       o.fechaRequerida        || 'Sin asignar',
        fechaCargaPlaneada:   o.fechaCarga            || 'Sin asignar',
        fechaCargaReal:       o.fechaCargaReal        || 'Sin asignar',
        programado:           o.programado,
        estado:               o.estado                || 'Sin asignar',
        fechaCompromiso:      o.fechaCompromiso       || 'Sin asignar',
        fechaEntregaReal:     o.fechaEntregaReal      || 'Sin asignar',
        obsEmbarque:          o.observacionesEmbarque || '',
        temLlegada:           o.temperaturaLlegada    || '',
        notaFlete:            o.notaFlete             || '',
        fechaSolicitud:       superAdmin ? o.fechaSolicitud || 'Sin asignar' : '',
        fechaRegistro:        superAdmin ? o.fechaRegistro : '',
        fechaSalidaRefineria: superAdmin ? o.fechaSalidaRefineria : '',
        fechaEntradaPlanta:   superAdmin ? o.fechaEntradaPlanta : '',
        fechaSalidaPlanta:    superAdmin ? o.fechaSalidaPlanta : '',
        kmsTraslado:          superAdmin ? o.kmsTraslado || 'Sin asignar' : '',
        hrsTraslado:          superAdmin ? o.hrsTraslado || 'Sin asignar' : ''
      }))
  }

  const handleSelectOffset     = createInputValueHandler(context.setOffset)
  const handleSelectDateType   = createInputValueHandler(context.setDateType)
  const handleDatePickerChange = value => context.setDateRange(value)

  return (
    <div className="search-bar">
      <div><Icon name='filter'/>Filtrar por:</div>
      <div className="row">
        <span> Planta: </span>
        <Form size="mini" className='plants'>
          <PlantsDropdown
            setSelectedPlant={ context.setSelectedPlant }
            setAlias={ context.setPlantName }
          />
        </Form>
        <span> Folios: </span>
        <Form size="mini">
          <Dropdown
            fluid
            selection
            search
            clearable
            options={ filterQuantity }
            value={ context.offset }
            onChange={ handleSelectOffset }
          />
        </Form>
        <span>Fecha: </span>
        <Form size="mini">
          <div>
            <DatePicker
              selectsRange
              isClearable
              locale={ es }
              startDate={ context.startDate }
              endDate={ context.endDate }
              onChange={ handleDatePickerChange }
            />
          </div>
        </Form>
        <span className='space'></span>
        <Form size="mini">
          <div>
            <Dropdown
              clearable
              fluid
              selection
              search
              placeholder="Tipo de fecha"
              options={ context.dateTypes }
              value={ context.dateType }
              onChange={ handleSelectDateType }
            />
          </div>
        </Form>
        <Button
          primary
          loading={ context.isSearching }
          onClick={ context.search }
          disabled={ context.isSearching }
        >
          Buscar
        </Button>
        <div className='separator'></div>
        <div className="download-buttons-container">
            { context.orders.length ? (
                <div>
                  <CSVLink
                    headers={ headers }
                    enclosingCharacter={ `'` }
                    data={ toPrintFormat() }
                    className="downloadFileButton"
                    filename={ `Pedidos en transito de ${context.plantName}.csv` }
                    target="_blank"
                    >
                    <Icon name="file alternate" />
                    Descargar CSV
                  </CSVLink>
                  <ExcelLink
                    headers={ headers }
                    data={ toPrintFormat() }
                    token={ context.credentials.token }
                    className="downloadFileButton"
                    filename={ `Pedidos en transito de ${context.plantName}.xlsx` }
                  >
                    <Icon name="file alternate" />
                    Descargar XLS
                  </ExcelLink>
                </div>
              ) : (<></>)
            }
          </div>
      </div>
    </div>
  )
}

export { SearchBar }
