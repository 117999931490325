import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'

const ProductDropdown = ({ productId = '', setProductId = () => {} }) => {
  const context = useOrderContext()

  const productSelectHandler = createInputValueHandler(setProductId)

  return (
    <div className="form-field">
      <label>Producto:</label>
      <Dropdown
        placeholder="Selecciona producto"
        fluid
        selection
        search
        options={ context.products }
        value={ productId }
        onChange={ productSelectHandler }
      />
    </div>
  )
}

export { ProductDropdown }
