import React from 'react'
import {
  DateNoAvailability
} from './no_availability_evidence_form/DateNoAvailability'
import { Unit } from './no_availability_evidence_form/Unit'
import {
  TankPlates
} from './no_availability_evidence_form/TankPlates'
import {
  TankPlatesTwo
} from './no_availability_evidence_form/TankPlatesTwo'
import { Type } from './no_availability_evidence_form/Type'
import { Note } from './no_availability_evidence_form/Note'
import {
  AvailabilityDate
} from './no_availability_evidence_form/AvailabilityDate'
import {
  MaintenanceType
} from './no_availability_evidence_form/MaintenanceType'
import {
  AreaToIntervene
} from './no_availability_evidence_form/AreaToIntervene'
import { BugReview } from './no_availability_evidence_form/BugReview'
import {
  NoteOfMaintenance
} from './no_availability_evidence_form/NoteOfMaintenance'

const UploadEvidenceFormSectionOne = () => {
  return (
    <div className='form-section-one'>
      <h1>Evidencia de no disponibilidad</h1>
      <DateNoAvailability />
      <Unit />
      <TankPlates />
      <TankPlatesTwo />
      <Type />
      <Note />
      <AvailabilityDate />
      <MaintenanceType />
      <AreaToIntervene />
      <BugReview />
      <NoteOfMaintenance />
    </div>
  )
}

export { UploadEvidenceFormSectionOne }
