import React from 'react'
import { UsersContextProvider } from '../contexts/UsersContext'
import { Table } from '../components/users/Table'
import { UsersForm } from '../components/users/UsersForm'

const Users = () => {
  return (
    <UsersContextProvider>
      <h1>Usuarios</h1>
      <div className='screen-users'>
        <UsersForm  />
        <Table />
      </div>
    </UsersContextProvider>
  )
}

export { Users }
