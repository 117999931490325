import React from 'react'

const FreightTollsCost = ({ order }) => {
  return (
    <div className='single-row'>
      <label>Costo de casetas:</label>
      <label>{ order?.fleteCostoCasetas || 'Sin asignar' }</label>
    </div>
  )
}

export { FreightTollsCost }
