import React from 'react'
import {
  InactivityDetailReportProvider
} from '../contexts/InactivityDetailReportContext'
import { Icon } from 'semantic-ui-react'
import { Table } from '../components/inactivity_detail_report/Table'
import { SearchBar } from '../components/inactivity_detail_report/SearchBar'
import {
  XLSDownloadButton
} from '../components/inactivity_detail_report/actions/XLSDownloadButton'

const InactivityDetailReport = () => {
  return (
    <InactivityDetailReportProvider>
      <div className='screen-inactivity-detail-report'>
        <h1>Reporte de inactividad detallado</h1>
        <XLSDownloadButton />
        <div className='content'>
          <div><Icon name='filter'/>Filtrar por:</div>
          <SearchBar />
          <Table />
        </div>
      </div>
    </InactivityDetailReportProvider>
  )
}

export { InactivityDetailReport }
