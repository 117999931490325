import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Input } from 'semantic-ui-react'
import { filterArrayByText } from '../utils/common'
import '../customStyles.css'
import _ from 'lodash'

//lodash get
const StyledTable = (params) => {

  const {
    enableMap=false,
    handleOnMap = () => { },
    headers = [], search = true,
    searchBy = ['nombre'],
    headerSearchBy = [],
    data = [],
    handleOnClickRow = () => { },
    handleOnClickHeader = () => { },
    enableDelete = false,
    enableDownloadPDF = false,
    handleOnDelete = () => { },
    handleDownloadPDF = () => {},
    fontSize= null,
    truncate=false
  } = params

  const [filterData, setFilterData] = useState(null)
  const [ textFilter, setTextFilter] = useState('')

  useEffect( ()=> {
    setTextFilter('')
    setFilterData(data)
  },[data])

  const renderHeaders = () => {
    if (Array.isArray(headers)) {
      let id = 0
      if (enableDownloadPDF && headers.find(o => o.path === 'descargarPDF') === undefined ) {
        headers.push( { path: 'descargarPDF', title: 'Documento PDF' } )
      }
      if (enableDelete && headers.find(o => o.path === 'Eliminar') === undefined ) {
        headers.push( { path: 'Eliminar', title: 'Eliminar' } )
      }
      if (enableMap && headers.find(o => o.path === 'Ubicacion') === undefined ) {
        headers.push( { path: 'Ubicacion', title: 'Mapa' } )
      }
      return headers.map(
        header => {
          id++
          return (
            <th
              key={ id }
              path={ id }
              onClick={ () => handleOnClickHeader(header.path) }
              style={ {
                backgroundColor: '#3E3E3E',
                color: 'white',
                position: 'sticky',
                top: 0,
                padding:6,
                fontSize: fontSize || 10
              } }
            >
              { header.title }
            </th>
          )
        }
      )
    }
  }

  const renderArrayCell = (id, value) => (
    <td
      key={id}
      path={ id }
      style={ {
        padding: 4,
        fontSize: fontSize || 10
      } }
    >
      { value.join(', ') }
    </td>
  )

  const renderDeleteActionCell = (id, obj) => (
    <td
      key={ id }
      path={ id }
      onClick={ (event, data) => { event.stopPropagation(); handleOnDelete(obj) } }
      style={ {
        padding: 4,
        fontSize: 12,
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center'
      } }
    >
      <div className='eliminateButtonInTable'>Eliminar</div>
    </td>
  )

  const renderDownloadActionCell = (id, obj) => (
    <td
      key={ id }
      path={ id }
      onClick={ (event, data) => { event.stopPropagation(); handleDownloadPDF(obj) } }
      style={ {
        padding: 4,
        fontSize: 12,
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center',
        textDecorationLine: 'underline'
      } }
    >
      <div className='downloadButtonInTable'>Descargar</div>
    </td>
  )

  const renderLocationActionCell = (id, obj) => (
    <td
      key={ id }
      path={ id }
      onClick={ (event, data) => { event.stopPropagation(); handleOnMap(obj) } }
      style={ { padding: 4,
        fontSize: 12,
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center'
      } }
    >
      <div className='linkButtonInTable'>Mapa</div>
    </td>
  )

  const renderTextCell = (id, style, value) => (
    <td
    key={ id }
    path={ id }
    style={ {
      padding: 4,
      fontSize: fontSize || 10,
      ...style
    } }
    >
      { truncate ? value.length > 50 ? `${value.substring(0,50)}... `: value : value }
    </td>
  )

  const renderComponentCell = (id, value) => (
    <td
      key={ id }
      path={ id }
      style={ {
        padding: 4,
        fontSize: fontSize || 10
      } }
    >
      { value }
    </td>
  )

  const renderNullCell = id => (
    <td
      key={ id }
      path={ id }
      style={ {
        padding: 4,
        fontSize: fontSize || 10
      } }
    >
      Sin asignar
    </td>
  )

  const isRegularText = value => (
    value != null               &&
    value !== undefined         &&
    value !== ''                &&
    value !== 'null'            &&
    value !== '[object Object]'
  )

  const renderRowCells = (obj) => {
    if (!obj) return
    let id = 0
    return headers.map(
      header => {
        let auxPathData = _.get(obj, header.path, 'Sin asignar')
        id++
        if (Array.isArray(auxPathData) )
          return renderArrayCell(id, auxPathData)
        if (header.path === 'Eliminar')
          return renderDeleteActionCell(id, obj)
        if (header.path === 'descargarPDF')
          return renderDownloadActionCell(id, obj)
        if (header.path === 'Ubicacion')
          return renderLocationActionCell(id, obj)
        if (isRegularText(auxPathData)) {
          return renderTextCell(id, header.style, auxPathData)
        }
        if (React.isValidElement(auxPathData))
          return renderComponentCell(id, auxPathData)
        else
          return renderNullCell(id)
      })
  }

  const renderBodyRows = () => {
    const arrayToRender = filterData ? filterData : data
    let id = 0
    return arrayToRender.map(
      (row,i) => {
        const handleStyle = () => {
          if (row.indicator) {
            return 'styled-row'
          }
          return 'normal-row'
        }
        id++
        return (
          <tr
            key={ id }
            path={ id }
            onClick={ () => handleOnClickRow(row,i) }
            className={ handleStyle() }
          >
            { renderRowCells(row) }
          </tr>
        )
      }
    )
  }

  function handleOnChangeText(text) {
    const newData = filterArrayByText(data, text, searchBy)
    setFilterData(newData)
    setTextFilter(text)
  }

  function parseArrayToText(array) {
    let text = ''
    array.map(o => text += ` ${o}, `)
    if (text.length > 0) {
      text = text.slice(0, -1)
    }
    return text
  }

  function showSearch() {
    if (search) {
      return (
        <div style={{
          width: '100%',
          marginBottom: 5,
          visibility: search ? '' : 'hidden'
        }}
        >
          <Input
            fluid
            size='mini'
            icon='search'
            labelPosition='right'
            value={ textFilter }
            placeholder={ `Buscar por ${parseArrayToText(headerSearchBy)} ...` }
            onChange={ (e, { value }) => handleOnChangeText(value) }
          />
        </div>)
    }
    return (<></>)
  }

  return (
    <div
      style={ {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginBottom: 10
      } }
    >
      { showSearch() }
      <Table striped hover responsive bordered>
        <thead>
          <tr>
            { renderHeaders() }
          </tr>
        </thead>
        <tbody>
          { renderBodyRows() }
        </tbody>
      </Table>
    </div>
  )
}

export { StyledTable }
