import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { AuthProvider } from './authContext'
import { getGeneralInfo, getImageFromServer } from '../services/ergonApi'
import { Swal1, Toast } from '../utils/common'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components/CustomDimmer'
import { ModalToElimate } from '../components/ModalToElimate'
import { create, destroy, getById, getTanks, update } from '../services/vehicleCrud'

const VehicleContext = createContext()

const VehicleProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)

  const [deleting, setDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)

  const [vehicles, setVehicles]     = useState([])
  const [showModal, setShowModal]   = useState(false)
  const [isCreating, setIsCreating] = useState(true)
  const [webfleetVehicles, setWebfleetVehicles]   = useState([])
  const [shipmentCompanies, setShipmentCompanies] = useState([])
  const [webfleetVehiclesToDropdown, setWebfleetVehiclesToDropdown] = useState([])
  const [tanksOne, setTanksOne] = useState([])
  const [tanksTwo, setTanksTwo] = useState([])

  const [id, setId]       = useState('')
  const [state, setState] = useState('Disponible')
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [color, setColor] = useState('')
  const [notes, setNotes] = useState('')
  const [image, setImage] = useState(null)
  const [plates, setPlates]     = useState('')
  const [plantId, setPlantId]   = useState('')
  const [imageUid, setImageUid] = useState('')
  const [economico, setEconomico] = useState('')
  const [objectuid, setObjectuid] = useState('')
  const [isWithoutGps, setIsWithoutGps]   = useState(false)
  const [tankOneId, setTankOneId] = useState('')
  const [tankTwoId, setTankTwoId] = useState('')
  const [shipmentCompany, setShipmentCompany] = useState('')
  const [currentPlantName, setCurrentPlantName] = useState('')
  const [isInternalTransfer, setIsInternalTransfer] = useState(false)

  useEffect(() => {
    if (credentials) loadResources()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const showSuccessMessage = ({ data: { message } }) => (
    Toast.fire({ title: message })
  )
  const showErrorMessage   = ({ data: { message } }) => {
    setIsLoadingAction(false)
    Swal1.fire({ title: message })
  }

  const loadVehicles = async () => (
    await getGeneralInfo(credentials.token, '/generalUnit')
  )
  const loadShipmentCompanies = async () => (
    await getGeneralInfo(credentials.token, '/generalUnit/shipmentCompanies')
  )
  const loadWebfleetVehicles = async () => (
    await getGeneralInfo(credentials.token, '/generalUnit/webfleet')
  )

  const toDropdown = data => data.map(v => ({
    key:   v.key,
    text:  v.text,
    value: v.value
  }))

  const loadResources = async () => {
    setVehicles(await loadVehicles())
    const response = await loadWebfleetVehicles()
    const tanks = await getTanks(credentials.token)
    setIsLoading(false)
    setWebfleetVehiclesToDropdown(toDropdown(response))
    setWebfleetVehicles(response)
    setShipmentCompanies(await loadShipmentCompanies())
    setTanksOne(tanks)
    setTanksTwo(tanks)
  }

  const convertImage = async ({ imagenUid }) => {
    const img = await getImageFromServer(credentials.token, imagenUid)
    setImage(`data:image/*;base64, ${img}`)
  }

  const onSelectVehicle = async ({ id }) => {
    setIsLoadingImage(true)
    setIsCreating(false)
    setImage(null)
    const vehicle = await getById(credentials.token, id)
    setState(vehicle.estadoNombre)
    setId(vehicle.id)
    setObjectuid(vehicle.objectuid)
    setEconomico(vehicle.economico)
    setIsWithoutGps(vehicle.sinGps)
    setPlantId(vehicle.plantaId)
    setCurrentPlantName(vehicle.plantaActualNombre)
    setPlates(vehicle.placas)
    setShipmentCompany(vehicle.transportistaId)
    setBrand(vehicle.marca)
    setModel(vehicle.modelo)
    setColor(vehicle.color)
    setIsInternalTransfer(vehicle.traspasos)
    setTankOneId(vehicle.tanqueIdUno)
    setTankTwoId(vehicle.tanqueIdDos)
    setNotes(vehicle.notas)
    setImageUid(vehicle.imagenUid)
    if (vehicle.imagenUid) await convertImage(vehicle)
    setIsLoadingImage(false)
  }

  const findTankOneByPlates = tankOnePlates => (
    tanksOne.find(t => t.text === tankOnePlates)
  )

  const findTankOne = () => (
    tanksOne.find(t => t.key === tankOneId)
  )

  const findTankTwo = () => (
    tanksTwo.find(t => t.key === tankTwoId)
  )

  const findVehicle = key => {
    const vehicle = findWebfleetVehicleFullObject(key)
    return vehicles.find(v => v.economico === vehicle.text)
  }

  const findWebfleetVehicleFullObject = key => (
    webfleetVehicles.find(w => w.key === key)
  )

  const assignWebfleetValues = vehicle => {
    clear()
    setEconomico(vehicle.text)
    setObjectuid(vehicle.key)
    setPlates(vehicle.plates)
    const tank = findTankOneByPlates(vehicle.platesTankOne)
    setTankOneId(tank?.key || '')
  }

  const onSelectWebfleetVehicle = key => {
    const vehicle = findVehicle(key)
    if (vehicle) {
      setEconomico(vehicle.economico)
      setObjectuid(key)
      return onSelectVehicle(vehicle)
    }
    assignWebfleetValues(findWebfleetVehicleFullObject(key))
  }

  const clear = () => {
    setIsCreating(true)
    setId('')
    setState('Disponible')
    setPlantId('')
    setCurrentPlantName('')
    setBrand('')
    setModel('')
    setColor('')
    setNotes('')
    setImage('')
    setPlates('')
    setImageUid('')
    setEconomico('')
    setObjectuid('')
    setShipmentCompany('')
    setTankOneId('')
    setTankTwoId('')
    setIsWithoutGps(false)
    setIsInternalTransfer(false)
  }

  const atEndAction = async response => {
    setVehicles(await loadVehicles())
    showSuccessMessage(response)
    clear()
    setIsLoadingAction(false)
  }

  const hasError = ({ data }) => data.errorCode || data.status > 400

  const isVehicleWithoutGps  = () => economico && isWithoutGps
  const isVehicleWithGps     = () => objectuid && !isWithoutGps
  const hasSpecifiedUnitType = () => isVehicleWithGps() || isVehicleWithoutGps()

  const hasRequiredParams = () => (
    plates                 &&
    shipmentCompany        &&
    plantId                &&
    tankOneId              &&
    hasSpecifiedUnitType()
    )

  const messingParams = () => Swal1.fire({ title: 'Falta información' })

  const handleResponse = response => {
    if (hasError(response)) return showErrorMessage(response)
    atEndAction(response)
  }

  const buildParams = () => ({
    economico: economico,
    placas: plates,
    objectuid: objectuid,
    marca: brand,
    modelo: model,
    color: color,
    transportista: shipmentCompany,
    traspasos: isInternalTransfer,
    sinGps: isWithoutGps,
    notas: notes,
    imagen: image,
    imagenUid: imageUid,
    plantaId: plantId,
    tanqueIdUno: tankOneId,
    tanqueIdDos: tankTwoId
  })

  const saveVehicle = async () => {
    if (!hasRequiredParams()) return messingParams()
    setIsLoadingAction(true)
    if(isCreating) {
      return handleResponse(await create(credentials.token, buildParams()))
    }
    handleResponse(await update(credentials.token, id, buildParams()))
  }

  const handleDeletingConfirmation = async () => {
    setDeleting(true)
    handleResponse(await destroy(credentials.token, id))
    setShowModal(false)
    setDeleting(false)
  }

  const context = {
    vehicles,
    webfleetVehicles,
    shipmentCompanies,
    image,
    economico,
    objectuid,
    plates,
    shipmentCompany,
    brand,
    model,
    color,
    isInternalTransfer,
    notes,
    state,
    isWithoutGps,
    currentPlantName,
    plantId,
    tankOneId,
    tankTwoId,
    tanksOne,
    tanksTwo,

    setVehicles,
    setImage,
    setEconomico,
    setPlates,
    setShipmentCompany,
    setBrand,
    setModel,
    setColor,
    setIsInternalTransfer,
    setNotes,
    setState,
    setObjectuid,
    setIsWithoutGps,
    setIsLoading,
    setShowModal,
    setPlantId,
    setTankOneId,
    setTankTwoId,

    toDropdown,
    findTankOne,
    findTankTwo,
    isCreating,
    isLoading,
    isLoadingAction,
    isLoadingImage,
    saveVehicle,
    clear,
    onSelectVehicle,
    onSelectWebfleetVehicle,
    webfleetVehiclesToDropdown
  }

  return (
    <AuthProvider>
      <VehicleContext.Provider value={context}>
        <ModalToElimate
          title="Confirmación para eliminar unidad"
          textContent="Una vez eliminada la información no se podrá recuperar. ¿Está seguro que desea eliminar?"
          showModal={showModal}
          deleting={deleting}
          handleOnClickCancel={() => setShowModal(false)}
          handleOnClickConfirm={handleDeletingConfirmation}
        />
        <CustomDimmer visible={isLoading} />
        { children }
      </VehicleContext.Provider>
    </AuthProvider>
  )
}

const useVehicleContext = () => useContext(VehicleContext)

export { VehicleProvider, useVehicleContext }
