import React from 'react'
import { Checkbox, Input } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../../../contexts/AuthorizedFreightsContext'

const UploadPDFButton = () => {
  const context = useAuthorizedFreightsContext()

  const inputHandler = e => context.preparePDFBase64(e.target.files[0])

  return (
    <div className='pdf-button'>
      <label htmlFor='c-pdf'>Seleccionar</label>
      <Input
        id='c-pdf'
        type='file'
        onChange={ inputHandler }
        accept='application/pdf'
      />
      <div className='checkbox'>
        <Checkbox
          className='value'
          checked={ context.havePdf }
        />
      </div>
    </div>
  )
}

export { UploadPDFButton }
