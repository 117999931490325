import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const StatusDropdown = () => {
  const context = useOrderAssignationContext()

  const statusHandler = createInputValueHandler(context.setSelectedStatus)

  return (
    <div className="form-field">
      <label>Estado:</label>
      <Dropdown
        placeholder="Seleccione un estado"
        options={ context.statusList }
        value={ context.selectedStatus }
        onChange={ statusHandler }
        fluid
        search
        selection
      />
    </div>
  )
}

export { StatusDropdown }
