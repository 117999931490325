import React from 'react'
import { Button } from 'semantic-ui-react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'

const FormActions = () => {
  const context = useParkedUnitsContext()

  const canPress = () => !context.isWorking && context.unitInfo.economico

  return (
    <footer>
      <Button
        color="grey"
        onClick={ context.sendChecklistTask }
        loading={ context.isWorking }
        disabled={ !canPress() }
      >
        Enviar tarea de checklist
      </Button>
      <Button
        color="grey"
        onClick={ context.clean }
        loading={ context.isWorking }
        disabled={ !canPress() }
      >
        Cancelar cambios
      </Button>
      <Button
        primary
        onClick={ context.save }
        loading={ context.isWorking }
        disabled={ !canPress() }
      >
        Guardar
      </Button>
    </footer>
  )
}

export { FormActions }
