import axios from 'axios'
import { showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const buildUrl = (endpoint, id = '') => (
  `${resources.urls.ergonApi}/noAvailabilityEvidence/${endpoint}/${id}`
)

const validateSession = async (authParams) => {
  const { data } = await axios.post(
    buildUrl('validateSession'),
    authParams
  )
  return data
}

const unitData = async (authParams) => {
  const { data } = await axios.post(
    buildUrl(''),
    authParams
  )
  return data
}

const maintenanceTypes = async (authParams) => {
  const { data } = await axios.post(
    buildUrl('maintenanceTypes'),
    authParams
  )
  return data
}

const save = async (authParams) => {
  const { data } = await axios.post(
    buildUrl('save'),
    authParams
  )
  return data
}

const reactivate = async (authParams) => {
  const { data } = await axios.post(
    buildUrl('reactivate'),
    authParams
  )
  return data
}

const downloadPDF = async (params, id, unitName) => {
  const url = buildUrl('download')
  const filename = `evidencias_de_no_disponibilidad_${unitName}_${id}.pdf`
  const options = { responseType: 'arraybuffer' }
  try {
    const response  = await axios.post(url, params, options)
    const blob      = new Blob([response.data])
    const windowUrl = window.URL.createObjectURL(blob)
    const link      = document.createElement('a')
    link.href = windowUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }
  catch (error) {
    const message = 'Ocurrio un error al intentar descargar la información,' +
      ' intente de nuevo'
    showErrorAlert(message)
  }
}

export {
  validateSession,
  unitData,
  maintenanceTypes,
  save,
  reactivate,
  downloadPDF
}
