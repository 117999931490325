import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { useChangeUnitsContext } from '../../contexts/ChangeUnitsContext'
import { filterdata } from '../../utils/common'

const headers = [
  { path: 'transportistaNombre', title: 'Transportista'   },
  { path: 'economico',           title: 'Económico'       },
  { path: 'productoNombre',      title: 'Último producto' },
  { path: 'estadoUnidadNombre',  title: 'Estatus'         }
]

const toSearch = headers.map(h => h.path)

const Table = () => {
  const context = useChangeUnitsContext()
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false)

  const handleOrdingData = (data, e) => {
    context.setUnits(filterdata(data, e, reloadDataOfTable))
    setReloadDataOfTable(!reloadDataOfTable)
  }

  const addIndicator = () => context.units.map(u => ({
    ...u,
    indicator: isReactivation(u)
  }))

  const isReactivation = unit => unit.estadoUnidadNombre === 'Reactivación'

  return (
    <div className='table'>
      <StyledTable
        search
        data={ addIndicator() }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickRow={ context.onSelectUnit }
        handleOnClickHeader={ e => handleOrdingData(context.units, e) }
      />
    </div>
  )
}

export { Table }
