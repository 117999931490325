import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'
import { hoursFormat } from '../../../../helpers/date'

const ParkingTime = () => {
  const context = useOrdersInTransitStayContext()

  const getValue = () => (
    hoursFormat(context.stayInfo.tiempoPatio || 0)
  )

  return (
    <div className="form-field">
      <label>Espera en patio:</label>
      <label>{ getValue() }</label>
    </div>
  )
}

export { ParkingTime }
