import React from 'react'
import { useOrderContext } from '../../../contexts/OrderContext'

const QuantityLabel = () => {
  const context = useOrderContext()

  const getTotalQuantity = () => {
    if (!context.detalles.length) return 0
    return context.detalles
      .map(d => parseFloat(d.cantidad))
      .reduce((a, b) => a + b)
  }

  return (
    <h6>Total Cantidad: { getTotalQuantity() }</h6>
  )
}

export { QuantityLabel }
