import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderContext } from '../../../contexts/OrderContext'
import { createInputValueHandler } from '../../../helpers/input'
import { isSuperadmin } from '../../../helpers/sessionCredentials'

const OrderDateInput = () => {
  const context = useOrderContext()

  const orderDateHandler = createInputValueHandler(context.setFechaPedido)

  const canEdit = () => !context.isEditing || isSuperadmin()

  return (
    <div className="form-field">
      <label>Fecha de registro:</label>
      <Input
        disabled={ !canEdit() }
        type="datetime-local"
        value={ context.fechaPedido }
        onChange={ orderDateHandler }
      />
    </div>
  )
}

export { OrderDateInput }
