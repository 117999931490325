import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { getGeneralInfo } from '../services/ergonApi'
import { useAuthContext } from '../contexts/authContext'

const PlantsDropdown = ({
  value = '',
  defaultOption = false,
  setSelectedPlant = () => {},
  setAlias = () => {},
  clearable,
  disabled,
  multiple
}) => {
  const { credentials } = useAuthContext()

  const [plants, setPlants] = useState([])
  const [plant,  setPlant]  = useState('')

  useEffect(() => {
    if (credentials) loadPlants()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => setPlant(value), [value])

  const hasOnlyOnePlant = (data = '') => {
    if (!data) return plants.length === 1
    return data.length === 1
  }

  const handleDefaultOptions = plants => {
    const withOption = [{ key: 0, text: 'Todas', value: 0 }, ...plants]
    setPlants(withOption)
  }

  const handleResponse = data => {
    if (hasOnlyOnePlant(data)) {
      setSelectedPlant(data[0].key)
      setAlias(data[0].text)
      setPlants(data)
      return setPlant(data[0].key)
    }
    if (defaultOption) return handleDefaultOptions(data)
    setPlants(data)
  }

  const handleError = error => console.error('LOADPLANTS ERROR :', error)

  const loadPlants = () => {
    getGeneralInfo(credentials.token, '/plantsByUser')
      .then(handleResponse)
      .catch(handleError)
  }

  const findAlias = key => (
    plants.find(p => p.key === key)?.text || ''
  )

  const onChangePlant = (_, { value }) => {
    setPlant(value)
    setSelectedPlant(value)
    setAlias(findAlias(value))
  }

  const getValue = () => (value || plant) || 0

  return (
    <Dropdown
      fluid
      search
      selection
      multiple={ multiple }
      clearable={ clearable }
      value={ getValue() }
      options={ plants }
      disabled={ hasOnlyOnePlant() || disabled }
      onChange={ onChangePlant }
      placeholder='Seleccione una planta'
      id='c-plants-dropdown'
    />
  )
}

export { PlantsDropdown }
