import React from 'react'
import { PlantsDropdown } from '../PlantsDropdown'
import { useScheduleContext } from '../../contexts/ScheduleContext'

const PlantDropdown = () => {
  const context = useScheduleContext()

  return (
    <div className='plant-container'>
      <PlantsDropdown
        value={ context.selectedPlant }
        setSelectedPlant={ context.setSelectedPlant }
      />
    </div>
  )
}

export { PlantDropdown }
