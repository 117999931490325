import React from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import { ChangeBaseActions } from './ChangeBaseActions'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'

const ChangeBaseForm = () => {
  const context = useChangeUnitsContext()

  const handleChangePlant = (_, { value }) => context.setCurrentPlantId(value)

  return (
    <Form
      size='mini'
      className='change-base-form'
    >
      {
        !context.selectedUnit.esPrestada ? (
          <div className='dropdown-container' >
            <label>Cambiar base:</label>
            <Dropdown
              fluid
              search
              className='dropdown'
              placeholder='Seleccione una planta'
              options={ context.plantsToLend }
              onChange={ handleChangePlant }
            />
          </div>
        ) : (<></>)
      }
      <ChangeBaseActions />
    </Form>
  )
}

export { ChangeBaseForm }
