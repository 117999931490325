import React from 'react'
import { Input } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'

const RequestedDateInput = () => {
  const context = useOrderAssignationContext()

  return (
    <div className="form-field">
      <label>Fecha requerida:</label>
      <Input
        placeholder="Fecha/hora requerida por el cliente"
        disabled
        value={ context.fechaRequerida }
      />
    </div>
  )
}

export { RequestedDateInput }
