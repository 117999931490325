import React, { useState } from 'react'
import { StyledTable } from '../StyledTable'
import { filterdata } from '../../utils/common'
import {
  useAssignmentParticipationContext
} from '../../contexts/AssignmentParticipationContext'

const headers = [
  { path: 'transportistaAlias',   title: 'Transportista'        },
  { path: 'unidades',             title: 'Unidades'             },
  { path: 'asignaciones',         title: 'Asignaciones'         },
  { path: 'kpi',                  title: 'KPI'                  },
  { path: 'calculoParticipacion', title: '(%) de participación' }
]

const toSearch = headers.map(h => h.path)
const headerSearchBy = headers.map(h => h.title)

const Table = () => {
  const context = useAssignmentParticipationContext()
  const [reloadData, setReloadData] = useState(false)

  const handleSort = e => {
    context.setAssignmentData(filterdata(context.assignmentData, e, reloadData))
    setReloadData(!reloadData)
  }

  return (
    <div className='table'>
      <StyledTable
        search
        headerSearchBy={ headerSearchBy }
        data={ context.assignmentData }
        headers={ headers }
        searchBy={ toSearch }
        handleOnClickHeader={ handleSort }
      />
    </div>
  )
}

export { Table }
