import React from 'react'
import {
  Input,
  Dropdown,
  TextArea,
  Form
} from 'semantic-ui-react'
import { useUsersContext } from '../../contexts/UsersContext'
import { ImageContainer } from '../ImageContainer'
import { createFileInputHandler, createInputValueHandler } from '../../helpers/input'
import { LevelOptions } from './LevelOptions'
import { ProfileOptions } from './ProfileOptions'
import { FormActions } from './FormActions'
import { PlantsDropdown } from '../PlantsDropdown'

const UsersForm = () => {
  const context = useUsersContext()

  const nameHandler     = createInputValueHandler(context.setName)
  const emailHandler    = createInputValueHandler(context.setEmail)
  const passwordHandler = createInputValueHandler(context.setPassword)
  const phoneHandler    = createInputValueHandler(context.setPhone)
  const notesHandler    = createInputValueHandler(context.setNotes)
  const imageHandler    = createFileInputHandler(context.setImage)
  const permissionsHandler = createInputValueHandler(context.setPermissions)

  return (
    <Form size='mini' className='form'>
      <div className='image-section'>
        <ImageContainer
          source={ context.image }
          onChangeImage={ imageHandler }
          isLoading={ context.isLoadingImage }
        />
      </div>
      <div className='content'>
        <div>
          <label> Nombre completo: </label>
          <Input
            value={ context.name }
            onChange={ nameHandler }
          />
        </div>
        <div>
          <label> Correo: </label>
          <Input
            type='email'
            value={ context.email }
            onChange={ emailHandler }
          />
        </div>
        <div>
          <label> Contraseña: </label>
          <Input
            type='password'
            value={ context.password }
            onChange={ passwordHandler }
          />
        </div>
        <div>
          <label> Celular: </label>
          <Input
            value={ context.phone }
            onChange={ phoneHandler }
          />
        </div>
        <LevelOptions />
        <ProfileOptions />
        {
          context.isPlantLevel() ?
            <div>
              <label className='multi-line-input'> Plantas: </label>
              <PlantsDropdown
                multiple
                value={ context.plantIds }
                setSelectedPlant={ context.setPlantIds }
              />
            </div>
          :
            (<></>)
        }
        {
          context.isUserProfile() ?
            <div>
              <label> Permisos: </label>
              <Dropdown
                fluid
                multiple
                search
                selection
                placeholder='Seleccione permisos'
                value={ context.permissions }
                options={ context.permissionsOptions }
                onChange={ permissionsHandler }
              />
            </div>
          :
            (<></>)
        }
        <div>
          <label className='multi-line-input'> Notas: </label>
          <div className='input'>
            <TextArea
              value={ context.notes }
              onChange={ notesHandler }
            />
          </div>
        </div>
        <FormActions />
      </div>
    </Form>
  )
}

export { UsersForm }
