import React from 'react'
import { Modal } from 'react-bootstrap'
import { useOrderAssignationContext } from '../../contexts/OrderAssignationContext'
import { Button } from 'semantic-ui-react'

const UnitStateModal = () => {
  const context = useOrderAssignationContext()

  const close = () => {
    context.setShowUnitStateModal(false)
  }

  return (
    <Modal
      backdrop="static"
      show={ context.showUnitStateModal }
    >
      <Modal.Header>
        <Modal.Title>Unidad asignada</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>La unidad que se seleccionó, se encuentra en este momento "<b>Asignada</b>".</p>
        <p>Si se asigna el pedido a esta unidad, quedará en estrado "<b>Asignado en espera</b>"</p>
        <p>Una vez que la unidad libere el pedido que actualmente está entregando, este pedido pasará a "<b>Confirmado</b>" y continuará el proceso con normalidad, con lo siguiente:</p>
        <ul>
          <li>La generación de tareas <b>Navixy</b>.</li>
          <li>La asignación de la unidad a este pedido.</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="blue"
          onClick={ close }
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { UnitStateModal }
