import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'

const TankPlatesTwo = () => {
  const context = useUploadEvidenceContext()

  return (
    <div className="form-field">
      <label>Placas Tanque 2:</label>
      <label className='content'>
        { context.tankPlatesTwo || 'Sin asignar' }
      </label>
    </div>
  )
}

export { TankPlatesTwo }
