import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Button } from 'semantic-ui-react'

const ReactivateUnitButton = () => {
  const context = useUploadEvidenceContext()

  const handleOnClick = () => context.setShowConfirmReactivate(true)

  return (
    <Button
      className='reactivate-button'
      loading={ context.isWorking }
      onClick={ handleOnClick }
    >
      Reactivar unidad
    </Button>
  )
}

export { ReactivateUnitButton }
