import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  useAuthorizedFreightsContext
} from '../../contexts/AuthorizedFreightsContext'

const IsInternalCheckbox = () => {
  const context = useAuthorizedFreightsContext()

  const cleanInvolvedFields = () => {
    context.setClientId('')
    context.setDestinationId('')
  }

  const handleCheck = (_, { checked }) => {
    context.setIsInternal(checked)
    cleanInvolvedFields()
  }

  return (
    <div className='is-internal'>
      <label>Flete interno:</label>
      <Checkbox
        checked={ context.isInternal }
        onChange={ handleCheck }
      />
    </div>
  )
}

export { IsInternalCheckbox }
