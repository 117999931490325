import { GET_AUTHENTICATION, LOGOUT_AND_CLEAN_DATA } from '../actions/types'

const INITAL_STATE = { credentials: null }

export default function (state = INITAL_STATE, action) {
  switch(action.type) {
    case GET_AUTHENTICATION:
      return { ...state, credentials: action.payload }
    case LOGOUT_AND_CLEAN_DATA:
      return { ...state, credentials: action.payload }
    default:
      return state
  }
}
