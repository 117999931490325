import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { Dropdown } from 'semantic-ui-react'

const MeasureUnit = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setMeasureUnitId)

  return (
    <div className='form-field'>
      <label>Unidad de medida:</label>
      <Dropdown
        search
        selection
        placeholder='Selecciona una unidad de medida'
        value={ context.measureUnitId }
        options={ context.measureUnitData }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { MeasureUnit }
