import React from 'react'
import { Button } from 'semantic-ui-react'
import { usePlantsContext } from '../../../../contexts/PlantsContext'

const AddYardButton = () => {
  const context = usePlantsContext()
  return (
    <Button
      primary
      onClick={ context.addYard }
    >
      Añadir
    </Button>
  )
}

export { AddYardButton }
