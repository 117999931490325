import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { TextArea } from 'semantic-ui-react'

const Notes = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setNotes)

  return (
    <div className='form-field'>
      <label>Notas:</label>
      <TextArea
        type='text'
        value={ context.notes || '' }
        onChange={ InputHandler }
      />
    </div>
  )
}

export { Notes }
