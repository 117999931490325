import React from 'react'
import { Radio } from 'semantic-ui-react'
import { usePlantsContext } from '../../../contexts/PlantsContext'
import { createInputValueHandler } from '../../../helpers/input'

const StateRadio = () => {
  const context = usePlantsContext()
  const stateHandler = createInputValueHandler(context.setState)

  return (
    <div className='field'>
      <label>Estado:</label>
      <div className='radio-group'>
        <Radio
          value={ 1 }
          checked={ context.state === 1 }
          label='Activo'
          onChange={ stateHandler }
        />
        <Radio
          value={ 2 }
          checked={ context.state === 2 }
          label='Inactivo'
          onChange={ stateHandler }
        />
      </div>
    </div>
  )
}

export { StateRadio }
