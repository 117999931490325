import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const OrderNotesInput = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.notas

  return (
    <div className='notas-pedido'>
      <div>Notas del pedido:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { OrderNotesInput }
