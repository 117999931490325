import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useVehicleContext } from '../../../contexts/vehicleContext'
import { createInputValueHandler } from '../../../helpers/input'

const TanksPlates = () => {
  const context = useVehicleContext()

  const tankOneHandler = createInputValueHandler(context.setTankOneId)
  const tankTwoHandler = createInputValueHandler(context.setTankTwoId)

  const differentToTankOne = tank => tank.key !== context.tankOneId
  const differentToTankTwo = tank => tank.key !== context.tankTwoId

  const filterNoSelected = (tanks, evaluator) => {
    const noSelectedTanks = tanks.filter(evaluator)
    return context.toDropdown(noSelectedTanks)
  }

  return (
    <div className='tanks-dropdowns'>
      <Dropdown
        selection
        search
        value={ context.tankOneId }
        options={ filterNoSelected(context.tanksOne, differentToTankTwo) }
        onChange={ tankOneHandler }
        placeholder='Tanque 1'
      />
      <Dropdown
        selection
        search
        clearable
        value={ context.tankTwoId }
        options={ filterNoSelected(context.tanksTwo, differentToTankOne) }
        onChange={ tankTwoHandler }
        placeholder='Tanque 2'
      />
    </div>
  )
}

export { TanksPlates }
