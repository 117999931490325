import React from 'react'
import { Tolls } from './right_container/Tolls'
import { AuthorizedFreight } from './right_container/AuthorizedFreight'
import { Distance } from './right_container/Distance'
import { AuthorizedDate } from './right_container/AuthorizedDate'
import { ExpirationDate } from './right_container/ExpirationDate'

const RightContainer = () => {
  return (
    <div className='right-container'>
      <Tolls />
      <AuthorizedFreight />
      <Distance />
      <AuthorizedDate />
      <ExpirationDate />
    </div>
  )
}

export { RightContainer }
