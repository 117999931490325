import React from 'react'
import { Input } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const RealWeightInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()

  const getRealWeight = () => {
    const calc = contextModal.weight - contextModal.taraWeight
    if (isNaN(calc)) return ''
    return parseFloat(calc.toFixed(2))
  }

  return (
    <>
      <div>Peso Neto:</div>
      <Input disabled value={ getRealWeight() } />
    </>
  )
}

export { RealWeightInput }
